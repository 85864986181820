<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h1 mat-dialog-title>{{pageTitle}}</h1>
<h3>{{ alertMessage }}</h3>
<h3>{{ errorMessage }}</h3>

<!--<mat-card>
    <mat-card-title>Add a new project</mat-card-title>-->

<form [formGroup]="y4projectForm" *ngIf="showform">
    <input type="hidden" formControlName="projSubjectgroup" />
    <input type="hidden" formControlName="projOwnerSeqNum" />
    <input type="hidden" formControlName="projContactgroupcentre" />
    <input type="hidden" formControlName="projSubmittedOn" />
    <input type="hidden" formControlName="projApprovedOn" />
    <input type="hidden" formControlName="projPublishedOn" />
    <input type="hidden" formControlName="projConfidentiality" />
    <input type="hidden" formControlName="projLoggedInAs" />
    <div>
        <div class="flex-container">
            <div class="half">
                <mat-form-field class="example-full-width">
                    <input type="text" placeholder="Enter owner name" aria-label="Enter Owner" matInput
                        [matAutocomplete]="autoOwner" formControlName="projOwner">
                    <mat-hint>Enter text to find owner by crsid or last name</mat-hint>
                </mat-form-field>
                <mat-autocomplete #autoOwner="matAutocomplete"
                    (optionSelected)="optionOwnerClicked($event, $event.option.value)" [displayWith]="displayOwnerFn">
                    <mat-option *ngFor="let owner of filteredOwners$ | async" [value]="owner">
                        <div>
                            {{ owner.display_name()+" ("+owner.stfCrsid+")" }}
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>

        <div class="flex-container">
            <div class="half">
                <campl-ngx-input-text id="emailContact" [label]="'Email contact'" type="text"
                    placeholder="Contact Email (leave blank if not by email)" formControlName="ownerEmail">
                </campl-ngx-input-text>
                <br />
            </div>
            <div class="half">
                <campl-ngx-input-text id="locationContact" [label]="'Office/lab location'" type="text"
                    placeholder="Contact Location (leave blank if none)" formControlName="ownerLocation">
                </campl-ngx-input-text>
                <br />
            </div>
        </div>

        <div class="flex-container">
            <div class="half">
                <campl-ngx-input-text id="projTitleId" [label]="'Project Title*'" type="text"
                    placeholder="Project Title (required)" formControlName="projTitle"
                    [validator]="y4projectForm.get('projTitle').validator"></campl-ngx-input-text>
                <br />
                <span *ngIf="y4projectForm.get('projTitle').errors?.minlength">
                    (Minimum length = 10)
                </span>
            </div>
        </div>
        <div class="flex-container">
            <div class="full">

                <ckeditor [editor]="ckEditor"
                    [config]="{ removePlugins: ['Image','ImageCaption','ImageStyle','ImageToolbar','ImageUpload','EasyImage','UploadAdapter','MediaEmbed'] }"
                    formControlName="projDescription" data="<p>Hello, world!</p>"></ckeditor>
                <!--<campl-ngx-input-textarea id="projDescriptionId" [label]="'Project Description'"
                    placeholder="Enter a summary of the project" formControlName="projDescription">
                </campl-ngx-input-textarea>-->
                <br />
                <span *ngIf="y4projectForm.get('projDescription').errors?.maxlength">
                    Your description is too long
                </span>
            </div>
        </div>

        <!-- This could be the cause of the incorrect course being added / updated?-->
        <!-- The input showing before ngIf being actioned? the way the -->
        <!-- <div *ngIf="!showTypeCourse; else hideTypeCourse">
            <input type="hidden" formControlName="projCourseOffering" />
            <select style="display:none" formControlName="projCourseOffering"><option>Test me</option></select>
            <input type="hidden" formControlName="projType" />
        </div>
        <ng-template #hideTypeCourse> -->
        <div class="flex-container">
            <div class="half">
                <campl-ngx-input-reg-select [hidden]="!showTypeCourse" id="projCourseOffering" [label]="'Course Year*'"
                    [options]="courses" formControlName="projCourseOffering" aria-label="Course Year"
                    [validator]="y4projectForm.get('projCourseOffering').validator">
                </campl-ngx-input-reg-select>
            </div>

            <div class="half">
                <campl-ngx-input-reg-select [hidden]="!showTypeCourse" id="projTypeId" aria-label="Project Type*"
                    [label]="'Project Type*'" [options]="types" formControlName="projType"
                    [validator]="y4projectForm.get('projType').validator">
                </campl-ngx-input-reg-select>
            </div>
        </div>
        <!--    </ng-template> -->
        <!-- 
        <div class="flex-container" *ngIf="showTypeCourse; else hideTypeCourse">
            <div class="half">
                <campl-ngx-input-reg-select id="projCourseOffering" [label]="'Course Year*'" [options]="courses"
                    formControlName="projCourseOffering" aria-label="Course Year"
                    [validator]="y4projectForm.get('projCourseOffering').validator">
                </campl-ngx-input-reg-select>
            </div>

            <div class="half">
                <campl-ngx-input-reg-select id="projTypeId" aria-label="Project Type*" [label]="'Project Type*'"
                    [options]="types" formControlName="projType" [validator]="y4projectForm.get('projType').validator">
                </campl-ngx-input-reg-select>
            </div>
        </div>
        <ng-template #hideTypeCourse>
            <input type="hidden" formControlName="projCourseOffering" />
            <input type="hidden" formControlName="projType" />
        </ng-template> -->

        <div class="flex-container">
            <div class="half">
                <campl-ngx-input-reg-select id="projSubjectgroupId" aria-label="Project Group"
                    [label]="'Project Group*'" [options]="subjectgroups" formControlName="projSubjectgroup"
                    [validator]="y4projectForm.get('projSubjectgroup').validator">
                </campl-ngx-input-reg-select>
            </div>
            <div class="half">

                <campl-ngx-input-reg-select id="projTopicId" aria-label="Topic" [label]="'Topic*'"
                    formControlName="projTopic" [options]="populateTopics()"
                    [validator]="y4projectForm.get('projTopic').validator">
                </campl-ngx-input-reg-select>
            </div>
        </div>


        <div class="flex-container">
            <div class="full">
                <campl-ngx-input-text id="projUrl" [label]="'Project URL'" placeholder="URL for further information"
                    formControlName="projUrl">
                </campl-ngx-input-text>
            </div>
        </div>

        <div class="flex-container">
            <div class="full">
                <div class="full">
                    <campl-ngx-input-textarea id="projIndustrialId" [label]="'External partner details'"
                        placeholder="Enter the External partner's contact details" formControlName="projIndustrial">
                    </campl-ngx-input-textarea>
                    <br />
                    <span *ngIf="y4projectForm.get('projIndustrial').errors?.maxlength">
                        Details for Industrial partner are too long
                    </span>
                </div>
            </div>
        </div>
        <div class="flex-container">
            <div class="half">
                <label for="projMeeting">Location, date and time of meeting to find out more about this project</label>
                <app-presentation-details id="projMeeting"
                    formControlName="projMeetingDetails"></app-presentation-details>
                <span class="highlight_errors" *ngIf="y4projectForm.get('projMeetingDetails').errors">
                    Both fields are required if entering meeting details
                </span>
            </div>
            <div class="half">
                <label for="projConfidentiality">A confidentiality agreement is required</label>
                <input id="projConfidentiality" type="checkbox" aria-label="Confidentiality required"
                    formControlName="projConfidentiality" matTooltip='Select to require an agreement' />
            </div>
        </div>
        <br>
        <div class="flex-container">
            <div class="half">
                <app-ea-selections formControlName="projEngineeringAreas"></app-ea-selections>
            </div>

            <div class="half">
                <app-crt-selections formControlName="projTopics"></app-crt-selections>
            </div>
        </div>
        <br />
        <!--end wrap in component - build list via autocomplete-->

        <div class="flex-container">
            <div class="half">
                <app-assistant-selections formControlName="projSupervisors"></app-assistant-selections>
            </div>
        </div>


        <!--    
        <label for="projPreallocatedId">Project Preallocated</label>
        <input id="projPreallocatedId" type="checkbox" formControlName="projPreallocated" />-->
        <button mat-raised-button color="primary" (click)="save()"
            [title]="y4projectForm.valid ? 'Save your entered data' : 'Disabled until the form data is valid'"
            [disabled]="!y4projectForm.valid" aria-label="Save project">
            Save
        </button>
        <button mat-raised-button color="primary" (click)="saveSubmit()"
            [title]="y4projectForm.valid ? 'Submit your project for approval' : 'Disabled until the form data is valid'"
            [disabled]="!y4projectForm.valid" aria-label="Save and submit project">
            Save and submit
        </button>
        <button mat-raised-button color="warn" [title]="'Cancel'" (click)="cancel()" aria-label="Cancel">
            Cancel
        </button>

        <!--<button type="button" (click)="populateTestData()">Test data</button>-->
    </div>
</form>

<!--
</mat-card>
-->
<!-- formGroup -->