import { Component, OnInit } from '@angular/core';
import { SgtopicService } from '../lib/services/sgtopic.service';
import { Sgtopic } from '../lib/models/sgtopic';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';

/**
 * This component lists the subjectgroups which exist in the database
 *
 * TODO: At the moment there isn't the ability to add a new project (subject) group topic
 *
 */
@Component({
  selector: 'app-subject-group-topic-list',
  templateUrl: './subject-group-topic-list.component.html',
  styleUrls: ['./subject-group-topic-list.component.css']
})
export class SubjectGroupTopicListComponent implements OnInit {

  // topicsList: Sgtopic[];

  topicsList$ = this.sgtopics.list;

  constructor(
    private sgtopics: SgtopicService) { }

  ngOnInit(): void {

  }

}
