<div class="campl-row campl-page-header">
  <div class="clearfix" CamplNgxWrap>
    <div class="campl-column12">
      <div class="campl-content-container">
        <p class="campl-page-title">
          {{ config.page_title || "Set config.page_title!"}}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="campl-row campl-page-header">
  <campl-ngx-nav [nav_menu$]="nav_menu$"></campl-ngx-nav>
</div>