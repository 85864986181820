

<h3 class="mat-subheading">Engineering areas:</h3>
The associated engineering areas
<mat-list>
    <mat-list-item *ngIf="selectedEAs?.length === 0">(None)</mat-list-item>
    <mat-list-item *ngFor="let ea of selectedEAs">
        <span>
            <button mat-icon-button aria-label="Button to remove engineering area"
                matTooltip="Remove engineering area">
                <mat-icon (click)="removeEA(ea.id)">delete</mat-icon>
            </button>
        </span>
        <span>
            <span matLine>{{ea.display_name()}}</span>
        </span>
    </mat-list-item>
</mat-list>
<mat-form-field class="example-full-width"  [formGroup]="eaForm">
    <input type="text" placeholder="Add engineering area" aria-label="Add engineering area" matInput
        [matAutocomplete]="autoEA" formControlName="eas">
    <mat-hint>Enter text to find engineering areas</mat-hint>
</mat-form-field>
<mat-autocomplete #autoEA="matAutocomplete" [displayWith]="clearOnSelectFn">
    <mat-option *ngFor="let ea of filteredEAs$ | async" [value]="selectedEAs">
        <div (click)="optionEAClicked($event, ea)">
            {{ ea.display_name() }}
        </div>
    </mat-option>
</mat-autocomplete>
