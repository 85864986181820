import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { Y4project } from '../lib/models/y4project';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Student } from '../lib/models/student';
import { Y4ProjectselectionService } from '../lib/services/y4-projectselection.service';
import { Y4ProjectService } from '../lib/services/y4-project.service';
import { map, share, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Y4projectselection } from '../lib/models/y4projectselection';
import { AuthService } from '../lib/services/auth.service';
import { Y4ProjectChoicesStatusDialogComponent } from '../y4-project-choices-status-dialog/y4-project-choices-status-dialog.component';

/**
 * The selection and browse modal used by the student
 *
 * Select or unselect their choice as well as view their list of choices
 *
 * This is opened when a student selects a project to view its details
 * As well as the project's details the dialog provides navigation
 * to other projects that match the students chosen group/topic criteria,
 * along with a further tab to view the current set of choices
 *
 * Inputs via {MAT_DILAOG_DATA}
 *
 * @input {student} - the student who is browsing
 * @input {project} - The project that was selected
 *
 * Before entering this dialog the calling component must have:
 *
 * Y4ProjectService.browseProject(project: Y4project) - set the browsing project
 */

@Component({
  selector: 'app-y4-project-select-dialog',
  templateUrl: './y4-project-select-dialog.component.html',
  styleUrls: ['./y4-project-select-dialog.component.css']
})
export class Y4ProjectSelectDialogComponent implements OnInit, OnDestroy {


  constructor(
    private selectionService: Y4ProjectselectionService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private projectsService: Y4ProjectService,
    public dialog: MatDialog,
    private _auth: AuthService) {

    this.student = new Student().deserialize(data.student);
    this.project = new Y4project().deserialize(data.project);
    this.projectsService.browseProject(this.project);

  }


  /**
   * Are our selections valid?
   */
  valid$ = this.selectionService.choicesStudentValid$;

  /**
   *
   */
  private activateStatusMonitor = false;
  private choiceToggled = false; // tracks whether any chnages have been made - to activate the statusMonitor

  /**
   * Set via DATA passed in dialog.open() call
   */
  student: Student;

  /**
   * Set via DATA passed in dialog.open() call
   */
  project: Y4project;

  /**
   * Holds our subscriptions - so we can unsubscribe
   */
  subscriptions = [];

  /**
   * IDs of projects that the user has already chosen
   */
  public choices$ = this.selectionService.choicesStudent$.pipe(shareReplay(1));

  /**
   * Observe the project that this use is currently browsing
   */
  public currentProject$ = this.projectsService.currentlyBrowsingProject$;

  /**
   * Retrieve the choice that the user may have for this project
   * (allows us to unchoose)
   */
  public choice$ = combineLatest([this.choices$, this.currentProject$]).pipe(
    map(([sels, curr]) => {
      // TODO remove the need for this side effect
      const choices = sels.filter(sel => sel.pslY4project.id === curr.id);
      return (choices.length > 0) ? choices[0] : null;
    })
  );

  /**
   * Returns whether the project is already a choice
   */
  public projectIsAChoice$ = this.choice$.pipe(
    map(choice => (choice) ? true : false)
  );

  /**
   * The list of projects that meet the filter criteria
   * The filter criteria was sent to the service by a parent
   * component eg y4browselist
   */
  public filteredProjects$ = this.projectsService.studentProjectAccordianBrowseList$;
  /*combineLatest([
    this.projectsService.studentProjectAccordianBrowseList$] //,
    //this.choices$] // however this does not trigger new retrieval of filtered projects
  ).pipe(
    map(([list]) => list)
  );*/

  /**
   * returns an observable to the next project or if there is no next project null
   */
  public nextProject$ = combineLatest([this.filteredProjects$, this.currentProject$]).pipe(
    map(([resData, currentProject]) => {
      const projectIndex = resData.findIndex(x => x.id === currentProject.id);
      return (projectIndex !== (resData.length - 1)) ?
        new Y4project().deserialize(resData[projectIndex + 1]) : null;
    }),
    shareReplay(1)
  );


  /**
   * returns an observable to the previous project or if there is no next project null
   */
  public previousProject$ = combineLatest([this.filteredProjects$, this.currentProject$]).pipe(
    map(([resData, currentProject]) => {
      const projectIndex = resData.findIndex(x => x.id === currentProject.id);
      return (projectIndex > 0) ? new Y4project().deserialize(resData[projectIndex - 1]) : null;
    }),
    shareReplay(1)
  );

  /**
   * Is our selection open? is so we can add/remove choices
   */
  public selectionOpen$ = this._auth.canSelectY4P$();
  /**
   * Subject to accept signal trigger to add choice
   */
  private addChoiceTriggerSubject = new Subject<number>();
  /**
   * Observable to watch for a add choice action
   */
  public addChoiceTrigger$ = this.addChoiceTriggerSubject.asObservable();

  /**
   * Subject to accept signal trigger remove choice
   */
  private removeChoiceTriggerSubject = new Subject<number>();
  /**
   * Observable to watch for remove choice action
   */
  public removeChoiceTrigger$ = this.removeChoiceTriggerSubject.asObservable();

  /**
   * Subject to accept signal trigger got to next
   */
  private browseNextSubject = new Subject<number>();
  /**
   * Observable to watch for got to next action
   */
  public browseNext$ = this.browseNextSubject.asObservable();

  /**
   * Subject to accept signal trigger got to previous
   */
  private browsePreviousSubject = new Subject<number>();
  /**
   * Observable to watch for got to previous action
   */
  public browsePrevious$ = this.browsePreviousSubject.asObservable();

  // emit me to display isValid dialog box for our selections
  private testIsValidSubject = new BehaviorSubject<number>(0);
  testIsValidAction$ = this.testIsValidSubject.asObservable();

  private sub3;
  /**
   * Setup subscriptions to the observables used to action:
   *
   * browse next
   * browse previous
   * remove choice
   * add choice
   */
  ngOnInit(): void {
    this.subscriptions = [
      this.subscribeToViewNextProject(),
      this.subscribeToViewPreviousProject(),
      this.subscribeToRemoveChoice(),
      this.subscribeToAddProjectChoice(),
      this.subscribeToChoiceStatusDialog()];

  }

  /**
   * make sure we unsubscribe from our observables
   */
  ngOnDestroy(): void {
    this.subscriptions.map(sub => sub.unsubscribe());
  }

  /**
   * Event handling functions and observables
   *
   * These follow the same pattern
   *
   * A Function to invoke a subject emission
   * A Subject used to broadcast the event
   * An Observable we watch for the event
   *    (We could use the subject directly)
   * A function to setup the subscription and action to our event emitter
   */
  /**
   * sends a signal to our subject to trigger the addition of
   * a choice for this project
   */
  addProjectChoice() {
    this.addChoiceTriggerSubject.next(1);
  }
  /**
   * A subscription to the add choice event emitter
   */
  subscribeToAddProjectChoice(): Subscription {
    return this.addChoiceTrigger$.pipe(
      withLatestFrom(this.currentProject$),
      switchMap(([trigger, project]) => this.selectionService.addSelection(this.student, project))
    ).subscribe((project) => {
      // this.selectionService.touchSelections()
      this.activateStatusMonitor = true;
      //this.testIsValidSubject.next(1) // this toggles too early!
    });
  }

  /**
   * Toggle signal to remove the choice associated to this project
   */
  removeChoice() {
    this.removeChoiceTriggerSubject.next(1);
  }
  /**
   * A subscription to the remove choice event emitter
   */
  subscribeToRemoveChoice(): Subscription {

    return this.removeChoiceTrigger$.pipe(
      withLatestFrom(this.choice$),
      // remove choice
      switchMap(([trigger, choice]) => this.selectionService.removeSelection(choice)),
      // update selections (which should update isvalid?!)
      // we must then inquire whether selections are valid and wait for completion cf touchSelections
      // switchMap(() => this.valid$)
    )
      .subscribe((choice) => {
        //this.selectionService.touchSelections()

        this.activateStatusMonitor = true;
        // this.testIsValidSubject.next(1)
        // TODO: rename removeSelection to removeChoice (even rename selection service)

      });
  }

  /**
   * Triggers an event to set the browseProject to the next in the list
   */
  viewNextProject() {
    this.browseNextSubject.next(1);
  }
  /**
   * A subscription to go to next choice event emitter
   */
  subscribeToViewNextProject(): Subscription {
    // view next
    return this.browseNext$.pipe(
      withLatestFrom(this.nextProject$),
      map(([trigger, project]) => project))
      .subscribe((project) => {
        this.projectsService.browseProject(project);
      });
  }

  /**
   * Triggers an event to set the browseProject to the previous project
   */
  viewPreviousProject() {
    this.browsePreviousSubject.next(1);
  }
  /**
   * A subscription to go to previous choice event emitter
   */
  subscribeToViewPreviousProject(): Subscription {
    // view previous
    return this.browsePrevious$.pipe(
      withLatestFrom(this.previousProject$),
      map(([trigger, project]) => project)).subscribe((project) => {
        this.projectsService.browseProject(project);
      });
  }

  /**
   * If testIsValidAction$ is triggered
   *
   * Test whether the selections are valid and show the dialog (if necessary)
   *
   * The dialog is prevented from dsiplaying when this modal opens by the
   * default value for testIsValidAction$ which is toggled when choices are
   * added or removed
   *
   * @returns
   */
  public subscribeToChoiceStatusDialog() {

    return this.valid$.subscribe((val) => {
      if (this.activateStatusMonitor) {
        // trigger the dialog
        const dialogRef = this.dialog.open(Y4ProjectChoicesStatusDialogComponent, {
          //width: '250px',
          data: { status: val['status'], message: val['message'] }
        });
      }
    });
  }

  /**
   * listener for tab click
   *
   * At the moment we don't action anything
   *
   * @param eve event occured (includes the actioned tab)
   */
  tabClick(eve) {

  }

}
