<div *ngIf="selection; else noSelection" class="valign-center row">
    <div class="column">
        <span mat-body2 class="mat-body-strong">{{selection.pslSerial}}&nbsp;</span>
    </div>
    <div class="column-3">
        {{
        selection.pslY4project?.reference() }}</div>
    <div class="column-6">
        {{ selection.pslY4project.projTitle }}
        <span *ngIf="selection.pslY4project.projType === 'b'" style="display:inline">&nbsp;(b)</span>
    </div>
    <div class="column">
        <mat-icon *ngIf="selection.isAllocated() && showAllocation" aria-label="Your chosen selection" matTooltip="Allocated choice">
            star_border</mat-icon>
    </div>
</div>

<ng-template #noSelection>
    <mat-icon>report_problem</mat-icon>No selection has been provided
</ng-template>