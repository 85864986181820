import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Y4ProjectselectionService } from '../lib/services/y4-projectselection.service';
import { map, take } from 'rxjs/operators';
import { Y4project } from '../lib/models/y4project';
import { Y4ProjectService } from '../lib/services/y4-project.service';
import { Observable } from 'rxjs';
import { Y4projectselection } from '../lib/models/y4projectselection';

/**
 * To Allow the removal of a project the project must not have been chosen
 * by students.
 *
 * If the project has been chosen by students then the superisor must contact
 * those students and get them to remove this choice.
 *
 * Alternativey the supervisor could contact the teaching office who could remove
 * the choice on behalf of the student and then remove the project
 */

@Component({
  selector: 'app-y4-project-remove-dialog',
  templateUrl: './y4-project-remove-dialog.component.html',
  styleUrls: ['./y4-project-remove-dialog.component.scss']
})
export class Y4ProjectRemoveDialogComponent implements OnInit {

  project;
  choices$:  Observable<Y4projectselection[]>;
  choicesbcc$;
  msg='';

  constructor(
    private _projectService: Y4ProjectService,
    private _projectSelectionService: Y4ProjectselectionService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<Y4ProjectRemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    this.project = new Y4project().deserialize(this.data.project);
    this.msg = `Please unselect '`+this.project.projTitle+`' from your list of choices.%0D%0A%0D%0A
    This project will NOT be going ahead`;

    this.choices$ = this._projectSelectionService.projectChoices$(this.project.id);
    this.choicesbcc$=this.choices$.pipe(
      map((cs)=>cs.map( c=> c.pslStudent.stuCrsId+'@cam.ac.uk').join()),
    );
  }

  cancel() {
    // this.openSnackBar('Updating project details cancelled: ', 'close');
    this._dialogRef.close(false);
  }

  remove() {
    // we can only action this if the number of choices is 0
    // the backend will prevent removal
    this._projectService
      .removeProject(this.project)
      .pipe(take(1))
      .subscribe(res => {
        this.openSnackBar('The project has been removed', 'close');
        this._dialogRef.close(true);
        this._projectService.getProjects();
      });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
