<button mat-icon-button class="close-button" [mat-dialog-close]="false">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<mat-card>
    <mat-card-title>Remove project</mat-card-title>
    <mat-card-subtitle>Project: {{project.projTitle}}</mat-card-subtitle>
    <mat-card-content>
        <div *ngIf="(choices$|async)?.length > 0; else okayToRemove">
            <h3>WARNING</h3>
            This project has been seletecd by {{(choices$|async)?.length}} student(s):
            <ul>
                <li *ngFor="let choice of (choices$| async)">
                    {{choice.pslStudent.stuCrsId}}
                </li>
            </ul>
            <p>While there are students with choices for this project it can not be removed.</p>
            <p>Please either
                <a
                    href="mailto:teaching-office@eng.cam.ac.uk?bcc={{(choicesbcc$| async)}}&subject=IIB projects action required&body={{msg}}">notify</a>
                the students and ask them to de-select the project, or contact the Teaching Office.
            </p>
        </div>
        <ng-template #okayToRemove>
          This project can be removed.
        </ng-template>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button class="cancel-button" color="warn" (click)="cancel()">
            Cancel
        </button>
        <button mat-raised-button class="approve-button" color="primary" (click)="remove()"
            [disabled]="(choices$|async)?.length > 0 ">
            Remove
        </button>
    </mat-card-actions>
</mat-card>
