import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Y4project } from '../lib/models/y4project';
import { Y4ProjectService } from '../lib/services/y4-project.service';

/**
 * A dialog used to show a preview of the Project to
 * the Owner / Approver
 *
 * The project is displayed via the y4-project-summary component and passed by an Input parameter
 *
 * eg called from y4-project-table
 *
 * @example
 * this.dialog.open(Y4ProjectSummaryDialogComponent, { height: '90%', data: { project: row } });
 *
 */
@Component({
  selector: 'app-y4-project-summary-dialog',
  templateUrl: './y4-project-summary-dialog.component.html',
  styleUrls: ['./y4-project-summary-dialog.component.css']
})
export class Y4ProjectSummaryDialogComponent implements OnInit {

  /**
   * Y4project that will be displayed
   *
   * populated by data (MAT_DIALOG_DATA) send via dialog.open
   *
   */
  project: Y4project;

  constructor(
    // private _projectService: Y4ProjectService,
              private _dialogRef: MatDialogRef<Y4ProjectSummaryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    // this._projectService.browseProject(this.data.project);
    this.project = new Y4project().deserialize(this.data.project);
  }

  close() {
    // this.openSnackBar('Updating project details cancelled: ', 'close');
    this._dialogRef.close(false);
  }
  print() {
    // Hide the body element - print - display body element
    document.getElementsByTagName('body')[0].classList.add("no-print")
    window.print();
    document.getElementsByTagName('body')[0].classList.remove("no-print")
  }
}
