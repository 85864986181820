import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { StatusesService } from '../lib/services/statuses.service';
import { Appstatus } from '../lib/models/appstatus';

import { MatAccordion } from '@angular/material/expansion';
import { KeydateEditorDialogComponent } from '../keydate-editor-dialog/keydate-editor-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, of, Subject } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';


/**
 * Used to manage the start and end of
 */
@Component({
  selector: 'app-keydates',
  templateUrl: './keydates.component.html',
  styleUrls: ['./keydates.component.css']
})
export class KeydatesComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  statussub; // unsubscribe from our
  copysub;

  statuses: Appstatus[];
  latestYear: number;

  // used to action a copy of the statuses
  copyYearSubject$ = new Subject<boolean>();
  copyYear$ = this.copyYearSubject$.asObservable();

  statuses$ = this.statusesService.list$;

  latestYear$ = this.statusesService.list$.pipe(
    map(statuses =>
      Math.max.apply(Math, statuses.map( (o) => o.staYear))
    )
  );

  constructor(private statusesService: StatusesService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.statussub = this.statuses$.subscribe(
      statuses => {
        this.statuses = statuses;
      }
    );
    this.copysub = this.copyYear$
      .pipe(
        switchMap(ret => this.latestYear$),
        take(1) // this is required otherwise switchMap continues to output
      )
      .subscribe(
        year => { this.populateStatuses(year + 1); }
      );

  }
  ngOnDestroy() {
    this.statussub.unsubscribe();
    this.copysub.unsubscribe();
  }

  /**
   * currentStatuses$()
   *
   * @param [offset] - TODO: implement optional day offset
   *
   * returns an array of current and future statuses in year then status code order
   */
  currentStatuses$() {
    return this.statuses$.pipe(
      map(statuses => {
        const now = new Date(); // we should offset this by days argument - so we can see dates just passed
        // //
        // //
        return statuses
          .filter(sta => sta.endDate() > now)
          .sort(
            (a, b) => (b.staYear - a.staYear === 0) ?
                ((b.staCode > a.staCode) ? -1 : 1) : b.staYear - b.staYear);
      })
    );
  }

  /**
   * currentYears$()
   *
   * returns an array of the years we are interested in for statuses
   *
   */
  currentYears$() {
    return this.currentStatuses$().pipe(
      map(statuses =>
        // return [2019]
         [...new Set(statuses.map((sta) => sta.staYear))]
      )
    );
  }

  /**
   * statusesForYear - retrieves the key dates for the provided academic year (startof)
   *
   * @param yyyy - the start of the academic year for which we would like to retrieve
   */
  statusesForYear(yyyy: number) {
    return this.statuses.filter(sta => sta.staYear === yyyy);
  }

  /**
   * currentKeyDates$()
   *
   * returns an array of relevant (periods not yet complete) key dates in order.
   *
   * @param offset - number of days
   */
  currentKeyDates$(offset?: number) {
    const start = new Date();
    start.setDate(start.getDate() - offset);
    return this.statuses$.pipe(
      map(statuses => [].concat.apply([{ date: new Date(), type: 'today', sta: {} }], statuses.filter(
          sta => sta.endDate() > start
        ).map(
          sta => [
              { date: sta.staStartsOn, type: 'start', sta },
              { date: sta.staEndsOn, type: 'end', sta }]
        )).sort((a, b) => a.date - b.date))
    );
  }

  /**
   * Triggers an action of the populateStatuses(year: number) method
   *
   * Required as the latestYear is an observable
   */
  populateStatusesAction() {
    //;
    // this.copyYear$ = of(true)
    this.copyYearSubject$.next(true);
  }

  /**
   * populateStatuses
   *
   * Given a year copy over all the previous statuses from the proevious year
   * This also needs to update the course offering ID
   * (so not to associate the status with the previous year)
   *
   * @param year - the year to prepopulate dates for
   */

  populateStatuses(year: number) {

    // TODO copy last year and populate (estimate for this year)
    const dialogRef = this.dialog.open(KeydatesCopyyearDialogComponent, { data: { year } });

    dialogRef.afterClosed().pipe(
      switchMap((yeari, index) => {
        if (!isNaN(yeari)) {
          return this.statusesService.copyYear$(yeari - 1, yeari);
          // return of(NaN)
        } else {
          return of(0);
        }
      })
    ).subscribe(done => {
      if (isNaN(done)) {
        // TODO call via on success
        this.statusesService.getStatuses();
      } else {
      }
    });
  }


  /**
   * TODO rename: editProjectDialog
   *
   * @param status - status to modify.
   *
   * deps:
   *   import {MatDialog} from '@angular/material/dialog';
   */
  editKeydateDialog(status: Appstatus) {
    //;
    // alert(JSON.stringify(status))
    const dialogRef =
      this.dialog.open(KeydateEditorDialogComponent, { height: '90%', data: { status } });

    dialogRef.afterClosed().subscribe(result => {
      if (result) { this.statusesService.getStatuses(); }
    });

  }


}

@Component({
  selector: 'app-keydates-copyyear-dialog',
  templateUrl: 'keydates.copyyear.dialog.html',
})
export class KeydatesCopyyearDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<KeydatesCopyyearDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close('cancel');
  }

  copyYear(year): void {
    this.dialogRef.close(year);
  }

}
