import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Allocation } from '../../lib/models/allocation';
import { Student } from '../../lib/models/student';
import { CourseAllocations } from '../../lib/services/y4-project-choices-admin-bulk-upload.service';
import { Y4ProjectChoicesAdminService } from '../../lib/services/y4-project-choices-admin.service';
import { Y4ProjectService } from '../../lib/services/y4-project.service';
import { Y4ProjectselectionService } from '../../lib/services/y4-projectselection.service';

import { DropdownItem } from 'cued-lib/src/lib/campl-ngx';
/**
 * A dialog that provides the selection of a student to allocate to the project
 */

/**
 * Used to search the autocomplete and allocated to 
 * a student who has not yet been allocated
 */
export class StudentSelect implements DropdownItem {
  student: Student;

  constructor(student) {
    this.student = student
  }

  // returns whether the searchValue is found 
  filter(searchValue: string): boolean {
    return this.student.stuLastname.toLowerCase().includes(searchValue.toLowerCase()) ||
      this.student.stuCrsId.toLowerCase().includes(searchValue.toLowerCase())
  }

  // displayed in the element
  display(): string {
    return this.student.stuLastname + " (" + this.student.stuCrsId + ")"
  }

}

@Component({
  selector: 'app-y4-project-choice-allocation-student-dialog',
  templateUrl: './y4-project-choice-allocation-student-dialog.component.html',
  styleUrls: ['./y4-project-choice-allocation-student-dialog.component.scss']
})
export class Y4ProjectChoiceAllocationStudentDialogComponent implements OnInit, OnDestroy {

  public submitted = false;
  // TODO pass as a required/optional param 
  public project$ = this._projectService.currentlyBrowsingProject$;

  _subscriptions = [];
  // our list autocomplete list
  unallocatedStudentSelectList = []
  studentSelectForm: FormGroup;

  private _debug = false;
  private _debug_message(msg) { if (this._debug) { console.log("y4-projectselections.service: " + msg) } }

  // Allocate to student
  private allocateToStudentSubject$ = new Subject();
  public allocateToStudent$ = this.allocateToStudentSubject$.asObservable();

  constructor(
    private _projectService: Y4ProjectService,
    private _choicesService: Y4ProjectselectionService,
    private _choicesAdmin: Y4ProjectChoicesAdminService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<Y4ProjectChoiceAllocationStudentDialogComponent>,
    private fb: FormBuilder) {


  }

  ngOnInit(): void {
    this._subscriptions.push(
      combineLatest([this.allocateToStudent$, this.project$])
        .pipe(
          tap(() => this._debug_message("allocate to student called with project")),
          map(([student, project]) => {
            let s = this.studentSelectForm.get('unallocatedStudent')?.value
            let allocation = {
              crsid: s.student.stuCrsId,
              reference: project.reference(),
              projid: project.id
            }
            let allocations = [new Allocation().deserialize(allocation)]
            // return [allocations, project.projCourseOffering]
            return { cooid: project.projCourseOffering.id, allocations } as CourseAllocations;
          }),
          // todo replace this with specific backend action
          switchMap((upl) => this._choicesService.uploadAllocations(upl.allocations, upl.cooid)))

        .subscribe(res => {
          this.openSnackBar('Allocation to student made', 'close');
          this._dialogRef.close(null);
        }))
    this.studentSelectForm = this.fb.group({
      unallocatedStudent: ['', [this._validStudent()]],
    })
    //this.studentSelectForm.statusChanges.subscribe(
    //  status => { console.log(status) })

    // setup our list of searchable students
    this._subscriptions.push(
      this._choicesAdmin.studentsUnallocated$()
        .subscribe(lst => {
          this.unallocatedStudentSelectList = lst.map(l => { return new StudentSelect(l) })
        })
    )
  }

  ngOnDestroy() {
    this._subscriptions.map(sub => sub.unsubscribe())
  }

  /**
   * Checks to see that the object has been selected as it is one in our list
   * 
   * @returns 
   */
  private _validStudent(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let valid = this.unallocatedStudentSelectList.filter(v => v === control.value).length > 0
      return (valid) ? null : { message: 'Not a student' };
    };
  }
  /**
   * This should only be available if no choice$ has been provided
   * 
   * We allocate the chosen student to the project
   */
  public allocateToStudent() {
    // first disable button
    this.submitted = true;
    this.allocateToStudentSubject$.next(new Date());
  }

  // /**
  //  * List of students who have not yet been allocated a project
  //  * 
  //  * We build a select list with these so that we can create a choice and allocate
  //  */
  // public studentsUnallocated$() {
  //   return this._choicesAdmin.studentsUnallocated$()
  // }

  // currently using the standard material snackbar service
  // campl-ngx-snackbar could be created to display different types of message etc?
  // We could modify sendMessage to send to a snack bar or messageare
  // See elsewhere (y4projects)
  public openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  public cancel() {
    this.openSnackBar('Allocation cancelled', 'close');
    this._dialogRef.close(null);
  }

}
