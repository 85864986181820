import { Role } from './role';
import { DeserializableStop } from './deserializableStop.model';
import { Staff } from './staff';
import { Student } from './student';
import { Grouprole } from './grouprole';
import { CourseOffering } from './courseoffering';


export class Auth implements DeserializableStop {
    username: string;
    email: string;
    display_name: string;
    roles?: Role[];
    createdAt?: string;
    updatedAt?: string;
    id?: number;
    staff?: Staff;
    students?: Student[];
    impersonating: boolean;
    grouproles?: Grouprole[];

    deserialize(input: any): this {
        Object.assign(this, input);
        // TODO this.roles = (input.roles) ?
        // input.roles.map(r => new Role().deserialize(r)) :
        // [];

        this.staff = new Staff().deserialize(input.staff);
        this.students = (input.students) ?
            input.students.map(stu => new Student().deserialize(stu)) :
            [];
        // this.roles can we aggregate these from the student and staff objects?
        this.grouproles = ( input.grouproles) ?
            input.grouproles.map(ugr => new Grouprole().deserialize(ugr)) :
            [];
        return this;
    }

    /**
     * Prevent the recursion of the serialization
     *
     * @param input - object we are deseriazing
     */
    deserializeStop(input: any): this {
        Object.assign(this, input);
        return this;
    }

    isStaff(): boolean {
        // It is legitimate for a pgrad to propose a fourth year project
        // in which case we should search stfCancelled only??
        return (
            ( this.staff.id && ( this.staff.stfClass == 'staff' || this.staff.stfCancelled == false ) ) ) ? true : false;
    }

    /**
     * Returns th elatest egt3 student record if exists
     *
     * NOTE - if several exists this chooses the last found!
     *      - to handle multiple return array and use filter
     *
     * TODO - provide a year to retrieve for
     *
     * TODO: create a test!
     */
    egt3Student() {
        // //
        return (this.students.length > 0) ?
            this.students
            .filter( stu => stu.isEGT3() )
            .sort((a, b) => b.stuCourseOffering.cooOfferingYear -  a.stuCourseOffering.cooOfferingYear  )[0] :
            undefined;
    }

    hasStudent(): boolean {
        return (this.students.length > 0) ? true : false;
    }

    /**
     * returns a course for our egt3Student record
     */
    egt3Course(): CourseOffering | undefined{
        return  (this.egt3Student() && this.egt3Student().stuCourseOffering) ?
        new CourseOffering().deserialize(this.egt3Student().stuCourseOffering) :
        undefined;
    }

    /**
     * Return whether or not we have a genuine auth entry
     * ie it has been created in our database
     */
    isLoggedIn(): boolean {
        return (this.id) ? true : false;
    }


}
