<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h1 mat-dialog-title>Provide role "{{role.roleType}}"</h1>

<form [formGroup]="roleForm">
    Add a member of staff
    <br />
    <mat-form-field class="example-full-width">
        <input type="text" placeholder="Enter staff member name" aria-label="Enter staff" matInput
            [matAutocomplete]="autoStaffMember" [formControl]="staffMemberCtrl">
        <mat-hint>Find member of staff by last name</mat-hint>
    </mat-form-field>
    <mat-autocomplete #autoStaffMember="matAutocomplete" [displayWith]="displayStaffMemberFn">
        <mat-option *ngFor="let staffMember of (filteredStaffMembers$ | async)" [value]="selectedStaffMember">
            <div (click)="optionStaffMemberClicked($event, staffMember)">
                {{ staffMember.display_name()+" ("+staffMember.stfCrsid+")" }}
            </div>
        </mat-option>
    </mat-autocomplete>

    <br/>
    <campl-ngx-input-reg-select id="projSubjectgroupId" [label]="'Project Group*'" [options]="topicSelections"
        aria-label="Project Group"
        formControlName="staffSubjectgroup" [validator]="roleForm.get('staffSubjectgroup').validator">
    </campl-ngx-input-reg-select>

    <br />
    <button mat-raised-button color="primary" (click)="save()"
        [title]="staffMemberCtrl.valid ? 'Add role to staff member' : 'Disabled until the form data is valid'"
        [disabled]="!staffMemberCtrl.valid">
        Save
    </button>
    <button mat-raised-button color="warn" [title]="'Cancel'" (click)="cancel()">
        Cancel
    </button>
</form>
<br />
Add a role to a user
