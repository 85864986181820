import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../lib/services/auth.service';
import { Auth } from '../lib/models/auth';
import { Staff } from '../lib/models/staff';
import { Y4project } from '../lib/models/y4project';
import { SgtopicService } from '../lib/services/sgtopic.service';
import { StaffService } from '../lib/services/staff.service';
import { Y4ProjectService } from '../lib/services/y4-project.service';

/**
 * Component for our student to create their Type (b) project
 */
@Component({
  selector: 'app-y4-project-student-create',
  templateUrl: './y4-project-student-create.component.html',
  styleUrls: ['./y4-project-student-create.component.scss']
})
export class Y4ProjectStudentCreateComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  pageTitle = 'Upload a Type (b) project proposal';
  alertMessage = '';
  errorMessage = '';

  isEditable; // once submitted we can not go back

  newProject: Y4project = new Y4project();

  // fields
  // Supervisor
  // Title
  // Description
  // Type (B)  - hidden
  // Course offering - hidden (current EGT3 course for this student)
  // Industrial
  // SubjectgroupTopic

  y4projectForm: FormGroup;
  showForm = false; // we wait until our form is ready

  // Owner related info
  ownerCtrl = new FormControl('', Validators.required); // we need this to interact with autoComplete
  filteredOwners$: Observable<Staff[]>;

  // subjectgroups$ = this._sgtopicsService.groupsSelectOptions$
  subjectgroups$ = this._sgtopicsService.topicGroupSelectOptions$;
  // subjectgroups = [] // refactor out: stop gap - had trouble combineLatest[subjectgroups$, this.y4projectForm.valueChanges]
  groupTopicName$; // the name of the selected groupTopic

  // newProject - the project we intend to create
  // newY4project: Y4project;

  constructor(
    private _fb: FormBuilder,
    private _sgtopicsService: SgtopicService,
    private _staffService: StaffService,
    private _authService: AuthService,
    private _y4projectService: Y4ProjectService
  ) { }

  ngOnInit(): void {
    this.setFormDefaults();

    // we are passing newProject to our child form
    // Therefore we need to keep the same reference
    // so that updates are displayed
    // https://stackoverflow.com/questions/52741486
    // We susbscribe to the group name change
    this.subscriptions.push(this.getProjectFromForm$().subscribe(
      ret => {
        // this.newProject['projTitle']="another title"
        this.newProject.deserialize(ret);
      }
    ))
  }

  ngOnDestroy(): void {
    this.subscriptions.map( sub => sub.unsubscribe() )
  }
  /**
   * Sets up our form and activates a valueChange observer on the value entered in projOwner
   */
  setFormDefaults() {
    this.y4projectForm = this._fb.group({
      projOwner: ['', [this._validStaffMember, Validators.required]],
      projTitle: ['', [Validators.required]],
      projDescription: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(2048)]],
      projSubjectgroupTopic: ['', [Validators.required]],
      projIndustrial: '',
      // projCourseOffering: ['', [Validators.required]], // included in the submit
      // projType: ['', [Validators.required]], // This will be included on submit
    });
    this.filteredOwners$ = this.y4projectForm.get('projOwner').valueChanges.pipe(
      //
      debounceTime(300),
      map(value => typeof value === 'string' ? value : ''),
      switchMap(value => this._staffService.search(value, 20))
    );

    // set the observable for the groupTopicName (label to the user)
    // Notice the kludge for behaviour with async in the ngIf of the stepper
    const lastSGStatus$ = this.y4projectForm.get('projSubjectgroupTopic').statusChanges.pipe(shareReplay(1));
    this.groupTopicName$ = combineLatest([lastSGStatus$, this.subjectgroups$]).pipe(map(
      ([status, groups]) => {
        let ret = 'NOT SET'; // not needed?
        if (status === 'VALID') { // not needed?
          const arr = groups.filter(grp => grp.id === this.y4projectForm.get('projSubjectgroupTopic').value.selected);
          ret = arr[0].value;
        }
        return ret;
      }
    )
    );

    // Refresh the object sent to child each time form updates
    const lastFormStatus$ =  this.y4projectForm.statusChanges;
    this.subscriptions.push(lastFormStatus$.pipe(
      switchMap( (ret) =>
      {
        if (ret === 'VALID'){
          // add the suscribe in here to :
          return this.getProjectFromForm$();
        }else{
          return 'empty';
        }
      }),
      map(ret => ( ret !== 'empty') ? this.newProject.deserialize(ret) : 'empty'  )
    ).subscribe(

    ))
    // Kludge the async subscription in review menu doesn not seem to trigger a subscription?!
    // This is required to toggle the subscription that doesn't happen in the stepper
    // when we step forward
    this.subscriptions.push(this.groupTopicName$.subscribe(val => { }))




    this.showForm = true;
    this.isEditable = true;
  }

  /**
   *
   * Functions to manage the Owner select
   */
  /**
   * Returns a value to be displayed in the text field
   *
   * @param value - the staff member to display as the value, or at load a blank string
   */
  displayOwnerFn(value: Staff | string): string | undefined {
    let ret = '';
    if (typeof (value) === 'string') {
      ret = '' + value;
    }
    else if (value) {// makes sure not null
      //;
      ret = value.display_name() + ' (' + value.stfCrsid + ')';
    }


    return ret;
  }
  /**
   *
   * This 'guarantees' a valid staff member
   *
   * Not to be used if the user does not have proxy permissions! oops
   */
  optionOwnerClicked(event: Event, owner: Staff) {
    this.y4projectForm.patchValue({
      projOwner: new Staff().deserialize(owner)
    });
  }

  /**
   * Extract our Y4Project from our form
   *
   * Test for validation is required before calling!
   *
   * TODO use this in our template to display the details
   * TODO return correct type
   */
  parseY4Project() {

    const parsedForm = {};
    // Grab our selected ids (foriegn keys)
    // we could even set the topic name here!
    Object.keys(this.y4projectForm.value).map((key, index) => {
      if ((this.y4projectForm.value[key] != null && typeof (this.y4projectForm.value[key]) === 'object' &&
        'selected' in this.y4projectForm.value[key])) {
        parsedForm[key] = { id: this.y4projectForm.value[key].selected };
      } else {
        //;
        parsedForm[key] = this.y4projectForm.value[key];
      }
    });

    // TODO: parsed forms properties need to be stored correctly
    parsedForm['projType'] = 'b';

    return parsedForm;
    // return new Y4project().deserialize(parsedForm)
  }

  getProjectFromForm$(): Observable<Y4project> {
    // we need to replace the ID of the project (subject) group topic with the project (subject) group
    // Hence why we return the observable


    return this.groupTopicName$.pipe(
      map( (name: string) => {
        const parsed = this.parseY4Project();
        //;
        let subjGroup = name.split(':')[0]
        let subjGroupTopic = name.split(':')[1]
        parsed['projSubjectgroupTopic'] = { subjGroup, id: parsed['projSubjectgroupTopic'], subjGroupTopic };
        return new Y4project().deserialize(parsed);
      }));
  }

  save() {
    // create the project
    //;

    // we also need:
    const postBody$ = this._authService.getMe$().pipe(
      map(
        me => {
          // FIXME: if we accidently unimpersonate handle the issue (no student record)
          // student id
          const auth = new Auth().deserialize(me);
          auth.students.map(stu => console.log(stu));
          const parsedForm = this.parseY4Project();
          const student = auth.egt3Student(); // careful this will be the latest student record
          parsedForm['pslStudent'] = student.id;
          parsedForm['projCourseOffering'] = student.egt3Selection().id;
          parsedForm['projOwner'] = parsedForm['projOwner'].id;
          parsedForm['projSubjectgroupTopic'] = parsedForm['projSubjectgroupTopic'].id;
          const deref = { ...parsedForm };
          return parsedForm;
        }
      )
    );

    // create the project with our post body
    postBody$.pipe(
      switchMap(
        (body) => this._y4projectService.createTypeBStudent$(body)
      ),
      take(1)
    ).subscribe(
      // TODO test for error and either;
      // trigger list, or display problem
    );

    // and allocate to this user
  }
  cancel() {
  }

  /**
   * Once complete prevent our user from returning to a previous step
   */
  preventBackSteps() {
    this.isEditable = false;
  }

  test() {
    alert('selected changed');
  }
  /**
   * Translate the selected ID of the projSubjectgroupTopic
   * into a subjectgrouptopic object for display
   *
   * @param id - our subjectgrouptopic id
   * ?Not used?
   */
  groupNameFromID$(id: number) {
    return this.subjectgroups$.pipe(
      map(subjectgroups => {
        const arr = subjectgroups.filter(grp => grp.id === id);
        return arr;
      })
    );
  }
/**
 * Used as synchronous validation
 */
private _validStaffMember(control: AbstractControl): { [key: string]: any } | null {
  const staff = control.value;
  return (staff && staff.id) ? null : { invalidRecord: { value: staff } };
}
  /*
  A debug function to display our form errors
  getFormValidationErrors() {
    //
    Object.keys(this.y4projectForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.y4projectForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          //;
        });
      }
    });
  }
  */
}
