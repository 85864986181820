/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Attribute } from '../models/attribute';
import { AttributeScheme } from '../models/attribute-scheme';
import { Group } from '../models/group';
import { Institution } from '../models/institution';
import { Person } from '../models/person';


/**
 * Methods for querying and manipulating people.
 *
 * #### Notes on the fetch parameter
 *
 * All methods that return people, institutions or groups also accept an
 * optional `fetch` parameter that may be used to request
 * additional information about the entities returned. Without this
 * parameter, only a few basic details about each person, institution or
 * group are returned. The `fetch` parameter is quite flexible,
 * and may be used in a number of different ways:
 *
 * * **Attribute fetching**. Attributes may be fetched by specifying the
 *   `schemeid` of an attribute scheme. For example to fetch a
 *   person's email addresses, use the value `"email"`. For people common
 *   attribute schemes include `"jpegPhoto"`, `"misAffiliation"`,
 *   `"title"`, `"universityPhone"`, `"mobexPhone"`,
 *   `"landlinePhone"`, `"mobilePhone"`, `"pager"`,
 *   `"labeledURI"` and `"address"`. The full list of person
 *   attribute schemes may be obtained using `#allAttributeSchemes`.
 *
 * * **Pseudo-attributes**. Certain special pseudo-attributes are defined
 *   for convenience. For people, the following pseudo-attributes are supported:
 *
 *   * `"phone_numbers"` - fetches all phone numbers. This is
 *     equivalent to
 *     `"universityPhone,instPhone,mobexPhone,landlinePhone,mobilePhone,pager"`.
 *
 *   * `"all_identifiers"` - fetches all identifiers. Currently people
 *     only have CRSid identifiers, but in the future additional identifiers such
 *     as USN or staffNumber may be added.
 *
 *   * `"all_attrs"` - fetches all attributes from all person attribute
 *     schemes. This does not include identifiers or references.
 *
 * * **Reference fetching**. For people, the following references are
 *   supported (and will fetch only non-cancelled institutions and groups):
 *
 *   * `"all_insts"` - fetches all the institutions to which the person
 *     belongs (sorted in name order).
 *
 *   * `"all_groups"` - fetches all the groups that the person is a
 *     member of, including indirect group memberships, via groups that include
 *     other groups.
 *
 *   * `"direct_groups"` - fetches all the groups that the person is
 *     directly a member of. This does not include indirect group memberships -
 *     i.e., groups that include these groups.
 *
 * * **Chained reference fetching**. To fetch properties of referenced
 *   objects, the "dot" notation may be used. For example, to fetch the email
 *   addresses of all the institutions to which a person belongs, use
 *   `"all_insts.email"`. Chains may include a number of reference
 *   following steps, for example
 *   `"all_insts.managed_by_groups.all_members.email"` will fetch all the
 *   institutions to which the person belongs, all the groups that manage those
 *   institutions, all the visible members of those groups and all the email
 *   addresses of those managing group members. For more information about what
 *   can be fetched from referenced institutions and groups, refer to the
 *   documentation for `InstitutionMethods` and `GroupMethods`.
 *
 * Multiple values of the `fetch` parameter should be separated
 * by commas.
 *
 * #### Fetch parameter examples
 *
 * `fetch = "email"`
 * This fetches all the person's email addresses.
 *
 * `fetch = "title,address"`
 * This fetches all the person's titles (roles) and addresses.
 *
 * `fetch = "all_attrs"`
 * This fetches all the person's attributes.
 *
 * `fetch = "all_groups,all_insts"`
 * This fetches all the groups and institutions to which the person belongs.
 *
 * `fetch = "all_insts.parent_insts"`
 * This fetches all the person's institutions, and their parent institutions.
 *
 * `fetch = "all_insts.email,all_insts.all_members.email"`
 * This fetches all the person's institutions and their email addresses, and
 * all the members of those institutions, and the email addresses of all
 * those members.
 */
@Injectable({
  providedIn: 'root',
})
export class PersonService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation personAllAttributeSchemes
   */
  static readonly PersonAllAttributeSchemesPath = '/person/all-attr-schemes';

  /**
   * Return a list of all the person attribute schemes available.
   *
   * Return a list of all the person attribute schemes available. The
   * `schemeid` values of these schemes may be used in the
   * `fetch` parameter of other methods that return people.
   *
   * NOTE: Some of these attribute schemes are not currently used (no
   * people have attribute values in the scheme). These schemes are
   * reserved for possible future use.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personAllAttributeSchemes()` instead.
   *
   * This method doesn't expect any request body.
   */
  personAllAttributeSchemes$Response(params?: {
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attributeSchemes'?: Array<AttributeScheme>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonAllAttributeSchemesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'attributeSchemes'?: Array<AttributeScheme>;
        };
        }>;
      })
    );
  }

  /**
   * Return a list of all the person attribute schemes available.
   *
   * Return a list of all the person attribute schemes available. The
   * `schemeid` values of these schemes may be used in the
   * `fetch` parameter of other methods that return people.
   *
   * NOTE: Some of these attribute schemes are not currently used (no
   * people have attribute values in the scheme). These schemes are
   * reserved for possible future use.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personAllAttributeSchemes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personAllAttributeSchemes(params?: {
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attributeSchemes'?: Array<AttributeScheme>;
};
}> {

    return this.personAllAttributeSchemes$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attributeSchemes'?: Array<AttributeScheme>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attributeSchemes'?: Array<AttributeScheme>;
};
})
    );
  }

  /**
   * Path part for operation personAllPeople
   */
  static readonly PersonAllPeoplePath = '/person/all-people';

  /**
   * Return a list of all people (in batches).
   *
   * Return a list of all people (in batches).
   *
   * The results are sorted by identifier, starting with the first person
   * after the person with the specified identifier. Thus, to iterate over
   * all people, pass a `null` identifier to get the first batch of
   * people, then pass the last identifier from the previous batch to get
   * the next batch, and repeat until no more people are returned.
   *
   * By default, only a few basic details about each person are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personAllPeople()` instead.
   *
   * This method doesn't expect any request body.
   */
  personAllPeople$Response(params?: {

    /**
     * Flag to allow cancelled people to
     * be included (people who are no longer members of the University).
     * Defaults to &#x60;false&#x60;.
     */
    includeCancelled?: boolean;

    /**
     * The identifier (CRSid) of the person to
     * start after, or &#x60;null&#x60; to start from the first person.
     */
    identifier?: string;

    /**
     * The maximum number of people to return.
     * Defaults to 100.
     */
    limit?: number;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonAllPeoplePath, 'get');
    if (params) {
      rb.query('includeCancelled', params.includeCancelled, {});
      rb.query('identifier', params.identifier, {});
      rb.query('limit', params.limit, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'people'?: Array<Person>;
        };
        }>;
      })
    );
  }

  /**
   * Return a list of all people (in batches).
   *
   * Return a list of all people (in batches).
   *
   * The results are sorted by identifier, starting with the first person
   * after the person with the specified identifier. Thus, to iterate over
   * all people, pass a `null` identifier to get the first batch of
   * people, then pass the last identifier from the previous batch to get
   * the next batch, and repeat until no more people are returned.
   *
   * By default, only a few basic details about each person are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personAllPeople$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personAllPeople(params?: {

    /**
     * Flag to allow cancelled people to
     * be included (people who are no longer members of the University).
     * Defaults to &#x60;false&#x60;.
     */
    includeCancelled?: boolean;

    /**
     * The identifier (CRSid) of the person to
     * start after, or &#x60;null&#x60; to start from the first person.
     */
    identifier?: string;

    /**
     * The maximum number of people to return.
     * Defaults to 100.
     */
    limit?: number;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}> {

    return this.personAllPeople$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
})
    );
  }

  /**
   * Path part for operation personListPeople
   */
  static readonly PersonListPeoplePath = '/person/list';

  /**
   * Get the people with the specified identifiers (typically CRSids).
   *
   * Get the people with the specified identifiers (typically CRSids).
   *
   * Each identifier may be either a CRSid, or an identifier from another
   * identifier scheme, prefixed with that scheme's name and a slash. For
   * example `"mug99"` or `"usn/123456789"`.
   *
   * By default, only a few basic details about each person are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * The results are sorted by identifier scheme and value.
   *
   * NOTE: The number of people that may be fetched in a single call is
   * limited by the URL path length limit (around 8000 characters). A
   * CRSid is up to 7 characters long, and other identifiers are typically
   * longer, since they must also include the identifier scheme. Thus the
   * number of people that this method may fetch is typically limited to a
   * few hundred.
   *
   * NOTE: The people returned may include cancelled people. It is the
   * caller's repsonsibility to check their cancelled flags.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personListPeople()` instead.
   *
   * This method doesn't expect any request body.
   */
  personListPeople$Response(params: {

    /**
     * A comma-separated list of identifiers.
     */
    crsids: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonListPeoplePath, 'get');
    if (params) {
      rb.query('crsids', params.crsids, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'people'?: Array<Person>;
        };
        }>;
      })
    );
  }

  /**
   * Get the people with the specified identifiers (typically CRSids).
   *
   * Get the people with the specified identifiers (typically CRSids).
   *
   * Each identifier may be either a CRSid, or an identifier from another
   * identifier scheme, prefixed with that scheme's name and a slash. For
   * example `"mug99"` or `"usn/123456789"`.
   *
   * By default, only a few basic details about each person are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * The results are sorted by identifier scheme and value.
   *
   * NOTE: The number of people that may be fetched in a single call is
   * limited by the URL path length limit (around 8000 characters). A
   * CRSid is up to 7 characters long, and other identifiers are typically
   * longer, since they must also include the identifier scheme. Thus the
   * number of people that this method may fetch is typically limited to a
   * few hundred.
   *
   * NOTE: The people returned may include cancelled people. It is the
   * caller's repsonsibility to check their cancelled flags.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personListPeople$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personListPeople(params: {

    /**
     * A comma-separated list of identifiers.
     */
    crsids: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}> {

    return this.personListPeople$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
})
    );
  }

  /**
   * Path part for operation personModifiedPeople
   */
  static readonly PersonModifiedPeoplePath = '/person/modified-people';

  /**
   * Find all people modified between the specified pair of transactions.
   *
   * Find all people modified between the specified pair of transactions.
   *
   * The transaction IDs specified should be the IDs from two different
   * requests for the last (most recent) transaction ID, made at different
   * times, that returned different values, indicating that some Lookup
   * data was modified in the period between the two requests. This method
   * then determines which (if any) people were affected.
   *
   * By default, only a few basic details about each person are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * NOTE: All data returned reflects the latest available data about each
   * person. It is not possible to query for old data, or more detailed
   * information about the specific changes made.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personModifiedPeople()` instead.
   *
   * This method doesn't expect any request body.
   */
  personModifiedPeople$Response(params: {

    /**
     * Include modifications made in transactions
     * after (but not including) this one.
     */
    minTxId: number;

    /**
     * Include modifications made in transactions
     * up to and including this one.
     */
    maxTxId: number;

    /**
     * Only include people with identifiers in this
     * list. By default, all modified people will be included.
     */
    crsids?: string;

    /**
     * Include cancelled people (people
     * who are no longer members of the University). By default, cancelled
     * people are excluded.
     */
    includeCancelled?: boolean;

    /**
     * Include people whose group or
     * institutional memberships have changed. By default, only people whose
     * attributes have been directly modified are included.
     */
    membershipChanges?: boolean;

    /**
     * Include people who are members of
     * instituions whose names have changed. This will also cause people
     * whose group or institutional memberships have changed to be included.
     * By default, changes to institution names do not propagate to people.
     */
    instNameChanges?: boolean;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonModifiedPeoplePath, 'get');
    if (params) {
      rb.query('minTxId', params.minTxId, {});
      rb.query('maxTxId', params.maxTxId, {});
      rb.query('crsids', params.crsids, {});
      rb.query('includeCancelled', params.includeCancelled, {});
      rb.query('membershipChanges', params.membershipChanges, {});
      rb.query('instNameChanges', params.instNameChanges, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'people'?: Array<Person>;
        };
        }>;
      })
    );
  }

  /**
   * Find all people modified between the specified pair of transactions.
   *
   * Find all people modified between the specified pair of transactions.
   *
   * The transaction IDs specified should be the IDs from two different
   * requests for the last (most recent) transaction ID, made at different
   * times, that returned different values, indicating that some Lookup
   * data was modified in the period between the two requests. This method
   * then determines which (if any) people were affected.
   *
   * By default, only a few basic details about each person are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * NOTE: All data returned reflects the latest available data about each
   * person. It is not possible to query for old data, or more detailed
   * information about the specific changes made.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personModifiedPeople$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personModifiedPeople(params: {

    /**
     * Include modifications made in transactions
     * after (but not including) this one.
     */
    minTxId: number;

    /**
     * Include modifications made in transactions
     * up to and including this one.
     */
    maxTxId: number;

    /**
     * Only include people with identifiers in this
     * list. By default, all modified people will be included.
     */
    crsids?: string;

    /**
     * Include cancelled people (people
     * who are no longer members of the University). By default, cancelled
     * people are excluded.
     */
    includeCancelled?: boolean;

    /**
     * Include people whose group or
     * institutional memberships have changed. By default, only people whose
     * attributes have been directly modified are included.
     */
    membershipChanges?: boolean;

    /**
     * Include people who are members of
     * instituions whose names have changed. This will also cause people
     * whose group or institutional memberships have changed to be included.
     * By default, changes to institution names do not propagate to people.
     */
    instNameChanges?: boolean;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}> {

    return this.personModifiedPeople$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
})
    );
  }

  /**
   * Path part for operation personSearch
   */
  static readonly PersonSearchPath = '/person/search';

  /**
   * Search for people using a free text query string.
   *
   * Search for people using a free text query string. This is the same
   * search function that is used in the Lookup web application.
   *
   * By default, only a few basic details about each person are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * NOTE: If the query string starts with the prefix `"person:"`, it
   * is treated as an [LQL query](/lql), allowing
   * more advanced searches. An LQL query will ignore the
   * `approxMatches` and `attributes` parameters, but
   * it will respect the values of `includeCancelled` and
   * `misStatus`. In addition, an LQL query will ignore the
   * `orderBy` parameter, since LQL queries always return
   * results in ID order.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  personSearch$Response(params: {

    /**
     * The search string.
     */
    query: string;

    /**
     * Flag to enable more approximate
     * matching in the search, causing more results to be returned. Defaults
     * to &#x60;false&#x60;. This is ignored for LQL queries.
     */
    approxMatches?: boolean;

    /**
     * Flag to allow cancelled people to
     * be included (people who are no longer members of the University).
     * Defaults to &#x60;false&#x60;.
     */
    includeCancelled?: boolean;

    /**
     * The type of people to search for. This may
     * be
     *
     * * &#x60;&quot;staff&quot;&#x60; - only include people whose MIS status is
     *   &#x60;&quot;&quot;&#x60; (empty string), &#x60;&quot;staff&quot;&#x60;, or
     *   &#x60;&quot;staff,student&quot;&#x60;.
     *
     * * &#x60;&quot;student&quot;&#x60; - only include people whose MIS status is set to
     *   &#x60;&quot;student&quot;&#x60; or &#x60;&quot;staff,student&quot;&#x60;.
     *
     * Otherwise all matching people will be included (the default). Note
     * that the &#x60;&quot;staff&quot;&#x60; and &#x60;&quot;student&quot;&#x60; options are not
     * mutually exclusive.
     */
    misStatus?: string;

    /**
     * A comma-separated list of attributes to
     * consider when searching. If this is &#x60;null&#x60; (the default) then
     * all attribute schemes marked as searchable will be included. This is
     * ignored for LQL queries.
     */
    attributes?: string;

    /**
     * The number of results to skip at the start
     * of the search. Defaults to 0.
     */
    offset?: number;

    /**
     * The maximum number of results to return.
     * Defaults to 100.
     */
    limit?: number;

    /**
     * The order in which to list the results.
     * This may be either &#x60;&quot;identifier&quot;&#x60; or &#x60;&quot;surname&quot;&#x60; (the
     * default for non-LQL queries). This is ignored for LQL queries, which
     * always return results in identifier order.
     */
    orderBy?: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonSearchPath, 'get');
    if (params) {
      rb.query('query', params.query, {});
      rb.query('approxMatches', params.approxMatches, {});
      rb.query('includeCancelled', params.includeCancelled, {});
      rb.query('misStatus', params.misStatus, {});
      rb.query('attributes', params.attributes, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('orderBy', params.orderBy, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'people'?: Array<Person>;
        };
        }>;
      })
    );
  }

  /**
   * Search for people using a free text query string.
   *
   * Search for people using a free text query string. This is the same
   * search function that is used in the Lookup web application.
   *
   * By default, only a few basic details about each person are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * NOTE: If the query string starts with the prefix `"person:"`, it
   * is treated as an [LQL query](/lql), allowing
   * more advanced searches. An LQL query will ignore the
   * `approxMatches` and `attributes` parameters, but
   * it will respect the values of `includeCancelled` and
   * `misStatus`. In addition, an LQL query will ignore the
   * `orderBy` parameter, since LQL queries always return
   * results in ID order.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personSearch(params: {

    /**
     * The search string.
     */
    query: string;

    /**
     * Flag to enable more approximate
     * matching in the search, causing more results to be returned. Defaults
     * to &#x60;false&#x60;. This is ignored for LQL queries.
     */
    approxMatches?: boolean;

    /**
     * Flag to allow cancelled people to
     * be included (people who are no longer members of the University).
     * Defaults to &#x60;false&#x60;.
     */
    includeCancelled?: boolean;

    /**
     * The type of people to search for. This may
     * be
     *
     * * &#x60;&quot;staff&quot;&#x60; - only include people whose MIS status is
     *   &#x60;&quot;&quot;&#x60; (empty string), &#x60;&quot;staff&quot;&#x60;, or
     *   &#x60;&quot;staff,student&quot;&#x60;.
     *
     * * &#x60;&quot;student&quot;&#x60; - only include people whose MIS status is set to
     *   &#x60;&quot;student&quot;&#x60; or &#x60;&quot;staff,student&quot;&#x60;.
     *
     * Otherwise all matching people will be included (the default). Note
     * that the &#x60;&quot;staff&quot;&#x60; and &#x60;&quot;student&quot;&#x60; options are not
     * mutually exclusive.
     */
    misStatus?: string;

    /**
     * A comma-separated list of attributes to
     * consider when searching. If this is &#x60;null&#x60; (the default) then
     * all attribute schemes marked as searchable will be included. This is
     * ignored for LQL queries.
     */
    attributes?: string;

    /**
     * The number of results to skip at the start
     * of the search. Defaults to 0.
     */
    offset?: number;

    /**
     * The maximum number of results to return.
     * Defaults to 100.
     */
    limit?: number;

    /**
     * The order in which to list the results.
     * This may be either &#x60;&quot;identifier&quot;&#x60; or &#x60;&quot;surname&quot;&#x60; (the
     * default for non-LQL queries). This is ignored for LQL queries, which
     * always return results in identifier order.
     */
    orderBy?: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}> {

    return this.personSearch$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
})
    );
  }

  /**
   * Path part for operation personSearchCount
   */
  static readonly PersonSearchCountPath = '/person/search-count';

  /**
   * Count the number of people that would be returned by a search using
   * a free text query string.
   *
   * Count the number of people that would be returned by a search using
   * a free text query string.
   *
   * NOTE: If the query string starts with the prefix `"person:"`, it
   * is treated as an [LQL query](/lql), allowing
   * more advanced searches. An LQL query will ignore the
   * `approxMatches` and `attributes` parameters, but
   * it will respect the values of `includeCancelled` and
   * `misStatus`.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personSearchCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  personSearchCount$Response(params: {

    /**
     * The search string.
     */
    query: string;

    /**
     * Flag to enable more approximate
     * matching in the search, causing more results to be returned. Defaults
     * to &#x60;false&#x60;. This is ignored for LQL queries.
     */
    approxMatches?: boolean;

    /**
     * Flag to allow cancelled people to
     * be included (people who are no longer members of the University).
     * Defaults to &#x60;false&#x60;.
     */
    includeCancelled?: boolean;

    /**
     * The type of people to search for. This may
     * be
     *
     * * &#x60;&quot;staff&quot;&#x60; - only include people whose MIS status is
     *   &#x60;&quot;&quot;&#x60; (empty string), &#x60;&quot;staff&quot;&#x60;, or
     *   &#x60;&quot;staff,student&quot;&#x60;.
     *
     * * &#x60;&quot;student&quot;&#x60; - only include people whose MIS status is set to
     *   &#x60;&quot;student&quot;&#x60; or &#x60;&quot;staff,student&quot;&#x60;.
     *
     * Otherwise all matching people will be included (the default). Note
     * that the &#x60;&quot;staff&quot;&#x60; and &#x60;&quot;student&quot;&#x60; options are not
     * mutually exclusive.
     */
    misStatus?: string;

    /**
     * A comma-separated list of attributes to
     * consider when searching. If this is &#x60;null&#x60; (the default) then
     * all attribute schemes marked as searchable will be included. This is
     * ignored for LQL queries.
     */
    attributes?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: number;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonSearchCountPath, 'get');
    if (params) {
      rb.query('query', params.query, {});
      rb.query('approxMatches', params.approxMatches, {});
      rb.query('includeCancelled', params.includeCancelled, {});
      rb.query('misStatus', params.misStatus, {});
      rb.query('attributes', params.attributes, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'value'?: number;
        };
        }>;
      })
    );
  }

  /**
   * Count the number of people that would be returned by a search using
   * a free text query string.
   *
   * Count the number of people that would be returned by a search using
   * a free text query string.
   *
   * NOTE: If the query string starts with the prefix `"person:"`, it
   * is treated as an [LQL query](/lql), allowing
   * more advanced searches. An LQL query will ignore the
   * `approxMatches` and `attributes` parameters, but
   * it will respect the values of `includeCancelled` and
   * `misStatus`.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personSearchCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personSearchCount(params: {

    /**
     * The search string.
     */
    query: string;

    /**
     * Flag to enable more approximate
     * matching in the search, causing more results to be returned. Defaults
     * to &#x60;false&#x60;. This is ignored for LQL queries.
     */
    approxMatches?: boolean;

    /**
     * Flag to allow cancelled people to
     * be included (people who are no longer members of the University).
     * Defaults to &#x60;false&#x60;.
     */
    includeCancelled?: boolean;

    /**
     * The type of people to search for. This may
     * be
     *
     * * &#x60;&quot;staff&quot;&#x60; - only include people whose MIS status is
     *   &#x60;&quot;&quot;&#x60; (empty string), &#x60;&quot;staff&quot;&#x60;, or
     *   &#x60;&quot;staff,student&quot;&#x60;.
     *
     * * &#x60;&quot;student&quot;&#x60; - only include people whose MIS status is set to
     *   &#x60;&quot;student&quot;&#x60; or &#x60;&quot;staff,student&quot;&#x60;.
     *
     * Otherwise all matching people will be included (the default). Note
     * that the &#x60;&quot;staff&quot;&#x60; and &#x60;&quot;student&quot;&#x60; options are not
     * mutually exclusive.
     */
    misStatus?: string;

    /**
     * A comma-separated list of attributes to
     * consider when searching. If this is &#x60;null&#x60; (the default) then
     * all attribute schemes marked as searchable will be included. This is
     * ignored for LQL queries.
     */
    attributes?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: number;
};
}> {

    return this.personSearchCount$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: number;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: number;
};
})
    );
  }

  /**
   * Path part for operation personGetPerson
   */
  static readonly PersonGetPersonPath = '/person/{scheme}/{identifier}';

  /**
   * Get the person with the specified identifier.
   *
   * Get the person with the specified identifier.
   *
   * By default, only a few basic details about the person are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of the person.
   *
   * NOTE: The person returned may be a cancelled person. It is the
   * caller's repsonsibility to check its cancelled flag.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personGetPerson()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetPerson$Response(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person to fetch
     * (typically their CRSid).
     */
    identifier: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'person'?: Person;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonGetPersonPath, 'get');
    if (params) {
      rb.path('scheme', params.scheme, {});
      rb.path('identifier', params.identifier, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'person'?: Person;
        };
        }>;
      })
    );
  }

  /**
   * Get the person with the specified identifier.
   *
   * Get the person with the specified identifier.
   *
   * By default, only a few basic details about the person are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of the person.
   *
   * NOTE: The person returned may be a cancelled person. It is the
   * caller's repsonsibility to check its cancelled flag.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personGetPerson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetPerson(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person to fetch
     * (typically their CRSid).
     */
    identifier: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'person'?: Person;
};
}> {

    return this.personGetPerson$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'person'?: Person;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'person'?: Person;
};
})
    );
  }

  /**
   * Path part for operation personGetAttributes
   */
  static readonly PersonGetAttributesPath = '/person/{scheme}/{identifier}/get-attributes';

  /**
   * Get one or more (possibly multi-valued) attributes of a person.
   *
   * Get one or more (possibly multi-valued) attributes of a person. The
   * returned attributes are sorted by attribute scheme precedence and
   * then attribute precedence.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personGetAttributes()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetAttributes$Response(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * The attribute scheme(s) to fetch. This may
     * include any number of the attributes or pseudo-attributes, but it
     * may not include references or attribute chains (see the documentation
     * for the &#x60;fetch&#x60; parameter in this class).
     */
    attrs: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attributes'?: Array<Attribute>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonGetAttributesPath, 'get');
    if (params) {
      rb.path('scheme', params.scheme, {});
      rb.path('identifier', params.identifier, {});
      rb.query('attrs', params.attrs, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'attributes'?: Array<Attribute>;
        };
        }>;
      })
    );
  }

  /**
   * Get one or more (possibly multi-valued) attributes of a person.
   *
   * Get one or more (possibly multi-valued) attributes of a person. The
   * returned attributes are sorted by attribute scheme precedence and
   * then attribute precedence.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personGetAttributes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetAttributes(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * The attribute scheme(s) to fetch. This may
     * include any number of the attributes or pseudo-attributes, but it
     * may not include references or attribute chains (see the documentation
     * for the &#x60;fetch&#x60; parameter in this class).
     */
    attrs: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attributes'?: Array<Attribute>;
};
}> {

    return this.personGetAttributes$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attributes'?: Array<Attribute>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attributes'?: Array<Attribute>;
};
})
    );
  }

  /**
   * Path part for operation personGetGroups
   */
  static readonly PersonGetGroupsPath = '/person/{scheme}/{identifier}/groups';

  /**
   * Get all the groups to which the specified person belongs, including
   * indirect group memberships, via groups that include other groups.
   *
   * Get all the groups to which the specified person belongs, including
   * indirect group memberships, via groups that include other groups.
   * The returned list of groups is sorted by groupid.
   *
   * Note that some group memberships may not be visible to you. This
   * method will only return those group memberships that you have
   * permission to see.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of each group.
   *
   * NOTE: This method will not include cancelled groups.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personGetGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetGroups$Response(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonGetGroupsPath, 'get');
    if (params) {
      rb.path('scheme', params.scheme, {});
      rb.path('identifier', params.identifier, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'groups'?: Array<Group>;
        };
        }>;
      })
    );
  }

  /**
   * Get all the groups to which the specified person belongs, including
   * indirect group memberships, via groups that include other groups.
   *
   * Get all the groups to which the specified person belongs, including
   * indirect group memberships, via groups that include other groups.
   * The returned list of groups is sorted by groupid.
   *
   * Note that some group memberships may not be visible to you. This
   * method will only return those group memberships that you have
   * permission to see.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of each group.
   *
   * NOTE: This method will not include cancelled groups.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personGetGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetGroups(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}> {

    return this.personGetGroups$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
})
    );
  }

  /**
   * Path part for operation personGetInsts
   */
  static readonly PersonGetInstsPath = '/person/{scheme}/{identifier}/insts';

  /**
   * Get all the institutions to which the specified person belongs.
   *
   * Get all the institutions to which the specified person belongs. The
   * returned list of institutions is sorted by name.
   *
   * By default, only a few basic details about each institution are
   * returned, but the optional `fetch` parameter may be used
   * to fetch additional attributes or references of each institution.
   *
   * NOTE: This method will not include cancelled institutions.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personGetInsts()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetInsts$Response(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'institutions'?: Array<Institution>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonGetInstsPath, 'get');
    if (params) {
      rb.path('scheme', params.scheme, {});
      rb.path('identifier', params.identifier, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'institutions'?: Array<Institution>;
        };
        }>;
      })
    );
  }

  /**
   * Get all the institutions to which the specified person belongs.
   *
   * Get all the institutions to which the specified person belongs. The
   * returned list of institutions is sorted by name.
   *
   * By default, only a few basic details about each institution are
   * returned, but the optional `fetch` parameter may be used
   * to fetch additional attributes or references of each institution.
   *
   * NOTE: This method will not include cancelled institutions.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personGetInsts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetInsts(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'institutions'?: Array<Institution>;
};
}> {

    return this.personGetInsts$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'institutions'?: Array<Institution>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'institutions'?: Array<Institution>;
};
})
    );
  }

  /**
   * Path part for operation personIsMemberOfGroup
   */
  static readonly PersonIsMemberOfGroupPath = '/person/{scheme}/{identifier}/is-member-of-group/{groupid}';

  /**
   * Test if the specified person is a member of the specified group.
   *
   * Test if the specified person is a member of the specified group.
   *
   * NOTE: This may be used with cancelled people and groups.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personIsMemberOfGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  personIsMemberOfGroup$Response(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * The ID or name of the group.
     */
    groupid: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: boolean;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonIsMemberOfGroupPath, 'get');
    if (params) {
      rb.path('scheme', params.scheme, {});
      rb.path('identifier', params.identifier, {});
      rb.path('groupid', params.groupid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'value'?: boolean;
        };
        }>;
      })
    );
  }

  /**
   * Test if the specified person is a member of the specified group.
   *
   * Test if the specified person is a member of the specified group.
   *
   * NOTE: This may be used with cancelled people and groups.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personIsMemberOfGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personIsMemberOfGroup(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * The ID or name of the group.
     */
    groupid: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: boolean;
};
}> {

    return this.personIsMemberOfGroup$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: boolean;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: boolean;
};
})
    );
  }

  /**
   * Path part for operation personIsMemberOfInst
   */
  static readonly PersonIsMemberOfInstPath = '/person/{scheme}/{identifier}/is-member-of-inst/{instid}';

  /**
   * Test if the specified person is a member of the specified institution.
   *
   * Test if the specified person is a member of the specified institution.
   *
   * NOTE: This may be used with cancelled people and institutions, but
   * it will not include cancelled membership groups.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personIsMemberOfInst()` instead.
   *
   * This method doesn't expect any request body.
   */
  personIsMemberOfInst$Response(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * The ID of the institution.
     */
    instid: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: boolean;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonIsMemberOfInstPath, 'get');
    if (params) {
      rb.path('scheme', params.scheme, {});
      rb.path('identifier', params.identifier, {});
      rb.path('instid', params.instid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'value'?: boolean;
        };
        }>;
      })
    );
  }

  /**
   * Test if the specified person is a member of the specified institution.
   *
   * Test if the specified person is a member of the specified institution.
   *
   * NOTE: This may be used with cancelled people and institutions, but
   * it will not include cancelled membership groups.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personIsMemberOfInst$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personIsMemberOfInst(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * The ID of the institution.
     */
    instid: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: boolean;
};
}> {

    return this.personIsMemberOfInst$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: boolean;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: boolean;
};
})
    );
  }

  /**
   * Path part for operation personGetManagedGroups
   */
  static readonly PersonGetManagedGroupsPath = '/person/{scheme}/{identifier}/manages-groups';

  /**
   * Get all the groups that the specified person has persmission to edit.
   *
   * Get all the groups that the specified person has persmission to edit.
   * The returned list of groups is sorted by groupid.
   *
   * Note that some group memberships may not be visible to you. This
   * method will only include groups for which you have persmission to
   * see the applicable manager group memberships.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of each group.
   *
   * NOTE: This method will not include cancelled groups.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personGetManagedGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetManagedGroups$Response(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonGetManagedGroupsPath, 'get');
    if (params) {
      rb.path('scheme', params.scheme, {});
      rb.path('identifier', params.identifier, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'groups'?: Array<Group>;
        };
        }>;
      })
    );
  }

  /**
   * Get all the groups that the specified person has persmission to edit.
   *
   * Get all the groups that the specified person has persmission to edit.
   * The returned list of groups is sorted by groupid.
   *
   * Note that some group memberships may not be visible to you. This
   * method will only include groups for which you have persmission to
   * see the applicable manager group memberships.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of each group.
   *
   * NOTE: This method will not include cancelled groups.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personGetManagedGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetManagedGroups(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}> {

    return this.personGetManagedGroups$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
})
    );
  }

  /**
   * Path part for operation personGetManagedInsts
   */
  static readonly PersonGetManagedInstsPath = '/person/{scheme}/{identifier}/manages-insts';

  /**
   * Get all the institutions that the specified person has permission to
   * edit.
   *
   * Get all the institutions that the specified person has permission to
   * edit. The returned list of institutions is sorted by name.
   *
   * Note that some group memberships may not be visible to you. This
   * method will only include institutions for which you have permission
   * to see the applicable editor group memberships.
   *
   * By default, only a few basic details about each institution are
   * returned, but the optional `fetch` parameter may be used
   * to fetch additional attributes or references of each institution.
   *
   * NOTE: This method will not include cancelled institutions.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personGetManagedInsts()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetManagedInsts$Response(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'institutions'?: Array<Institution>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonGetManagedInstsPath, 'get');
    if (params) {
      rb.path('scheme', params.scheme, {});
      rb.path('identifier', params.identifier, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'institutions'?: Array<Institution>;
        };
        }>;
      })
    );
  }

  /**
   * Get all the institutions that the specified person has permission to
   * edit.
   *
   * Get all the institutions that the specified person has permission to
   * edit. The returned list of institutions is sorted by name.
   *
   * Note that some group memberships may not be visible to you. This
   * method will only include institutions for which you have permission
   * to see the applicable editor group memberships.
   *
   * By default, only a few basic details about each institution are
   * returned, but the optional `fetch` parameter may be used
   * to fetch additional attributes or references of each institution.
   *
   * NOTE: This method will not include cancelled institutions.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personGetManagedInsts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetManagedInsts(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'institutions'?: Array<Institution>;
};
}> {

    return this.personGetManagedInsts$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'institutions'?: Array<Institution>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'institutions'?: Array<Institution>;
};
})
    );
  }

  /**
   * Path part for operation personGetAttribute
   */
  static readonly PersonGetAttributePath = '/person/{scheme}/{identifier}/{attrid}';

  /**
   * Get a specific attribute of a person.
   *
   * Get a specific attribute of a person.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `personGetAttribute()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetAttribute$Response(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * The ID of the attribute to fetch.
     */
    attrid: number;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attribute'?: Attribute;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, PersonService.PersonGetAttributePath, 'get');
    if (params) {
      rb.path('scheme', params.scheme, {});
      rb.path('identifier', params.identifier, {});
      rb.path('attrid', params.attrid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'attribute'?: Attribute;
        };
        }>;
      })
    );
  }

  /**
   * Get a specific attribute of a person.
   *
   * Get a specific attribute of a person.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `personGetAttribute$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  personGetAttribute(params: {

    /**
     * The person identifier scheme. Typically this
     * should be &#x60;&quot;crsid&quot;&#x60;, but other identifier schemes may be
     * available in the future, such as &#x60;&quot;usn&quot;&#x60; or
     * &#x60;&quot;staffNumber&quot;&#x60;.
     */
    scheme: string;

    /**
     * The identifier of the person (typically
     * their CRSid).
     */
    identifier: string;

    /**
     * The ID of the attribute to fetch.
     */
    attrid: number;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attribute'?: Attribute;
};
}> {

    return this.personGetAttribute$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attribute'?: Attribute;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'attribute'?: Attribute;
};
})
    );
  }

}
