<campl-ngx-page-nav *ngIf="initialIndex"
  [heading]="'Your projects for: '"
  [initialIndex]="initialIndex" 
  [values]="offerings" 
  (current)="populateProjects($event)">
</campl-ngx-page-nav>

<!--the ngIf waits until am offering is found as the edit dialog requires this-->
<app-y4-projects-table 
*ngIf="offering"
[add]="true" [edit]="true" [copy]="true" [remove]="true"
[submit]="true" [type]="'a'" [offering]="offering?.id"
[parentData$]="projects$" 
(submitProjectO)="submitProject($event)" (copyProjectO)="copyProject($event)"></app-y4-projects-table>
