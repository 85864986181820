import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SgtopicService } from '../lib/services/sgtopic.service';
import { Sgtopic } from '../lib/models/sgtopic';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';


/**
 * This allows a use to edit a subjectgrouptopics
 *
 * If might be mor ein line with the user interface to provide
 * as a dialog
 */
@Component({
  selector: 'app-subject-group-topic-edit',
  templateUrl: './subject-group-topic-edit.component.html',
  styleUrls: ['./subject-group-topic-edit.component.css']
})
export class SubjectGroupTopicEditComponent implements OnInit {

  sgtopicForm: FormGroup;
  sgtopic: Sgtopic;
  pageTitle = '';

  constructor(private _fb: FormBuilder,
              private _route: ActivatedRoute,
              private _sgtopics: SgtopicService,
              private _messageBuffer: CamplNgxMessageBufferService) { }


  ngOnInit(): void {
    this.setFormDefaults();
    this._route.paramMap.subscribe(
      params => {
        const id = +params.get('id');
        // this.populateSgtopic(id);
      }
    );
  }

  setFormDefaults() {
    this.sgtopicForm = this._fb.group({
      subjGroup: ['', [Validators.required]],
      subjGroupTopic: ['', [Validators.required]],
      subjActive: false
    });
  }

  populateSgtopic(id: number): void {
    // Show how to populate a form
    // setValue - all || patchValue - some
    if (id > 0) {
      this._sgtopics.getSgtopic(id)
        .subscribe({
          next: (topic: Sgtopic) => this.displaySgtopic(topic),
          error: err => this._messageBuffer.sendMessage('Issue finding topic ID ' + id, 'error')
        });
    } else {
      this.sgtopic = new Sgtopic(id);
      this.displaySgtopic(this.sgtopic);
    }
  }

  displaySgtopic(topic): void {

    if (this.sgtopicForm) {
      this.sgtopicForm.reset();
    }
    this.sgtopic = topic;

    if (this.sgtopic.id === 0) {
      this.pageTitle = 'Add a New Topic';
    } else {
      this.pageTitle = `Edit Topic: ${this.sgtopic.subjGroupTopic}`;
    }
    this.sgtopicForm.patchValue({
      subjGroup: topic.subjGroup,
      subjGroupTopic: topic.subjGroupTopic,
      subjActive: topic.subjActive
    });
  }

  save() {
    // Methods of testing valid input:
    //
    // y4projectForm.controle.projTitle.valid
    // y4projectForm.get('projTitle').valid
    //

    const t = { ...this.sgtopicForm.value };
    //;
    if (this.sgtopic.id === 0) {
      this._sgtopics.createSgtopic(t)
        .subscribe({
          next: (sgtopic: Sgtopic) => this.onSaveComplete(sgtopic),
          error: err => this.onSaveError(err, t)
        });
    } else {
      this._messageBuffer.sendMessage('TODO - implement updateSgtopic ');
    }

    //;
    //;
  }
  onSaveComplete(sgtopic: Sgtopic): void {
    // Reset the form to clear the flags
    this.sgtopicForm.reset();
    this.setFormDefaults();
    this._messageBuffer.sendMessage('SAVED: \'' + sgtopic.subjGroup + ' - ' + sgtopic.subjGroupTopic, 'information');
    // this.router.navigate(['/products']);
  }
  onSaveError(err, p) {
    this._messageBuffer.sendMessage('NOT SAVED: ' + JSON.stringify(p) + ' error: ' + err);
  }
}
