
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { Sgtopic } from '../models/sgtopic';
import { EngineeringArea } from '../models/engineeringarea';
import { AppService } from './app.service';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { ErrorService } from './error.service';
@Injectable({
  providedIn: 'root'
})

export class EngineeringareaService {



  baseurl = '/api/v1/engineeringarea';
  appendlimit = '?limit=10000';
  // list$=  new ReplaySubject<EngineeringArea[]>();

  areas$ = this.http.get<EngineeringArea[]>(this.baseurl + this.appendlimit).pipe(
    map(
      res => res.map((ea: EngineeringArea) => new EngineeringArea().deserialize(ea))),
    shareReplay() );

  list$  = this.areas$;

  constructor(
    private http: HttpClient,
    pageMessage: CamplNgxMessageBufferService,
    private errorService: ErrorService
  ) { }

  // runs on app startup (app.component) - so our data is ready
  init(): Observable<EngineeringArea[]> {
    // Startup logic here
    // //
    // this.getEngineeringAreas();
    // this.list = this.http.get<Sgtopic[]>(this.baseurl+this.appendlimit)
    return this.list$;
  }

  /**
   * Helper
   * added to enable spying during tests
   *
   * TODO: can we replace all .list$ calls with .getList$() ? or change the tests to remove this method?
   * TODO: replace init() cals with getList$() calls
   */
   getList$(): Observable<EngineeringArea[]> {
    return this.list$;
  }

  /**
   * TODO rewrite (when needed) with observable pattern
   *
   * @param ea
   */
  // createEngineeringArea(ea: EngineeringArea): Observable<EngineeringArea> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   delete ea.id
  //   return this.http.post<EngineeringArea>(this.baseurl, ea, { headers })
  //     .pipe(
  //       tap(data => //,
  //       catchError(this.handleError)
  //     );
  // }

  // private initializeEngineeringArea(): EngineeringArea {
  //   // Return an initialized object
  //   return new EngineeringArea();
  // }

  /**
   * TODO replace with observable pattern
   *
   * @param id
   */
  // getEngineeringArea(id: number): Observable<Sgtopic> {
  //   if (id === 0) {
  //     return of(this.initializeEngineeringArea());
  //   }
  //   const url = `${this.baseurl}/${id}`;
  //   return this.http.get<EngineeringArea>(url)
  //     .pipe(
  //       tap(data => //,
  //       catchError(this.handleError)
  //     );

  // }


}
