import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
  status: string;
  message: string;
}


@Component({
  selector: 'app-y4-project-choices-status-dialog',
  templateUrl: './y4-project-choices-status-dialog.component.html',
  styleUrls: ['./y4-project-choices-status-dialog.component.scss']
})
export class Y4ProjectChoicesStatusDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<Y4ProjectChoicesStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void { }

  okay(): void {
    this.dialogRef.close();
  }


}
