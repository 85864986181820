import { Injectable } from '@angular/core';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { Observable, of, throwError } from 'rxjs';
import { RollbarErrorHandler } from '../../y4-log-rollbar';

// Service to be used for cathing the HTTP errors and
// sending the information elsewhere

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  // should be private?!
  constructor(
      //private pageMessage: CamplNgxMessageBufferService,
    //private errorHandler: RollbarErrorHandler
    ) { }

  public handleError(err) {
    // TODO send the server to some remote logging infrastructure
    // instead of just logging it to the console
    console.log("HELP ERROR IN HERE!!!")
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status} ${err.error}`;
    }
    console.error("an error occured: "+err);
    return throwError({message:errorMessage});
  }


  public handleErrorPage<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      const errorMessage = 'Operation ' + operation + ' failed';
      error.localMessage = errorMessage;
      console.error(error.localMessage);
      //this.pageMessage.sendMessage(errorMessage, 'Error');
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      //this.errorHandler.handleError(errorMessage)
      return throwError(error);
    };
  }


  /**
   * Temporary handler for our error messages
   *
   * (a side effect that displays the error)
   * displays: "An Error occured" in the templates message area
   *
   *
   * @param err - error message (to console.error)
   */
  public handleErrorToPage(err) {
    console.error('error: ' + err);
    //this.errorHandler.handleError('An Error Occured')
    //this.pageMessage.sendMessage('An Error occured!', 'alert');
    return of('An Error occured'); // Rxjs catchError() requires an observable to be returned
  }

  /**
   * Public Accessor
   */
  //public getPageMessage(){
  //  return this.pageMessage;
  //}
}
