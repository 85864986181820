<h3 mat-h3>Choice reminders</h3>

The application can currently send two types of messages to students. The last time each type of message was sent to the student is listed against their record below.
<br/>
<br/>
You may only wish to send reminders to students with incomplete choices.
Also, as projects may be allocated to multiple students, you may not want students to remove choices
from their lists at the moment.
<br/>
<br/>
The messages highlighting pre-allocation are also sent during the process of allocating a project, though the user doing the
allocation may choose to not send the notification at that stage.

<br/>
<br/>
<div *ngIf="(preallocated$ | async)?.length > 0; else noPreallocs">
    <h4>
        <span class="valign-center">
            <mat-icon>report_problem</mat-icon>{{ (preallocated$ | async)?.length }} choice(s) have been affected by pre-allocation
        </span>
    </h4>

    <div *ngIf="(queuedPreallocMsg$|async); else displayPrealloc">
        The following email is queued for: {{(msgRecipients(queuedPreallocMsg$|async))}}

        <pre style="white-space: pre-line" [innerHTML]="msgSubject(queuedPreallocMsg$|async)">
        </pre>

        <pre style="white-space: pre-line" [innerHTML]="msgMessage(queuedPreallocMsg$|async)">
        </pre>

        <p>A copy of this message will be sent to the application's configured mailbox.</p>
        <p>Please note that although the particular project affecting the students choices is not 
            mentioned in this email, it is highlighed to them when they log in and view their selections.</p>

        <button mat-raised-button color="primary" (click)="sendPreallocMessage()">
            Send emails
        </button>

        <button mat-raised-button color="warn" (click)="cancelPreallocMessage()">
            Cancel
        </button>
    </div>
    <ng-template #displayPrealloc>
        <!--<div *ngFor="let choice of (preallocated$ | async)">-->
        <div *ngFor="let choice of (lastPreallocatedSend$ | async)">
            Student: {{ choice.pslStudent.display() }} - project title: {{ choice.pslY4project.projTitle }} latest
            message to this student: {{ choice['lastMessage'] }}
        </div>

        <button mat-raised-button color="primary" (click)="genPreallocEmails()">
            Generate emails
        </button>

    </ng-template>


</div>

<ng-template #noPreallocs>
    <h4>
        <span class="valign-center">
            <mat-icon>insert_emoticon</mat-icon> {{ (preallocated$ | async)?.length }} choice(s) have been affected by
            pre-allocation
        </span>
    </h4>
</ng-template>


<br/>
<br/>



<div *ngIf="(incomplete$ | async)?.length > 0; else noIncomplete">
    <h4>
        <span class="valign-center">
            <mat-icon>report_problem</mat-icon>{{ (incomplete$ | async)?.length }} students(s) still need to complete
            their choices
        </span>
    </h4>

    <div *ngIf="(queuedIncompleteMsg$|async); else displayIncomplete">
        The following email is queued for: {{(msgRecipients(queuedIncompleteMsg$|async))}}

        <pre style="white-space: pre-line" [innerHTML]="msgSubject(queuedIncompleteMsg$|async)">
        </pre>

        <pre style="white-space: pre-line" [innerHTML]="msgMessage(queuedIncompleteMsg$|async)">
        </pre>

        <p>A copy of this message will be sent to the application's configured mailbox.</p>

        <button mat-raised-button color="primary" (click)="sendIncompleteMessage()">
            Send emails
        </button>

        <button mat-raised-button color="warn" (click)="cancelIncompleteMessage()">
            Cancel
        </button>
    </div>
    <ng-template #displayIncomplete>
        <!--<div *ngFor="let choice of (preallocated$ | async)">-->
        <div *ngFor="let stu of (incomplete$ | async)">
            Student: {{ stu.display() }} latest message to this student: {{ (stu['lastMessage'])? stu['lastMessage'] : 'N/A' }}
        </div>

        <button mat-raised-button color="primary" (click)="genIncompleteEmails()">
            Generate emails
        </button>

    </ng-template>


</div>


<ng-template #noIncomplete>
    <h4>
        <span class="valign-center">
            <mat-icon>insert_emoticon</mat-icon> All students have valid choices!
        </span>
    </h4>
</ng-template>
