
    <mat-form-field class="example-full-width" [formGroup]="autoForm">
        <mat-label>{{label}}</mat-label>
        <input type="text" placeholder={{placeholder}} matInput
            [matAutocomplete]="auto" formControlName="myauto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.display()}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
