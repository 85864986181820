import { Directive, Input, OnInit } from '@angular/core';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Auth } from '../models/auth';
import { EnvironmentsService } from '../services/environments.service';

/**
 * Structural Directive - used to show an element if the use has permissions
 *
 * Warning: Over use of this directive will lead to complicated components (to test at least)
 *
 * @example
 * // provides access to users with roles included in allocationsAction
 * <div *appHasPerm="'allocationsAction'" style="float:right">
 */
 // TODO: populate the lookup from config or other means that can be synchronised with the backend
 // TODO: (possibly) extend this to user type and app status? eg student selectionOpen

@Directive({
  selector: '[appHasPerm]'
})
export class HasPermDirective implements OnInit {

  private _requiredPerm = '';
  private _userRoles = [''];
  private _calls = 0;

  @Input('appHasPerm') set hasPerm(permType: string) {

    this._requiredPerm = permType;

  }

  _updateView(){
    this._calls++;
    if (this._testAccess(this._userRoles, this.envService.env.permsLookup[this._requiredPerm])) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }

  }

  ngOnInit() {
    this.userService.roles$
    .subscribe( (roles) => {
      this._userRoles = roles;
      this._updateView();
    });
  }

  /**
   * Returns whether roles intersect
   *
   * @param userroles - an array of users roles
   * @param accessroles - array of roles that provide access
   */
  private _testAccess(userroles: string[], accessroles: string[]): boolean {
    return (userroles.filter(value => accessroles && accessroles.includes(value)).length > 0);
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userService: AuthService,
    private envService: EnvironmentsService) { }

}
