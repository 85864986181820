import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

/**
 * As name suggests tests whether the current user is a staff member
 */
@Injectable({
  providedIn: 'root'
})
export class IsStaffGuard implements CanActivate {

  constructor(
    private userService: AuthService
  ){}

  canActivate(): Observable<boolean>  {
    return this.userService.getMe$().pipe(
      map(me => me.staff.id > 0)
     );
  }

}
