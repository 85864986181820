import { AfterViewInit, Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Y4projectsTableDataSource, Y4projectsTableItem } from './y4-projects-table-datasource';
import { Y4ProjectService } from '../lib/services/y4-project.service';

import { MatDialog } from '@angular/material/dialog';
import { Y4ProjectEditorComponent } from '../y4-project-editor/y4-project-editor.component';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { Y4project } from '../lib/models/y4project';
import { Y4ProjectApprovalDialogComponent } from '../y4-project-approval-dialog/y4-project-approval-dialog.component';
import { Y4ProjectSummaryDialogComponent } from '../y4-project-summary-dialog/y4-project-summary-dialog.component';
import { outputProxy } from 'shallow-render/dist/lib/tools/output-proxy';
import { take } from 'rxjs/operators';
import { Y4ProjectRemoveDialogComponent } from '../y4-project-remove-dialog/y4-project-remove-dialog.component';

import { CourseOffering } from '../lib/models/courseoffering';

/**
 * A table from which popup edit dialogs are produced
 *
 * @param filter - a function (i: 4Yproject) => {returns condition}
 * @param displayedColumns - an array of column names that will be displayed
 */
@Component({
  selector: 'app-y4-projects-table',
  templateUrl: './y4-projects-table.component.html',
  styleUrls: ['./y4-projects-table.component.css']
})
export class Y4ProjectsTableComponent implements AfterViewInit, OnInit {



  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Y4projectsTableItem>;
  @Input() parentData$; // pass an observable fro parent component
  @Output() submitProjectO = new EventEmitter<Y4project>();
  @Output() copyProjectO = new EventEmitter<Y4project>();
  // TODO there must be a better way than this
  @Input() edit = false;
  @Input() remove = false;
  @Input() approve = false;
  @Input() add = false;
  @Input() copy = false;
  @Input() submit = false;
  @Input() ownNotes = true;
  // TODO is type needed here? as an admin we will just be presented wit hthe options!!
  @Input() type: string;
  @Input() offering: number; // actually courseofferingid
  @Input() offering$: Observable<CourseOffering>; // actually courseofferingid
  // TOOD why is this here?
  // A? to prevent the confusion of generating project as someone else in teh users list?  
  // But we are not enforcing?!
  @Input() showTypeCourse = false; // by default hide this from our user
  @Input() showSummary = true; // not required by approval list




  @Input() displayedColumns = ['id', 'title', 'description', 'status', 'action'];
  // NB in y4-project-my-choices this created too many queries:
  //
  // filterProjectsTopic(topic: string) {
  //   return function(project: Y4project){
  //       return project.projSubjectgroupTopic.subjGroupTopic === topic}
  // }
  // <app-y4-projects-table [filter]="filterProjectsTopic(topic.subjGroupTopic)"></app-y4-projects-table>>
  filteredProjects$ = new ReplaySubject<Y4projectsTableItem[]>();

  dataSource: Y4projectsTableDataSource;

  // @Input() filter = (i: Y4project) => {return (( i.projType === 'a' || i.projType === 'b')) && i.id > 2 }
  // return filters[key](item[key]);


  // TODO remove this and pass as an observable
  // owner -
  // manage -
  // approve -
  tableData$ = this.y4projectsService.projects$;


  constructor(
    private y4projectsService: Y4ProjectService,
    public dialog: MatDialog
  ) {
  }


  @Input() filter = (i: Y4project) => true;


  ngOnInit() {
    // by subscribing to the projects$ subject we can send filtered results
    // to our datasource
    // TODO could this be done with .pipe(filter).subscribe()
    // rather than creating a new observable??
    //
    // Could we pass an observable?
    // or should we be passing an array - so that it can be used elsewhere without
    // repeatedly creating observables

    if (this.parentData$) {
      this.tableData$ = this.parentData$;
    }

    this.tableData$.subscribe(
      (projs) => {
        this.filteredProjects$.next(
          projs.filter(this.filter)
        );
      });
    this.dataSource = new Y4projectsTableDataSource(this.filteredProjects$);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
  alert(istring: string) {
    alert(JSON.stringify(istring));
  }

  /**
   * TODO rename: editProjectDialog
   *
   * @param id - project id, 0 for new Project.
   *
   * deps:
   *   import {MatDialog} from '@angular/material/dialog';
   */
  editY4ProjectDialog(id: number = 0, courseOfferingId?: number, y4projectType?: string, showTypeCourse?: boolean) {
    //immediately create an observable take and subscribe
    //this.offering$
      //.pipe(take(1))
      //.subscribe((off) => {
      //  const offID = off.id
      //  console.log("DEBUG offering: "+offID)
        const dialogRef =
          this.dialog.open(Y4ProjectEditorComponent, {
            height: '90%',
            width: '70%',
            data: {
              id,
              courseOfferingId, //offID, // this will be used to set the course for owners
              y4projectType,
              showTypeCourse
            },
            disableClose: true
          });

        // Todo take(1) to action unsubscribe
        dialogRef.afterClosed().subscribe(result => {
          //;
          // TODO add an emitter that will trigger a list refresh
        });
      //})


  }
  /**
   *
   * A placeholder that is unlikely to be used
   * if it is then it opens up an R U sure dialog before actioning!
   *
   * @param id -  the y4projectid to remove
   *
   */
  removeY4ProjectDialog(project: Y4project) {

    const dialogRef =
      this.dialog.open(Y4ProjectRemoveDialogComponent, { height: '90%', width: '70%', data: { project } });

    dialogRef.afterClosed().subscribe(approved => {
      if (approved) {
        // btw we are optimistically approving
        //;
      } else {
        //;
      }
    });
    //console.log(project)
    //this.y4projectsService.removeProject(project).pipe(take(1)).subscribe()
  }

  /**
   *
   * TODO replace this with an eventemitter to handle the action in the parent component
   *
   * @param project - project to pass to the dialog
   */
  approveY4ProjectDialog(project: Y4project) {
    const dialogRef =
      this.dialog.open(Y4ProjectApprovalDialogComponent, { height: '90%', width: '70%', data: { project } });

    dialogRef.afterClosed().subscribe(approved => {
      if (approved) {
        // btw we are optimistically approving
        //;
      } else {
        //;
      }
    });
  }

  /**
   * Emit a submit project event
   */
  submitProject(project: Y4project) {
    this.submitProjectO.emit(project);
  }
  /**
   * Copy a project event
   */
  copyProject(project: Y4project) {
    this.copyProjectO.emit(project);
  }


  viewProjectSummaryDialog(row) {
    this.dialog.open(Y4ProjectSummaryDialogComponent, { height: '90%', data: { project: row } });
  }
}
