import { Component, OnInit } from '@angular/core';

import { Y4ProjectService } from '../lib/services/y4-project.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-y4-example-projects',
  templateUrl: './y4-example-projects.component.html',
  styleUrls: ['./y4-example-projects.component.scss']
})
export class Y4ExampleProjectsComponent implements OnInit {

  // This might be better passed as an input
  // fix typo
  examples$ = this._y4projectService.projectExamplesList$;

  displayArr = [];

  constructor(private _y4projectService: Y4ProjectService) {
  }


  ngOnInit(): void {
  }

  hideDetails(hideid){
    this.displayArr = this.displayArr.filter( v => v != hideid);
  }

}
