

import { Deserializable } from './deserializable.model';

/**
 * Used to upload via the choices admin page
 */
export class Allocation implements Deserializable {
    crsid: string;
    reference: string;
    projid: number;

    deserialize(input: any): this {
        //;
        Object.assign(this, input);
        return this;
    }
  }
