<h3 class="mat-subheading">Cross-reference topics:</h3>
The project will also be listed under these topics
<mat-list>
    <mat-list-item *ngIf="selectedTopics?.length === 0">(None)</mat-list-item>
    <mat-list-item *ngFor="let top of selectedTopics">
        <span>
            <button mat-icon-button aria-label="Button to remove cross-reference topic"
                matTooltip="Remove cross-reference">
                <mat-icon (click)="removeTopic(top.id)">delete</mat-icon>
            </button>
        </span>
        <span>
            <span matLine>{{top.label()}}</span>
        </span>
    </mat-list-item>
</mat-list>


<!--wrap in component - build list via autocomplete-->
<mat-form-field class="example-full-width" [formGroup]="topicsForm">
    <input type="text" placeholder="Add cross-reference" aria-label="Add cross-reference" matInput
        [matAutocomplete]="autoTopics" formControlName="topics">
    <mat-hint>Enter text to find a cross-reference topic</mat-hint>
</mat-form-field>
<mat-autocomplete #autoTopics="matAutocomplete" [displayWith]="clearOnSelectFn">
    <mat-option *ngFor="let top of filteredTopics$ | async" [value]="selectedTopics">
        <div (click)="optionTopicsClicked($event, top)">
            {{top.label()}} -- label
        </div>
    </mat-option>
</mat-autocomplete>
