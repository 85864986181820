import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService } from '../lib/services/auth.service';

@Component({
  selector: 'app-noaccount',
  templateUrl: './noaccount.component.html',
  styleUrls: ['./noaccount.component.css']
})
export class NoaccountComponent implements OnInit {

  username$ = this.authService.me$.pipe(map(me => me.username));

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

}
