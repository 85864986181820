import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

/**
 * Tests whether the current user can access the browse lists
 *
 * The can if they:
 *
 * a, are a staff member
 * b, are a student whose EGT3 course is select
 */
@Injectable({
  providedIn: 'root'
})
export class SelectionActiveGuard implements CanActivate {
  constructor(private authService: AuthService) {

  }
  // canActivate(
  //  next: ActivatedRouteSnapshot,
  //  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

  canActivate(): Observable<boolean> {
    // if
    //    user.student is enrolled and SELECTION is active or
    //    user.student is enrolled and Y4PBROWSE is active or
    //    user.isStaff
    // https://stackoverflow.com/questions/57574976/rxjs-angular-two-observablebooleans-should-return-observableboolean

    this.authService.updateMe(); // triggers a check of the user - we have share() attached to me$
    return this.authService.getMe$().pipe(
      map(me => {
        const stu = me.egt3Student();
        return (me.isStaff() || (stu && ( stu.egt3Selection() ) ) ) ? true : false;
      })
    );

  }

}
