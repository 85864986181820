import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from '../models/role';
import { map, share, switchMap } from 'rxjs/operators';
import { Grouprole } from '../models/grouprole';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private baseurl = 'api/v1/role';
  private rgbaseurl = 'api/v1/usergrouprole';
  private limit = "limit=1000"

  private refreshRoleGroupsSubject$ = new BehaviorSubject(1);
  public refreshRoleGroupsAction$ = this.refreshRoleGroupsSubject$.asObservable();

  public roles$ = this.http.get<Role[]>(this.baseurl).pipe(share());

  public roleGroups$ = this.refreshRoleGroupsAction$.pipe(
    switchMap(res => this.http.get<Grouprole[]>(this.rgbaseurl+"?"+this.limit).pipe(share())),
    share()
  );

  constructor(
    private http: HttpClient) { }

  /**
   * returning an observable!
   * TODO convert to Subject asObservable combineLatest
   *
   * @param role - role ID we wish to retrieve the user of
   */
  // Deprecated?
  retrieveRolesUsers(role: Role) {
    return this.http.get<Role>(this.baseurl + '/' + role.id);
  }

  refreshRoleGroups() {
    this.refreshRoleGroupsSubject$.next(1);
  }

}
