import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Auth } from '../models/auth';
import { Role } from '../models/role';
import { ErrorService } from './error.service';


/**
 * Although we are called users the model is actually called auth!?!
 */

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseurl = '/api/v1/user';

  private userRoleSubject$ = new BehaviorSubject<Role[]>(null);
  public userRole$ = this.userRoleSubject$.asObservable();

  // TODO check whether used?
  public usersWithRole$ = this.userRole$.pipe(switchMap(roles => this.http.get(this.baseurl + '?roles=' + roles)));

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  getuserWithRole(role: Role){
    this.userRoleSubject$.next([role]);
  }

  removeRole(userid: number, role: Role){
    return this.http.delete<Auth>(this.baseurl + '/' + userid + '/roles/' + role.id);
  }


  // Not used?
  retrieveUsersWithRole(role: Role){
    return this.http.get<Auth[]>(this.baseurl + '?roles=' + role.id);
  }

}
