import { CourseOffering } from './courseoffering';
import { Deserializable } from './deserializable.model';

export class Appstatus implements Deserializable {

    // constructor(
    //     public staCode: string,
    //     public staDescription: string,
    //     public staYear: number,
    //     public staStartsOn: string, // but this is date time
    //     public staStartsOnYTWD: string, // Term Week Day
    //     public staEndsOn: string, // date time
    //     public staEndsOnTWD: string // Term Week Day
    // ) { }

    id: number;
    staCode: string;
    staDescription: string;
    staYear: number;
    staStartsOn: Date; // but this is date time
    staStartsOnYTWD: string; // Term Week Day
    staEndsOn: Date; // date time
    staEndsOnTWD: string; // Term Week Day
    staCourseOffering: CourseOffering;

    /**
     * Call this to cnvert response from database into an appstatus object
     * 
     * @param input - object we wish to marshall into our appstatus
     * @returns 
     */
    deserialize(input: any): this {
        Object.assign(this, input);
        if (this.staStartsOn) { this.staStartsOn = new Date(this.staStartsOn)}
        if (this.staEndsOn) { this.staEndsOn = new Date(this.staEndsOn)}
        if (this.staCourseOffering) { this.staCourseOffering = new CourseOffering().deserialize(input.staCourseOffering); }
        return this;
    }

    startDate(): Date {
        return new Date(this.staStartsOn);
    }
    endDate(): Date {
        return new Date(this.staEndsOn);
    }

    // Now implemented in the backend addyear endpoint
    // /**
    //  * nextStartDate
    //  *
    //  * adds a year to the current startDate
    //  */
    // nextStartsOnDate(): string{
    //     return this._addYear(this.staStartsOn)
    // }

    // /**
    //  * nextEndDate
    //  *
    //  * adds a year to the current startDate
    //  */
    // nextEndsOnDate(): string{
    //     return this._addYear(this.staEndsOn)
    // }

    // /**
    //  * _addYear
    //  *
    //  * return a mysql date for input date + 1 year
    //  *
    //  * @param idate
    //  */
    // _addYear(idate: string): string{
    //     let s = new Date(idate)
    //     s.setFullYear(s.getFullYear()+1)
    //     return s.toISOString()
    // }
    info(): string {
        return '' +
        this.id + ' ' +
        this.staYear + ' -> '
        + this.staCode + ': ' + this.staDescription +
        ' starts: ' + this.startDate().toLocaleDateString() +
        ' ends: ' + this.endDate().toLocaleDateString();
    }
}
