<h3 class="mat-subheading">Assistants:</h3>
<div *ngIf="assistantForm.get('assistants').value != '' && (filteredAssistants$ | async)?.length < 1">
    Your search did not bring up any available assistants.<br/>
    Please email the Teaching Office with the details of the assistant you wish to add.<br/>
    Once added to the selection list you will be able to add them to this project</div>
<mat-list>
    <mat-list-item *ngIf="selectedAssistants?.length === 0">(None)</mat-list-item>
    <mat-list-item *ngFor="let user of selectedAssistants">
        <span><button mat-icon-button aria-label="Button to remove assistant"
                matTooltip="Remove assistant">
                <mat-icon (click)="removeAssistant(user.id)">delete</mat-icon>
            </button></span>
        <span>
            <span matLine>Name: {{ user.display_name() }}</span>
            <span matLine>Email {{ user.email() }}</span>
            <span matLine>Contact Location {{ user.stfLocation }}</span>
        </span>
    </mat-list-item>
</mat-list>
<!--wrap in component - build list via autocomplete-->
<mat-form-field class="example-full-width" [formGroup]="assistantForm">
    <input type="text" placeholder="Add assistant" aria-label="Add assistant" matInput
        [matAutocomplete]="autoAssist1" formControlName="assistants">
    <mat-hint>Enter text to find users by crsid or last name</mat-hint>
</mat-form-field>
<mat-autocomplete #autoAssist1="matAutocomplete" [displayWith]="clearOnSelectFn">
    <mat-option *ngFor="let assistant of filteredAssistants$ | async" [value]="selectedAssistants">
        <div (click)="optionAssistantClicked($event, assistant)">
            {{ assistant.stfPrefName }} {{ assistant.stfSurname }} ({{assistant.stfCrsid}})
        </div>
    </mat-option>
   
</mat-autocomplete>
<br />
<!--end wrap in component - build list via autocomplete-->