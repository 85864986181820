import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Y4projectselection } from '../models/y4projectselection';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { Student } from '../models/student';

/**
 * The Choices Management Service used to report and action choice related tasks
 *
 * List students whose chocies are not valie (still need to complete)
 * List choices which have been preallocated to others
 *
 * Produce messages for students:
 *
 * Notifing them that (a) choice has been preallocated to others
 * Notifing them that that they still need to complete their choices
 */

@Injectable({
  providedIn: 'root'
})
export class ChoicesManagementService {

  httpOptions;
  base_url = '/api/v1/';

  /**
   * PREALLOCATED MESSAGES
   */

  // The selections affected by a preallocation
  public preallocated$ = this.http.get(this.base_url + 'choices/preallocated')
    .pipe(
      map(
        res => res['results']
          .map(r => new Y4projectselection()
            .deserialize(r)
            .deserializeProject()
            .deserializeStudent()))
            //,
      //catchError(err => this.errorService.handleError(err))
      );

  public preallocMessageQueue$ = this.http.get(this.base_url + 'message?msgBatchId');

  // The last time a prealloacted message was sent
  public lastPreallocatedSend$ = this.http.get(
    this.base_url + 'message?msgStatus=SENT&sort=msgSentOn%20desc&limit=1&msgType=choiceAllocatedNotify');

  /**
   * The last times preallocated messages that were sent to these students
   *
   * @param stuids
   * @returns array of messages (TODO create model)
   */
  public lastPreallocatedSendStus$(stuids) {
    if (!stuids ||stuids.length===0){stuids=[-9999999];} // no existant student
    return this.http
      .get<Student[]>(this.base_url + 'student?where={"id":{"in":[' + stuids + ']}}&populate=messages') //studnet
      // filter out the latest sent messages for each student
      .pipe(
        map(stus => stus.map(s => {
          s['messages'] = s['messages']
            .filter(m => m.msgType === 'choiceAllocatedNotify' && m.msgStatus === 'SENT')
            .sort((a, b) => b.id - a.id)[0];
          return s;
        }))
      );
  }

  /**
   * INCOMPLETE MESSAGES
   */

  // This should include the messages for the user
  public incomplete$ = this.http.get(this.base_url + 'choices/valid/0')
    .pipe(
      map(
        res => res['results']
          .map(s => new Student().deserialize(s))
      ),
      tap( stus => console.log(stus)),
      map(
        //append the latest message as ['latestMessage']
        stus => stus.map(
          s => {
            s['messages'] = s['messages'].filter( m => m.msgType === 'studentChoiceReminders'  && m.msgStatus === 'SENT' )
            .sort((a, b) => b.id - a.id)[0];
            s['lastMessage'] = (s['messages'])? s['messages']['msgSentOn'] : 'N/A';
            return s;
          }
        )
      ),
      // debug comment not required
      // tap( stus=> console.log(stus[0]['lastMessage'])),

    );



  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  /**
   * PREALLOCATED MESSAGES
   */
  /**
   * Generate preallocation warnings to send to students
   *
   * @param pslIds - The selection IDs
   *
   * return an observable that will mark completetion of the
   */
  genPreallocWarnings$(pslids) {
    return this.http.post(this.base_url + 'message/create/prealloc', { pslids }, this.httpOptions);
  }



  /**
   * Cancel the send of a message
   *
   * @param msgid
   */
  cancelPreallocWarnings$(msgid) {
    console.log(msgid);
    return this.http.patch(this.base_url + 'message/' + msgid, { msgStatus: 'CANCELLED' });
  }

  /**
   * Send the message
   *
   */
  sendPreallocWarnings$(msgid) {
    return this.http.post(this.base_url + 'message/send/', { id: msgid });
  }

  /**
   * Generate and send the message
   *
   * @param pslids
   */

  genAndSendPrealloc$(pslids) {
    return this.genPreallocWarnings$(pslids)
        .pipe(switchMap( msg=> this.sendPreallocWarnings$(msg['id']) ));
  }

  /**
   * INCOMPLETE MESSAGES
   */

  /**
   * Generate incomplete warnings to send to students
   *
   * @param pslIds - The selection IDs
   *
   * return an observable that will mark completetion of the
   */
   genIncompleteWarnings$(sids) {
    return this.http.post(this.base_url + 'message/create/incomplete', { sids }, this.httpOptions);
  }



  /**
   * Cancel the send of a message
   *
   * @param msgid
   */
   cancelIncompleteWarnings$(msgid) {
    return this.http.patch(this.base_url + 'message/' + msgid, { msgStatus: 'CANCELLED' });
  }

  /**
   * Send the message
   *
   */
   sendIncompleteWarnings$(msgid) {
    return this.http.post(this.base_url + 'message/send/', { id: msgid });
  }


}
