import { Deserializable } from './deserializable.model';

import { Sgtopic } from './sgtopic';
import { Staff } from './staff';
import { EngineeringArea } from './engineeringarea';
import { CourseOffering } from './courseoffering';
import { Y4projectselection } from './y4projectselection';
import { Supervisor } from './supervisor';

export class Y4project implements Deserializable {

    projOwner?: Staff;
    projTitle?: string;
    createdAt?: string;
    updatedAt?: string;
    projType?: string;
    projCourseOffering?: CourseOffering;
    projSubjectgroupTopic?: Sgtopic;
    projOwnerSeqNum?: number;
    projDescription?: string;
    projUrl?: string;
    projIndustrial?: string;
    projContactgroupcentre?: number;
    projSubmittedOn?: Date;
    projApprovedOn?: string;
    projPublishedOn?: string;
    projPreallocated?: string;
    projConfidentiality?: boolean;
    projMeetingLocation?: string;
    projMeetingDatetime?: Date;

    projSupervisors?: Staff[];
    projSupervisorContacts?: Supervisor[];
    projEngineeringAreas?: EngineeringArea[];
    projTopics?: Sgtopic[];
    projSelections?: Y4projectselection[];

    constructor(public id?: number) {
        this.id = id;
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        this.projSubjectgroupTopic = new Sgtopic().deserialize(input.projSubjectgroupTopic);
        this.projOwner = new Staff().deserialize(input.projOwner);
        this.projSupervisors = (input.projSupervisors) ?
            input.projSupervisors.map(sv => new Staff().deserialize(sv)) :
            [];
        this.projSupervisorContacts = (input.projSupervisorContacts) ?
            input.projSupervisorContacts.map(sv => new Supervisor().deserialize(sv)) :
            [];
        this.projTopics = (input.projTopics) ?
            input.projTopics.map(top => new Sgtopic().deserialize(top)) :
            [];
        this.projEngineeringAreas = (input.projEngineeringAreas) ?
            input.projEngineeringAreas.map(ea => new EngineeringArea().deserialize(ea)) :
            [];
        this.projSelections = (input.projSelections) ?
            input.projSelections.map(ea => new Y4projectselection().deserialize(ea)) :
            [];
        
        if (input.projSubmittedOn) {
            this.projSubmittedOn = new Date(input.projSubmittedOn)
        } 

        if (input.projMeetingDatetime) {
            this.projMeetingDatetime = new Date(input.projMeetingDatetime);
        }

        return this;
    }

    /**
     * The reference associated to this project
     * In the original COMET code the project_listing
     * was a join of project, projectlist, people
     *
     * References for Type b projects are appended with the ID of the project
     */
    reference(): string {
        const seq = (this.projType === 'b') ? 'b' + (this.id).toString() : this.projOwnerSeqNum;
        return this.groupShort() // 'Group X' Fix me should we store a group identifier in the table?
            + '-' + this.projOwner.stfCrsid
            + '-' + seq;
    }

    /**
     * returns the Group letter (by convention)
     */
    groupShort(): string {
        return this.projSubjectgroupTopic.subjGroup.substring(6, 7);
    }

    /**
     *
     */
    approvalStatus(): string {
        return this.projApprovedOn ? 'Approved' : 'Provisional';
    }
    /**
     * descriptionTeaser
     *
     * partially display the projects description
     *
     * We strip any html tags out in case we are using WYSIWYG editor
     *
     * @param c - the number of characters to display (default 128)
     */
    descriptionTeaser(c: number = 128): string {
        const str = this.projDescription.replace(/(<([^>]+)>)/ig, '');
        return str.substr(0, c) + '...';
    }

    /**
     * titleTeaser
     *
     * partially display the project's title
     *
     * @param c - the number of characters to display (default 128)
     */
    titleTeaser(c: number = 56): string {
        return (this.projTitle.length > (c - 1)) ? this.projTitle.substr(0, c) + '...' : this.projTitle;
    }

    // Used to highlight the status of the project
    allocatedTo(): Y4projectselection[] {
        return this.projSelections.filter(sel => sel.pslAllocated === true);
    }

    /**
     * A method to quickly print the project details
     */
    referenceTitle() {
        return this.reference() + ' - ' + this.projTitle;
    }

    /**
     * Return the allocated choices
     * (there could be more than one!)
     */
    allocations() {
        return this.projSelections.filter(choice => choice.isAllocated());
    }

    /**
     * return whether the project has been submitted
     */
    submitted() {
        return (this.projSubmittedOn && this.projSubmittedOn.getTime() !== 0 );
    }

    /**
     * 
     * @returns submittedOn date in format managable by mysql
     */
    /* projSubmittedOnMysql(){
        return (new Date(this.projSubmittedOn)).toISOString().replace('Z','')
    } */
    
    /**
     * return whether the project has been approved
     */
    approved() {
        return (this.projApprovedOn && this.projApprovedOn !== '0000-00-00 00:00:00');
    }

    /**
     * Waiting approval
     */
    waitingApproval() {
        return this.submitted() && !this.approved();
    }

    /**
     * The owner's email contact
     *
     * N.B. The owner may wish to keep this 'hidden' in which case it is blank
     *
     * 'default' implies follow the staff record value
     */
    ownerContactEmail() {
        const owner = this.projSupervisorContacts.filter(s => s.supIsOwner === true);
        const ownerStaff = this.projSupervisors.filter(s => s.id === owner[0].supStaff);
        return (owner[0]) ?
            (owner[0].supEmail === 'default') ? ownerStaff[0].email() : owner[0].supEmail :
            ''; // no owner supervisor currently set as the owner
    }

    /**
     * The owner's contact location
     *
     * N.B. The owner may wish to keep this 'hidden' in which case it is blank
     *
     * 'default' implies follow the staff record value
     */
    ownerContactLocation() {
        const owner = this.projSupervisorContacts.filter(s => s.supIsOwner === true);
        const ownerStaff = this.projSupervisors.filter(s => s.id === owner[0].supStaff);
        return (owner[0]) ?
            (owner[0].supLocation === 'default') ? ownerStaff[0].stfLocation : owner[0].supLocation :
            ''; // no owner supervisor currently set as the owner
    }

    /**
     * Return the supervisors who are not the primary supervisor
     */
    projectAssistants() {
        const staff = this.projSupervisorContacts.filter(s => s.supIsOwner !== true).map(s => s.supStaff);
        return this.projSupervisors.filter(s => staff.includes(s.id));
    }

    /**
     * Return the Topics as a sort list of labels
     */
    sortedTopics() {
        return this.projTopics.sort((a, b) => (a.label() > b.label()) ? 1 : -1);
    }

    /**
     * infoMeeting
     * 
     * Returns the time date location of the Information meeting
     */
    infoMeeting() {
        // The datetime is entered as locale but stored as UTC
        // using toLocaleString()
        let m =  this.projMeetingDatetime
        var dateString = m.getUTCFullYear() +"/"+ (m.getUTCMonth()+1) +"/"+ m.getUTCDate() + " @ " + m.getUTCHours() + ":" + String(m.getUTCMinutes()).padStart(2, '0');
        return this.projMeetingLocation + " " + dateString
    }
}
