<h3>Examples of projects offered last year</h3>
<!-- todo use campl-ngx-table ; is this implemented yet? -->

<table  class="campl-table-bordered campl-table campl-table-striped campl-vertical-stacking-table ">
    <thead>
        <tr>
            <th>
                Title
            </th>
            <th>
                Description
            </th>
            <th>
                Subject group
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let proj of (examples$ | async)">
            <td>
                {{proj.projTitle}}
            </td>
            <td>
                <p [innerHTML]="proj.projDescription" [hidden]="!displayArr.includes(proj.id)"></p>
                <a (click)="hideDetails(proj.id)"  [hidden]="!displayArr.includes(proj.id)">hide</a>
                <a (click)="displayArr.push(proj.id)" [hidden]="displayArr.includes(proj.id)">show</a>
            </td>
            <td>
                {{proj.projSubjectgroupTopic.subjGroup}}:
                {{proj.projSubjectgroupTopic.subjGroupTopic}}
            </td>
        </tr>
    </tbody>
</table>
