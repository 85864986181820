
<button mat-icon-button class="close-button" [mat-dialog-close]="false">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
These details will be displayed to the students
<button mat-icon-button  class="print-button" (click)="print()">
    <mat-icon>print</mat-icon>
</button>
<mat-card>
    <mat-card-title></mat-card-title>
    <mat-card-content>
        <app-y4-project-summary [projectIn]="project" [noIndustrial]="true"></app-y4-project-summary>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="warn" (click)="close()">
            Close
        </button>
    </mat-card-actions>
</mat-card>
