import { Deserializable } from './deserializable.model';

export class EngineeringArea implements Deserializable {

    id: number;
    eaName: string;
    eaIsCurrent: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    display_name(): string{
        return this.eaName;
    }
}
