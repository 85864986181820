import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { Staff } from '../../models/staff';
import { AssistantService } from '../../services/assistant.service';
import { Observable } from 'rxjs-compat/Observable';

import { startWith, map, debounceTime, switchMap, tap, take, combineLatest } from 'rxjs/operators';

/**
 * The Assistant selections will allow us to select an assistant.
 * After each debounce time a http query will be made!
 * OR
 * (TODO) Add a new assistant and add them.
 * 
 * THOUGHTS: The option to add a new assistant will appear if the search returns no results
 */

@Component({
  selector: 'app-assistant-selections',
  templateUrl: './assistant-selections.component.html',
  styleUrls: ['./assistant-selections.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssistantSelectionsComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AssistantSelectionsComponent),
      multi: true
    }]
})
export class AssistantSelectionsComponent implements OnInit, ControlValueAccessor {

  @Input() validator?: ValidatorFn; // send if required
  
  selectedAssistants: Staff[] = new Array<Staff>();
  filteredAssistants$: Observable<Staff[]>;
  lastFilter = '';
  assistantCtrl = new FormControl();
  public assistantForm: FormGroup;
  assistants: Staff[] = [];
  filteredStaff: Observable<Staff[]>;
  value;
  onChange;
  onTouched;

  constructor(
    private _assistantService: AssistantService) { }

  ngOnInit(): void {
    this.assistantCtrl = this.validator ? new FormControl('', this.validator) : new FormControl('')
    this.assistantForm = new FormGroup({
     assistants : this.assistantCtrl
    });
    this.configureAutoCompleteFilters()
  }

  /**
   * sets up the filters to be used by some of our components
   */
  configureAutoCompleteFilters() {
    // We subscribe late in HTML
    this.filteredAssistants$ = this.assistantCtrl.valueChanges.pipe(
      debounceTime(300),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      switchMap(value => this._assistantService.searchAssistants(value, 20)) // change to assistant search 
    );
  }

  /**
    * removeAssistant
    * reomves Assistant from our superviors list prior to form submission
    */
  removeAssistant(id: number) {
    this.selectedAssistants = this.selectedAssistants.filter(ass => ass.id !== id);
    this.writeValue(this.selectedAssistants)
  }

  optionAssistantClicked(event: Event, assistant: Staff) {
    this.addAssistant(assistant);
  }

  /**
   * Action when we select from the list
   * @param assistant 
   */
  addAssistant(assistant: Staff) {

    if (this.selectedAssistants.length > 0) {
      this.selectedAssistants.push(assistant)
    } else {
      this.selectedAssistants = [assistant]
    }
    this.writeValue(this.selectedAssistants)
    this.assistantCtrl.setValue('');
  }

  /**
   * Clears the text once a value has been selected
   * 
   */
  clearOnSelectFn() {
    return ''
  }

  // NG_VALIDATORS
  validate({ value }: FormControl) {
    // returns errors or null if valid
    return this.assistantForm.get('assistants').valid ? null : { invalid: true }
  }
  // ControlValueAccessor implementation
  writeValue(val: any) {
    this.selectedAssistants = val
    return val && this.assistantForm.patchValue({ assistants: val });
  }
  registerOnChange(fn: (val: any) => void) {
    this.assistantForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  // ??
  setDisabledState(disabled: boolean) {
    disabled ? this.assistantForm.disable()
      : this.assistantForm.enable();
  }
}
