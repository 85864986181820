import { Deserializable } from './deserializable.model';

export class Y4staffjobtitle implements Deserializable  {

    id: number;
    sjtTitle: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
