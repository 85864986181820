import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, merge, Observable, of, ReplaySubject, scheduled } from 'rxjs';
import { Auth } from '../models/auth';
import { map, mergeAll, share, shareReplay, switchMap, take, tap } from 'rxjs/operators';

/**
 * The Auth(User)Service used to identify our users and their roles
 *
 */

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient) {
    // move this to init
    // https://stackoverflow.com/questions/35191617/how-to-run-a-service-when-the-app-starts-in-angular-2
    // this.meobs = this.http.get<Auth>(this.baseurl)
  }

  public baseurl = '/me';

  /**
   * Subject used to trigger another fetch of me$
   *
   * @example
   * this.updateMe$()
   */
  private meFetchSubject$ = new BehaviorSubject<boolean>(true);
  // we do not access whether it has been toggled externally
  // public meFetch$ = this.meFetchSubject$.asObservable();

  /**
   * Subscribe to this to provide access to the current user (Auth) object
   */
  public me$ = this.meFetchSubject$.pipe(
    switchMap(() => this.http.get<Auth>(this.baseurl)),
    map(auth => new Auth().deserialize(auth)),
    // unsure why this sometimes does not send the latest
    // eg getMe$() when creating type by as an impersonated student
    shareReplay(1)
  );

  /**
   * returns the roles the current user has
   *
   * - utilized in the has-perm directive
   */
  public roles$ = this.me$.pipe(
    map(user => user.roles.map(role => role.roleType))
  );


  /**
   * Helper
   * added to enable spying during tests
   *
   * TODO: can we replace all .me$ calls with .getMe$() ? or change the tests to remove this method?
   */
  getMe$(): Observable<Auth> {
    return this.me$;
  }

  /**
   * Triggers another retrieval of our user
   *
   * refs:
   * selection-active.guard
   * impersonate.component
   */
  updateMe() {
    this.meFetchSubject$.next(true);
  }

  /**
   * Can this user (student) select Y4 projects.
   *
   * is their Y4Project open for selection?
   *
   * @return boolean
   *
   * refs:
   * y4-project-select-dialog (as selectionOpen)
   *
   * TODO: could we implement as a directive cf has-perm?
   */
  public canSelectY4P$() {
    return this.me$
      .pipe(
        map(me => me.egt3Student()),
        map(stu => (stu) ? stu.egt3Selection() : undefined),
        map(coo => (coo) ? true : false)
      );
  }

  // Possible method to run service at 'startup': https://www.cidean.com/blog/2019/initialize-data-before-angular-app-starts/
  /**
   * TODO: deprecate
   *
   * (used in app.component? )
   */
  init(): Observable<Auth> {
    // Startup logic here
    return this.me$;
  }



  /**
   * Calls the logout routine on the server
   * and triggers an update of the current user
   *
   * TODO: should we have a switchMap or similar in here
   * to make sure the updateMe is called before returning?
   */
  logout() {
    this.http.post('/logout', {})
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.updateMe();
        },  // changed
        (err) => {
          //;
        },
        () => {
          //;
        }
      );
  }


  //
  // Q, should we place these <stop>impersonate<type> methods into a new service
  //
  //    impersonateService(authService: AuthService)?
  //
  //

  /**
   * Sets up session to appear to be the sid user
   * Call the API endpoint to switch user - then update the UI user
   *
   * @param sid - staff id
   *
   * TODO: handle error https://blog.angular-university.io/rxjs-error-handling/
   */
  impersonateStaff(sid: string) {
    return this.http.get<any>('impersonate/staff/' + sid).pipe(
      map(ret => { this.meFetchSubject$.next(true); })
      // ret => this._retrieve_me() // but really means fetch again
    );
  }

  /**
   * Sets up session to appear to be the sid user
   * Call the API endpoint to switch user - then update the UI user
   *
   * @param sid - staff id
   */
  impersonateStudent(sid: string) {
    return this.http.get<any>('impersonate/student/' + sid).pipe(
      map(ret => { this.meFetchSubject$.next(true); })
      // ret => this._retrieve_me() // but really means fetch again
    );
  }
  /**
   * Sets up session to appear to be the sid user
   * Call the API endpoint to switch user - then update the UI user
   *
   * @param sid - staff id
   */
  stopImpersonating() {
    this.http.get<any>('impersonate/stop').subscribe(
      ret => {
        this.updateMe();
      }// but really means fetch again
    );
  }

}
