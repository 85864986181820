
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Observable } from 'rxjs';

import { Y4ProjectService } from '../lib/services/y4-project.service';

/**
 * Organises and groups project offerings by owner
 * The list of projects have already been filtered by
 * some criteria, and are retrieved into this component
 * by the y4projectService - perhaps we should retrieve
 * via an input parameter?
 *
 *
 * (y4projectbrowse)
 */
@Component({
  selector: 'app-y4-projectlist-accordion',
  templateUrl: './y4-projectlist-accordion.component.html',
  styleUrls: ['./y4-projectlist-accordion.component.css']
})
export class Y4ProjectlistAccordionComponent implements OnInit, OnDestroy {


  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() expanded$: Observable<boolean>; // expand all the accordians
  expanded = false; // used in template to set initial state, and thoughout to find action o

  subscriptions = [];


  // This might be better passed as an input
  // fix typo
  projects$ = this._y4projectService.studentProjectAccordianList$;

  constructor(private _y4projectService: Y4ProjectService) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.expanded$.subscribe(status => {
        // when testing this.accordion didn't appear defined initially?!
        if (status && !this.expanded && this.accordion) {
          this.expanded = true;
          this.accordion.openAll();
        } else if (!status && this.expanded && this.accordion) {
          this.accordion.closeAll();
          this.expanded = false;
        }
      }));
  }
  ngOnDestroy(): void {
    this.subscriptions.map(sub => sub.unsubscribe());
  }

}
