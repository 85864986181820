<!-- start local footer -->
<div class="campl-row campl-local-footer">
  <div class="clearfix" CamplNgxWrap>
    <!-- local footer content -->

    <div class="campl-column3 campl-footer-navigation">
      <div class="campl-content-container campl-navigation-list list-1">
        <h3 *ngFor="let item of col1">
          <a href="{{ item.link }}">{{ item.label }}</a>
        </h3>
      </div>
    </div>
    <div class=" campl-column3 campl-footer-navigation">
      <div class="campl-content-container campl-navigation-list list-2">
        <h3 *ngFor="let item of col2"><a href="{{ item.link }}">{{ item.label }}</a></h3>
      </div>
    </div>
    <div class="campl-column3 campl-footer-navigation">
      <div class="campl-content-container campl-navigation-list  list-3">
        <h3 *ngFor="let item of col3"><a href="{{ item.link }}">{{ item.label }}</a></h3>
        <!-- This was a section for mentoring links-->
      </div>
    </div>
    <div class="campl-column3 campl-footer-navigation last">
      <div class="campl-content-container campl-navigation-list  list-4">
        <h3 *ngFor="let item of col4"><a href="{{ item.link }}">{{ item.label }}</a></h3>
        <!-- This was a section for resources -->
      </div>
    </div>
  </div>
</div>
<!-- end local footer -->