// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


// https://stackoverflow.com/questions/34907682/how-to-display-the-app-version-in-angular
// This will now be the version of our multiproject workspace?!
import packageInfo  from '../../../../package.json';
import codeInfo  from './git.sha.json';

const siteConfig = {
  page_title: 'COMET Part IIB Project Selection - Test System',
  local_footer_col1: [{}
//    {
//      label: 'Have you got a link that could go here?',
//      link: 'https://basdlink1'
//    },
//    {
//      label: 'OR a link for here?',
//      link: 'https://badlink'
//    }
  ],
  local_footer_col2: [{}
//    {
//      label: 'Maybe even here?',
//      link: 'https://somebadlink'
//    }
  ],
  local_footer_col3: [{}],
  local_footer_col4: [{}],
  quicklinks: [
    {
      link: 'http://www.cam.ac.uk/for-staff',
      label: 'For staff '
    },
    {
      link: 'http://www.cam.ac.uk/current-students',
      label: 'For current students'
    },
    {
      link: 'http://www.alumni.cam.ac.uk',
      label: 'For alumni'
    },
    {
      link: 'http://www.cam.ac.uk/for-business',
      label: 'For business'
    },
    {
      link: 'http://www.cam.ac.uk/colleges-and-departments',
      label: 'Colleges & departments'
    },
    {
      link: 'http://www.lib.cam.ac.uk/libraries/',
      label: 'Libraries & facilities'
    },
    {
      link: 'http://www.cam.ac.uk/museums-and-collections',
      label: 'Museums & collections'
    },
    {
      link: 'http://www.cam.ac.uk/email-and-phone-search',
      label: 'Email & phone search'
    }
  ],
  global_nav: [
    {
      label: 'Study at Cambridge',
      link: 'http://www.cam.ac.uk/study-at-cambridge',
      anchor: 'studyatcambridge',
      sub: [
        {
          label: 'Undergraduate',
          link: 'http://www.study.cam.ac.uk/undergraduate/',
          sub: [
            {
              label: 'Course',
              link: 'http://www.undergraduate.study.cam.ac.uk/courses'
            },
            {
              label: 'Applying',
              link: 'http://www.undergraduate.study.cam.ac.uk/applying'
            },
            {
              label: 'Events and open days',
              link: 'http://www.undergraduate.study.cam.ac.uk/events'
            },
            {
              label: 'Fees and Finances',
              link: 'http://www.undergraduate.study.cam.ac.uk/financess'
            },
            {
              label: 'Student blogs and videos',
              link: 'http://www.becambridge.com/'
            }
          ]
        },
        {
          label: 'Graduate',
          link: 'http://www.graduate.study.cam.ac.uk',
          sub: [
            {
              label: 'Why Cambridge',
              link:
                'http://www.graduate.study.cam.ac.uk/why-cambridge/welcome-vice-chancellor'
            },
            {
              label: 'How to apply',
              link: 'http://www.graduate.study.cam.ac.uk/how-do-i-apply'
            },
            {
              label: 'Fees and funding',
              link: 'http://www.cambridgestudents.cam.ac.uk/fees-and-funding'
            },
            {
              label: 'Frequently asked questions',
              link: 'http://www.graduate.study.cam.ac.uk/faqs/applicant'
            }
          ]
        },
        {
          label: 'International students',
          link: 'http://www.internationalstudents.cam.ac.uk'
        },
        {
          label: 'Continuing education',
          link: 'http://www.ice.cam.ac.uk'
        },
        {
          label: 'Executive and professional education',
          link: 'http://www.epe.admin.cam.ac.uk/'
        },
        {
          label: 'Courses in education',
          link: 'http://www.educ.cam.ac.uk'
        }
      ]
    },
    {
      label: 'About the University',
      link: 'http://www.cam.ac.uk/about-the-university',
      anchor: 'abouttheuniversity',
      sub: [
        {
          sub: [
            {
              label: 'How the University and Colleges work',
              link:
                'http://www.cam.ac.uk/about-the-university/how-the-university-and-colleges-work',
              sub: []
            },
            {
              label: 'History',
              link: 'http://www.cam.ac.uk/about-the-university/history'
            },
            {
              label: 'Visiting the University',
              link:
                'http://www.cam.ac.uk/about-the-university/visiting-the-university'
            },
            {
              label: 'Term dates and calendars',
              link:
                'http://www.cam.ac.uk/about-the-university/term-dates-and-calendars'
            },
            {
              label: 'Map',
              link: 'http://map.cam.ac.uk'
            }
          ]
        },
        {
          sub: [
            {
              label: 'For media',
              link: 'http://www.communications.cam.ac.uk/'
            },
            {
              label: 'Video and audio',
              link: 'http://www.cam.ac.uk/video-and-audio'
            },
            {
              label: 'Find an expert',
              link: 'http://webservices.admin.cam.ac.uk/fae/'
            },
            {
              label: 'Publications',
              link: 'http://www.cam.ac.uk/about-the-university/publications'
            },
            {
              label: 'Global Cambridge',
              link: 'http://www.cam.ac.uk/global-cambridge'
            }
          ]
        },
        {
          sub: [
            {
              link: 'http://www.cam.ac.uk/news',
              label: 'News'
            },
            {
              link: 'http://www.admin.cam.ac.uk/whatson/',
              label: 'Events'
            },
            {
              link: 'http://www.cam.ac.uk/public-engagement',
              label: 'Public engagement'
            },
            {
              link: 'http://www.jobs.cam.ac.uk',
              label: 'Jobs'
            },
            {
              link: 'https://philanthropy.cam.ac.uk',
              label: 'Give to Cambridge'
            }
          ]
        }
      ]
    },
    {
      label: 'Research at Cambridge',
      link: 'http://www.cam.ac.uk/research'
    }
  ]
};

// Place in here all our config keys:

export const environmentCommon = {
  config: siteConfig,
  production: false,
  mock: true,
  // The permsLookup provideds a list of role types who are provided the permissions
  // ie permission -> [role]
  permsLookup: {
    allocationsAccess: ['to_admin', 'tc_committee', 'tc_member', 'iib_p_coordinator', 'sg_coordinator', 'sg_admin'],
    allocationsAction: ['to_admin', 'iib_p_coordinator', 'sg_coordinator', 'sg_admin'],
    bulkAllocationsAction: ['to_admin', 'sg_admin'],
    toadmin: ['to_admin'],
    manageY4projectsAccess: ['to_admin', 'iib_p_coordinator', 'sg_admin'],
    approveY4projectsAccess: ['to_admin', 'iib_p_coordinator', 'sg_admin', 'sg_coordinator']
  },
  version: packageInfo.version,
  // advisery on the max number of projects an academic should have
  maxProjects: 4,
  appCode:'IIBP', // cued-shared: used to prefix error
  rollbarToken: '50c83d933ed54e14afd08f886391160e',
  CODE_VERSION: codeInfo.CODE_VERSION
};
