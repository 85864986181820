/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Group } from '../models/group';
import { Person } from '../models/person';


/**
 * Methods for querying and manipulating groups.
 *
 * #### The fetch parameter for groups
 *
 * All methods that return groups also accept an optional `fetch`
 * parameter that may be used to request additional information about the
 * groups returned. For more details about the general rules that apply to
 * the `fetch` parameter, refer to the `PersonMethods`
 * documentation.
 *
 * For groups the `fetch` parameter may be used to fetch references
 * to people, institutions or other groups. In each case, only non-cancelled
 * people, institutions and groups will be included when fetching references.
 * The following references are supported:
 *
 * * `"all_members"` - fetches all the people who are members of the
 *   group, including members of groups included by the group, and groups
 *   included by those groups, and so on.
 *
 * * `"direct_members"` - fetches all the poeple who are direct
 *   members of the group, not taking into account any included groups.
 *
 * * `"members_of_inst"` - if the group is a membership group for an
 *   institution, this fetches that institution.
 *
 * * `"owning_insts"` - fetches all the institutions to which the
 *   group belongs.
 *
 * * `"manages_insts"` - fetches all the institutions that the group
 *   manages. Typically this only applies to "Editor" groups.
 *
 * * `"manages_groups"` - fetches all the groups that this group
 *   manages. Note that some groups are self-managed, so this may be a
 *   self-reference.
 *
 * * `"managed_by_groups"` - fetches all the groups that manage this
 *   group.
 *
 * * `"reads_groups"` - fetches all the groups that this group has
 *   privileged access to. This means that members of this group can see the
 *   members of the referenced groups regardless of the membership visibility
 *   settings.
 *
 * * `"read_by_groups"` - fetches all the groups that have privileged
 *   access to this group.
 *
 * * `"includes_groups"` - fetches all the groups included by this
 *   group.
 *
 * * `"included_by_groups"` - fetches all the groups that include
 *   this group.
 *
 * As with person `fetch` parameters, the references may be used
 * in a chain by using the "dot" notation to fetch additional information
 * about referenced people, institutions or groups. For example
 * `"all_members.email"` will fetch the email addresses of all members
 * of the group. For more information about what can be fetched from
 * referenced people and institutions, refer to the documentation for
 * `PersonMethods` and `InstitutionMethods`.
 */
@Injectable({
  providedIn: 'root',
})
export class GroupService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation groupAllGroups
   */
  static readonly GroupAllGroupsPath = '/group/all-groups';

  /**
   * Return a list of all groups.
   *
   * Return a list of all groups.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupAllGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupAllGroups$Response(params?: {

    /**
     * Whether or not to include cancelled
     * groups. By default, only live groups are returned.
     */
    includeCancelled?: boolean;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GroupAllGroupsPath, 'get');
    if (params) {
      rb.query('includeCancelled', params.includeCancelled, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'groups'?: Array<Group>;
        };
        }>;
      })
    );
  }

  /**
   * Return a list of all groups.
   *
   * Return a list of all groups.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `groupAllGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupAllGroups(params?: {

    /**
     * Whether or not to include cancelled
     * groups. By default, only live groups are returned.
     */
    includeCancelled?: boolean;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}> {

    return this.groupAllGroups$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
})
    );
  }

  /**
   * Path part for operation groupListGroups
   */
  static readonly GroupListGroupsPath = '/group/list';

  /**
   * Get the groups with the specified IDs or names.
   *
   * Get the groups with the specified IDs or names.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * The results are sorted by groupid.
   *
   * NOTE: The URL path length is limited to around 8000 characters,
   * which limits the number of groups that this method can fetch. Group
   * IDs are currently 6 characters long, and must be comma separated and
   * URL encoded, which limits this method to around 800 groups by ID,
   * but probably fewer by name, depending on the group name lengths.
   *
   * NOTE: The groups returned may include cancelled groups. It is the
   * caller's repsonsibility to check their cancelled flags.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupListGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupListGroups$Response(params: {

    /**
     * A comma-separated list of group IDs or
     * group names (may be a mix of both).
     */
    groupids: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GroupListGroupsPath, 'get');
    if (params) {
      rb.query('groupids', params.groupids, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'groups'?: Array<Group>;
        };
        }>;
      })
    );
  }

  /**
   * Get the groups with the specified IDs or names.
   *
   * Get the groups with the specified IDs or names.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * The results are sorted by groupid.
   *
   * NOTE: The URL path length is limited to around 8000 characters,
   * which limits the number of groups that this method can fetch. Group
   * IDs are currently 6 characters long, and must be comma separated and
   * URL encoded, which limits this method to around 800 groups by ID,
   * but probably fewer by name, depending on the group name lengths.
   *
   * NOTE: The groups returned may include cancelled groups. It is the
   * caller's repsonsibility to check their cancelled flags.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `groupListGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupListGroups(params: {

    /**
     * A comma-separated list of group IDs or
     * group names (may be a mix of both).
     */
    groupids: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}> {

    return this.groupListGroups$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
})
    );
  }

  /**
   * Path part for operation groupModifiedGroups
   */
  static readonly GroupModifiedGroupsPath = '/group/modified-groups';

  /**
   * Find all groups modified between the specified pair of transactions.
   *
   * Find all groups modified between the specified pair of transactions.
   *
   * The transaction IDs specified should be the IDs from two different
   * requests for the last (most recent) transaction ID, made at different
   * times, that returned different values, indicating that some Lookup
   * data was modified in the period between the two requests. This method
   * then determines which (if any) groups were affected.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * NOTE: All data returned reflects the latest available data about each
   * group. It is not possible to query for old data, or more detailed
   * information about the specific changes made.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupModifiedGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupModifiedGroups$Response(params: {

    /**
     * Include modifications made in transactions
     * after (but not including) this one.
     */
    minTxId: number;

    /**
     * Include modifications made in transactions
     * up to and including this one.
     */
    maxTxId: number;

    /**
     * Only include groups with IDs or names in
     * this list. By default, all modified groups will be included.
     */
    groupids?: string;

    /**
     * Include cancelled groups. By
     * default, cancelled groups are excluded.
     */
    includeCancelled?: boolean;

    /**
     * Include groups whose members have
     * changed. By default, changes to group memberships are not taken into
     * consideration.
     */
    membershipChanges?: boolean;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GroupModifiedGroupsPath, 'get');
    if (params) {
      rb.query('minTxId', params.minTxId, {});
      rb.query('maxTxId', params.maxTxId, {});
      rb.query('groupids', params.groupids, {});
      rb.query('includeCancelled', params.includeCancelled, {});
      rb.query('membershipChanges', params.membershipChanges, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'groups'?: Array<Group>;
        };
        }>;
      })
    );
  }

  /**
   * Find all groups modified between the specified pair of transactions.
   *
   * Find all groups modified between the specified pair of transactions.
   *
   * The transaction IDs specified should be the IDs from two different
   * requests for the last (most recent) transaction ID, made at different
   * times, that returned different values, indicating that some Lookup
   * data was modified in the period between the two requests. This method
   * then determines which (if any) groups were affected.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * NOTE: All data returned reflects the latest available data about each
   * group. It is not possible to query for old data, or more detailed
   * information about the specific changes made.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `groupModifiedGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupModifiedGroups(params: {

    /**
     * Include modifications made in transactions
     * after (but not including) this one.
     */
    minTxId: number;

    /**
     * Include modifications made in transactions
     * up to and including this one.
     */
    maxTxId: number;

    /**
     * Only include groups with IDs or names in
     * this list. By default, all modified groups will be included.
     */
    groupids?: string;

    /**
     * Include cancelled groups. By
     * default, cancelled groups are excluded.
     */
    includeCancelled?: boolean;

    /**
     * Include groups whose members have
     * changed. By default, changes to group memberships are not taken into
     * consideration.
     */
    membershipChanges?: boolean;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}> {

    return this.groupModifiedGroups$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
})
    );
  }

  /**
   * Path part for operation groupSearch
   */
  static readonly GroupSearchPath = '/group/search';

  /**
   * Search for groups using a free text query string.
   *
   * Search for groups using a free text query string. This is the same
   * search function that is used in the Lookup web application.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * NOTE: If the query string starts with the prefix `"group:"`, it
   * is treated as an [LQL query](/lql), allowing
   * more advanced searches. An LQL query will ignore the
   * `approxMatches` parameter, but it will respect the value of
   * `includeCancelled`. In addition, an LQL query will ignore
   * the `orderBy` parameter, since LQL queries always return
   * results in ID order.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupSearch$Response(params: {

    /**
     * The search string.
     */
    query: string;

    /**
     * Flag to enable more approximate
     * matching in the search, causing more results to be returned. Defaults
     * to &#x60;false&#x60;. This is ignored for LQL queries.
     */
    approxMatches?: boolean;

    /**
     * Flag to allow cancelled groups to
     * be included. Defaults to &#x60;false&#x60;.
     */
    includeCancelled?: boolean;

    /**
     * The number of results to skip at the start
     * of the search. Defaults to 0.
     */
    offset?: number;

    /**
     * The maximum number of results to return.
     * Defaults to 100.
     */
    limit?: number;

    /**
     * The order in which to list the results.
     * This may be &#x60;&quot;groupid&quot;&#x60;, &#x60;&quot;name&quot;&#x60; (the default for non-LQL
     * queries) or &#x60;&quot;title&quot;&#x60;. This is ignored for LQL queries, which
     * always return results in groupid order.
     */
    orderBy?: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GroupSearchPath, 'get');
    if (params) {
      rb.query('query', params.query, {});
      rb.query('approxMatches', params.approxMatches, {});
      rb.query('includeCancelled', params.includeCancelled, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('orderBy', params.orderBy, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'groups'?: Array<Group>;
        };
        }>;
      })
    );
  }

  /**
   * Search for groups using a free text query string.
   *
   * Search for groups using a free text query string. This is the same
   * search function that is used in the Lookup web application.
   *
   * By default, only a few basic details about each group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references.
   *
   * NOTE: If the query string starts with the prefix `"group:"`, it
   * is treated as an [LQL query](/lql), allowing
   * more advanced searches. An LQL query will ignore the
   * `approxMatches` parameter, but it will respect the value of
   * `includeCancelled`. In addition, an LQL query will ignore
   * the `orderBy` parameter, since LQL queries always return
   * results in ID order.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `groupSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupSearch(params: {

    /**
     * The search string.
     */
    query: string;

    /**
     * Flag to enable more approximate
     * matching in the search, causing more results to be returned. Defaults
     * to &#x60;false&#x60;. This is ignored for LQL queries.
     */
    approxMatches?: boolean;

    /**
     * Flag to allow cancelled groups to
     * be included. Defaults to &#x60;false&#x60;.
     */
    includeCancelled?: boolean;

    /**
     * The number of results to skip at the start
     * of the search. Defaults to 0.
     */
    offset?: number;

    /**
     * The maximum number of results to return.
     * Defaults to 100.
     */
    limit?: number;

    /**
     * The order in which to list the results.
     * This may be &#x60;&quot;groupid&quot;&#x60;, &#x60;&quot;name&quot;&#x60; (the default for non-LQL
     * queries) or &#x60;&quot;title&quot;&#x60;. This is ignored for LQL queries, which
     * always return results in groupid order.
     */
    orderBy?: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}> {

    return this.groupSearch$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'groups'?: Array<Group>;
};
})
    );
  }

  /**
   * Path part for operation groupSearchCount
   */
  static readonly GroupSearchCountPath = '/group/search-count';

  /**
   * Count the number of groups that would be returned by a search using
   * a free text query string.
   *
   * Count the number of groups that would be returned by a search using
   * a free text query string.
   *
   * NOTE: If the query string starts with the prefix `"group:"`, it
   * is treated as an [LQL query](/lql), allowing
   * more advanced searches. An LQL query will ignore the
   * `approxMatches` parameter, but it will respect the value of
   * `includeCancelled`.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupSearchCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupSearchCount$Response(params: {

    /**
     * The search string.
     */
    query: string;

    /**
     * Flag to enable more approximate
     * matching in the search, causing more results to be returned. Defaults
     * to &#x60;false&#x60;. This is ignored for LQL queries.
     */
    approxMatches?: boolean;

    /**
     * Flag to allow cancelled groups to
     * be included. Defaults to &#x60;false&#x60;.
     */
    includeCancelled?: boolean;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: number;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GroupSearchCountPath, 'get');
    if (params) {
      rb.query('query', params.query, {});
      rb.query('approxMatches', params.approxMatches, {});
      rb.query('includeCancelled', params.includeCancelled, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'value'?: number;
        };
        }>;
      })
    );
  }

  /**
   * Count the number of groups that would be returned by a search using
   * a free text query string.
   *
   * Count the number of groups that would be returned by a search using
   * a free text query string.
   *
   * NOTE: If the query string starts with the prefix `"group:"`, it
   * is treated as an [LQL query](/lql), allowing
   * more advanced searches. An LQL query will ignore the
   * `approxMatches` parameter, but it will respect the value of
   * `includeCancelled`.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `groupSearchCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupSearchCount(params: {

    /**
     * The search string.
     */
    query: string;

    /**
     * Flag to enable more approximate
     * matching in the search, causing more results to be returned. Defaults
     * to &#x60;false&#x60;. This is ignored for LQL queries.
     */
    approxMatches?: boolean;

    /**
     * Flag to allow cancelled groups to
     * be included. Defaults to &#x60;false&#x60;.
     */
    includeCancelled?: boolean;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: number;
};
}> {

    return this.groupSearchCount$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: number;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'value'?: number;
};
})
    );
  }

  /**
   * Path part for operation groupGetGroup
   */
  static readonly GroupGetGroupPath = '/group/{groupid}';

  /**
   * Get the group with the specified ID or name.
   *
   * Get the group with the specified ID or name.
   *
   * By default, only a few basic details about the group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of the group.
   *
   * NOTE: The group returned may be a cancelled group. It is the caller's
   * repsonsibility to check its cancelled flag.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupGetGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupGetGroup$Response(params: {

    /**
     * The ID or name of the group to fetch. This
     * may be either the numeric ID or the short hyphenated group name (for
     * example &#x60;&quot;100656&quot;&#x60; or &#x60;&quot;cs-editors&quot;&#x60;).
     */
    groupid: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'group'?: Group;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GroupGetGroupPath, 'get');
    if (params) {
      rb.path('groupid', params.groupid, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'group'?: Group;
        };
        }>;
      })
    );
  }

  /**
   * Get the group with the specified ID or name.
   *
   * Get the group with the specified ID or name.
   *
   * By default, only a few basic details about the group are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of the group.
   *
   * NOTE: The group returned may be a cancelled group. It is the caller's
   * repsonsibility to check its cancelled flag.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `groupGetGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupGetGroup(params: {

    /**
     * The ID or name of the group to fetch. This
     * may be either the numeric ID or the short hyphenated group name (for
     * example &#x60;&quot;100656&quot;&#x60; or &#x60;&quot;cs-editors&quot;&#x60;).
     */
    groupid: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'group'?: Group;
};
}> {

    return this.groupGetGroup$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'group'?: Group;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'group'?: Group;
};
})
    );
  }

  /**
   * Path part for operation groupGetCancelledMembers
   */
  static readonly GroupGetCancelledMembersPath = '/group/{groupid}/cancelled-members';

  /**
   * Get all the cancelled members of the specified group, including
   * cancelled members of groups included by the group, and groups included
   * by those groups, and so on.
   *
   * Get all the cancelled members of the specified group, including
   * cancelled members of groups included by the group, and groups included
   * by those groups, and so on.
   *
   * By default, only a few basic details about each member are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of each person.
   *
   * NOTE: This method returns only cancelled people. It does not include
   * people who were removed from the group. Cancelled people are no longer
   * considered to be current staff, students or accredited visitors, and
   * are no longer regarded as belonging to any groups or institutions. The
   * list returned here reflects their group memberships just before they
   * were cancelled, and so is out-of-date data that should be used with
   * caution.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupGetCancelledMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupGetCancelledMembers$Response(params: {

    /**
     * The ID or name of the group.
     */
    groupid: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch for each person.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GroupGetCancelledMembersPath, 'get');
    if (params) {
      rb.path('groupid', params.groupid, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'people'?: Array<Person>;
        };
        }>;
      })
    );
  }

  /**
   * Get all the cancelled members of the specified group, including
   * cancelled members of groups included by the group, and groups included
   * by those groups, and so on.
   *
   * Get all the cancelled members of the specified group, including
   * cancelled members of groups included by the group, and groups included
   * by those groups, and so on.
   *
   * By default, only a few basic details about each member are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of each person.
   *
   * NOTE: This method returns only cancelled people. It does not include
   * people who were removed from the group. Cancelled people are no longer
   * considered to be current staff, students or accredited visitors, and
   * are no longer regarded as belonging to any groups or institutions. The
   * list returned here reflects their group memberships just before they
   * were cancelled, and so is out-of-date data that should be used with
   * caution.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `groupGetCancelledMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupGetCancelledMembers(params: {

    /**
     * The ID or name of the group.
     */
    groupid: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch for each person.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}> {

    return this.groupGetCancelledMembers$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
})
    );
  }

  /**
   * Path part for operation groupGetDirectMembers
   */
  static readonly GroupGetDirectMembersPath = '/group/{groupid}/direct-members';

  /**
   * Get the direct members of the specified group, not including members
   * included via groups included by the group.
   *
   * Get the direct members of the specified group, not including members
   * included via groups included by the group.
   *
   * By default, only a few basic details about each member are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of each person.
   *
   * NOTE: This method will not include cancelled people.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupGetDirectMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupGetDirectMembers$Response(params: {

    /**
     * The ID or name of the group.
     */
    groupid: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch for each person.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GroupGetDirectMembersPath, 'get');
    if (params) {
      rb.path('groupid', params.groupid, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'people'?: Array<Person>;
        };
        }>;
      })
    );
  }

  /**
   * Get the direct members of the specified group, not including members
   * included via groups included by the group.
   *
   * Get the direct members of the specified group, not including members
   * included via groups included by the group.
   *
   * By default, only a few basic details about each member are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of each person.
   *
   * NOTE: This method will not include cancelled people.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `groupGetDirectMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupGetDirectMembers(params: {

    /**
     * The ID or name of the group.
     */
    groupid: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch for each person.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}> {

    return this.groupGetDirectMembers$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
})
    );
  }

  /**
   * Path part for operation groupGetMembers
   */
  static readonly GroupGetMembersPath = '/group/{groupid}/members';

  /**
   * Get all the members of the specified group, including members of
   * groups included by the group, and groups included by those groups,
   * and so on.
   *
   * Get all the members of the specified group, including members of
   * groups included by the group, and groups included by those groups,
   * and so on.
   *
   * By default, only a few basic details about each member are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of each person.
   *
   * NOTE: This method will not include cancelled people.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupGetMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupGetMembers$Response(params: {

    /**
     * The ID or name of the group.
     */
    groupid: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch for each person.
     */
    fetch?: string;
  }): Observable<StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GroupGetMembersPath, 'get');
    if (params) {
      rb.path('groupid', params.groupid, {});
      rb.query('fetch', params.fetch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Class representing the top-level container for all XML and JSON results.
         * This may be just a simple textual value or it may contain more complex
         * entities such as people, institutions, groups, attributes, etc.
         */
        'result'?: {
        'people'?: Array<Person>;
        };
        }>;
      })
    );
  }

  /**
   * Get all the members of the specified group, including members of
   * groups included by the group, and groups included by those groups,
   * and so on.
   *
   * Get all the members of the specified group, including members of
   * groups included by the group, and groups included by those groups,
   * and so on.
   *
   * By default, only a few basic details about each member are returned,
   * but the optional `fetch` parameter may be used to fetch
   * additional attributes or references of each person.
   *
   * NOTE: This method will not include cancelled people.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `groupGetMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupGetMembers(params: {

    /**
     * The ID or name of the group.
     */
    groupid: string;

    /**
     * A comma-separated list of any additional
     * attributes or references to fetch for each person.
     */
    fetch?: string;
  }): Observable<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}> {

    return this.groupGetMembers$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
}>) => r.body as {

/**
 * Class representing the top-level container for all XML and JSON results.
 * This may be just a simple textual value or it may contain more complex
 * entities such as people, institutions, groups, attributes, etc.
 */
'result'?: {
'people'?: Array<Person>;
};
})
    );
  }

}
