import { Component, OnInit, Input, Inject } from '@angular/core';
import { Y4project } from '../lib/models/y4project';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Student } from '../lib/models/student';
import { Y4ProjectselectionService } from '../lib/services/y4-projectselection.service';
import { Y4ProjectService } from '../lib/services/y4-project.service';

@Component({
  selector: 'app-y4-project-summary',
  templateUrl: './y4-project-summary.component.html',
  styleUrls: ['./y4-project-summary.component.css']
})
export class Y4ProjectSummaryComponent implements OnInit {
  // @Input() project: Y4project
  @Input() student: Student;
  // optional argument otherwise we use the project from our service
  // Q, Rather than this should we set currentlyBrowsingProject$?
  @Input() projectIn: Y4project;
  @Input() noRef: boolean; // do not display reference
  @Input() noTopics: boolean; // don not display Topics
  @Input() noAreas: boolean; // do not display areas
  @Input() noFooter: boolean; // do not display Footer
  @Input() noIndustrial: boolean; // do not display Footer

  // TODO: remove the project variable join this.projectsService.currentlyBrowsingProject$ with nex(project)
  project: Y4project;
  public currentProject$ = this.projectsService.currentlyBrowsingProject$;

  constructor(
    private projectsService: Y4ProjectService) {
  }

  ngOnInit(): void {
    // TODO try to pass the current student via the component input?
    // otherwise grab the current student through the observable (we can match with the project (it's course))
    if (this.projectIn) {
      this.project = this.projectIn;
    } else {
      this.currentProject$.subscribe(proj =>
        this.project = new Y4project().deserialize(proj));
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
