import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

/**
 * This service provides a mechanism for the filters on the 
 * choices admin page to communicate with the choices-admin-service 
 * 
 * The filters below are to be actioned on the admin page
 * 
 * 
 * ubscribe to the filters using the functiosn returning observables:
 * groups$()
  choices$(){ return this.filterChoices$ }
  nochoices$(){ return this.filterNochoices$ }
  choicesOnly$(){ return this.filterChoicesOnly$ }
  nochoicesOnly$(){ return this.filterNochoicesOnly$ }
  unallocated$(){}
  allocated$(){}
  unallocatedOnly$(){}
  allocatedOnly$(){}
  noAgreement$(){}
  typeAOnly$(){}
  typeBOnly$(){}
 */

@Injectable({
  providedIn: 'root'
})
export class Y4ProjectChoicesAdminFiltersService {

  // observe sortStudent$ to know when to re-build
  private _sortStudentSurname = 0;
  private sortStudentSubject$ = new BehaviorSubject<number>(1);
  public sortStudent$: Observable<number> = this.sortStudentSubject$.asObservable();

  // (Only one of these can be set)
  // Observe sort$ to know when to rebuild
  private _sortAlloc = 0;
  private _sortSupervisor = 0;
  private sortAllocatedSupervisorSubject$ = new BehaviorSubject<number>(1);
  public sortAllocatedSupervisor$: Observable<number> = this.sortAllocatedSupervisorSubject$.asObservable();

  constructor() { }

  //
  // Sorting
  // 
  // The sorting state is held in variables in this service
  //
  // We still need to trigger re-processing. 
  // This is done by observing the 
  //   sortStudentSurname$ and sortAllocatedSupervisor$ Observables 
  // in the filter maps
  //

  /**
   * Sorts the student list by student Lastname (Surname)
   */
  public toggleSortStudentSurname() {
    this._sortStudentSurname = (this._sortStudentSurname === 0) ? 1 : this._sortStudentSurname * -1;
    // this is requried to trigger a re-calculation / re-ordering
    this.sortStudentSubject$.next(1);
  }

  /**
   * Subscribe to this to know when to run the re-order
   */
  public reSortStudentSurname$() {
    return this.sortStudent$;
  }
  /**
   * 
   * @returns value of sortStudentSurname
   */
  public sortStudentSurname() {
    return this._sortStudentSurname;
  }




  public toggleSortAllocated() {
    this._sortSupervisor = 0;
    this._sortAlloc = (this._sortAlloc === 0) ? 1 : this._sortAlloc * -1;
    this.sortAllocatedSupervisorSubject$.next(1);
  }
  public toggleSortSupervisor() {
    this._sortAlloc = 0;
    this._sortSupervisor = (this._sortSupervisor === 0) ? 1 : this._sortSupervisor * -1;
    this.sortAllocatedSupervisorSubject$.next(1);
  }

  /**
   * Watch whether the list need resorting 
   */
  public reSortAllocatedSupervisor$() {
    return this.sortAllocatedSupervisor$
  }
  /**
   * @returns order of sorting by allocated
   */
  public sortAllocated() {
    return this._sortAlloc;
  }
  /**
   * @returns order of sorting by supervisor
   */
  public sortSupervisor() {
    return this._sortSupervisor;
  }

  /**
   * ---- Filter groups ----
   * 
   * Manage an array of groups that will be filtered by the admin service
   */

  filterGroupsSubject$ = new BehaviorSubject<string[]>([]);
  filterGroups$ = this.filterGroupsSubject$.asObservable();
  toggleGroupSubject$ = new Subject<string>();
  toggleGroupAction$ = this.toggleGroupSubject$.asObservable();

  /**
   * Setup handler adds / removes the group to the filter
   * 
   * Call this from your component when it it initialised 
   * 
   * - it sets up the groups array used to filter
   */
  handleGroupToggle() {
    this.toggleGroupAction$
      .pipe(
        withLatestFrom(this.filterGroups$),
        map(([toggle, groups]) => {
          if (groups.includes(toggle)) {
            // remove the group
            groups = groups.filter(group => group !== toggle);
          } else {
            groups.push(toggle);
          }
          return groups;
        }
        )
      ).subscribe(groups => this.filterGroupsSubject$.next(groups));
  }

  /**
   * Manages the filter by group button event
   * 
   * Call this from your component to add/remove the groups to filter by
   */
  toggleGroup(group: string) {
    this.toggleGroupSubject$.next(group);
  }
  /**
   * 
   * @returns Observable<string[]>
   */
  groups$(): Observable<string[]> {
    return this.filterGroups$;
  }



  /**
   * ---- Filter choices, nochoices and choicesOnly ----
   * 
   * Manage an array of groups that will be filtered by the admin service
   */

  toggleFilterChoicesSubject$ = new BehaviorSubject<boolean>(false);
  filterChoices$ = this.toggleFilterChoicesSubject$.asObservable();

  toggleFilterNochoicesSubject$ = new BehaviorSubject<boolean>(false);
  filterNochoices$ = this.toggleFilterNochoicesSubject$.asObservable();

  filterChoicesOnly$ = combineLatest([this.toggleFilterChoicesSubject$, this.toggleFilterNochoicesSubject$])
    .pipe(
      map(([choices, nochoices]) => (choices && !nochoices))
    );

  filterNochoicesOnly$ = combineLatest([this.toggleFilterChoicesSubject$, this.toggleFilterNochoicesSubject$])
    .pipe(
      map(([choices, nochoices]) => (!choices && nochoices))
    );

  /**
   * Call toggleChoices fronm the componnent to setup 
   */
  public toggleChoices() {
    this.toggleFilterChoicesSubject$.next(!this.toggleFilterChoicesSubject$.getValue());
  }
  public toggleNochoices() {
    this.toggleFilterNochoicesSubject$.next(!this.toggleFilterNochoicesSubject$.getValue());
  }

  choices$() { return this.filterChoices$ }
  nochoices$() { return this.filterNochoices$ }
  choicesOnly$() { return this.filterChoicesOnly$ }
  nochoicesOnly$() { return this.filterNochoicesOnly$ }




  /**
   * ---- Filter unallocated allocated unallocatedOnly allocatedOnly ----
   * 
   * Manage an array of groups that will be filtered by the admin service
   */


  toggleFilterAllocatedSubject$ = new BehaviorSubject<boolean>(false);
  filterAllocated$ = this.toggleFilterAllocatedSubject$.asObservable();

  toggleFilterUnallocatedSubject$ = new BehaviorSubject<boolean>(false);
  filterUnallocated$ = this.toggleFilterUnallocatedSubject$.asObservable();


  // by those selection that are allocated - not by choices
  // We must combine the two toggles to produce the correct query
  filterAllocatedOnly$ = combineLatest([this.filterAllocated$, this.filterUnallocated$])
    .pipe(
      map(([allocated, unallocated]) => (allocated && !unallocated))
    );

  filterUnallocatedOnly$ = combineLatest([this.filterAllocated$, this.filterUnallocated$])
    .pipe(
      map(([allocated, unallocated]) => (!allocated && unallocated))
    );

  public toggleAllocated() {
    this.toggleFilterAllocatedSubject$.next(!this.toggleFilterAllocatedSubject$.getValue());
  }
  public toggleUnallocated() {
    this.toggleFilterUnallocatedSubject$.next(!this.toggleFilterUnallocatedSubject$.getValue());
  }

  unallocated$() { return this.filterUnallocated$ }
  allocated$() { return this.filterAllocated$ }
  unallocatedOnly$() { return this.filterUnallocatedOnly$ }
  allocatedOnly$() { return this.filterAllocatedOnly$ }


  /**
   * ---- Filter NoAgreement ----
   * 
   * Manage an array of groups that will be filtered by the admin service
   */

  toggleFilterNoAgreementSubject$ = new BehaviorSubject<boolean>(false);
  filterNoAgreement$ = this.toggleFilterNoAgreementSubject$.asObservable();


  public toggleNoAgreement() {
    this.toggleFilterNoAgreementSubject$.next(!this.toggleFilterNoAgreementSubject$.getValue());
  }



  noAgreement$() { return this.filterNoAgreement$; }



  /**
   * ---- Filter typeA typeB ----
   * 
   * Manage an array of groups that will be filtered by the admin service
   */


  toggleFilterTypeASubject$ = new BehaviorSubject<boolean>(false);
  filterTypeA$ = this.toggleFilterTypeASubject$.asObservable();

  toggleFilterTypeBSubject$ = new BehaviorSubject<boolean>(false);
  filterTypeB$ = this.toggleFilterTypeBSubject$.asObservable();

  filterTypeAOnly$ = combineLatest([this.toggleFilterTypeASubject$, this.toggleFilterTypeBSubject$])
    .pipe(
      map(([typeA, typeB]) => (typeA && !typeB)),
      //
    );

  filterTypeBOnly$ = combineLatest([this.toggleFilterTypeASubject$, this.toggleFilterTypeBSubject$])
    .pipe(
      //
      map(([typeA, typeB]) => (!typeA && typeB)),
      //

    );

  public toggleTypeA() {
    this.toggleFilterTypeASubject$.next(!this.toggleFilterTypeASubject$.getValue());
  }
  public toggleTypeB() {
    this.toggleFilterTypeBSubject$.next(!this.toggleFilterTypeBSubject$.getValue());
  }

  typeAOnly$() { return this.filterTypeAOnly$; }
  typeBOnly$() { return this.filterTypeBOnly$; }
  typeA$() { return this.filterTypeA$; }
  typeB$() { return this.filterTypeB$; }

}
