import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Y4projectselection } from '../lib/models/y4projectselection';
import { Y4ProjectselectionService } from '../lib/services/y4-projectselection.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Y4project } from '../lib/models/y4project';
import { Y4ProjectStudentCreateComponent } from '../y4-project-student-create/y4-project-student-create.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Y4ProjectChoicesStatusDialogComponent } from '../y4-project-choices-status-dialog/y4-project-choices-status-dialog.component';

/**
 * Displays the choices a student has made
 *
 * Optional config:
 *
 * A, Displaying the 'Type b upload' button is optional
 * B, Providing drag and drop  is also optional (doesn't work when displayed in a dialog)
 *
 * To activate A set displayBUpload via input or pass via route data
 * To activate B set dragdrop via input or pass via route data
 *
 * The choices are validated by a call in Y4ProjectselectionService (choicesStudentValid$)
 *
 * Notes: Not to be displayed in dialog context:
 *
 *  - Drag and drop
 *  - Type (b) upload
 *
 *
 */
@Component({
  selector: 'app-y4-project-selection-basket',
  templateUrl: './y4-project-selection-basket.component.html',
  styleUrls: ['./y4-project-selection-basket.component.css']
})
export class Y4ProjectSelectionBasketComponent implements OnInit, OnDestroy {

  @Input() context = 'normal'; // || "dialog"
  /**
   * Sets whether we display the upload button
   * if we are displaying this in a dialog set to false
   */
  @Input() displayBUpload = false; // this can be passed in the RouteConfig - default no
  /**
   * Do we display the drag-drop hint
   */
  @Input() dragdrop = false; // again can passed in the route

  /**
   * Read only list (no removal or reordering)
   */
  @Input() ro = false;

  /**
   * Whether we display the alert dialog after selection change
   */
  @Input() dialogAlerts = true;

  valid$ = this._selectionService.choicesStudentValid$;

  // set this to wait for the return from the query
  waitingUpdate = false;

  // emit me to display isValid dialog box
  private testIsValidSubject = new BehaviorSubject<number>(0);
  testIsValidAction$ = this.testIsValidSubject.asObservable();

  choicesObjects$ = this._selectionService.choicesStudent$;
  takenChoices$ = combineLatest(
    [this._selectionService.preallocatedStudentChoices$,
    this.choicesObjects$])
    .pipe(
      tap(([prealloc, choices]) => console.log(prealloc['results'].map(p => p.id))),
      map(([prealloc, choices]) =>
        choices.filter(c => prealloc['results'].map(p => p.id).includes(c.id))),
      map(sels => sels.map(sel => new Y4projectselection().deserialize(sel).pslY4project.projTitle))
    );

  preallocids$ = this._selectionService.preallocatedStudentChoices$.pipe(map((res) => res['results'].map(r => r.id)));
  choiceids$ = this.choicesObjects$.pipe(map((res) => res.map(r => r.id)));

  typeBchoices$ =
    this.choicesObjects$.pipe(
      map(choices => choices.filter(choice => choice.pslY4project.projType === 'b'))
    );



  public choices: Y4projectselection[] = [];
  sub: any;
  sub2: any;
  sub3: any;

  constructor(
    private route: ActivatedRoute,
    private _selectionService: Y4ProjectselectionService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.sub2 = this.choicesObjects$.subscribe(sels => this.choices = sels);
    if (this.context === 'dialog') {
      this.dragdrop = false;
      this.displayBUpload = false;
    }
    // If we have come directly from a route grab the data
    this.sub = (this.route.data as any)
      .subscribe(
        d => {
          // We may have set displayBUpload via the Input parameter, check whether it has been set before assiging false
          this.displayBUpload = (d.displayBUpload) ? d.displayBUpload : (this.displayBUpload) ? this.displayBUpload : false;
          this.dragdrop = (d.dragdrop) ? d.dragdrop : false;
        });
    this.sub3 = this.valid$.subscribe((valid) => {
      if (this.dialogAlerts) {
        // trigger the dialog to confirm whether choices are valid etc
        const dialogRef = this.dialog.open(Y4ProjectChoicesStatusDialogComponent, {
          //width: '250px',
          data: { status: valid['status'], message: valid['message'] }
        });
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
    this.sub3.unsubscribe();
  }

  /**
   * Rather than drag and drop shift the selection up using a button
   */

  /**
   * Lower serial of this project higher
   */
  raiseChoice(sel: Y4projectselection) {
    this._selectionService
      .raiseSelection(sel)
  }

  /**
   * Increase serial of this project lower
   */
  lowerChoice(sel: Y4projectselection) {
    this._selectionService
      .lowerSelection(sel)
  }

  // deactivate - 
  //drop(event: CdkDragDrop<Y4projectselection[]>) {
  //  // get serial of the target location and apply to the dropped  selection
  //  const selection = this.choices[event.previousIndex];
  //  selection.pslSerial = this.choices[event.currentIndex].pslSerial;
  //  this._selectionService.updateSelection(selection);
  //}

  /**
   * Remove the choice from our list
   *
   * @param index - the index in our list
   */
  triggerRemoveSelection(index: number) {
    this._selectionService.removeSelection(this.choices[index]).subscribe(res => {
      this._selectionService.touchSelections();
      //this.testIsValidSubject.next(1)
    });
    //after which we need to notify to the user whether their selections are valid

  }

  uploadTypeBY4ProjectDialog() {

    const dialogRef =
      this.dialog.open(Y4ProjectStudentCreateComponent, { height: '90%', width: '70%' });

    dialogRef.afterClosed().subscribe(result => {
      this._selectionService.touchSelections();
    });

  }
}

