import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AddRoleDialogComponent } from '../add-role-dialog/add-role-dialog.component';
import { AreyousureDialogComponent } from 'cued-lib/src/lib/cued-shared';
import { Auth } from '../lib/models/auth';
import { Role } from '../lib/models/role';
import { RoleService } from '../lib/services/role.service';
// import { StaffService } from '../lib/services/staff.service';
import { UserService } from '../lib/services/user.service';

/**
 * Component displaying list of the current available roles along with the users who have those roles
 *
 * Provides the ability to remove a user-role and call a dialog to create a user-role record
 *
 */
@Component({
  selector: 'app-roles-admin',
  templateUrl: './roles-admin.component.html',
  styleUrls: ['./roles-admin.component.css']
})
export class RolesAdminComponent implements OnInit, OnDestroy {

  private _subscriptions = [];

  /**
   * The current roles provided by our application
   */
  public roles$ = this._rolesService.roles$;
  /**
   * List of users and their roles
   */
  public roleGroups$ = this._rolesService.roleGroups$;
  // public staff$ = this._staffService.allStaff$;

  private tabSelectedSubject$ = new ReplaySubject<string>();
  public tabSelected$ = this.tabSelectedSubject$.asObservable().pipe(tap(res => console.log(res)));

  private _selectedRole$ = combineLatest([this.roles$, this.tabSelected$]).pipe(
    map(([roles, tab]) => roles.filter(role => role.roleType === tab)[0])
  );
  private _selectedRole: Role;

  // we find the Role object that we wish to pass to our role service filter
  public usersWithRole$ = combineLatest([this._selectedRole$, this.roleGroups$]).pipe(
    map(([role, rgs]) => rgs.filter(rg => (rg.ugrRole.id === role.id))),
  );



  constructor(
    private _userService: UserService,
    private _rolesService: RoleService,
    // private _staffService: StaffService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar // TODO place in parent after testing for return value?!
  ) {
  }

  /**
   * Keep a track of the current tab (thouigh)
   */
  ngOnInit(): void {
    this._subscriptions.push(this._selectedRole$.subscribe(role => this._selectedRole = role));
  }

  /**
   * Remove any subscriptions
   *
   * TODO: remove the need to track subscriptions pipe(take(1)) in addRole()?
   */
  ngOnDestroy(): void {
    this._subscriptions.map(sub => sub.unsubscribe());
  }

  /**
   * Action a click on a tab
   *
   * @param eve - event containing the tab name clicked
   */
  public tabClick(eve) {
    // we wish to extract the role from roles$ and text label, pass this to userRole$ so that we can use usersWithRole$
    if (eve.tab.textLabel) {
      this.tabSelectedSubject$.next(eve.tab.textLabel);
    }
  }
  /**
   *
   * Remove a role from user, calling the 'are you sure' before taking action
   *
   * @param user - a user (Auth) object
   * @param role - role (Role) object
   *
   * TODO: remove the need to subscribe
   */
  public removeRole(user: Auth, role: Role) {
    // to implement -> perhaps remove from user! and disply the staff details?
    const dialogRef = this.dialog.open(AreyousureDialogComponent, {});
    const instance = dialogRef.componentInstance;
    instance.message = `You wish to remove ${role.roleType} from ${user.display_name}`;
    dialogRef.afterClosed()
      .subscribe((val) => {
        if (val) {
          this._userService.removeRole(user.id, role).subscribe(
            res => {
              this._rolesService.refreshRoleGroups();
              this.openSnackBar(`Role ${role.roleType} removed from ${user.display_name}`, 'close');
            }
          );
        }
      });
  }

  /**
   * Adds a role (calls the dialog to do so)
   *
   * TODO: remove the need to store the selectedRole ina variable (use the Observable?)
   */
  addRole() {
    // TODO can we get rid of the selectedRole variable ?
    const dialogRef = this.dialog.open(AddRoleDialogComponent, { autoFocus: true, height: '90%', data: { role: this._selectedRole } });
    dialogRef.afterClosed()
      .subscribe((val) => {
        this._rolesService.refreshRoleGroups();
        if (val){ this.openSnackBar('Role added', 'close'); }
      }
      );
  }

  // currently using the standard material snackbar service
  // campl-ngx-snackbar could be created to display different types of message etc?
  // We could modify sendMessage to send to a snack bar or messageare
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
