import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CamplNgxModule } from 'cued-lib/src/lib/campl-ngx';

import { CuedInstituteSelectComponent } from './components/cued-institute-select/cued-institute-select.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule } from '@angular/forms';
import { CuedUserDetailsComponent } from './components/cued-user-details/cued-user-details.component';

import { CuedMaterialsModule } from 'cued-lib/src/lib/cued-materials'


@NgModule({
  declarations: [
    CuedInstituteSelectComponent,
    CuedUserDetailsComponent
  ],
  imports: [
    CommonModule,
    CamplNgxModule,
    CuedMaterialsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule // for debugging hw to pass valeus up
  ],
  exports: [
    CuedInstituteSelectComponent,
    CuedUserDetailsComponent
  ]
})
export class CuedWidgetsModule { }
