
<campl-ngx-page-nav *ngIf="initialIndex"
  [heading]="'All projects for year: '"
  [initialIndex]="initialIndex" 
  [values]="offerings" 
  (current)="populateProjects($event)">
</campl-ngx-page-nav>

<app-y4-projects-table *ngIf="offering" 
  [edit]="true" 
  [add]="true" 
  [remove]="true" 
  [offering]="offering?.id"></app-y4-projects-table>
