import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge, Subject } from 'rxjs';
import { Y4project } from '../lib/models/y4project';
import { Y4ProjectService } from '../lib/services/y4-project.service';

// TODO: remove the use of this interface it is equivalent to Y4project
export type Y4projectsTableItem = Y4project;

/**
 * Data source for the Y4projectsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 *
 * TODO: replace/remove the data attribute utilizing dataobs$
 * a subscription to dataobs$ is updating this.data but this does not
 * seem intuative or clean enough
 */
export class Y4projectsTableDataSource extends DataSource<Y4projectsTableItem> {
  data: Y4projectsTableItem[] = []; // = EXAMPLE_DATA;
  // dataobs$: Subject<Y4projectsTableItem[]>;
  paginator: MatPaginator;
  sort: MatSort;

  constructor(private dataobs$: Subject<Y4projectsTableItem[]>) {
    // constructor(private y4projectsService: Y4ProjectService) {
    super();
    // this.dataobs$ = y4projectsService.projects$;
    this.dataobs$.subscribe(projs => {
      //;
      this.data = projs.map(proj => new Y4project().deserialize(proj));
    });
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   *
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<Y4projectsTableItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      // observableOf(this.data),
      this.dataobs$,
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => this.getPagedData(this.getSortedData([...this.data]))));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: Y4projectsTableItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: Y4projectsTableItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'proTitle': return this.compare(a.projTitle, b.projTitle, isAsc);
        case 'id': return this.compare(+a.id, +b.id, isAsc);
        default: return 0;
      }
    });
  }
  private  compare(a: string | number, b: string | number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
// function
