import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Role } from '../lib/models/role';
import { Staff } from '../lib/models/staff';
import { RoleService } from '../lib/services/role.service';
import { SgtopicService } from '../lib/services/sgtopic.service';
import { StaffService } from '../lib/services/staff.service';
import { UserService } from '../lib/services/user.service';

/**
 * Component providing a dialog used to provide a staff member
 * with the provided role for a selected group
 *
 * TODO: rename to include the y4p prefix
 */
@Component({
  selector: 'app-add-role-dialog',
  templateUrl: './add-role-dialog.component.html',
  styleUrls: ['./add-role-dialog.component.css']
})
export class AddRoleDialogComponent implements OnInit, OnDestroy {

  /**
   * The role we wish to provide to a user
   */
  @Input() role: Role;

  /**
   * The form group
   */
  roleForm: FormGroup;


  // Some subscriptions to manage
  // TODO: replace with a subscriptions array
  topicsSub; // wait for topics to return (we assign a role to a topic)
  staffFilterSub; // wait for staff filter
  subscriptions = [];

  topicSelections; // used in the topics drop down

  // staffMember autocomplete
  // selectedstaffMember is the registered selected staff member from the autocomplete
  staffMemberCtrl = new FormControl();
  selectedStaffMember: Staff = new Staff();

  // TODO: change list to list$
  topicsList$ = this._sgtopics.list.pipe(
    map(sgtopics => sgtopics.map(sgtopic => sgtopic.subjGroup)),
    map(sgtopics => [...new Set(sgtopics)]),
    map(sgtopics => sgtopics.map(sgtopic => ({ id: sgtopic, value: sgtopic }))),
    map(sels => sels.sort((a, b) => (a.value > b.value) ? 1 : -1))
  );

  /**
   * staffMemberCtrl is the input element for our staff select box
   */
  private _staffSearchAction$ = this.staffMemberCtrl.valueChanges.pipe(
    debounceTime(300),
    map(value => typeof value === 'string' ? value : '')
  );

  // TODO Fix me
  // This calls service http request twice because a subscription updates searchBy()
  public filteredStaffMembers$ = combineLatest([
    this._staffService.staffList$,
    this._staffSearchAction$
  ])
    .pipe(
      map(([staff, search]) => staff.filter((istaff) => istaff.stfSurname.toLowerCase().includes(search.toLowerCase())))
    );



  constructor(
    private _fb: FormBuilder,
    private _staffService: StaffService,
    private _sgtopics: SgtopicService,
    private _snackBar: MatSnackBar, // TODO place in parent after testing for return value?!
    private _dialogRef: MatDialogRef<AddRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.role = this.data.role;
  }

  ngOnInit(): void {
    this.roleForm = this._fb.group({
      staffMember: ['', [Validators.required]],
      // TODO set the default of this to be the current topic associated (if exists)
      staffSubjectgroup: ['', Validators.required]
    });

    this.subscriptions.push(
      this.topicsSub = this.topicsList$.subscribe(
        topics => this.topicSelections = topics
      ));

    this.subscriptions.push(
      this.staffFilterSub = this._staffSearchAction$.subscribe(
        res => this._staffService.searchBy(res)
      ));
  }
  ngOnDestroy(): void {
    this.subscriptions.map(sub => sub.unsubscribe());
    // this.topicsSub.unSubscribe()
    // this.staffFilterSub.unSubscribe();
  }


  /**
   * On selected of an autocomplete option register the selectedStaffMember
   * This 'guarantees' a valid staff member
   *
   * Not to be used if the user does not have proxy permissions
   */
  optionStaffMemberClicked(event: Event, staffMember: Staff) {
    this.selectedStaffMember = staffMember;
  }

  /**
   * Returns a value to be displayed in the text field
   * side effects - sets the selectedStaffMember / projStaffMember of the project
   *
   * @param value - the staff member to display as the value, or at load a blank string
   */
  displayStaffMemberFn(value: Staff | string): string | undefined {
    let ret = '';
    if (typeof (value) === 'string' || value === null) {
      ret = (value === null) ? '' : '' + value;
    }
    else {
      this.selectedStaffMember = value;
      ret = value.display_name() + ' (' + value.stfCrsid + ')';
    }
    return ret;
  }
  save() {
    const parsedForm = this.roleForm.value;
    parsedForm.staffMember = this.selectedStaffMember.id;
    this._staffService.addRole(parsedForm.staffMember, parsedForm.staffSubjectgroup.selected, this.role).subscribe(
      (res) => {
        this._dialogRef.close(true);
      },
      (err) => { console.log(err); }
    );
  }
  cancel() {
    this.openSnackBar('Adding Role for Staff member cancelled ', 'close');
    this._dialogRef.close(null);
  }

  // currently using the standard material snackbar service
  // campl-ngx-snackbar could be created to display different types of message etc?
  // We could modify sendMessage to send to a snack bar or messageare
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
