import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, combineLatest, Subject, BehaviorSubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Sgtopic } from '../models/sgtopic';
import { AppService } from './app.service';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { getMatScrollStrategyAlreadyAttachedError } from '@angular/cdk/overlay/scroll/scroll-strategy';
import { ErrorService } from './error.service';
@Injectable({
  providedIn: 'root'
})
export class SgtopicService {

  baseurl = '/api/v1/subjectgrouptopic';
  appendlimit = '?limit=10000';
  // list = new ReplaySubject<Sgtopic[]>();

  // topicsListSubject = new Subject<Sgtopic[]>()
  public topics$ = this.http.get<Sgtopic[]>(this.baseurl + this.appendlimit).pipe(
    map(res => res.map((topic: Sgtopic) => new Sgtopic().deserialize(topic)))
    , shareReplay(1)
  );

  public groups$ = this.topics$.pipe(
    map(topics => topics.reduce((uni, curr) => {
      uni[curr.subjGroup] = (uni[curr.subjGroup]) ? uni[curr.subjGroup] : curr;
      return uni;
    }, {}))
  );

  // Below is not used atm maybe a list of topic ids assciaited
  // for the filter tags should be added
  // For select list drop down unique groups and their ids
  // { id: grp, value: grp }
  public groupsSelectOptions$ = this.topics$.pipe(
    map(sgtArr =>
      [... new Set(sgtArr
        .filter(grp => grp.subjActive)
        .map(data => data.subjGroup)
      )].map(grp => ({ id: grp, value: grp }))
        .sort()
    )
  );

  // Select the topic group for the project
  public topicGroupSelectOptions$: Observable<any[]> = this.topics$.pipe(
    map(sgtArr => sgtArr.map(sgt => ({ id: sgt.id, value: sgt.subjGroup + ': ' + sgt.subjGroupTopic }))
      .sort((a, b) => (a.value > b.value) ? 1 : -1)
    ));

  // TODO replace with lists$
  list = this.topics$;
  // private selectedGroupSubject$ = new BehaviorSubject<string>('');
  // public selectedGroupAction$ = this.selectedGroupSubject$.asObservable();

  // public topicsByGroup$ = combineLatest([this.topics$, this.selectedGroupAction$]).pipe(
  //   map( ([list,group]) => {
  //     let groupTopics = {}
  //     let topicsList = list;
  //     let groups = new Set(list.map(sgt => sgt.subjGroup))
  //     let groupsList = Array.from(groups) // [...groups] only provides indexes?
  //     groups.forEach(grp => groupTopics[grp] = this.listGoupTopics(grp));

  //     let object = groupTopics
  //     this.allTopics = Object.keys(object).reduce(
  //       function (r, k) {
  //         return r.concat(object[k]);
  //       }, []);
  //   })
  // )

  constructor(
    private http: HttpClient,
    pageMessage: CamplNgxMessageBufferService,
    private errorService: ErrorService
  ) { }

  // runs on app startup (app.component) - so our data is ready
  init(): Observable<Sgtopic[]> {
    // Startup logic here
    // //
    // this.getTopics();
    // this.list = this.http.get<Sgtopic[]>(this.baseurl+this.appendlimit)
    return this.topics$;
  }

  /**
   * Helper
   * added to enable spying during tests
   *
   * TODO: can we replace all .list$ calls with .getList$() ? or change the tests to remove this method?
   * TODO: replace init() cals with getList$() calls
   */
   getList$(): Observable<Sgtopic[]> {
    return this.topics$;
  }

  createSgtopic(sgtopic: Sgtopic): Observable<Sgtopic> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    delete sgtopic.id;
    return this.http.post<Sgtopic>(this.baseurl, sgtopic, { headers })
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  private initializeSgtopic(): Sgtopic {
    // Return an initialized object
    return new Sgtopic(0);
  }
  getSgtopic(id: number): Observable<Sgtopic> {
    if (id === 0) {
      return of(this.initializeSgtopic());
    }
    const url = `${this.baseurl}/${id}`;
    return this.http.get<Sgtopic>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );

  }

}
