import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'campl-ngx-footer',
  templateUrl: './campl-ngx-footer.component.html',
  styleUrls: ['./campl-ngx-footer.component.css']
})
export class CamplNgxFooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
