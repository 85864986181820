<!--<div *ngIf="(choices$ | async) as choices"> subscribed in init -->
<mat-card>
    <mat-card-title>
        IIB project choices
    </mat-card-title>
    <div *ngIf="choices.length > 0; else noSelections">

        <mat-card-subtitle>
            <h4 mat-h4>
                <span *ngIf="!(valid$|async)?.status" class="valign-center choices-invalid">
                    <mat-icon>report_problem</mat-icon>Your choices do NOT meet the required criteria.
                </span>

                <span *ngIf="(valid$|async)?.status" class="valign-center">
                    <mat-icon>insert_emoticon</mat-icon> Your choices are valid and will be submitted when selection closes.
                </span>
            </h4>
            <h5 *ngIf="!(valid$|async)?.status" mat-h5>{{(valid$|async)?.message}}</h5>
            <h5 *ngIf="(takenChoices$|async)?.length > 0 && !ro" mat-h5>
                <span class="choices-invalid">
                    <mat-icon>report_problem</mat-icon>Your choices contain projects that have been allocated to others:
                </span>
                <div *ngFor="let ptitle of (takenChoices$|async) ">
                   <h3 mat-h3> '{{ ptitle }}' </h3>
                </div>

            </h5>
        </mat-card-subtitle>
        <mat-card-content>
            <div class="example-list" >
                <div *ngFor="let sel of choices; index as i" class="example-list">
                    <div class="flex-con">
                        <div class="listing">
                            <app-y4-project-selection-listing [selection]="sel" [showAllocation]="!ro"></app-y4-project-selection-listing>
                        </div>
                        <div class="actions">
                            <span>
                                <mat-icon *ngIf="!sel.isAllocated() && !ro" class="pointer"
                                    (click)="triggerRemoveSelection(i)" aria-label="Remove choice"
                                    matTooltip="Remove choice">delete</mat-icon>
                            </span>
                            <span *ngIf="i !== (choices.length-1)  && !ro" class="pointer">
                                <mat-icon matTooltip="Move to choice {{(sel.pslSerial+1)}}" (click)="lowerChoice(sel)"
                                    aria-label="Reduce choice">
                                    arrow_downward</mat-icon>
                            </span>
                            <span *ngIf="i !== 0  && !ro" class="pointer">
                                <mat-icon matTooltip="Move to choice {{sel.pslSerial-1}}" (click)="raiseChoice(sel)"
                                    aria-label="Raise choice">
                                    arrow_upward</mat-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div *ngIf="displayBUpload && (typeBchoices$ | async )?.length == 0">
                <div *ngIf="choices?.length > 0 ">
                    If you wish to upload a type (b) project you must first remove all your type (a) choices.
                </div> 
                <div *ngIf="choices.length == 0 ">
                    If you have talked to a supervisor and have agreed a type (b) project, please enter details using the
                    link below:<br /><br />
                    <button mat-raised-button color="primary" (click)="uploadTypeBY4ProjectDialog()"
                        aria-label="Upload a type b project">Upload a type (b)
                        project</button>
                </div>
            </div>

        </mat-card-content>
    </div>


    <ng-template #noSelections>
        <mat-card-subtitle>
            <h4 mat-h4>
                <span *ngIf="!(valid$|async)?.status" class="valign-center choices-invalid">
                    <mat-icon>report_problem</mat-icon>You currently do not have any choices.
                </span>
            </h4>
            <h5 mat-h5>{{(valid$|async)?.message}}</h5>
            <div *ngIf="displayBUpload">
                If you have talked to a supervisor and have agreed a <span class="mat-body-strong">type (b)</span>
                project, please enter using the link below:<br /><br />
                <button mat-raised-button color="primary" (click)="uploadTypeBY4ProjectDialog()">Upload a type (b)
                    project</button>
            </div>
        </mat-card-subtitle>
    </ng-template>

    <mat-card-footer>
        Your choices will be submitted automatically when the selection period closes.<br />

    </mat-card-footer>
</mat-card>
