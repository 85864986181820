import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../lib/services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  message = 'Logging out';
  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    // when called post to the logout handler
    // Once returned  update the page to display logged out?
    this.auth.logout();
  }

}
