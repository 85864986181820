<p>People, stored in EDDA, with the following job titles will be imported as members of staff who can propose and supervise projects.</p>
<div *ngFor="let sjt of (jobTitles$() | async)">
    {{sjt.sjtTitle}} <button mat-icon-button aria-label="Button to remove title" matTooltip="Remove title">
        <mat-icon (click)="removeJobtitle(sjt)">delete_forever</mat-icon>
    </button>
</div>
<div class="flex-container">
    <div class="half">
        <div [formGroup]="jobTitleForm">
            <mat-form-field appearance="fill">
                <input matInput placeholder='Add a new job title' formControlName="jobTitle">
            </mat-form-field>
        </div>
    </div>
    <div class="full">
        <button mat-raised-button color="primary" (click)="addJobtitle()"
            [title]="jobTitleForm.valid ? 'Save your entered data' : 'Disabled until the form data is valid'"
            [disabled]="!jobTitleForm.valid" aria-label="Add Job title">
            Add title
        </button>
    </div>
</div>