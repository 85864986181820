import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
import { Auth } from '../models/auth';

/**
 * This guard will check that the user can access the studentAllocations page
 */

@Injectable({
  providedIn: 'root'
})
export class ManageY4projectsActiveGuard implements CanActivate {

  permsLookup = environment.permsLookup;

  constructor(
    private userService: AuthService
  ){}

  canActivate(): Observable<boolean>{
    return this.userService.getMe$().pipe(
      map(me => this._testAccess(me, this.permsLookup.manageY4projectsAccess))
     );
  }

  private _testAccess(user: Auth, accessroles: string[]): boolean {
    return (user.roles.filter(value => accessroles.includes(value.roleType)).length > 0);
  }

}
