import { Component, OnInit, Input, forwardRef } from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  ValidatorFn
} from '@angular/forms';
import { DropdownItem } from '../../models/dropdown-item';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Deserializable } from 'cued-lib/src/lib/cued-shared';


/*
We can provide an array of objects (which must extend the DropdownItem)

<campl-ngx-input-autocomplete 
    *ngIf="institutionObjects.length > 0"
    [optionObjects]="institutionObjects"
    [label]="label" 
    [placeholder]="placeholder">
</campl-ngx-input-autocomplete>

Or a plain string array - which we can choose whether or not the search string matchs the start of the string (matchStart)

<campl-ngx-input-autocomplete 
    *ngIf="institutionObjects.length > 0"
    [optionStrings]="debugInstitutionIds"
    [label]="'test strign arrays'" 
    [placeholder]="placeholder"
    [matchStart]="'true'">
</campl-ngx-input-autocomplete>
*/


@Component({
  selector: 'campl-ngx-input-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CamplNgxAutocompleteComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CamplNgxAutocompleteComponent),
    multi: true
  }
  ]
})
export class CamplNgxAutocompleteComponent implements OnInit, ControlValueAccessor {

  // myAutoControl = new FormControl('');
  // Accept options via string || object array
  @Input() optionStrings: string[] = [];

  // Object array must implement Dropdownitem
  @Input() optionObjects: DropdownItem[] = [];

  @Input() label: string;
  @Input() placeholder: string;
  @Input() validator: ValidatorFn;
  @Input() matchStart: false //only valid for optionsStrings

  // @Input() displayWith: any; // do we need this if we convert the options to their display value?

  filteredOptions: Observable<DropdownItem[]>;

  public autoForm: FormGroup;

  // @Input() formControlName: string;
  value;
  onChange;
  onTouched;

  ngOnInit() {
    // if there is a mis-match here then remove project node_modules dir!!

    if (this.optionStrings.length>0) {
      // convert our strings to DropdownItemDefault
      this.optionObjects = this.optionStrings.map(
        option => new DropdownItemDefault(this.matchStart).deserialize({ label: option, value: option })
      )
    }

    this.autoForm = new FormGroup({
      myauto: new FormControl('', this.validator)
    });
    this.filteredOptions = this.autoForm.get('myauto').valueChanges.pipe(
      startWith(''),
      map(value => (typeof (value) === 'string')? this._filter(value) : [])
    );

  }

  private _filter(value: string): DropdownItem[] {
    const filterValue = (value)? value.toLowerCase() : '';
    return this.optionObjects.filter(
      option => option.filter(filterValue))
      //.map(option => option.display())   
  }



  displayFn(value: DropdownItem | string): string | undefined {
    let ret = '';
    if (typeof (value) === 'string') {
      ret = '' + value;
    }
    else if (value) {// makes sure not null
      ret = value.display();
    }
    return ret;
  }

  // NG_VALIDATORS
  validate({ value }: FormControl) {
    // returns errors or null if valid
    return this.autoForm.get('myauto').valid ? null : { invalid: true };
  }

  writeValue(val: any) {
    return val && this.autoForm.get('myauto').setValue(val, { emitEvent: false });
  }
  registerOnChange(fn: (val: any) => void) {
    this.autoForm.get('myauto').valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  setDisabledState(disabled: boolean) {
    disabled ? this.autoForm.disable()
      : this.autoForm.enable();
  }
}

/**
 * Class to hold a default object when a plain string[] has been provided
 * 
 * We do not add these to the declarations in the library model: https://stackoverflow.com/questions/53932862
 * 
 */

class DropdownItemDefault implements Deserializable, DropdownItem {
  value: string;
  label: string;
  matchStart: boolean

  constructor(matchStart: boolean) {
    this.matchStart = matchStart
  }

  filter(filterValue: string) {
    // match string anywhere in title / instid
    return this._match(this.label, filterValue);
  }

  display(): string {
    return this.label
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  private _match(str: string, filterValue: string) {
    return (this.matchStart) ?
      str.toLowerCase().indexOf(filterValue) === 0 :
      str.toLowerCase().indexOf(filterValue) >= 0;
  }

}
