import { Deserializable } from './deserializable.model';
import { CourseOffering } from './courseoffering';
import { EngineeringArea } from './engineeringarea';
import { Auth } from './auth';
import { Staff } from './staff';
import { Y4projectselection } from './y4projectselection';

export class Student implements Deserializable {


    id: number;
    stuPersonnelKey: string;
    stuCrsId: string;
    stuStatusId: number;
    stuCandidateNo: string;
    stuFinalClass: string;
    stuEAAOffering: number;
    stuModulesChanged: string; // date time
    stuEntryConfirmed: string; // datetime
    stuRelaxRule: string;
    stuRelaxReason: string;
    stuFinalPct: number;
    stuFinalRank: string;
    stuCwkCandNo: string;
    stuLastname: string;
    stuPreferredName: string;
    stuCollegeCode: string;

    //  ╔═╗╔╦╗╔╗ ╔═╗╔╦╗╔═╗
    //  ║╣ ║║║╠╩╗║╣  ║║╚═╗
    //  ╚═╝╩ ╩╚═╝╚═╝═╩╝╚═╝


    //  ╔═╗╔═╗╔═╗╔═╗╔═╗╦╔═╗╔╦╗╦╔═╗╔╗╔╔═╗
    //  ╠═╣╚═╗╚═╗║ ║║  ║╠═╣ ║ ║║ ║║║║╚═╗
    //  ╩ ╩╚═╝╚═╝╚═╝╚═╝╩╩ ╩ ╩ ╩╚═╝╝╚╝╚═╝


    // The COURSE_OFFERINGS entry includes the year which the project is offered - and who to
    stuCourseOffering?: CourseOffering;

    // stuCourseOffering?: CourseOffering;

    stuEngineeringArea?: EngineeringArea;
    stuDOS: Staff;
    stuUser: Auth; // effecively User
    stuProjectchoices: Y4projectselection[];

    constructor() { }

    deserialize(input: any): this {
        Object.assign(this, input);

        // until we deep populate (which migh be handy with association to app status also!)
        // we utilise this as an ID
        this.stuCourseOffering = new CourseOffering().deserialize(input.stuCourseOffering);
        this.stuEngineeringArea = new EngineeringArea().deserialize(input.stuEngineeringArea);
        this.stuDOS = new Staff().deserialize(input.stuDOS);

        this.stuProjectchoices = (input.stuProjectSelections) ?
            input.stuProjectSelections.map(sel => new Y4projectselection().deserialize(sel)) :
            [];
        return this;
    }

    /**
     * Is this student a EGT3 student
     * Todo: make general
     */

    isEGT3(): boolean {
        // is this student registered on an EGT3 course
        return (this.stuCourseOffering && this.stuCourseOffering.cooH03Code === 'EGT3') ? true : false;
    }
    /**
     * For the EGT3 student record that we are
     *
     * return the course where selection is open
     *
     */
    egt3Selection(): CourseOffering | null {
        // search the statuses for the course  - do any match 'SELECTION'

        // TODO move me should be a method in courseofferings
        // return this.students.reduce((s3,stu):any=>(stu.isEGT3())?stu:s3)
        //;

        const today = (new Date()).getTime();
        const sta = (typeof (this.stuCourseOffering.cooStatus) === 'undefined' || this.stuCourseOffering.cooStatus.length === 0) ?
            undefined :
            this.stuCourseOffering.cooStatus.filter(
                (stai): any => (stai.staCode === 'SELECTION' &&
                    stai.staStartsOn.getTime() < today &&
                    stai.staEndsOn.getTime() > today))[0];

        return (typeof (sta) === 'undefined') ? null : this.stuCourseOffering;
        // return true
    }
    /**
     * For the EGT3 student record that we are
     *
     * return the course where selection is open for browse
     *
     */
    egt3Y4PBrowse(): CourseOffering | null {
        // search the statuses for the course  - do any match 'SELECTION'

        // TODO move me should be a method in courseofferings
        // return this.students.reduce((s3,stu):any=>(stu.isEGT3())?stu:s3)
        const today = (new Date()).getTime();
        const sta = (typeof (this.stuCourseOffering.cooStatus) === 'undefined' || this.stuCourseOffering.cooStatus.length === 0) ?
            undefined :
            this.stuCourseOffering.cooStatus.filter(
                (status): any => (status.staCode === 'Y4PBROWSE' &&
                    status.staStartsOn.getTime() < today &&
                    status.staEndsOn.getTime() > today))[0];


        return (typeof (sta) === 'undefined') ? null : this.stuCourseOffering;
        // return true
    }



    display(): string {
        return this.stuLastname + ', ' + this.stuPreferredName + ' (' + this.stuCrsId + ')';
    }

    /**
     * If the student has an allocated project then the project selection is returned
     */
    hasAllocated(): Y4projectselection | undefined {
        const allocated = this.stuProjectchoices.filter(choice => choice.pslAllocated);
        return (allocated.length > 0) ? allocated.reduce((a, b) => a) : null;
    }
}
