<div [formGroup]="userDetailsForm">
    
    <mat-checkbox (click)="toggleStaffFilter()">Staff only &nbsp; </mat-checkbox>
    <mat-checkbox *ngIf="defaultInst" (click)="toggleInstFilter()">{{defaultInst}} ( Search this institute only; takes
        longer )</mat-checkbox>
    <br/>
    <campl-ngx-input-text formControlName="displayName" label="Search by Surname">
    </campl-ngx-input-text>
    <br />
    <campl-ngx-input-text formControlName="username" label="username (CRSID)">
    </campl-ngx-input-text>
    <br />
    <campl-ngx-input-text formControlName="email" label="Email">
    </campl-ngx-input-text>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Users found in lookup:
                </mat-panel-title>
                <mat-panel-description>
                    <mat-progress-bar *ngIf="userSearch" mode="buffer"></mat-progress-bar>
                    <span *ngIf="!userSearch">Click to view ({{(searchResults$|async)?.length}}) Users found in
                        Lookup</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let person of (searchResults$ | async)">
                {{person.displayName}} ({{person.username}}) <button (click)="selectUser(person)">Select</button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<!--<div *ngFor="let person of (searchResults$ | async)?.result.people" >
{{person.displayName}} <button (click)="selectUser(person)">Select</button>
</div>-->