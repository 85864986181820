import { Auth } from './auth';
import { Deserializable } from './deserializable.model';
import { Staff } from './staff';

export class Role implements Deserializable {

    createdAt: string;
    updatedAt: string;
    id: number;
    roleType: string;
    roleShortDescription: string;
    roleDescription: string;
    roleSubjectGroup: string;

    staff?: Staff[];
    users?: Auth[];

    constructor() { }

    deserialize(input: any): this {

        if (typeof input === 'number') {
            Object.assign(this, { id: input });
        } else {
            Object.assign(this, input);
        }
        return this;
    }
}
