<p>The list below details CRSID of users which are to be included/excluded in the synchronisation process.</p>

<div *ngFor="let scf of (crsidFilters$() | async)">
    {{scf.display()}} <button mat-icon-button aria-label="Button to remove title" matTooltip="Remove title">
        <mat-icon (click)="removeCrsidfilter(scf)">delete_forever</mat-icon>
    </button>
</div>
<div class="flex-container">
    <div class="full">
        <h3>Add a new filter</h3>
    </div>
    <div class="half">
        <div [formGroup]="crsidFilterForm">
            <campl-ngx-input-text [label]="'CRS ID'" placeholder="Enter the user's CRS ID" formControlName="scfCrsId">
            </campl-ngx-input-text>
            <campl-ngx-input-reg-select id="filterType" [label]="'Fiter type*'" [options]="types"
                formControlName="scfType" aria-label="Filter type"
                [validator]="crsidFilterForm.get('scfType').validator">
            </campl-ngx-input-reg-select>
            <campl-ngx-input-text [label]="'Notes'" placeholder="Enter notes related to the filter"
                formControlName="scfNotes"></campl-ngx-input-text>

        </div>
    </div>
    <div class="full">
        <button mat-raised-button color="primary" (click)="addCrsidfilter()"
            [title]="crsidFilterForm.valid ? 'Save your entered data' : 'Disabled until the form data is valid'"
            [disabled]="!crsidFilterForm.valid" aria-label="Add Filter">
            Add Filter
        </button>
    </div>
</div>