
<div id="maxProjectWarning" *ngIf="numAllocatedToSupervisor >= maxProjects">
    <h3>WARNING</h3>
    <p>This supervisor is already supervising <strong>{{ numAllocatedToSupervisor }}</strong> projects. The usual maximum is <strong>{{ maxProjects }}</strong>.</p>    <p>Please do not proceed before checking with the Group Coordinator.</p>
</div>

<!--<div *ngIf=" (project$ | async)?.allocations().length > 0 ">-->
<div  *ngIf=" (allocatedToProject$ | async) as allocations">
    <div id="projectAllocatedList" *ngIf="allocations.length > 0">
        <h3>WARNING</h3>
        <p>This project has already been allocated to</p>

        <div *ngFor="let stu of allocations">
            <h4 mat-h4>{{stu.display()}} ( {{stu.stuCollegeCode}} )</h4>
        </div>
        <p>Continuing will not de-allocate the current student<span
                *ngIf=" (project$ | async)?.allocations().length > 1">s</span>
            and will mean that the project is assigned to multiple students.</p>
    </div>
</div>