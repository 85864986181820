<h3>Search for and add available assistants</h3>

<p>
    An Assistant must have a Univeristy ID (CRS ID).
</p>
<p>
    To add an assistant search for them in the University lookup and then add to the list of available assistants. Not
    all
    users will be listed in this lookup as they can decide to remove themselves from this system, if this is the case
</p>
<mat-card>
    <form [formGroup]="addAssistantForm">
        <h4>Search for user in University lookup:</h4>
        <div class="flex-container">
            <div class="full">
                <cued-user-details defaultInst="ENG" formControlName="user" [validator]=validator></cued-user-details>
            </div>
        </div>
        <br />
        <br />
    </form>
    <div class="highlight_errors" *ngIf="alreadyExists">
        Can not add: The user chosen already exists in the Assistants list
    </div>
    <button mat-raised-button color="primary" (click)="addAssistant()"
        [title]="addAssistantForm.valid ? 'Add user record' : 'Disabled until the form data is valid'"
        [disabled]="!addAssistantForm.valid || alreadyExists" aria-label="Add assistant record">
        Add Assistant
    </button>
</mat-card>