<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h1 mat-dialog-title>{{pageTitle}}</h1>
<h3>{{ alertMessage }}</h3>
<h3>{{ errorMessage }}</h3>

<!--<mat-card>
    <mat-card-title>Add a new project</mat-card-title>-->

<mat-horizontal-stepper linear #stepper>
    <mat-step [stepControl]="y4projectForm" [editable]="isEditable">
        <ng-template matStepLabel>Enter details</ng-template>
        <form [formGroup]="y4projectForm" *ngIf="showForm">
            <div>
                <div class="flex-container">
                    <div class="half">
                        <mat-form-field class="example-full-width">
                            <input type="text" placeholder="Enter supervisor name*" aria-label="Enter supervisor"
                                matInput [matAutocomplete]="autoOwner" formControlName="projOwner">
                            <mat-hint>Enter text to find supervisor by crsid or last name</mat-hint>
                        </mat-form-field>
                        <mat-autocomplete #autoOwner="matAutocomplete"
                            (optionSelected)="optionOwnerClicked($event, $event.option.value)"
                            [displayWith]="displayOwnerFn">
                            <mat-option *ngFor="let owner of filteredOwners$ | async" [value]="owner">
                                <div>
                                    {{ owner.display_name()+" ("+owner.stfCrsid+")" }}
                                </div>
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>


                <div class="flex-container">
                    <div class="full">
                        <campl-ngx-input-text id="projTitleId" [label]="'Project Title*'" type="text"
                            placeholder="Project title (required)" formControlName="projTitle"
                            [validator]="y4projectForm.get('projTitle').validator"></campl-ngx-input-text>
                    </div>
                </div>
                <div class="flex-container">
                    <div class="full">
                        <campl-ngx-input-textarea id="projDescriptionId" [label]="'Project Description*'"
                            placeholder="Enter a summary of the project" formControlName="projDescription"
                            [validator]="y4projectForm.get('projDescription').validator">
                        </campl-ngx-input-textarea>
                        <span class="formfield_error" *ngIf="y4projectForm.get('projDescription').errors?.minlength">
                            *(Minimum length = 10)
                        </span>
                        <span color="warn" *ngIf="y4projectForm.get('projDescription').errors?.maxlength">
                            *Sorry, the description is too long.
                        </span>
                        <br /><br />
                    </div>
                </div>

                <div class="flex-container">
                    <div class="half">
                        <campl-ngx-input-reg-select id="projSubjectgroupTopicId" [label]="'Project group*'"
                            [options]="subjectgroups$|async" formControlName="projSubjectgroupTopic"
                            [validator]="y4projectForm.get('projSubjectgroupTopic').validator" [aria-label]="'Project Group'">
                        </campl-ngx-input-reg-select>
                    </div>
                </div>
                <!-- PNT email ~ Jan/feb external partners on Type a only
                If your project is to be sponsored by an Industrial partner please enter the contact details here<br/>

                <div class="flex-container">
                    <div class="half">
                        <campl-ngx-input-textarea id="projIndustrialId" [label]="'Industrial details'"
                            placeholder="Enter the industrial contact details for the project."
                            formControlName="projIndustrial">
                        </campl-ngx-input-textarea>
                        <br />
                        <span *ngIf="y4projectForm.get('projIndustrial').errors?.maxlength">
                            Sorry, contact details are too long.
                        </span>
                    </div>
                </div>
            -->
                <!--    
        <label for="projPreallocatedId">Project pre-allocated</label>
        <input id="projPreallocatedId" type="checkbox" formControlName="projPreallocated" />-->

                <button mat-raised-button color="warn" [title]="'Cancel'" (click)="cancel()" class="back-button">
                    Cancel
                </button>
                <button mat-raised-button color="primary" matStepperNext [disabled]="!y4projectForm.valid"
                    class="proceed-button">Next</button>

                <!--<button type="button" (click)="populateTestData()">Test data</button>-->
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="y4projectForm" [editable]="isEditable">
        <ng-template matStepLabel>Review entry</ng-template>
        You are about to submit the following details. Please note that once submitted you cannot alter them using this
        interface.<br /><br />
        <div *ngIf="y4projectForm.valid">

            <app-y4-project-summary [noFooter]="true" [noRef]="true" [noAreas]="true" [noTopics]="true"
                [projectIn]="newProject"></app-y4-project-summary>

        </div>
        <br />
        If you are happy with the details above please submit, otherwise navigate back to edit the details.<br />
        <br />
        <br />

        <button mat-raised-button color="warn" mat-button matStepperPrevious class="back-button">Back</button>
        <button mat-raised-button color="primary" (click)="save()"
            [title]="y4projectForm.valid ? 'Save your entered data' : 'Disabled until the form data is valid'"
            [disabled]="!y4projectForm.valid" matStepperNext class="proceed-button">
            Submit
        </button>

    </mat-step>
    <mat-step [stepControl]="y4projectForm">
        <ng-template matStepLabel>Finished</ng-template>
        Your project proposal has been submitted and should appear in your list of project choices.

        <button mat-raised-button color="primary" mat-button [mat-dialog-close]="true"
            class="proceed-button">Close</button>
    </mat-step>
</mat-horizontal-stepper>
<!--
</mat-card>
-->
<!-- formGroup -->
