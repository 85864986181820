<h2 class="mat-h2">{{title}}</h2>
<h3 class="mat-h3">{{keydatesForm.get('staYear').value}}: {{keydatesForm.get('staCode').value}}</h3>
<p class="mat-small">{{keydatesForm.get('staDescription').value}}</p>
<form [formGroup]="keydatesForm" *ngIf="showform">
    <input type="hidden" formControlName="id" />
    <input type="hidden" formControlName="staCode" />
    <input type="hidden" formControlName="staDescription" />
    <input type="hidden" formControlName="staYear" />
    <div>

        <campl-ngx-input-date-select formControlName="staStartsOn" id="startDate" [label]="'Start date'"
            [validator]="keydatesForm.get('staStartsOn').validator"></campl-ngx-input-date-select>
        <mat-error *ngIf="keydatesForm.get('staStartsOn').dirty && keydatesForm.get('staStartsOn').invalid">Required
        </mat-error>
        <br />
        <campl-ngx-input-date-select formControlName="staEndsOn" id="endDate" [label]="'End date'"
            [validator]="keydatesForm.get('staEndsOn').validator"></campl-ngx-input-date-select>
        <mat-error *ngIf="keydatesForm.get('staEndsOn').dirty && keydatesForm.get('staEndsOn').invalid">Required
        </mat-error>

        <br />

    </div>
    <!--TODO: style - place the buttons at the bottom of the form (keydates update)-->
    <button mat-raised-button color="primary" id="submit" [title]="keydatesForm.valid ? 'Update' : 'Please complete form'"
        [disabled]="!keydatesForm.valid" (click)="save()">
        Update
    </button>
    <button mat-raised-button color="warn" id="cancel" [title]="'Cancel'" (click)="cancel()">
        Cancel
    </button>
</form>