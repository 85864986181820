import { Deserializable } from './deserializable.model';
import { Auth } from './auth';
import { Role } from './role';

export class Grouprole implements Deserializable {

    createdAt?: string;
    updatedAt?: string;
    id?: number;
    ugrGroup: string;
    ugrUser: Auth; // but not full User
    ugrRole: Role;



    deserialize(input: any): this {

        if (typeof input === 'number') {
            this.id = input;
        } else {
            Object.assign(this, input);
            this.ugrUser = new Auth().deserializeStop(input.ugrUser);
            this.ugrRole = new Role().deserialize(input.ugrRole);
        }
        return this;
    }

}
