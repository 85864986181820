import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusesService } from '../lib/services/statuses.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Appstatus } from '../lib/models/appstatus';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';


/**
 * Dialog used to update a status period
 *
 * On success returns true, else returns false
 *
 *
 * dialogRef.afterClosed().subscribe(result => {
 *     if (result) { this._statusesService.getStatuses() }
 *  })
 *
 */
@Component({
  selector: 'app-keydate-editor-dialog',
  templateUrl: './keydate-editor-dialog.component.html',
  styleUrls: ['./keydate-editor-dialog.component.css']
})
export class KeydateEditorDialogComponent implements OnInit {

  title = 'Edit key dates';
  status: Appstatus;
  keydatesForm: FormGroup;
  showform = false;
  constructor(
    private messageBuffer: CamplNgxMessageBufferService,
    private fb: FormBuilder,
    private _statusesService: StatusesService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<KeydateEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    // this.setFormDefaults();
  }

  ngOnInit(): void {
    // //
    this.status = this.data.status;
    this.setFormDefaults();
  }
  /**
   * SetFormDefaults
   *
   * Sets the default values for the keydateForm
   * TODO: handle creation but on update of: Code, Description, Year
   * TODO: Provide YTWD input
   */
  setFormDefaults() {
    // Currently only to_admin has the prvilege to put statuses
    // we trust them not to attempt to play with the year, code and description values!
    this.keydatesForm = this.fb.group({
      id: [this.status.id, [Validators.required]],
      staCode: [this.status.staCode, [Validators.required]], // atm readonly
      staDescription: [this.status.staDescription, [Validators.required]], // atm readonly
      staYear: [this.status.staYear, Validators.required], // atm readonly
      staStartsOn: [{date: this.status.staStartsOn}, Validators.required],
      // staStartsOnYTWD: ["", Validators.required],
      staEndsOn: [{date: this.status.staEndsOn}, Validators.required],
      // staEndsOnYTWD: ["", Validators.required]
    });
    this.showform = true;
  }
  save(){

    const parsedForm = this.keydatesForm.value;
    //console.log("startsOn date hmm: "+JSON.stringify(parsedForm.staStartsOn.date))
    //console.log("endsOn date hmm: "+JSON.stringify(parsedForm.staEndsOn.date))
    // date forms provided as {date:''}
    parsedForm.staStartsOn = parsedForm.staStartsOn.date;
    parsedForm.staEndsOn = parsedForm.staEndsOn.date;

    this._statusesService.putStatus(parsedForm).subscribe({
      next: (status: Appstatus) => this.onSaveComplete(status),
      error: err => this.onSaveError(err, parsedForm)
    });
  }
  onSaveComplete(status: Appstatus): void {
    this.showform = false;

    this.openSnackBar('Successfully saved status: ' + status.staCode, 'close');
    // trigger the service to refetch the new status list
    // -> this will update any observables subscribed (ie parent page )
    this._statusesService.getStatuses();
    this._dialogRef.close(true);
  }

  onSaveError(err, p) {
    this.messageBuffer.sendMessage('NOT SAVED: ' + p.projTitle + ' error: ' + err, 'error');
    this._dialogRef.close(null);
  }

  cancel() {
    this.openSnackBar('Updating keydate cancelled: ', 'close');
    this._dialogRef.close(null);
  }
  // currently using the standard material snackbar service
  // campl-ngx-snackbar could be created to display different types of message etc?
  // We could modify sendMessage to send to a snack bar or messageare
  // See elsewhere (y4projects)
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
