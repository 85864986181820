import { Deserializable } from './deserializable.model';

export class Y4staffcrsidfilter implements Deserializable {

    id: number;
    scfType: string;
    scfCrsId: string;
    scfNotes: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    display() {
        return this.scfCrsId + ' - ' + this.scfType + ' - ' + this.scfNotes
    }
}
