import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { Student } from './student';
import { Y4project } from './y4project';

export class Y4projectselection {

    id: number;
    pslSerial: number;
    pslAllocated: boolean;
    pslStudent: Student;
    pslY4project: Y4project;
    pslAgreementReturned?: string;


    deserialize(input: any, depth: number = 1000): this {
        Object.assign(this, input);
        // Attempted to do this but got messy
        // add deserializing where extra objects are required 9in services)
        // if (depth > 0) {
        //     depth = depth - 1;
        //     //
        //     this.pslY4project = new Y4project().deserialize(input.pslY4project, depth);
        //     this.pslStudent = new Student().deserialize(input.student, depth)
        // }
        return this;
    }
    /**
     * Returns whether or not this project has been allocated
     */
    isAllocated(): boolean {
        return this.pslAllocated;
    }

    /**
     * Deserialize our project object
     */
    deserializeProject(): Y4projectselection {
        this.pslY4project = new Y4project().deserialize(this.pslY4project);
        return this;
    }

    /**
     * Deserialize our student (if populated)
     */
    deserializeStudent(): Y4projectselection {
        this.pslStudent = new Student().deserialize(this.pslStudent);
        return this;
    }
}

