<campl-ngx-page-nav *ngIf="initialIndex"
  [heading]="'Approve IIB projects: '"
  [initialIndex]="initialIndex" 
  [values]="offerings" 
  (current)="populateProjects($event)">
</campl-ngx-page-nav>

<div *ngIf="allgroups">
    <button class="subjGroup" mat-raised-button *ngFor="let group of (groups$ | async) | keyvalue" value="{{group.key}}"
        (click)="toggleGroup(group.key)" [color]=" (activeGroup === group.key) ? 'primary' : '' ">
        {{group.key}}
    </button>
</div>

<div *ngIf="groupsAllowed?.length>1">
    <button mat-raised-button *ngFor="let group of groupsAllowed.sort() " (click)="toggleGroup(group)"
        [color]=" (activeGroup === group) ? 'primary' : '' ">
        {{group}}
    </button>
</div>

<app-y4-projects-table *ngIf="offering" 
  [parentData$]="todos$" 
  [approve]="true" 
  [ownNotes]="false"
  [showSummary]="false"></app-y4-projects-table>
