import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Y4staffcrsidfilter } from '../models/y4staffcrsidfilter';
import { ErrorService } from './error.service';
/**
 * Service retrives the list of staffCrsidFilters
 * 
 * which are used to load [prevent load] supervisors
 */
@Injectable({
  providedIn: 'root'
})
export class Y4staffcrsidfilterService {

  baseurl = 'api/v1/staffcrsidfilter'

  _refreshSubject$ = new BehaviorSubject<number>(1);
  _load$ = this._refreshSubject$.asObservable();

  _staffJobTitleList$ = this._load$.pipe(
    switchMap(() =>
      this.http.get<Y4staffcrsidfilter[]>(this.baseurl + '/get').pipe(
        map(list => list.map(staff => new Y4staffcrsidfilter().deserialize(staff)))
      )));

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  /**
   * Provided to use easier spying
   * @returns 
   */
  getList$() {
    return this._staffJobTitleList$;
  }

  /**
   * Toggles a retrieval of the list
   */
  refreshList() {
    this._refreshSubject$.next(1)
  }

  /**
   * Adds a title to the stored list
   * 
   * @param title 
   */
  addCrsidFilter(y4staffcrsidfilter: Y4staffcrsidfilter) {
    return this.http.post<any>(this.baseurl + '/create', { ...y4staffcrsidfilter }).pipe(
      catchError(this.errorService.handleErrorPage('Adding a StaffCrsidFilter'))
    );
  }

  /**
   * Removes a jobttile from our list
   */
  removeCrsidFilter(y4staffcrsidfilter: Y4staffcrsidfilter) {
    return this.http.delete<any>(this.baseurl + '/delete/' + y4staffcrsidfilter.id)
    //.pipe(
    //  catchError(this.errorService.handleErrorPage('Removing a StaffJobtTitle'))
    //);;
  }
}
