import { Component, OnDestroy, OnInit } from '@angular/core';

import { Y4ProjectService } from '../lib/services/y4-project.service';
// import { ActivatedRoute } from '@angular/router';
import { StatusesService } from '../lib/services/statuses.service';
import { combineLatest, concat, Subject } from 'rxjs';

// TODO move WrapperClassYear into a shared module?
import { ICamplNgxPageNav } from 'cued-lib/src/lib/campl-ngx';
import { map, take } from 'rxjs/operators';
import { CourseOffering } from '../lib/models/courseoffering';
import { CourseofferingService } from '../lib/services/courseoffering.service';

/**
 * Implement printOut() to display the current choice
 * in browse bar
 *
 * Here we should be using a courseofferings array
 * and emit the offering/offeringID
 */
 export class WrapperClassCourse implements ICamplNgxPageNav {
  offering: CourseOffering;

  constructor(offering: CourseOffering) {
    this.offering = offering;
  }

  getYear() {
    return this.offering.cooOfferingYear;
  }

  printOut(): string | number {
    return this.getYear()+' - '+(this.getYear()+1);
  }
}

/**
 * Component used to list all of the projects for a particular
 * year. Providing the ability to show the list for another year.
 *
 * To dsiplay the projects the y4-projects-table is used
 *
 * Originally it did provide the ability to supply a URL param
 * to go directly to a particular year however at the moment no
 * UI provides such a link.
 */
@Component({
  selector: 'app-y4-projectslist',
  templateUrl: './y4-projectslist.component.html',
  styleUrls: ['./y4-projectslist.component.css']
})
export class Y4ProjectslistComponent implements OnInit, OnDestroy {

  /**
   * An array holding the subscriptions we need to
   * unsubscribe from
   */
  subs = [];

  offerings: WrapperClassCourse[];

  /**
   * Observable used to set the offerings we will navigate
   * This needs to be called once to setup our navigation
   *
   */
   offerings$ = this._courseOfferingService.list$.pipe(
    take(1),
    map(coos => coos.sort((a, b) => a.cooOfferingYear - b.cooOfferingYear)),
    map(coos => coos.map(coo => new WrapperClassCourse(coo)))
  );

  /**
   * The initial index of our list of browseable courses
   *
   * This will be setup by a subscription to the associated observable
   */
  initialIndex = 0;
  /**
   * Observable collecting the initial index to set our browse list to
   */
  initialIndex$ = combineLatest([this.offerings$, this._appStatus.latestYear()])
    .pipe(
      take(1),
      map(
        ([offerings, lyear]) => offerings.findIndex((ele) => ele.getYear() === lyear)
      ));

  /**
   * The currently selected courseOffering
   *
   * Initial value is set by initialIndex then later by the signal
   * from our browse component
   */
  offering: CourseOffering;

  offeringSelected$ = new Subject<WrapperClassCourse>();

  initialOffering$ = combineLatest([this.offerings$, this.initialIndex$]).pipe(
    take(1),
    map(([offerings, initialIndex]) => offerings[initialIndex])
  );

  /**
   * Observable setting the currently selected course, merges event emitted from the navigation (offeringSelected)
   */
  selectedOffering$ = concat(this.initialOffering$, this.offeringSelected$);


  // This observable is filtered by calls to:
  // getProjects()
  // setOwner()
  // getProjectsYear(year: string, limit: number = 500, topics: number[] = [], areas: number[] = [])
  projects$ = this._y4projectService.projects$;

  year = 0;
  offset = 0;

  constructor(
    private _appStatus: StatusesService,
    private _y4projectService: Y4ProjectService,
    private _statuses: StatusesService,
    private _courseOfferingService: CourseofferingService,) { }

  /**
   * Setup our default
   *
   * Listen for the change in year selection
   * - to toggle retrieval of the correct lists
   */
  async ngOnInit() {

    this._y4projectService.setOwner(0); // we may have previously retrieved 'our' projects
    // our navigation requires both of these prior to init:
    combineLatest([this.initialIndex$, this.offerings$]).subscribe(
      ([i, o]) => {
        this.offerings = o;
        this.initialIndex = i;
      }
    );
    // we wait for changes in our selected item
    this.subs.push(
      this.selectedOffering$.subscribe(
        selectedBrowse => {
          // TODO in the case of no offering we need to throw an error
          if (selectedBrowse){
            this.offering = selectedBrowse.offering;
            this._y4projectService.setYear(selectedBrowse.getYear());
          }else{
            // TODO
            // we should display an error to the user that no offerings have been seelcted
            // how did we get here?
          }
        }
      )
    );
  }

  /**
   * We must unsubscribe to our observables!
   */
  ngOnDestroy() {
    this.subs.map(sub => sub.unsubscribe());
  }
  /**
   * Toggle the current year to retrieve projects for
   *
   * @returns void
   */
  populateProjects(selected: WrapperClassCourse): void {
    this.offeringSelected$.next(selected);
  }


}
