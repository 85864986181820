import { Appstatus } from './appstatus';
import { Deserializable } from './deserializable.model';

export class CourseOffering implements Deserializable {

    id: number;
    cooName: string;
    cooOfferingYear: number;
    cooStudentYear: number;
    cooLimitHandling: string;
    cooExamHandling: string;
    cooStudentLimit: number;
    cooH03Code: string;
    cooParentCourseId: string;
    cooCourseStatus: string;
    cooProjPubDate: string;
    cooStatus?: Appstatus[];

    deserialize(input: any): this {
        Object.assign(this, input);
        this.cooStatus = (input.cooStatus) ?
                                input.cooStatus.map(st => new Appstatus().deserialize(st)) :
                                [];
        return this;
    }

    /**
     * Generic method to report the status of a period
     *
     * @input code - the status code we wish to report on
     *
     * @returns : ['ACTIVE','CLOSED','FUTURE','UNKNOWN']
     *
     * TODO: tests for
     * no status
     * status future
     * status open
     * status starts today
     * status ended today
     * status closed
     */
    _isSstatusOpen(code: string){
        const now = (new Date()).getTime();
        const statuses = ( this.cooStatus ) ? this.cooStatus : [];
        const found = statuses.filter( sta => sta.staCode === code );
        let ret = 'UNKNOWN';

        if (found && found.length > 0 ) {
            const start = found[0].staStartsOn.getTime();
            const end = found[0].staEndsOn.getTime();

            if ( (now <= end) && (now >= start)){
                ret = 'ACTIVE';
            }else if ((now > end)){
                ret = 'CLOSED';
            }else if ((now < start)){
                ret = 'FUTURE';
            }
        }
        return ret;
    }

    /**
     * get the course status for our code
     *
     * @rturns ['ACTIVE','CLOSED','FUTURE','UNKNOWN']
     */
    getStatus(code: string){
        return this._isSstatusOpen(code);
    }

    /**
     * Get the date at which the status will close
     *
     * @param code  - the status code
     */
    getCloses(code: string): Date | undefined{
        const statuses = ( this.cooStatus ) ? this.cooStatus : [];
        const found = statuses.filter( sta => sta.staCode === code );
        return ( found.length > 0 ) ? new Date(found[0].staEndsOn) : undefined;
    }

    /**
     * Get the date at which the status will close
     *
     * @param code  - the status code
     */
    getOpens(code: string){
        const statuses = ( this.cooStatus ) ? this.cooStatus : [];
        const found = statuses.filter( sta => sta.staCode === code );
        return ( found.length > 0 ) ? found[0].staStartsOn : undefined;
    }


/** not used  query getStatus instead
 *   isSelectionOpen(){
 *       return ( this._isSstatusOpen('SELECTION') === 'ACTIVE' )? true : false;
 *   }
 *
 *   isBrowseOpen(){
 *       return ( this._isSstatusOpen('Y4BROWSE') === 'ACTIVE' )? true : false;
 *   }
 *
 *   isBuploadOpen(){
 *       return ( this._isSstatusOpen('BUPLOAD') === 'ACTIVE' )? true : false;
 *   }
 *   We don't need a  status for AUPLOAD as this can occur through the year
 */

}
