<div [formGroup]="presentationForm">
    <campl-ngx-input-textarea [label]="'Location of meeting'" placeholder="LT/LR, Building, Site"
        formControlName="location">
    </campl-ngx-input-textarea>
    <br />
    <mat-form-field>
        <input matInput type="datetime-local" placeholder="Date and time of meeting" formControlName="datetime">
    </mat-form-field>
    <br />
    <span *ngIf="presentationForm.valid == false">
        Both fields are required if filling in meeting details
    </span>
</div>