import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ChoicesManagementService } from '../lib/services/choices-management.service';

@Component({
  selector: 'app-y4-project-preallocated-choices',
  templateUrl: './y4-project-preallocated-choices.component.html',
  styleUrls: ['./y4-project-preallocated-choices.component.scss']
})
export class Y4ProjectPreallocatedChoicesComponent implements OnInit {

  /**
   * PREALLCOATED COMPONENT ATTRIBUTES
   */

  // we use observable rather than form - only one button
  private queuedPreallocMsgSubject$ = new BehaviorSubject<any>(null);
  // TODO: this will <Message> once we create the model
  public queuedPreallocMsg$ = this.queuedPreallocMsgSubject$.asObservable();

  //  The list of affected choices
  preallocated$ = this._choicesManagementService.preallocated$;

  // We need the students involved in preallocation before we can retrieve their historical messages
  lastPreallocatedSendbuf$ = this.preallocated$.pipe(
    switchMap(
      selections => this._choicesManagementService
        .lastPreallocatedSendStus$(selections.map(s => s.pslStudent.id))
    )
  );

  // we append to the list of preallocated affected choices the last contact to this user
  lastPreallocatedSend$ = combineLatest([this.lastPreallocatedSendbuf$,this.preallocated$])
                          .pipe(
                            map( ([stus,psls]) =>
                                // append onto preall[] the latest message for that student
                                 psls.map( p => {
                                  const students = stus.filter(s => s.id === p.pslStudent.id);
                                  const messages = (students[0]['messages'])? students[0]['messages'] : undefined;
                                  p['lastMessage']=( messages )? messages['msgSentOn'] : 'N/A';
                                  return p;
                                })

                            ));


  /**
   * INVALID COMPONENT ATTRIBUTES
   */


  // we use observable rather than form - only one button
  private queuedIncompleteMsgSubject$ = new BehaviorSubject<any>(null);
  // TODO: this will <Message> once we create the model
  public queuedIncompleteMsg$ = this.queuedIncompleteMsgSubject$.asObservable();

  //  The list of affected choices
  incomplete$ = this._choicesManagementService.incomplete$;

  // We need the students involved in preallocation before we can retrieve their historical messages
  // lastIncompleteSendbuf$ = this.incomplete$.pipe(
  //   switchMap(
  //     stuids => this._choicesManagementService
  //       .lastIncompleteSendStus$(stuids.map(s => s.id))
  //   )
  // )

  // // we append to the list of preallocated affected choices the last contact to this user
  // lastIncompleteSend$ = combineLatest([this.lastIncompleteSendbuf$,this.preallocated$])
  //                         .pipe(
  //                           map( ([stus,psls]) =>
  //                             {
  //                               // append onto preall[] the latest message for that student
  //                               return psls.map( p => {
  //                                 let staff = stus.filter(s => s.id === p.pslStudent.id)
  //                                 let messages = staff[0]['messages']
  //                                 p['lastMessage']=( messages )? messages['msgSentOn'] : 'N/A'
  //                                 return p
  //                               })
  //                             }
  //                           ))



  constructor(
    private _choicesManagementService: ChoicesManagementService,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }


  /**
   * Create emails in the database ready to send
   */
  genPreallocEmails() {
    this.preallocated$.pipe(
      switchMap(psls => this._choicesManagementService
        .genPreallocWarnings$(psls.map(p => p.id))
      ),
      take(1)
    ).subscribe(val => {
      this.queuedPreallocMsgSubject$.next(val);
    });
  }

  /**
   * Cancel the emails that hasv been queued
   */
  cancelPreallocMessage() {
    this.queuedPreallocMsg$.pipe(
      take(1),
      switchMap(msg => this._choicesManagementService.cancelPreallocWarnings$(msg['id'])),
      take(1)
    ).subscribe(
      (msg) => {
        if (msg['msgStatus'] === 'CANCELLED') {
          this._snackBar.open('Cancelled Email notification', 'close', { duration: 2000 });
          this.queuedPreallocMsgSubject$.next(null);
        }
      }
    );
  }

  sendPreallocMessage() {
    this.queuedPreallocMsg$.pipe(
      take(1),
      switchMap(msg => this._choicesManagementService.sendPreallocWarnings$(msg['id'])),
      take(1)
    ).subscribe(
      ( msg ) => {
        if (msg>=0){
          this._snackBar.open(msg+' emails sent', 'close', { duration: 2000 });
          this.queuedPreallocMsgSubject$.next(null);
        }
      }
    );
  }




  /**
   * Create emails in the database ready to send
   */
   genIncompleteEmails() {
    this.incomplete$.pipe(
      switchMap(s => this._choicesManagementService
        .genIncompleteWarnings$(s.map(si => si.id))
      ),
      take(1)
    ).subscribe(val => {
      this.queuedIncompleteMsgSubject$.next(val);
    });
  }

  /**
   * Cancel the emails that hasv been queued
   */
  cancelIncompleteMessage() {
    this.queuedIncompleteMsg$.pipe(
      take(1),
      switchMap(msg => this._choicesManagementService.cancelIncompleteWarnings$(msg['id'])),
      take(1)
    ).subscribe(
      (msg) => {
        if (msg['msgStatus'] === 'CANCELLED') {
          this._snackBar.open('Cancelled Email notification', 'close', { duration: 2000 });
          this.queuedIncompleteMsgSubject$.next(null);
        }
      }
    );
  }

  /**
   * Action the message in our queue
   */
  sendIncompleteMessage() {
    this.queuedIncompleteMsg$.pipe(
      take(1),
      switchMap(msg => this._choicesManagementService.sendIncompleteWarnings$(msg['id'])),
      take(1)
    ).subscribe(
      ( msg ) => {
        if (msg>=0){
          this._snackBar.open(msg+' emails sent', 'close', { duration: 2000 });
          this.queuedIncompleteMsgSubject$.next(null);
        }
      }
    );
  }


  /**
   * list the message recipients
   *
   * TODO replace this with a Message model
   */
  msgRecipients(msg) {
    return msg['msgStudents'].map(s => s.stuCrsId + '@cam.ac.uk');
  }
  msgSubject(msg) {
    return msg['msgTemplate']['mstSubject'];
  }
  msgMessage(msg) {
    return msg['msgTemplate']['mstMessage'].replaceAll('\\n', '<br/>');
  }
}
