<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h1 mat-dialog-title>Allocate {{ (project$ | async)?.reference() }} </h1>

<h2>({{ (project$ | async)?.titleTeaser() }})</h2>

<app-y4-project-choice-allocation-summary></app-y4-project-choice-allocation-summary>

<h3>Select an unallocated student</h3>
<div [formGroup]="studentSelectForm">
    <campl-ngx-input-autocomplete [optionObjects]="unallocatedStudentSelectList"
        [label]="'Select student'" [placeholder]="'Unallocated student'" formControlName="unallocatedStudent"
        [validator]="studentSelectForm.get('unallocatedStudent').validator">
    </campl-ngx-input-autocomplete>
</div>

<button mat-raised-button color="primary" (click)="allocateToStudent()" [disabled]="studentSelectForm.invalid || submitted">
    Allocate to student
</button>
<button mat-raised-button color="warn" (click)="cancel()">
    Cancel
</button>