import { Injectable } from '@angular/core';

import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable, of, Subject, ReplaySubject, BehaviorSubject, combineLatest } from 'rxjs';
import { catchError, concatMap, map, share, shareReplay, switchMap, tap } from 'rxjs/operators';

import { Y4project } from '../models/y4project';
import { AuthService } from './auth.service';
import { StatusesService } from './statuses.service';
import { CourseOffering } from '../models/courseoffering';
import { ErrorService } from './error.service';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { Auth } from '../models/auth';

/**
 * Shared with the y4projectlist-tree component
 * No longer used
 */
/* interface OwnerNode {
  name: string; // Project owner
  id: number;
  order: string; // we order by this
  project?: Y4project;
  projects?: OwnerNode[]; // project is set
} */

// TODO: Accordian -> Accordion SPELLING!!


/**
 * split this into
 * - student browse and select
 * - crud records
 *
 * Q, in doing so does this limit us if we require a staff browse (+select)
 */
@Injectable({
  providedIn: 'root'
})
export class Y4ProjectService {

  constructor(
    private http: HttpClient,
    private userService: AuthService,
    private appStatus: StatusesService,
    private pageMessage: CamplNgxMessageBufferService,
    private errorService: ErrorService) {
    //super(pageMessage);
  }

  // projects$ = new ReplaySubject<Y4project[]>();
  lim = '?limit=3000'; // lim = "?cooOfferingYear=2019"; // limit=3000" // if not set the default is 30!
  projects = [{ description: 'project 1' }, { description: 'project 2' }]; // our projects
  httpOptions; // ?? from https://angular.io/tutorial/toh-pt6
  // baseurl = '/api/y4project';
  api1url = '/api/v1/y4project';
  browseListUrl = this.api1url + '/get-browse-list';
  anonBrowseListUrl = this.api1url + '/get-anon-browse-list'; // anonymise the details from the list
  examplesListUrl = this.api1url + '/get-last-years-examples';

  currentYear = 0; // TODO: remove
  lastPlRequest: any; // TODO: remove stores the latest request for projectslist

  projectsLookup: any = {}; // TODO: remove

  projectsYearSubject$ = new ReplaySubject<number>();
  projectsYear$ = this.projectsYearSubject$.asObservable();

  projectsTriggerSubject$ = new BehaviorSubject<number>(1); // trigger new pull
  projectsTrigger$ = this.projectsTriggerSubject$.asObservable();

  projectAreasSubject$ = new BehaviorSubject<number[]>([]);
  projectAreas$ = this.projectAreasSubject$.asObservable();

  projectTopicsSubject$ = new BehaviorSubject<number[]>([]);
  projectTopics$ = this.projectTopicsSubject$.asObservable();

  // provide a topic id which will be used to filter by project (subject) group
  // @deprecated
  projectSGroupTopicSubject$ = new BehaviorSubject<number>(1);
  projectSGroupTopic$ = this.projectSGroupTopicSubject$.asObservable();

  projectSGroupSubject$ = new BehaviorSubject<string>('');
  projectSGroup$ = this.projectSGroupSubject$.asObservable();

  projectLimitSubject$ = new BehaviorSubject<number>(3000);
  projectLimit$ = this.projectLimitSubject$.asObservable();

  projectOwnerSubject$ = new BehaviorSubject<number>(0);
  projectOwner$ = this.projectOwnerSubject$.asObservable();


  // TODO combine / chain with projects$ can we given different queries of browseListUrl?
  projectsToApprove$ = combineLatest([this.projectSGroup$, this.projectsYear$, this.projectsTrigger$]).pipe(
    switchMap(
      ([groupid, year]) => this.http.get<Y4project[]>(
        this.browseListUrl + '?code=EGT3&year=' + year + '&sgroup=' + groupid + '&approved=' + false + '&submitted=' + true)),
    shareReplay(1)
  );
  /**
   * This is used in several places:
   *
   *  the student browse choices (all but owner)
   *  owner-page (owner & year)
   *  approvers (to find number of projects already offered)
   *
   * Once projectsYear$ has been provided () the full list of projects will be returned
   *   projectsYear$ is set by projectsYearSubject$ by several methods:
   *     setYear()
   *     getPreviousYear()
   *     getNextYear()
   *     getProjectsYear()
   *     cleanList()
   *
   * TODO rename approvedProjects$
   */
  projects$ = combineLatest([
    this.projectsYear$, // will trigger once the year has been set
    this.projectTopics$,
    this.projectLimit$,
    this.projectAreas$,
    this.projectOwner$,
    this.projectsTrigger$])
    .pipe(
      switchMap(
        ([year, topics, limit, areas, owner, trigger]) => {
          // let url = this.baseurl + "?cooOfferingYear=" + year + "&limit=" + limit
          let url = this.browseListUrl + '?code=EGT3&year=' + year + '&topics=' + topics + '&areas=' + areas + '';
          if (owner !== 0) {
            url = url + '&ownerid=' + owner;
          }
          return this.http.get<Y4project[]>(url);
          // return this.http.get<Y4project[]>(this.baseurl + this.lim)
        }
      ),
      map(res => res.map((pr: Y4project) => new Y4project().deserialize(pr))),
      // map(projs => projs.filter(proj => proj.projApprovedOn != null)), // by default deal with approved projects
      shareReplay(1)
    );

  // ownerProjects = function(owner, year)

  /**
   * TODO is this deprecated?
   */
  studentBrowse$ = this.projects$.pipe(
    map(projs => projs.filter(proj => proj.approved())));


  // url = 'api/v1/y-4-project/get-browse-list?
  // options: code=EGT3&year= || cooid  &topics= &areas= ;

  // used to filter the projects by topic
  // TODO a method is requried to manage the topic selection / deselection (buttons on filter page)
  private topicSelectionSubject$ = new BehaviorSubject<number[]>([]);
  public topicSelectionAction$ = this.topicSelectionSubject$.asObservable();

  // used to filter the projects by engineering areas
  // TODO a method is required to manage the area selection / deselection (buttons on filter page)
  private eareaSelectionSubject$ = new BehaviorSubject<number[]>([]);
  public eareaSelectionAction$ = this.eareaSelectionSubject$.asObservable();

  private currentlyBrowsingProjectSubject$ = new ReplaySubject<Y4project>();
  // NB shareReply() was provided in the pipe below
  public currentlyBrowsingProject$ = this.currentlyBrowsingProjectSubject$.asObservable()
    .pipe(
      map(proj => new Y4project().deserialize(proj)),
      shareReplay(1) // allocating dialog requires this
    );

  private projectUpdatedSubject$ = new BehaviorSubject<number>(0); // not used atm
  public projectUpdatedAction$ = this.projectUpdatedSubject$.asObservable();

  private egt3CooSubject$ = new ReplaySubject<CourseOffering>(); // no default course
  public egt3CooAction$ = this.egt3CooSubject$.asObservable();

  projectsByCoo$ = combineLatest([
    this.egt3CooAction$ // toggle the year to activate the results
    // may also need to track other state changes
  ]).pipe(
    switchMap(
      coo => this.http.get<Y4project[]>(this.browseListUrl + this.lim + '1&cooid=' + coo[0].id + '&approved=true').pipe(
        map(res => res.map((pr: Y4project) => new Y4project().deserialize(pr))),
        // map(projs => projs.filter(proj => proj.projApprovedOn != null)), // by default deal with approved projects
      )
    )
    , shareReplay(1)
  );

  // The projects associated to the current open selection for the EGT3 student of the current user
  // combine with the selected, topic, area and group
  // Used in browse accordian and in the browselist (optionstreeflat)



  anonStudentProjectOptions$ = combineLatest(
    [this.userService.me$,
    this.topicSelectionAction$, // todo can we wrap these two actions in their own combineLatest?
    this.eareaSelectionAction$, // todo can we wrap these two actions in their own combineLatest?
    this.projectUpdatedSubject$ // trigger update from other service via triggerProjectUpdate()
    ]).pipe(
      switchMap(([user, topics, eareas]) => {
        // as we update both topic and area we always get a cancelled http request
        // see above on un-educated ways to resolve the extra request
        // //

        // TODO:  new Auth().deserialize(user) - gets rid of ts error; we should place this in $me observer.pipe
        const stu = new Auth().deserialize(user).egt3Student(); // gets the egt3 student (latest hopefully)
        const course = (stu.egt3Selection()) ? stu.egt3Selection() : stu.egt3Y4PBrowse(); // cooid of active egt3

        let query = '';
        query += '&topics=' + topics; // testing topics.length > 0 does not remove empty array?!
        query += '&areas=' + eareas; // testing areas.length > 0 does not remove empty array?!

        return this.http.get<Y4project[]>(this.anonBrowseListUrl + this.lim + '&cooid=' + course.id + query).pipe(
          map(res => res.map((pr: Y4project) => new Y4project().deserialize(pr))),
          map(projs => projs.filter(proj => proj.approved())), // by default deal with approved projects
          map(projs => projs.filter(proj => proj.projType === 'a')) // todo provide as a url param
        );
      }),
      // share(),
      shareReplay(1) // provides later subscribers with the last ommited value
    );

  // This will return last years projects as a list of examples..
  projectExamplesList$ =  this.http.get<Y4project[]>(this.examplesListUrl);

  // studentProjectOptions$ = combineLatest(
  //   [this.userService.me$,
  //   this.topicSelectionAction$, // todo can we wrap these two actions in their own combineLatest?
  //   this.eareaSelectionAction$, // todo can we wrap these two actions in their own combineLatest?
  //   this.projectUpdatedSubject$ // trigger update from other service via triggerProjectUpdate()
  //   ]).pipe(
  //     switchMap(([user, topics, eareas]) => {
  //       // as we update both topic and area we always get a cancelled http request
  //       // see above on un-educated ways to resolve the extra request
  //       // //

  //       // TODO:  new Auth().deserialize(user) - gets rid of ts error; we should place this in $me observer.pipe
  //       const stu = new Auth().deserialize(user).egt3Student(); // gets the egt3 student (latest hopefully)
  //       const course = (stu.egt3Selection()) ? stu.egt3Selection() : stu.egt3Y4PBrowse(); // cooid of active egt3

  //       let query = '';
  //       query += '&topics=' + topics; // testing topics.length > 0 does not remove empty array?!
  //       query += '&areas=' + eareas; // testing areas.length > 0 does not remove empty array?!

  //       return this.http.get<Y4project[]>(this.browseListUrl + this.lim + '&cooid=' + course.id + query).pipe(
  //         map(res => res.map((pr: Y4project) => new Y4project().deserialize(pr))),
  //         map(projs => projs.filter(proj => proj.approved())), // by default deal with approved projects
  //         map(projs => projs.filter(proj => proj.projType === 'a')) // todo provide as a url param
  //       );
  //     }),
  //     // share(),
  //     shareReplay(1) // provides later subscribers with the last ommited value
  //   );

  /**
   * Generates a list of accordian content
   * - tied to the y4projectlist-accordian display
   *
   * Group project by owner
   * Order owner projects by reference
   * Order by owners
   *
   * y4projectlist-accordian + processing for studentProjectAccordianBrowseList$
   *                                          (y4-project-select-dialog)
   */
  studentProjectAccordianList$ = this.anonStudentProjectOptions$.pipe(
    // generate accordion panel content
    map(res =>
      res.reduce((a, c) => {
        const key = `${c.projOwner.display_name()}`;
        a[key] = a[key] || { projects: [] };
        a[key].projects.push(c);
        return a;
      }, {})
    ),
    // order projects in panel
    map(res => {
      Object.keys(res).forEach((key) => {
        res[key].projects = res[key].projects.sort((a, b) => (a.reference > a.reference) ? -1 : 1);
      });
      return res;
    }

    ),
    // extract the last name from the results and order by this
    map(data =>
      Object.keys(data)
        .sort((a, b) => {
          // extract lastname and sort
          const aarr = a.split(' ');
          const barr = b.split(' ');
          return (aarr[aarr.length - 1] > barr[barr.length - 1]) ? 1 : -1;
        })
        .map(v => ({ projOwnerName: v, projs: data[v] }))
    )
  );


  /**
   * Present to our browse dialog preserving the
   * order of the projects in the accordian list
   *
   */
  studentProjectAccordianBrowseList$ = combineLatest([
    this.studentProjectAccordianList$,
    this.projectUpdatedAction$]).pipe(

      //
      map(([res, status]) =>
        // typescript is not cofigured for es2019? which is why flat not found?
        res.map(accord => accord.projs.projects.map(proj => new Y4project().deserialize(proj))).flat()
      )
    );

  // @deprecated
  // setProjectSGroupTopic(sgtopicid: number) {
  //  this.projectSGroupTopicSubject$.next(sgtopicid);
  // }

  // y4project-approval-list
  setProjectSGroup(sg: string) {
    this.projectSGroupSubject$.next(sg);
  }

  // Todo consolidate setegt3Coo and setYear calls
  // (replace setYear with setEgt3Coo )

  /**
   * Sets the course offering that we will use on the browse page
   *
   * @param coo - course offering to be used
   */
  setEgt3Coo(coo: CourseOffering) {
    this.egt3CooSubject$.next(coo); // triggers the retrieval of projects list
  }
  getEgt3Coo() {
    return this.egt3CooAction$;
  }

  /**
   * Sets the year to retrieve projects for
   *
   * @param year The year to retrieve projects for
   */
  setYear(year: number) {
    this.projectsYearSubject$.next(year);
  }

  /**
   * Sets the owner to filter by
   *
   * @param id id of the owner
   */
  setOwner(id: number) {
    this.projectOwnerSubject$.next(id);
  }

  /**
   * Call this method to set the Project that the user is viewing.
   *
   * Setting this allows the current project to be found by this service
   *
   * Action If you are switch or browsing a project
   *
   * @param project - The porject we are now browsing
   */
  browseProject(project: Y4project) {
    this.currentlyBrowsingProjectSubject$.next(project);
  }

  /**
   * Sets the value of the observable by subscribing and emitting a value for the subject
   *
   * TODO: replace with a better chain
   *
   * @param id - id of project to retrieve
   */
  setBrowseProject(id: number) {
    const url = `${this.api1url}/${id}`;
    this.http.get<Y4project>(url).subscribe(proj => this.browseProject(proj));
  }

  triggerProjectsUpdate() {
    this.projectUpdatedSubject$.next(1); // triggers the retrieval of projects list
  }

  setStudentTopicSelection(topics: number[]) {
    this.topicSelectionSubject$.next(topics);
  }

  setStudentEareaSelection(eareas: number[]) {
    this.eareaSelectionSubject$.next(eareas);
  }
  // addProject(project){
  //  this.projects.push(project);
  // }
  /**
   * getProjects
   *
   * Reloads our projects list following possible updates etc
   *
   */
  getProjects() {
    this.projectsTriggerSubject$.next(1);
  }

  // getProjects1(): Observable<Y4project[]> {
  //  return this.http.get<Y4project[]>(this.baseurl + this.lim)
  // }

  getPreviousYear() {
    this.projectsYear$.subscribe(year => this.projectsYearSubject$.next(year - 1));
  }

  getNextYear() {
    this.projectsYear$.subscribe(year => this.projectsYearSubject$.next(year + 1));
  }

  // getByTopicAreas(topics: number[], areas: number[]) {

  //   if (this.currentYear != 0) {
  //     this.getProjectsYear("" + (this.currentYear), 500, topics, areas)
  //   } else {
  //     // trouble we have not got a year set?!
  //     this.handleErrorPage<any>('Trouble getting projects no year set!  ')
  //   }
  // }

  /**
   * getProjectsYear
   *
   * retrieves the projects associated for the provided year
   * side effect - sets the this.currentYear to the provided year
   *
   * @param year - year (start)
   * @param limit - max entries to retrieve
   * @param topics - array of topic ids
   * @param areas - array of engineering area ids
   */
  // Fix me requirement for unavailable named arguments
  getProjectsYear(year: string, limit: number = 500, topics: number[] = [], areas: number[] = []) {
    // Added here to keep signatures available

    this.projectsYearSubject$.next(parseInt(year, 10));
    this.projectAreasSubject$.next(areas);
    this.projectTopicsSubject$.next(topics);
    this.projectLimitSubject$.next(limit);
  }

  /**
   * Removes entries from our observable
   */
  cleanList() {
    this.projectsYearSubject$.next(-9000); // fake year that doesn't exist
  }

  addProject(project): Observable<HttpEvent<Y4project>> {
    /**
     * Adding the project we also need to manage the supervisors:
     *
     * projOwner
     * ownerLocation
     * ownerEmail
     *
     * projSupervisors (Email, Location)
     *
     */
    delete project.id; // copied from ../service/...version

    console.log("DEBUG projSubmittedOn: "+JSON.stringify(project.projSubmittedOn))
    

    if ( project.projSubmittedOn == ''  || project.projSubmittedOn == null || !project.projSubmittedOn) {
      delete project.projSubmittedOn
      console.log("removed submitted field")
    }else{
      // If we haev a date we must put it in a format handled by MySQL
      project.projSubmittedOn = new Date(project.projSubmittedOn).toISOString().replace('Z','')
    }

    console.log("DEBUG projSubmittedOn: "+JSON.stringify(project.projSubmittedOn))
    
    //alert("DEBUG projSubmittedOn: "+JSON.stringify(project.projSubmittedOn))

    return this.http.post<Y4project>(this.api1url, project, this.httpOptions)
    //.pipe(
    //  tap( a=> this.pageMessage.sendMessage("adding a project") )
    //) 
    /*.pipe(
      // tap((newHero: Hero) => this.log(`added hero w/ id=${newHero.id}`)),
      // handleerrorPage outputs all this to the screen
      // TODO: we would like to send the details  (apps stat, request made .. ) elsewhere
      catchError(this.errorService.handleErrorPage<any>('Add Project ' + JSON.stringify(project)))
    );*/
  }

  getProject(id): Observable<Y4project> {

    if (id === 0) {
      return of(this.initializeY4project());
    }
    const url = `${this.api1url}/${id}`;
    return this.http.get<Y4project>(url)
      .pipe(
        //
        catchError(this.errorService.handleErrorPage<any>('error retrieving project'))
      );
  }



  private initializeY4project(): Y4project {
    // Return an initialized object
    return new Y4project(0);
  }

  putProject(project): Observable<Y4project> {
    // TODO, FiXME, projOwner needs to be id of Owner
    // if ("id" in project.projOwner){
    //  project.projOwner=project.projOwner.id
    // }

    // project is a plain javascript object and not a y4project object
    // for the next statement it would be nice to be able to use something like: 
    // project.projSubmittedOn = project.projSubmittedOnMysql()
    console.log("submitted field: "+project.projSubmittedOn)
    if ( project.projSubmittedOn == '' || project.projSubmittedOn == null || !project.projSubmittedOn) {
      delete project.projSubmittedOn
      console.log("removed submitted field")
    }else{
      // if we have a date we must put it in a format handled by MySQL
      project.projSubmittedOn = new Date(project.projSubmittedOn).toISOString().replace('Z','')
    }

    console.log("submitted field: "+project.projSubmittedOn)


    //return this.http.patch<Y4project>(this.baseurl + '/' + project.id, project).pipe(
    return this.http.patch<Y4project>(this.api1url, project).pipe(
      // tap((newHero: Hero) => this.log(`added hero w/ id=${newHero.id}`)),
      catchError(this.errorService.handleErrorPage<any>('Update Project ' + project.id))
    );
  }

  /**
   * Remove a Project
   *
   * (do we ever use this?)
   *
   * @param project
   * @returns
   */
  removeProject(project): Observable<Y4project> {
    return this.http.delete<Y4project>(this.api1url + '/' + project.id, project).pipe(
      // tap((newHero: Hero) => this.log(`added hero w/ id=${newHero.id}`)),
      catchError(this.errorService.handleErrorPage<any>('Remove Project ' + project.id))
    );
  }

  approveProject(project) {
    return this.http.post<Y4project>(this.api1url + '/approve', { id: project.id }).pipe(
    // return this.http.patch<Y4project>(this.baseurl + '/' + project.id, { projApprovedOn: new Date() }).pipe(
      // tap((newHero: Hero) => this.log(`added hero w/ id=${newHero.id}`)),
      catchError(this.errorService.handleErrorPage<any>('Approve Project ' + project.id)),
      tap(() => this.projectsTriggerSubject$.next(1)) // trigger refresh
    );
  }

  /**
   * Actions a copy of the project
   *
   * @param project - project to copy
   * @param tgtcourse - tagert course to copy he project into
   *
   * Q, should we also copy the assistants and
   * the contact preferences (including the owners?)
   */
  copyProject(project, tgtcourse) {
    return this.http.post<Y4project>(this.api1url + '/copy', { id: project.id, tgtcourse }).pipe(
      // tap((newHero: Hero) => this.log(`added hero w/ id=${newHero.id}`)),
      catchError(this.errorService.handleErrorPage<any>('Copy Project ' + project.id)),
      tap(() => this.projectsTriggerSubject$.next(1)) // trigger refresh
    );
  }

  submitProject(project) {
    return this.http.post<Y4project>(this.api1url + '/submit/' + project.id, {}).pipe(
      // tap((newHero: Hero) => this.log(`added hero w/ id=${newHero.id}`)),
      catchError(this.errorService.handleErrorPage<any>('Submit Project ' + project.id)),
      tap(() => this.projectsTriggerSubject$.next(1)) // trigger refresh
    );
  }

  /**
   * Create a type (b) project associated to our student
   *
   * @param project - the project the student wishes to create
   */
  createTypeBStudent$(project) {
    return this.http.post<Y4project>(this.api1url + '/create-type-b', { ...project }).pipe(
      catchError(this.errorService.handleErrorPage<any>('Creating a Type (b) project ' + project))
    );
  }

}
