import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { Sgtopic } from '../../models/sgtopic';
import { SgtopicService } from '../../services/sgtopic.service';
import { Observable } from 'rxjs/Observable';
import { startWith, map, debounceTime, switchMap, tap, take, combineLatest } from 'rxjs/operators';

/**
 * Cross refrence topic selections list
 */

@Component({
  selector: 'app-crt-selections',
  templateUrl: './crt-selections.component.html',
  styleUrls: ['./crt-selections.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CrtSelectionsComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CrtSelectionsComponent),
      multi: true
    }]
})
export class CrtSelectionsComponent implements OnInit, ControlValueAccessor {

  private _subscriptions = [];
  @Input() validator?: ValidatorFn; // send if required

  // Cross reference Topics related info:

  topicsList: Sgtopic[] = [];
  lastTopicsFilter = '';
  selectedTopics: Sgtopic[] = [];
  filteredTopics$: Observable<Sgtopic[]>;

  topicsCtrl: FormControl;
  public topicsForm: FormGroup;
  value;
  onChange;
  onTouched;


  constructor(
    private sgtopics: SgtopicService,) {
  }

  ngOnInit(): void {
    // @Input not set in constructor/ - so use here
    this.topicsCtrl = this.validator ? new FormControl([], this.validator) : new FormControl([])
    this.topicsForm = new FormGroup({
      topics: this.topicsCtrl
    });

    this.sgtopics.getList$().pipe(take(1))
      .subscribe(
        (topics) => {
          this.topicsList = topics.sort((a, b) => (a.subjGroup + '' + a.subjGroupTopic > b.subjGroup + '' + b.subjGroupTopic) ? 1 : -1);
          this.configureAutoCompleteFilters();
        }
      )

  }


  /**
   * sets up the filters to be used by some of our components
   */
  configureAutoCompleteFilters() {
    // We subscribe in HTML
    this.filteredTopics$ = this.topicsCtrl.valueChanges.pipe(
      startWith<string | Sgtopic[]>(''),
      map(value => typeof value === 'string' ? value : this.lastTopicsFilter),
      map(filter => this.filterTopics(filter))
    );
  }

  removeTopic(id: number) {
    this.selectedTopics = this.selectedTopics.filter(top => top.id !== id);
    this.writeValue(this.selectedTopics)
  }

  filterTopics(filter: string): Sgtopic[] {
    this.lastTopicsFilter = filter;
    // let retarr = this.topicsList.slice();
    let retarr = this.topicsList.filter(option => (option.subjGroupTopic.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
    return retarr;
  }

  optionTopicsClicked(event: Event, top: Sgtopic) {
    // event.stopPropagation();
    this.addTopic(top);
  }

  addTopic(top: Sgtopic) {
    if (this.selectedTopics.length > 0) {
      this.selectedTopics.push(top)
    } else {
      this.selectedTopics = [top]
    }
    this.writeValue(this.selectedTopics)
    this.topicsCtrl.setValue('');
  }

  /**
   * Clears the text once a value has been selected
   * 
   */
  clearOnSelectFn() {
    return ''
  }

  // NG_VALIDATORS
  validate({ value }: FormControl) {
    // returns errors or null if valid
    return this.topicsForm.get('topics').valid ? null : { invalid: true }
  }
  // ControlValueAccessor implementation
  writeValue(val: any) {
    this.selectedTopics = val
    return val && this.topicsForm.patchValue({ topics: val });
  }
  registerOnChange(fn: (val: any) => void) {
    this.topicsForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  // ??
  setDisabledState(disabled: boolean) {
    disabled ? this.topicsForm.disable()
      : this.topicsForm.enable();
  }
}
