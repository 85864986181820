import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CourseofferingService } from '../services/courseoffering.service';

/**
 * Tests whether the current user can access the browse lists
 *
 * The can if they:
 *
 * a, are a staff member
 * b, are a student whose EGT3 course is select or Browseable
 */
@Injectable({
  providedIn: 'root'
})
export class HasSelectionActiveGuard implements CanActivate {
  constructor(private courseOfferingService: CourseofferingService) {

  }
  
  canActivate(): Observable<boolean> {
   
    return this.courseOfferingService.selectionActiveCourses$().pipe(
        //tap( coos => coos.map(coo => console.log(coo))),
        map(coos => {
            return (coos.length > 0)? true : false
        })
    ); 
    

  }

}
