import { Component, OnInit } from '@angular/core';
import { Auth } from '../lib/models/auth';
import { map } from 'rxjs/operators';
import { AuthService } from '../lib/services/auth.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  me$ = this._auth.me$;
  // .pipe(
  //  map(me => new Auth().deserialize(me))
  // );

  isStaff$ = this.me$.pipe(map(res => res.isStaff()));
  // TODO: rename isUgrad -> isEGT3!!
  isUgrad$ = this.me$.pipe(map(res => ( res.egt3Student() ) ? true : false));

  // return an EGT3 record
  ugradEGT3$ = this.me$.pipe(map( res => res.egt3Student() ));
  constructor(
    private _auth: AuthService) { }

  ngOnInit(): void {
  }

}
