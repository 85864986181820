import { Component, Input, OnInit } from '@angular/core';
import { Y4projectselection } from '../lib/models/y4projectselection';
import { Y4ProjectselectionService } from '../lib/services/y4-projectselection.service';

/**
 * Renders a student's choice in basket context
 *
 * Called in the basket to display the list of student selections
 */

@Component({
  selector: 'app-y4-project-selection-listing',
  templateUrl: './y4-project-selection-listing.component.html',
  styleUrls: ['./y4-project-selection-listing.component.css']
})
export class Y4ProjectSelectionListingComponent implements OnInit {

  @Input() selection: Y4projectselection; // the project to display
  @Input() showAllocation: true; // we do not wish for students to see tentative selections during allocation process

  constructor() { }

  ngOnInit(): void {
  }


}
