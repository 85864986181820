import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';

/**
 * This component will present input for the initial 
 * project presentation details
 * 
 * Very simply returns a date and location details
 * 
 * TODO: return class object -> EventTimeLocation
 * TODO: rename to refect EventTimeLocation model above? 
 */

@Component({
  selector: 'app-presentation-details',
  templateUrl: './presentation-details.component.html',
  styleUrls: ['./presentation-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PresentationDetailsComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PresentationDetailsComponent),
      multi: true
    }]
})
export class PresentationDetailsComponent implements OnInit, ControlValueAccessor {

  @Input() validator?: ValidatorFn; // send if required

  presentationForm: FormGroup;
  locationCtrl = new FormControl();
  datetimeCtrl = new FormControl();

  value;
  onChange;
  onTouched;

  constructor() { }

  ngOnInit(): void {
    this.locationCtrl = this.validator ? new FormControl('', this.validator) : new FormControl('')
    this.datetimeCtrl = this.validator ? new FormControl('', this.validator) : new FormControl('')
    this.presentationForm = new FormGroup({
      location: this.locationCtrl,
      datetime: this.datetimeCtrl
    });
  }

  // NG_VALIDATORS
  validate({ value }: FormControl) {

    // if one value is set both must be
    let valid = ( (this.presentationForm.get('datetime').value && this.presentationForm.get('location').value ) ||
                 ( !this.presentationForm.get('datetime').value && !this.presentationForm.get('location').value ))? true : false
    
    //alert(this.presentationForm.get('datetime').value)
    //alert(this.presentationForm.get('location').value)
    //alert("valid: "+valid)

    // returns errors or null if valid
    return (valid && this.presentationForm.get('datetime').valid && this.presentationForm.get('location').valid) ? null : { invalid: true }
    // this.presentationForm.get('datetime').valid ? null : { invalid: true }
  }
  // ControlValueAccessor implementation
  // We need to pass a presentation object
  // TODO pass a presentation obejct (location, datetime)
  writeValue(val: any) {
    this.presentationForm.patchValue({ location: val.location })
    if (val.datetime){
      this.presentationForm.patchValue({ datetime: new Date(val.datetime).toISOString().slice(0,16) })
    }
    return val
  }
  registerOnChange(fn: (val: any) => void) {
    this.presentationForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  // ??
  setDisabledState(disabled: boolean) {
    disabled ? this.presentationForm.disable()
      : this.presentationForm.enable();
  }
}
