import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/** This is used to confirm the user really want to do something */

@Component({
  selector: 'app-areyousure-dialog',
  templateUrl: './areyousure-dialog.component.html',
  styleUrls: ['./areyousure-dialog.component.css']
})
export class AreyousureDialogComponent implements OnInit {

  @Input() message = '';

  constructor(
    private _dialogRef: MatDialogRef<AreyousureDialogComponent>) { }

  ngOnInit(): void {
  }

  public continue(){
    // make it so
    this._dialogRef.close(true);
  }

  public cancel(){
    this._dialogRef.close(false);
  }

}
