import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreyousureDialogComponent } from './components/areyousure-dialog/areyousure-dialog.component';
import { Nl2brPipe } from './pipes/nl2br.pipe';

//import { CamplNgxModule } from 'cued-lib/src/lib/campl-ngx';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

/**
 * This Project and Angular Module provides some general
 * services and components used in cuedwebapps
 * 
 * ie A boiler plate set of functionality
 * 
 * 
 */
@NgModule({
  declarations: [
    AreyousureDialogComponent,
    Nl2brPipe
  ],
  imports: [
    CommonModule,
//    CamplNgxModule
  ],
  providers: [{
    provide: 'externalUrlRedirectResolver',
    useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      window.location.href = (route.data as any).externalUrl;
    }
  }
  ],
  exports: [
    AreyousureDialogComponent,
    Nl2brPipe,
//    CamplNgxModule
  ]
})
export class CuedSharedModule { }
