import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map} from 'rxjs/operators';

import { AuthService } from '../services/auth.service';


/*
 * This Guard will check that the user is logged in before forwarding them to any pages
 * TODO: FIXME: change name to ValidAccount
 */

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
  constructor(private authService: AuthService,
              private router: Router) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // window.location.href = "http://localhost:1337/login"
    // return false;
    return this.authService.me$.pipe(map(me => {
      if (me.isLoggedIn()) {
        if (!me.hasStudent() && !me.isStaff()){
          // can we try finding the user in the service
          this.router.navigate(['/public/noaccount']);
        }
        return true;
      }else{
        //;
        // window.location.href = "http://localhost:1337/auth/google"
        this.router.navigate(['/public/login']);
        return false;
      }
    })
    , catchError((err) => {
        //;
        //;
        // this.router.navigate(['/login']);
        window.location.href = 'http://localhost:1337/logmein';
        return of(false);
      })
    );
  }
}
