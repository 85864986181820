import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
import {
  Injectable,
  InjectionToken,
  Inject,
  ErrorHandler
} from '@angular/core';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { environment } from '../environments/environment';
import { AuthService } from './lib/services/auth.service';
import { take } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

/**
 * This file contains:
 *
 * RollbarErrorHandler, rollbarFactor(), RollbarService
 *
 * Used to register errors in the cloud rollbar account
 *
 * Note environments is required including:
 *
 * appCode - the code used to identify the app
 * rollbarToken - the token used to register the errors is rollbar
 *
 * TODO if we could inject the two values above we should consider moving this to _cued-shared_
 * TODO consider changing this to an interceptor for HttpErrors
 *
 */

const rollbarConfig = {
  // https://rollbar.com/knowledge-base/preventing-client-side-access-token-abuse/
  accessToken: environment['rollbarToken'],
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    // TODO provide a 'test' env so that we can send to rollbars (properly) during tests
    environment: environment['production']? 'production' : 'development',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: environment['CODE_VERSION'], // This should correlate with those uploaded to rollbar (git sha)
        guess_uncaught_frames: true,
      },
    },
  }
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    @Inject(CamplNgxMessageBufferService) private _message,
    private auth: AuthService,
    private router: Router) {}


  handleError(err: any): void {
    //alert('error ');
    //alert(JSON.stringify(err));

    const appCode =  environment['appCode'];
    const ref =  appCode+':'+(new Date()).getTime();
    let loginRedirect = false;
    if (err.localMessage){
      this._message.sendMessage(err.localMessage,'Warning');
    }

    //this._message.sendMessage(JSON.stringify(err))
    //this._message.sendMessage(err.message)
    if (err.name === 'HttpErrorResponse'){
      // todo if the respons eis 401 can we generate a popup or provide a link for the user to log back in?

      // This is a special error an API call is redirecting the user to the login page
      if (err.message.match(/Http failure during parsing for .*login/) ){
        this._message.sendMessage(`Your session has ended. Please reload this page. 
        Failing that it may need to be fixed by an IT administrator. Reference `+ref,'Warning');
      }
      else if (err.status === 500){
        this._message.sendMessage(` An error has occured on the server. 
        You may need to reload this page, failing that it may need to be fixed by an IT administrator. Reference `+ref,'Warning');
      }
      else if ( err.status >= 400 && err.status < 500){
        if ( err.status == 401 ){
          // remove messages - but what is prevent delayed new messages being appended?
          this._message.clearMessages()
          this._message.sendMessage(`Your session appeared to timeout. 
          Please login again`,'information');
          // re-route our user to login?
          loginRedirect = true;
          this.router.navigate(['/public/login',{}])
        }else{
          this._message.sendMessage(` An error has occured communicating with the the server (session timeout?). 
          You may need to reload this page. Reference `+ref,'Warning');
        }
      }
      else{
        this._message.sendMessage(`An error has occured communicating with the the server. 
        You may need to reload this page. Reference `+ref+" "+JSON.stringify(err),'Warning');
      }
    }
    // append our reference for the rollbarr search
    err.ref=ref;
    // only send to rollbars if production
    if ( ! environment['production'] ) {
      console.error(err);
    }
    // else{
    if (!loginRedirect){
      this.auth.getMe$().pipe(take(1)).subscribe(
        me => {
          this.rollbar.configure({
            payload: {
              person: {
                id: me.id, // required
                username: me.username,
                email: me.username
              },
              extra_data: {
                url: err.url
              }
            }
          });
          //this.rollbar.error(err.originalError || err);
          let myerr = (typeof err.originalError !== 'undefined')? err.originalError : err
          this.rollbar.error(myerr, "Message: "+err.message)
        }
      )
    }
      
    //}
  }
}

export const rollbarFactory = () => new Rollbar(rollbarConfig);

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
