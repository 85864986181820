import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { Deserializable } from 'cued-lib/src/lib/cued-shared';
import { PersonService } from 'cued-lib/src/lib/uis-lookup';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map, delay, distinctUntilChanged, debounceTime, shareReplay } from 'rxjs/operators';

/**
 * Enter text in any of the three fields
 * 
 * The component attempts to fill in the other fields with valid records 
 * and returns a UserDetails Object for the parent form
 * f.id
 * Provide a defaultInst value (eg 'ENG') to filter by a particular institute
 * 
 * Provide a validator [validator]='<your validator>' which will be used across all fields
 * cf a very specific autocomplete across multiple fields
 */

@Component({
  selector: 'cued-user-details',
  templateUrl: './cued-user-details.component.html',
  styleUrls: ['./cued-user-details.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CuedUserDetailsComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CuedUserDetailsComponent),
    multi: true
  }]
})
export class CuedUserDetailsComponent implements OnInit, ControlValueAccessor {

  /**
   * Stores a list of subscriptions
   */
  subscriptions = [];

  @Input() label = "Institute"
  @Input() placeholder = "Enter part of name"
  @Input() defaultInst // provide another to search by other institutes

  @Input() validator?: ValidatorFn;

  userDetailsForm: FormGroup; // this will be removed once we understand how to bubble the value up

  filterInstSubject$ = new BehaviorSubject<boolean>(false)
  filterInst$: Observable<boolean> = this.filterInstSubject$.asObservable();

  filterStaffSubject$ = new BehaviorSubject<boolean>(false)
  filterStaff$: Observable<boolean> = this.filterStaffSubject$.asObservable();

  // @Input() formControlName: string;
  //onChange;
  //onTouched;

  lastName = ''; // TODO replace with a better pattern we shouldn;t need to store here

  searchNameSubject$ = new BehaviorSubject<string>(''); // trigger new pull
  searchName$: Observable<string> = this.searchNameSubject$.asObservable();

  searchResults$: Observable<any>
  userSearch = false;

  //public institutionObjects: InstitutionDropdown[] = [];

  toggleStaffFilter() {
    this.filterStaffSubject$.next(
      (this.filterStaffSubject$.getValue()) ? false : true
    )
  }

  toggleInstFilter() {
    this.filterInstSubject$.next(
      (this.filterInstSubject$.getValue()) ? false : true
    )
  }

  get value(): IUserDetails {
    return this.userDetailsForm.value;
  }

  set value(val: IUserDetails) {
    // because theFilters are form fields we need to set these seperately
    this.userDetailsForm.get('username').setValue(val.username, { emitEvent: false })
    this.userDetailsForm.get('displayName').setValue(val.displayName, { emitEvent: false })
    this.userDetailsForm.get('email').setValue(val.email, { emitEvent: true }); // needed to update formValues
    //this.userDetailsForm.setValue(new UserDetails().deserialize(val), { emitEvent: true });
    this.onChange(val);
    console.log("cued-user-details value updated "+JSON.stringify(val))
    this.onTouched();
  }

  constructor(
    private _personService: PersonService,
    private fb: FormBuilder,) {
    // for debug

    //new 
    //this.userDetailsForm.valueChanges.subscribe(value => {
    //  this.onChange(value);
    //  this.onTouched();
    //})
  }


  ngOnInit(): void {
    this.userDetailsForm = this.fb.group({
      // filterStaff: ['', this.validator],
      // filterInst: ['', this.validator],
      displayName: ['', this.validator],
      username: [{ value: '', disabled: true }, this.validator],
      email: [{ value: '', disabled: true }, this.validator]
    })
    this.searchResults$ = combineLatest([this.searchName$, this.filterInst$, this.filterStaff$]).pipe(
      // tap((value) => console.log("updated value: " + value)),
      debounceTime(600),
      distinctUntilChanged(),
      switchMap(([name, filterInst, filterStaff]) => {
        let searchQ = { query: 'person: ' }
        let instFilter = (filterInst && this.defaultInst) ? 'IN INST (' + this.defaultInst + ' OR RECURSIVE CHILD OF INST (' + this.defaultInst + ')) AND ' : ''
        searchQ['query'] = searchQ['query'] + instFilter + ' surname ~* ' + name;
        if (filterStaff) {
          searchQ['misStatus'] = 'staff'
        }
        this.userSearch = (name) ? true : false
        return (name) ? this._personService.personSearch(searchQ) : of(undefined)
      }),
      map((res) => (res) ? res.result.people : []),
      map((arr) => arr.map(person => new UserDetails().deserializeFromLookup(person))),
      shareReplay()
      // TODO HANDLE HTTP ERROR (EG PERMISSION DENIED)
    )
    this.subscriptions.push(
      this.searchResults$
        .subscribe(() => this.userSearch = false)
    )

    // We action the search on change of: displayName, filterInst, filterStaff
    this.subscriptions.push(
      this.userDetailsForm
        .get('displayName')
        .valueChanges // .pipe(tap(val => console.log("displayName: " + val)))
        .subscribe((value) => this.searchNameSubject$.next(value))
    )
    // this.subscriptions.push(
    //   this.userDetailsForm
    //     .get('filterInst')
    //     .valueChanges.pipe(tap(val => console.log("filterInst: " + val)))
    //     .subscribe((value) => this.filterInstSubject$.next(value))
    // )
    // this.subscriptions.push(
    //   this.userDetailsForm
    //     .get('filterStaff')
    //     .valueChanges.pipe(tap(val => console.log("filterStaff: " + val)))
    //     .subscribe((value) => this.filterStaffSubject$.next(value))
    // )
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  // NG_VALIDATORS
  validate({ value }: FormControl) {
    // returns errors or null if valid
    // console.log("validating: "+this.userDetailsForm.valid)
    return this.userDetailsForm.valid ? null : { invalid: true };
  }

  writeValue(val: any) {
    if (val) {
      this.value = val;
    }

    if (val === null) {
      this.userDetailsForm.reset();
    }
    //this.userDetailsForm.get('username').setValue(val.id, { emitEvent: false })
    //this.userDetailsForm.get('displayName').setValue(val.displayName, { emitEvent: false })
    //this.userDetailsForm.get('email').setValue(val.email, { emitEvent: false });
    //return val
  }
  onChange = (value) => { };
  onTouched: any = () => { };
  registerOnChange(fn: any) {
  //registerOnChange(fn: (val: any) => void) {
    //this.userDetailsForm.valueChanges.subscribe(fn);
    console.log("register on change occured")
    this.onChange = fn
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  setDisabledState(disabled: boolean) {
    disabled ? this.userDetailsForm.disable()
      : this.userDetailsForm.enable();
  }

  selectUser(user) {
    // let ret = new UserDetails().deserializeFromLookup(user)
    // console.log(JSON.stringify(user))
    this.writeValue(user)
  }
}

export interface IUserDetails {
  username: string;
  displayName: string;
  email: string;
  surname: string;
}

export class UserDetails implements Deserializable, IUserDetails {

  username: string;
  displayName: string;
  email: string;
  surname: string;

  constructor() { }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  deserializeFromLookup(input: any): this {

    this.username = input.identifier.value // restrict to crsid
    this.email = this.username + '@cam.ac.uk'

    Object.assign(this, input);
    return this;
  }
}