import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that can be used to repalce newline characters '\n'
 * with <br/> tags allowing the text to be formatted in html tags:
 * 
 * @example
 * 
 * <div [innerHTML]="'hello\nworld' | nl2br"></div>
 * 
 * will render the div:
 * 
 * hello
 * world
 */

@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {

  transform(value: string): string {

    return value.replace(/\n/g, '<br/>');

  }

}
