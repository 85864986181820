<h3>{{ pageTitle }}</h3>

<form (ngSubmit)="save()" [formGroup]="sgtopicForm">
    <div>
        <label for="subjGroupId">Project group</label>
        <input id="subjGroupId" type="text" placeholder="Project group (required)" formControlName="subjGroup" />

        <br />
        <span *ngIf="sgtopicForm.get('subjGroup').errors?.required">
            Please enter a group name.
        </span>
        <br />

        <label for="subjGroupTopicId">Topic</label>
        <input id="subjGroupTopicId" type="text" placeholder="Topic (required)" formControlName="subjGroupTopic" />
        <br />
        <span *ngIf="sgtopicForm.get('subjGroupTopic').errors?.required">
            A topic is required.
        </span>
        <br /> 


        <label for="subjActiveId">Is active?</label>
        <input id="subjActiveId" type="checkbox" formControlName="subjActive" />
    </div>

    <button type="submit"
        [title]="sgtopicForm.valid ? 'Save your entered data' : 'Disabled until the form data is valid'"
        [disabled]="!sgtopicForm.valid">
        Save
    </button>
</form>
