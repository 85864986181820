<!--provided for consistent laf we do not navigate courses though-->
<campl-ngx-page-nav
  [heading]="'Browse projects'"
  [initialIndex]="initialIndex" 
  [values]="offerings" >
</campl-ngx-page-nav>

<mat-tab-group (selectedTabChange)="tabClick($event)">
    <mat-tab label="All">
        <mat-card>
            <div *ngIf="groupsInfo$ | async as groupsInfo">
                Topics: <button mat-raised-button *ngFor="let grp of groupsInfo['all'] "  
                value="{{grp.subjGroupTopic}}" (click)="toggleTopic(grp.id)"
                [color]=" (filterTopics.includes(grp.id)) ? 'primary' : '' ">
                    {{ grp.label() }}
                </button>
            </div>
            <!--providing a list of all topics here took up too much real-estate-->
            Areas: <button mat-raised-button *ngFor="let ea of engineeringAreas$ | async" value="{{ea.eaName}}"
                (click)="toggleEa(ea.id)" [color]=" (filterEas.includes(ea.id)) ? 'primary' : '' ">
                {{ea.eaName}}
            </button>

            <div style="float:right">
                <mat-slide-toggle class="allExpand" (toggleChange)="toggleView()" [matTooltip]="'Expand accordions'">
                    <div *ngIf="(expanded$ | async)">Collapse All</div>
                    <div *ngIf="(expanded$ | async) === false">Expand all</div>
                </mat-slide-toggle>
            </div><br />
            <app-y4-projectlist-accordion [expanded$]="expanded$"></app-y4-projectlist-accordion>
        </mat-card>
    </mat-tab>
    <div *ngIf="groupsInfo$ | async as groupsInfo">
        <mat-tab *ngFor="let grp of groupsInfo['list'] " label="{{grp}}">
            <mat-card>
                Topics:<button mat-raised-button *ngFor="let topic of groupsInfo['topics'][grp]"
                    value="{{topic.subjGroupTopic}}" (click)="toggleTopic(topic.id)"
                    [color]=" (filterTopics.includes(topic.id)) ? 'primary' : '' ">
                    {{topic.subjGroupTopic}}
                </button>
                <br />
                Areas:<button mat-raised-button *ngFor="let ea of engineeringAreas$ | async" value="{{ea.eaName}}"
                    (click)="toggleEa(ea.id)" [color]=" (filterEas.includes(ea.id)) ? 'primary' : '' ">
                    {{ea.eaName}}
                </button>
                <div style="float:right">
                    <mat-slide-toggle  class="eachExpand"  (toggleChange)="toggleView()" [matTooltip]="'Expand accordions'">
                        <div *ngIf="(expanded$ | async) === true">Collapse All</div>
                        <div *ngIf="(expanded$ | async) === false ">Expand All</div>
                    </mat-slide-toggle>
                </div><br />
                <app-y4-projectlist-accordion [expanded$]="expanded$"></app-y4-projectlist-accordion>

            </mat-card>
        </mat-tab>
    </div>
</mat-tab-group>
