import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Y4project } from '../../lib/models/y4project';
import { Y4ProjectChoicesAdminService, Y4supervisorselectionTableItem } from '../../lib/services/y4-project-choices-admin.service';
import { Y4ProjectService } from '../../lib/services/y4-project.service';

import { environment } from '../../../environments/environment';
import { Student } from '../../lib/models/student';

/**
 * This component should display a summary of:
 * - The number of other choices the supervisor has
 * - Current allocations for this project
 * 
 * To be used in the allocate to Student and allocate Choice dialogs
 */

@Component({
  selector: 'app-y4-project-choice-allocation-summary',
  templateUrl: './y4-project-choice-allocation-summary.component.html',
  styleUrls: ['./y4-project-choice-allocation-summary.component.scss']
})
export class Y4ProjectChoiceAllocationSummaryComponent implements OnInit, OnDestroy {

  public teststring: string
  public project$ = this._projectService.currentlyBrowsingProject$;

  public allocatedProject$

  maxProjects = 0
  numAllocatedToSupervisor = 0;

  private _debug = false;
  private _debug_message(msg) { if (this._debug) { console.log(msg) } }

  private _subscriptions = []

  constructor(
    private _choicesAdmin: Y4ProjectChoicesAdminService,
    private _projectService: Y4ProjectService) { }

  ngOnInit(): void {
    this.maxProjects = environment['maxProjects'];
    // getSupervisorAllocated$() returns multiple as the subscription appears to happen before  so we can not take(1)
    this._subscriptions.push(
      this.getSupervisorAllocated$().subscribe((num) => this.numAllocatedToSupervisor = num)
    )
  }

  ngOnDestroy(): void {
    this._subscriptions.map(sub => sub.unsubscribe());
  }

  /**
   * Find the choices that this supervisor has already been allocated to
   * 
   * Here we utilise the table results we have already generated filtering on our supervisor
   * 
   */
  public getSupervisorAllocated$(): Observable<number> {
    // filters the full selections list
    this._debug_message("called getSupervisorAllocated$()")
    return combineLatest([this._choicesAdmin.getCourseSelections$(), this.project$]).pipe(
      tap(([s, proj]) => this._debug_message("getSupervisorAllocated$ size: " + s.length + " project: " + proj.id)),
      map(([s, proj]) => s.filter(isel => isel.pslAllocated === true && isel.pslY4project.projOwner.id === proj.projOwner.id)),
      map(s => s.length)
    );
  }

  // retrieves the choices for to the project
  public projectChoices$ = this._projectService.currentlyBrowsingProject$
    .pipe(
      //tap( proj => console.log(proj.id)),
      switchMap(proj => this._choicesAdmin.getChoicesForProject$(proj.id)),
      // tap(choices => console.log(choices.map( cho => cho.pslY4project.reference() ))),
      //tap(choices => console.log("the choice: "+choices)),
    );

  public allocatedToProject$ = this.projectChoices$
    .pipe(
      //tap(choices => console.log(choices.map( cho => cho.pslY4project.projTitle ))),
      map(choices => choices.filter(cho => cho.pslAllocated)),
      //tap(choices => console.log(choices)),
      map(choices => choices.map(cho => new Student().deserialize(cho.pslStudent)))
    );
}
