import { environmentCommon } from './environment.common';

const retenv = environmentCommon;

// override some configs for production
retenv.config.page_title = 'COMET Part IIB Project Selection';

const env = {
  production: true,
  loginLink: '/public/rlogin' // rlogin redirects to raven
};

Object.keys(env).forEach( (key) => retenv[key] = env[key] );

export const environment = retenv;
