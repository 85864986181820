import { Component, OnInit } from '@angular/core';

import { environment } from '../../environments/environment'; // TODO if staying repale with EnvironmentsService

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isProd=false; // TODO 
                // replace '/public/login' with (environment.hasOwnProperty('loginLink')) ? environment['loginLink'] : '/public/login'
                // in routerGuard to navigate to the google login immediately (check with Andrew first)
  constructor() { }

  ngOnInit(): void {
    this.isProd=(environment.hasOwnProperty('production'))? environment['production'] : false;
  }

}
