<h1 mat-dialog-title>Please confirm</h1>

<h3>{{message}}</h3>

Select a year to copy to:
<div class="flex-container">
  <div class="half">
    <campl-ngx-input-reg-select *ngIf="(courses$|async)?.length > 0" id="projCourseOffering" [label]="'Course Year*'"
      [options]="courses" [formControl]="projCourseOfferingCtrl" [validator]="projCourseOfferingCtrl.validator"
      aria-label="Select Year">
    </campl-ngx-input-reg-select>
  </div>
</div>
<button mat-raised-button class="proceed-button" color="primary" (click)="continue()"
  [disabled]="!projCourseOfferingCtrl.valid">
  Copy
</button>
<button mat-raised-button class="cancel-button" color="warn" (click)="cancel()">
  Cancel
</button>