<button mat-icon-button class="close-button" [mat-dialog-close]="false">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<mat-card>
    <mat-card-title>Review project</mat-card-title>
    <mat-card-subtitle>({{project.projOwner.display_name() }} has {{(activeProjectsByUser$|async)?.length}} other approved project<span *ngIf="(activeProjectsByUser$|async)?.length != 1">s</span>)</mat-card-subtitle>
    <mat-card-content>
        <app-y4-project-summary [projectIn]="project"></app-y4-project-summary>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button class="cancel-button" color="warn" (click)="cancel()">
            Save for later
        </button>

        <button mat-raised-button class="approve-button" color="primary" (click)="approve()">
            Approve
        </button>
    </mat-card-actions>
</mat-card>