<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h1 mat-dialog-title>Allocate {{ (project$ | async)?.reference() }} <span *ngIf="student$ | async">to {{ (student$ |
        async)?.display() }}</span></h1>

<h2>({{ (project$ | async)?.titleTeaser() }})</h2>

<app-y4-project-choice-allocation-summary></app-y4-project-choice-allocation-summary>

<!-- we don;t have this until much later?!
<mat-checkbox (click)=" toggleAgreement()">Agreement has been returned</mat-checkbox><br />
-->


<button mat-raised-button color="primary" (click)="allocate()" [disabled]="submitted">
    Allocate
</button>
<button mat-raised-button color="warn" (click)="cancel()">
    Cancel
</button>