import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Allocation } from '../models/allocation';
import { Y4ProjectService } from './y4-project.service';
import { Y4ProjectselectionService } from './y4-projectselection.service';
/**
 * A service used to action the bulk upload of allocations
 * 
 * This is likely only ever to be used in the choices admin page
 * but has been moved into a seperate service in an attempt to 
 * produce code clarity 
 * 
 * In the component utlilizing this service a form group containing 
 * a field 'bulkChoices' is required eg;
 * 
 * // here we will validate on blur
 * uploadForm = this._fb.group({
 *  bulkChoices: ['', {
 *    validators: [this._bulkUpload._parseLinesBulkUploadValidator()],
 *    asyncValidators: [this._bulkUpload._bulkUploadValidator(this.validStudentCrsids$, this.validCourseReferences$, this._choicesAdmin.getAllocatedStudentCrsids$(), this.canBulkUploadSubject$)],
 *    updateOn: 'blur'
 *   }]
 * });
 * 
 * then (in ngOnInit etc) subscribe to the getBulkUpload$() function
 * this._bulkUpload.getBulkUpload$(this.uploadForm).subscribe(
 *    // () => alert('hit the submit button')
 *     (res) => {
 *       if (res) {
 *         this._choicesService.touchSelections();
 *         this.openSnackBar('Allocations have been loaded', 'close');
 *       }
 *     }
 * You may also need to setup some local functions to;
 * - disable the submit button when the bulkChoices field is edited 
 * - upload the allocations (a wrapper to ) .uploadAllocations()
 */


/**
 * Interface used to store the upload records
 */
export interface AllocationUpload {
  ref: string[];
  crs: string[];
  allocations: Allocation[];
  bad: string[];
}

export interface BulkValidator {
  invalidReferences: string[];
  invalidCrsids: string[];
  allocatedCrsids: string[];
}

/**
 * Used to bulk upload selections
 */
export interface CourseAllocations {
  cooid: number;
  allocations: Allocation[];
}


@Injectable({
  providedIn: 'root'
})
export class Y4ProjectChoicesAdminBulkUploadService {

  bulkUploadTriggerSubject$ = new Subject<number>();
  bulkUploadTrigger$ = this.bulkUploadTriggerSubject$.asObservable();

  constructor(
    private _projectService: Y4ProjectService, // projectsByCoo$ - this was set in parent?!
    private _choicesService: Y4ProjectselectionService) { }

  /**
   * Call this to action the upload
   * 
   */
  uploadAllocations() {
    this.bulkUploadTriggerSubject$.next(1);
  }

  /**
   * Pass the form group to action
   * 
   * The returned observable will generate when an upload occurs
   * 
   * @param uploadForm - the formGroup containing our bulkChoices field
   * @returns 
   */
  getBulkUpload$(uploadForm: FormGroup) {
    // when bulkupload is triggered upload the allocations
    // nextBulkUpload$ = 
    return this.bulkUploadTrigger$.pipe(
      withLatestFrom(this._projectService.projectsByCoo$),
      // loadNo is the triggered load number
      map(([loadNo, courserefs]) => {

        const allocations = this._parseInput(uploadForm.get('bulkChoices').value).allocations;

        const refsLookup = courserefs.reduce(
          (acc, ref) => Object.assign(acc, { [ref.reference()]: ref.id }),
          {});
        allocations.forEach(a => {
          // a.projid = refsLookup[a.reference()] // why not working?
          a.projid = refsLookup[a.reference];
        }
        );
        return { cooid: courserefs[0].projCourseOffering.id, allocations } as CourseAllocations;
        // return [courserefs[0].projCourseOffering.id, allocations];
      }),
      switchMap((coa) => (coa.allocations.length > 0) ? this._choicesService.uploadAllocations(coa.allocations, coa.cooid) : of())
    );
  }


  /**
   * Takes the input from the bulk load box and splits columns
   *
   * @param input - lines of choice entries eg crsid,projref
   * @param split - regex to use to split the columns
   *
   * returns object {
   * ref : string[] - array of references included
   * crs: string[] - array of crsids included
   * allocations: Allocation[] - array of the allocations included
   *  }
   */
  // TODO rename to parseBulkInput
  private _parseInput(input: string, splitter: RegExp = /[\s,]+/): AllocationUpload {
    const lines = input.split('\n');
    return lines.reduce((acc, k) => {
      k = k.trim();
      const cols = k.split(splitter);
      if (cols.length !== 2) {
        if (k.trim() !== '') {
          acc.bad = acc.bad || [];
          acc.bad.push(k);
        }
      } else {
        // ref & crs ids used for validation
        acc.ref = acc.ref || [];
        acc.ref.push(cols[0].trim());
        acc.crs = acc.crs || [];
        acc.crs.push(cols[1].trim());
        // we could just use this array to validate input!
        acc.allocations = acc.allocations || [];
        acc.allocations.push(new Allocation().deserialize({ crsid: cols[1], reference: cols[0] }));
      }
      return acc;
    }, {} as AllocationUpload);

  }

  /**
   * Used as synchronous validation
   *
   * @param split - string to split input into columne by
   */
  public _parseLinesBulkUploadValidator(split: string = ','): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const lookup = this._parseInput(control.value);
      return (lookup.bad) ? { invalidLines: { value: lookup.bad } } : null;
    };
  }

  /**
   * checks that the projects and students supplied in the bulk upload
   * are valid for this course
   *
   * A factory function returning the ValidatorFn
   * 
   * Here we check:
   * - whether the Ref is not in validReferences
   * - whether the crsid is not in validStudentCrsids
   * - TODO whether the crsid is in the allocated crsids
   * 
   * The last argument (toggleButton) is a subject which 
   * is updated by this function, and can be listened to by th eclient
   */
  public _bulkUploadValidator(
    validStudentCrsids$: Observable<string[]>,
    validReferences$: Observable<string[]>,
    allocatedStudentCrsids$: Observable<string[]>,
    toggleButton$: Subject<boolean>): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors | null> => {

      // return of({projects: true});
      const lookup = this._parseInput(control.value); // ref , crs

      if (lookup.ref) {

        return combineLatest([validStudentCrsids$, validReferences$, allocatedStudentCrsids$]).pipe(
          // return validStudentCrsids$.pipe(
          // return validList$.pipe(
          take(1),
          map(([crsids, refs, allocCrsids]) => {
            const invalidRefs = lookup.ref.filter((ref) => (!refs.includes(ref)));
            const invalidCrsids = lookup.crs.filter((ref) => (!crsids.includes(ref)));
            const allocatedCrsids = lookup.crs.filter((ref) => allocCrsids.includes(ref));
            const ret = {} as BulkValidator;
            if (invalidRefs.length > 0) { ret.invalidReferences = invalidRefs; }
            if (invalidCrsids.length > 0) { ret.invalidCrsids = invalidCrsids; }
            if (allocatedCrsids.length > 0) { ret.allocatedCrsids = allocatedCrsids; }
            if (invalidRefs.length > 0
              || invalidCrsids.length > 0
              || allocatedCrsids.length > 0) {
              return ret;
            } else {
              toggleButton$.next(true);
              return null;
            }
          }
          )
        );

      } else {
        return of(null);
      }
    };

  }

}
