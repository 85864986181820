<mat-accordion [multi]="true">
    <mat-expansion-panel *ngFor="let project of (projects$ | async) " [expanded]="expanded">
        <mat-expansion-panel-header>
            <mat-panel-title class="valign-center">
                <mat-icon>account_circle</mat-icon> &nbsp;{{project['projOwnerName']}}
            </mat-panel-title>
            <mat-panel-description>
                {{ project['projs']['projects'].length}} Project<span
                    *ngIf="project['projs']['projects'].length > 1">s</span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-y4-project-select-listing *ngFor="let proj of project['projs']['projects']" [project]="proj">
        </app-y4-project-select-listing>
    </mat-expansion-panel>
</mat-accordion>
