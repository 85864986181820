import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, of, BehaviorSubject } from 'rxjs';
import { Staff } from '../models/staff';
import { HttpClient } from '@angular/common/http';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppService } from './app.service';
import { Role } from '../models/role';
import { ErrorService } from './error.service';

@Injectable({
    providedIn: 'root'
})

/**
 * This service provides a staffList$ of staff entries
 *
 * staffList$ is produced and filtered by setting / calling various methods:
 *
 * Initially staffList$ will return staff unfiltered with limit provided by @private {appendlimit}
 *
 * Calling @function {searchBy} - staffList$ updated with staff whose surname begin with @param {query}
 *
 * Other functions/observables available (these use and will trigger further HTTP requests):
 *
 * @function {search} - returns an observable of staff records
 * @public {allStaff$} - list of all staff
 *
 * Access to staffList$ will be restricted by the api
 *
 */
export class AssistantService {

    constructor(
        private http: HttpClient,
        pageMessage: CamplNgxMessageBufferService,
        private errorService: ErrorService
    ) {
    }
    // This is a read only service
    // available to a subset of users; to prevent misuse (access to staff lists)

    // TODO: update to api/v1/staff B.E. change required
    baseurl = '/api/v1/assistant';
    appendlimit = '?limit=1000'; // used to

    // Update this to trigger new retrieval (getStaff(filter))
    private filterSubject = new BehaviorSubject<string>('');
    public filterObs$ = this.filterSubject.asObservable();

    /**
     * Returns a list of Assistants
     * 
     * @param filter 
     */
    searchAssistants(crsidname: string, limit: number): Observable<Staff[]> {
        const f = (typeof crsidname === 'string') ?
            '?limit=' + limit + '&crsidname=' + crsidname : '';

        return this.http.get<Staff[]>(this.baseurl + f).pipe(
            map(list => list.map(staff => new Staff().deserialize(staff)))
        );
    }

    /**
     * Add an assistant to the database
     * 
     * If the crsid already eixts then te backend will handle the update (if required)
     */
    addAssistant(ass): Observable<Staff> {
        return this.http.post<Staff>(this.baseurl, ass).pipe(
            map(stf => new Staff().deserialize(stf))
        );
    }

}
