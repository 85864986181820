<div id="summary">
    <div *ngIf="project">
        <h2 class="mat-heading" *ngIf="project"><span *ngIf="!noRef">{{project.reference()}}
            </span>{{project.projTitle}}</h2>
        <ul>
            <li *ngIf="!noRef">Reference -> {{project?.reference()}}</li>
            <li>Supervisor -> {{project.projOwner.display_name()}}</li>
            <li *ngIf="project.ownerContactEmail() !== 'n/a' ">Supervisor email -> {{project.ownerContactEmail()}}</li>
            <li *ngIf="project.ownerContactLocation() !== 'n/a' ">Contact location -> {{project.ownerContactLocation()}}
            </li>
            <li *ngIf="project.projUrl !== ''">Web link -> <a (click)="goToLink(project.projUrl)"
                    class="pointer">{{project.projUrl}}</a></li>
            <li *ngFor="let sv of project.projectAssistants()">
                Assistant: {{sv.display_name()}}
                <!-- TODO allow the assistants to have hidden contact details Location: {{sv.stfLocation}} -->
            </li>
            <li *ngIf="project.projMeetingLocation && project.projMeetingDatetime">Find out more about this project: {{project.infoMeeting()}}</li>
            <li *ngIf="project.projIndustrial">This project has an external partner</li>
            <div *ngIf="project.projIndustrial" [innerHTML]="project.projIndustrial | nl2br">
            </div>
            <h4 *ngIf="project.projConfidentiality">The student will be required to sign a confidentiality agreement for
                this project.</h4>

        </ul>

        <h3 *ngIf="!noTopics" class="mat-subheading">Project group topics:</h3>

        <mat-chip-list>
            <mat-chip [selected]="true"> {{project.projSubjectgroupTopic.label()}}</mat-chip>
            <mat-chip [selected]="true" *ngFor="let top of project.sortedTopics()">{{top.label()}}</mat-chip>
        </mat-chip-list>

        <h3 *ngIf="!noAreas" class="mat-subheading">Engineering areas:</h3>


        <mat-chip-list>
            <mat-chip [selected]="true" *ngFor="let ea of project.projEngineeringAreas">{{ea.eaName}}</mat-chip>
        </mat-chip-list>

        <h3 class="mat-subheading">Description</h3>
        <p [innerHTML]="project.projDescription"></p>

        <hr />
        <p *ngIf="!noFooter" class="mat-small mat-caption">This brief description may not fully convey every aspect of
            the project experience.
            Always arrange to meet the
            supervisor and discuss the project before you select it as one of your choices. Students away on exchange
            schemes
            should
            arrange a video call.</p>

    </div>
</div>