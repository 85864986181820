import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { environment } from '../../../environments/environment';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {

  env = environment;

  constructor(
    pageMessage: CamplNgxMessageBufferService,
    private errorService: ErrorService
  ) { }
  /**
   * Wrapper around the environment mechanism used by angular
   * allows us to modify in tests
   * (as well as provide an interface to the config)
   */
  // getEnv(){ return environment }
}
