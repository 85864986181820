import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Y4staffjobtitleService } from '../lib/services/y4staffjobtitle.service';
import { take } from 'rxjs/operators';

/**
 * A component to use to add and remove the job titles used to synchronize staff members
 * 
 * This should only be acecssible for to_admin
 */

@Component({
  selector: 'app-y4-job-titles-list',
  templateUrl: './y4-job-titles-list.component.html',
  styleUrls: ['./y4-job-titles-list.component.scss']
})
export class Y4JobTitlesListComponent implements OnInit {

  jobTitleForm: FormGroup

  constructor(
    private _y4staffjobtitleService: Y4staffjobtitleService) { }

  ngOnInit(): void {
    this.jobTitleForm = new FormGroup({
      jobTitle: new FormControl('', [Validators.required])
    })
  }

  jobTitles$() {
    return this._y4staffjobtitleService.getList$()
  }

  addJobtitle() {
    this._y4staffjobtitleService
      .addJobTitle(this.jobTitleForm.get('jobTitle').value)
      .pipe(take(1))
      .subscribe(() => {
        this._y4staffjobtitleService.refreshList()
        this.jobTitleForm.reset()
      })
  }

  removeJobtitle(sjt) {
    this._y4staffjobtitleService
      .removeJobTitle(sjt)
      .pipe(take(1))
      .subscribe(() => this._y4staffjobtitleService.refreshList())
  }
}
