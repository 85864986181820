import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject, combineLatest } from 'rxjs';
import { catchError, map, share, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Appstatus } from '../models/appstatus';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { AppService } from './app.service';
import { MAT_SORT_HEADER_INTL_PROVIDER } from '@angular/material/sort';
import { ErrorService } from './error.service';

// TODO refactor out to observables

@Injectable({
  providedIn: 'root'
})
export class StatusesService {

  // listSubject$  = new ReplaySubject<Appstatus[]>();
  // list$ = this.listSubject$.asObservable();

  listRefreshSubject$ = new BehaviorSubject<number>(1); // used to trigger refresh
  listRefresh$ = this.listRefreshSubject$.asObservable();

  baseurl = '/api/v1/appstatus';
  appendlimit = '?limit=10000';
  lYear: number;
  coursePrefixCode = 'EGT3'; // the prefix used for Y3Project


  listAll$ = this.listRefresh$.pipe(
    switchMap( ret => this.http.get<Appstatus[]>(this.baseurl + this.appendlimit + '&populate=staCourseOffering') ),
    shareReplay(1)
  );

  /**
   * The intention is to trigger the retrieval of the list
   * FIXME as listAll$ has shared replay the intention will not occur
   */
  list$ = this.listAll$.pipe(
    map( statuses => statuses.map((status: Appstatus) => new Appstatus().deserialize(status)) )
  );

  /**
   * Return an order list of years
   * Can be used to browse through the course years
   *
   * NB during test this might have gaps!
   */
  listYears$ = this.list$.pipe(
    map( stas => stas.map(sta => sta.staCourseOffering.cooOfferingYear ) ),
    map( years => [...new Set(years)].sort() )
  );

  /**
   * return the courses which have not year closed
   * These will include course that are not active
   * for student selection at the moment
   */
  futureYearsA$ =
    this.list$.pipe(
      map(statuses =>
        { const today = new Date();
          return statuses.filter(o => o.endDate() > today);
           } )
    );

  // returns the future and current statuses for EGT3
  // TODO generalise combine with a H03code$ observable
  futureEGT3$ = this.futureYearsA$.pipe(
    map( statuses => statuses
      .filter(o => o.staCourseOffering.cooH03Code = 'EGT3'))
  );

  futureYears$ = this.futureYearsA$.pipe(
    map( statuses => statuses.map(o => o.staCourseOffering.id))
  );



  constructor(
    private http: HttpClient,
    pageMessage: CamplNgxMessageBufferService,
    private errorService: ErrorService
  ) { }

  // runs on app startup (app.component) - so our data is ready
  init(): Observable<Appstatus[]> {

    // this.getStatuses()
    return this.list$;
  }

  /**
   * getStatuses
   *
   * toggles a subject to trigger refetch
   */
  getStatuses() {
    this.listRefreshSubject$.next(1);
  }
  /**putStatus
   * updates status period (keydates)
   * @param sta - the status to update
   */
  putStatus(sta): Observable<any> {
    // Mysql does not acept dates in ISO format (with trailing Z)
    sta.staStartsOn = sta.staStartsOn.toISOString().replace(/Z/g, '')
    sta.staEndsOn = sta.staEndsOn.toISOString().replace(/Z/g, '')

    return this.http.patch<any>(this.baseurl + '/' + sta.id, sta).pipe(
      catchError(this.errorService.handleErrorPage())
    );
  }
  /**
   * copyYear - copies the status periods from source year to the target year
   *
   * @param source - source year
   * @param target - target year
   */
  copyYear$(source: number, target: number): Observable<any> {
    return this.http.post<any>(this.baseurl + '/copyyear', { source: '' + source, target: '' + target }).pipe(
      catchError(this.errorService.handleErrorPage())
    );
  }

  /**
   * maxYear
   * returns the maximum year provided by our statuses
   */
  // TODO this needs converting to an Observable so that we can utilize RxJs functions etc
  async maxYear(): Promise<any> {
    // subscribe to the status list and filter out the maximum year
    this.list$.subscribe(statuses => {
      this.maxYear = Math.max.apply(Math, statuses.map(o => o.staYear));
    });
    return this.maxYear;
  }

  /** latestYear
   * returns the maximum relevant year where a status has started
   * (N.B. it may have also ended!)
   */
  // TODO correct the return type to specific (rather than any)
  // For latest year to be subscribe able we must return an observable
  latestYear(): Observable<number> {
    // filter out the maximum year
    const today = new Date();
    return this.list$.pipe(
      map(statuses =>
        // finds the max started status from statuses array
        Math.max.apply(Math,
          statuses.filter(o => o.startDate() < today)
            .map(o => o.staYear))
      )
    );
  }


  /**
   * Used to query whether the status of a course offering is active
   *
   * DEPRECATED??
   */
  isOfferingStatusActive(coid: number, status: string) {
    //;
    const today = new Date();
    // return new Promise(resolve => {
    return this.list$.pipe(
      map(statuses =>
        statuses.filter(s => (
          s.staCourseOffering.id === coid &&
          s.staCode === status &&
          s.endDate() > today &&
          s.startDate() < today))),
      map(f => f.length > 0)
    ).toPromise();
    // })
  }

  /**
   * returns an observable of all the active statuses
   * which also includes relevant course ids
   */
  activeStatuses$(): Observable<Appstatus[]>{
    const today = new Date();
    return this.list$.pipe(
      map(statuses =>
        statuses.filter(s => (
          s.endDate() > today &&
          s.startDate() < today)))
    );
  }

  /**
   * the Current year
   * TODO implement
   */
  //  currentCourse() : Observable<number>{
  //
  //  }

  /**
   * The course associated with next academic year
   * TODO implement
   */
  //  nextCourse() : Observable<number>{
  //
  //  }

}
