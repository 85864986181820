import { Component, OnInit, ViewChild } from '@angular/core';
import { Y4ProjectService } from '../lib/services/y4-project.service';
import { combineLatest, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { StatusesService } from '../lib/services/statuses.service';
import { SgtopicService } from '../lib/services/sgtopic.service';
import { EngineeringareaService } from '../lib/services/engineeringarea.service';
import { Sgtopic } from '../lib/models/sgtopic';
import { EngineeringArea } from '../lib/models/engineeringarea';
import { map, take } from 'rxjs/operators';
import { MatAccordion } from '@angular/material/expansion';
import { BehaviorSubject } from 'rxjs';
import { ICamplNgxPageNav } from 'cued-lib/src/lib/campl-ngx';



/**
 * Implement printOut() to display the current choice
 * in browse bar
 * We provide this so we can use a familiar page title
 */
export class WrapperClassDummy implements ICamplNgxPageNav {
  constructor() { }

  printOut(): string | number {
    return '';
  }
}

/**
 * Browse the projects offered and available to this EGT3 Student
 * - ie projects confined to their course offering
 *
 * This component provides the filters that the student uses to
 * narrow down the list.
 *
 * This component will interact with the y4projectselection service to set
 * the list of projects that match the users browse by criteria
 *
 *
 */

@Component({
  selector: 'app-y4-projectsbrowse',
  /**
   * Note mat-button are being used for more pleasent style
   * mat-toggle-button-group must appear in one element - causing scroll issues
   */
  templateUrl: './y4-projectsbrowse.component.html',
  styleUrls: ['./y4-projectsbrowse.component.css']
})
export class Y4ProjectsbrowseComponent implements OnInit {

  /**
   * Placeholder - we do not switch courses
   */
  initialIndex = 0;

  /**
   * Placeholder - we do not switch courses
   */
  offerings = [new WrapperClassDummy()];


  /**
   * Stores the full topic list
   */
  topicsList: Sgtopic[];

  /**
   * Stores the Topics we are filtering by
   */
  filterTopics: number[] = []; // The IDs of topics to filter by

  /**
   * Stores the Engineering Areas we are filtering by
   */
  filterEas: number[] = []; // IDs of engineering areas to filter by

  /**
   * The list of Engineering areas available
   */
  engineeringAreas$ = this._eaService.list$;

  /**
   * Subscribe to this to setup the topicsList
   *
   * take(1) as we setup once in the beginning
   */
  topicsList$ = this._sgtopicsService.topics$.pipe(take(1));

  /**
   * Object that stores the group / topic heirarchy
   *
   * Subscribed to in the template
   */
  groupsInfo$ = this.topicsList$.pipe(
    map(list => {
      const groupTopics = {};
      // This is important - used to filter projects on tab select
      const groups = new Set(list.map(sgt => sgt.subjGroup));
      groups.forEach(grp => groupTopics[grp] = this.listGoupTopics(list, grp));
      return {
        list: Array.from(groups),
        topics: groupTopics,
        all: this.topicsList.sort((a, b) => (a.label() > b.label()) ? 1 : -1)
      };
    }));

  /**
   * Toggle Accordion state
   */
  private expandedSubject$ = new BehaviorSubject<boolean>(false);


  /**
   * Manage Accordion state in view
   */
  public expanded$ = this.expandedSubject$.asObservable();

  /**
   * Returns an order list of Topics for the provided Project (Subject) Group
   *
   * @param grp - the group to filter by
   */
  listGoupTopics(topicsList: Sgtopic[], grp: string) {
    return topicsList.filter(sgt => sgt.subjGroup === grp).sort(
      (a, b) => (a.subjGroupTopic > b.subjGroupTopic) ? 1 : -1);
  }

  constructor(
    private _y4projectsService: Y4ProjectService, // communicate filter by
    private _sgtopicsService: SgtopicService,
    private _eaService: EngineeringareaService) { }

  ngOnInit(): void {
    this.topicsList$.subscribe(list => this.topicsList = list);

    // every visit we provide the full list
    this._y4projectsService.setStudentTopicSelection([]);
    this._y4projectsService.setStudentEareaSelection([]);
  }

  /**
   * listener for tab click - on this click we trigger the
   * retrieval of a list base on our selected Project (Subject) Group
   *
   * Here we could also clear the current list?
   *
   * @param eve event occurred (includes the actioned tab)
   */
  tabClick(eve) {
    this._y4projectsService.cleanList(); // this will remove entries from our list
    this.getProjectsByGroup(eve.tab.textLabel);
  }

  /**
   *
   * @param grp Retrieve the list of projects based on the group' topics
   */
  getProjectsByGroup(grp: string) {
    this.filterTopics = [];

    // This will cause the underlying combineLatest to trigger twice :-(
    // Make sure this is therefore a SwitchMap
    this._y4projectsService.setStudentTopicSelection(this.listGoupTopics(this.topicsList, grp).map(sgt => sgt.id));
    this._y4projectsService.setStudentEareaSelection(this.filterEas);
  }


  /**
   * toggleTopic - toggle whether or not to filter by this topic
   *
   * @param top - topic id to add / remove from our filter
   */
  toggleTopic(top: number) {
    const index = this.filterTopics.indexOf(top);
    if (index > -1) {
      // remove from filter array
      this.filterTopics.splice(index, 1);
    } else {
      this.filterTopics.push(top);
    }
    this._y4projectsService.setStudentTopicSelection(this.filterTopics);
  }

  /**
   * ToggleEa - whether to filter by engineering areas or not
   *
   */
  toggleEa(ea: number) {
    const index = this.filterEas.indexOf(ea);
    if (index > -1) {
      // remove from filter array
      this.filterEas.splice(index, 1);
    } else {
      this.filterEas.push(ea);
    }
    this._y4projectsService.setStudentEareaSelection(this.filterEas);
  }

  /**
   * Set the overall accordion state for this tab
   */
  toggleView() {
    this.expandedSubject$.next(!this.expandedSubject$.getValue());
  }
}
