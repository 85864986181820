import { Deserializable } from './deserializable.model';

export class Staff  implements Deserializable {

    id: number;
    stfPersonnelKey: string;
    stfCrsid: string;
    stfEngid: string;
    stfEmail: string; //for external staff members
    stfSurname: string;
    stfFnames: string;
    stfPrefName: string;
    stfInitials: string;
    stfCollegeId: string;
    stfUSN: string;
    stfClass: string;
    stfLocation: string;
    stfExternal: boolean;
    stfTitle: string;
    stfGender: string;
    stfAuthString: string;
    stfSubjectGroupTopic: number;
    stfCancelled: boolean;

    constructor() { }

    display_name(): string {
        const name = this.stfTitle + ' ' + this.stfPrefName + ' ' + this.stfSurname;
        return name.replace(/  +/g, ' ');
    }

    email(): string {
        // Return the email field for external member / or where an alternative has been set
        return this.stfEmail? this.stfEmail : this.stfCrsid + '@cam.ac.uk';
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    personnelKey(){
        return this.stfPersonnelKey
    }
}
