import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CourseofferingService } from '../lib/services/courseoffering.service';
import { StatusesService } from '../lib/services/statuses.service';


/**
 * A simple Modal allowing the user to select a year
 * which they would like to copy their project into
 *
 * Utilizes reactive forms
 *
 * On selecting an confirming the course the component will return the
 * courseID to copy the project to
 */
@Component({
  selector: 'app-y4-project-copy-dialog',
  templateUrl: './y4-project-copy-dialog.component.html',
  styleUrls: ['./y4-project-copy-dialog.component.css']
})
export class Y4ProjectCopyDialogComponent implements OnInit {

  @Input() message = '';
  courses = [];
  yearToCopyToForm = FormGroup;

  /**
   * Tracks the changes to the course offering selection
   */
  projCourseOfferingCtrl = new FormControl(null, [Validators.required]);

  /**
   * The courses we can copy this project to
   *
   * The list of options is derived from current year + future years registered in the database
   *
   * @returns [{id: cooid, value, cooName}] pairs
   *
   *
   * A subscrption to this is required so that the courses aray is populated
   * This subscription occurs in the *ngIf
   *
   * TODO: new service? courseDetails: future_courses (for courses$), current_course (for latest$), course_statuses (pass through)?
   */

  courses$ = combineLatest([this._statuses.latestYear(), this._cooservice.list$]).pipe(
    map ( ([lyear, coos]: any) =>
    this.courses = coos
        .filter(coo => coo.cooOfferingYear > lyear - 1 && coo.cooOfferingYear < lyear + 2)
        .map(coo => ({ id: coo.id, value:  coo.cooOfferingYear+' - '+(coo.cooOfferingYear+1) }))
        .sort((a, b) => (a.value > b.value) ? -1 : 1)
    )
  );

  constructor(
    private _statuses: StatusesService,
    private _cooservice: CourseofferingService,
    private _dialogRef: MatDialogRef<Y4ProjectCopyDialogComponent>) { }

  ngOnInit(): void {

  }

  /**
   * Confirm the copy
   */
  public continue(){
    // make it so
    //;
    //  this._dialogRef.close(this.projCourseOfferingCtrl.value.selected);
    this._dialogRef.close(this.projCourseOfferingCtrl.value.selected);
  }

  /**
   * Cancel the action
   */
  public cancel(){
    this._dialogRef.close(false);
  }

}
