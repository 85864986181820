<h3 mat-h3>Manage roles</h3>

<mat-tab-group (selectedTabChange)="tabClick($event)">
    <mat-tab *ngFor="let role of (roles$ | async)" label="{{role.roleType}}">
        <div *ngFor="let ugr of (usersWithRole$ | async)">
            <div class="with-icon">
                {{ugr.ugrUser.display_name}} ({{ugr.ugrUser.username}}) - {{ugr.ugrGroup}}
                <mat-icon (click)="removeRole(ugr.ugrUser,role)" class="pointer" matTooltip="Remove Role">delete</mat-icon>
            </div>
        </div> 
    </mat-tab>
</mat-tab-group>
<!--placing in mat-tab causes re-render issue with tests, we are also using an id so one element only!-->
<button mat-button class="valign-center" (click)="addRole()" id="addRole">
    <mat-icon  class="pointer">add</mat-icon>Add new role
</button>