import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, concat, of, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { StatusesService } from '../lib/services/statuses.service';
import { Y4ProjectService } from '../lib/services/y4-project.service';
import { CourseofferingService } from '../lib/services/courseoffering.service';
import { AuthService } from '../lib/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AreyousureDialogComponent } from 'cued-lib/src/lib/cued-shared';
import { Y4ProjectCopyDialogComponent } from '../y4-project-copy-dialog/y4-project-copy-dialog.component';
import { CourseOffering } from '../lib/models/courseoffering';

// TODO move into a share module?!
import { ICamplNgxPageNav } from 'cued-lib/src/lib/campl-ngx';

/**
 * Implement printOut() to display the current choice
 * in browse bar
 *
 * Here we should be using a courseofferings array
 * and emit the offering/offeringID
 */
export class WrapperClassCourse implements ICamplNgxPageNav {
  offering: CourseOffering;

  constructor(offering: CourseOffering) {
    this.offering = offering;
  }

  getYear() {
    return this.offering.cooOfferingYear;
  }

  printOut(): string | number {
    return this.getYear()+' - '+(this.getYear()+1);
  }
}


/**
 * Displays a users projects, and provides edit / insert / submission
 *
 * The owners projects are found from searching by:
 *
 * Course, Owner
 *
 * Displays the associated y4project data an owner might expect to see:
 * (for a particular <- course / year ->)
 * Their projects
 * Who has selected their projects
 * Link to all projects
 */

@Component({
  selector: 'app-y4-owner-page',
  templateUrl: './y4-owner-page.component.html',
  styleUrls: ['./y4-owner-page.component.css']
})
export class Y4OwnerPageComponent implements OnInit, OnDestroy {

  /**
   * Holds a list of subscriptions
   *
   * Unsubscribe from these when we destroy the component
   */
  subs = [];

  /**
   * Offerings is a list of ordered course that we will navigate though
   */
  offerings: WrapperClassCourse[];

  /**
   * Observable used to set the offerings we will navigate
   * This needs to be called once to setup our navigation
   *
   */
  offerings$ = this._courseOfferingService.list$.pipe(
    take(1),
    map(coos => coos.sort((a, b) => a.cooOfferingYear - b.cooOfferingYear)),
    map(coos => coos.map(coo => new WrapperClassCourse(coo)))
  );

  /**
   * The initial index of our list of browseable courses
   *
   * This will be setup by a subscription to the associated observable
   */
  initialIndex = 0;
  /**
   * Observable collecting the initial index to set our browse list to
   */
  initialIndex$ = combineLatest([this.offerings$, this._appStatus.latestYear()])
    .pipe(
      take(1),
      map(
        ([offerings, lyear]) => offerings.findIndex((ele) => ele.getYear() === lyear)
      ));

  /**
   * The currently selected courseOffering
   *
   * Initial value is set by initialIndex then later by the signal
   * from our browse component
   */
  offering: CourseOffering;

  offeringSelected$ = new Subject<WrapperClassCourse>();

  initialOffering$ = combineLatest([this.offerings$, this.initialIndex$]).pipe(
    take(1),
    map(([offerings, initialIndex]) => offerings[initialIndex])
  );

  /**
   * Observable setting the currently selected course, merges event emitted from the navigation (offeringSelected)
   */
  selectedOffering$ = concat(this.initialOffering$, this.offeringSelected$);


  // This observable is filtered by calls to:
  // getProjects()
  // setOwner()
  // getProjectsYear(year: string, limit: number = 500, topics: number[] = [], areas: number[] = [])
  projects$ = this._y4projectService.projects$;

  year = 0;
  offset = 0;

  constructor(
    private _appStatus: StatusesService,
    private _y4projectService: Y4ProjectService,
    private _courseOfferingService: CourseofferingService,
    private _auth: AuthService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar) { }


  ngOnInit(): void {
    // prepares the y4project service for the current owner
    this._auth.me$.pipe(take(1)).subscribe(
      me => this._y4projectService.setOwner(me.staff.id)
    );
/*
    // We only need to set up our list once
    // NB these are wrapper course offerings
    this.offerings$.subscribe(
        offerings => this.offerings = offerings
      );

    // we only need to do this once
    this.initialIndex$.subscribe(
        initialIndex => this.initialIndex = initialIndex
      );
 */
    // our navigation requires both of these prior to init:
    combineLatest([this.initialIndex$, this.offerings$]).subscribe(
      ([i, o]) => {
        this.offerings = o;
        this.initialIndex = i;
      }
    );
    // we wait for changes in our selected item
    this.subs.push(
      this.selectedOffering$.subscribe(
        selectedBrowse => {
          // TODO in the case of no offering we need to throw an error
          this.offering = selectedBrowse.offering;
          this._y4projectService.setYear(selectedBrowse.getYear());
        }
      )
    );

  }
  ngOnDestroy() {
    this.subs.map(sub => sub.unsubscribe());
  }

  /**
   * Indirectly triggers populates projects by triggering the service to retrieve the
   * projects for a particular year / owner
   *
   * @param selected - a WrapperClassYear object emitted by the nav or the year (number)
   */
  populateProjects(selected: WrapperClassCourse) {
    this.offeringSelected$.next(selected);
  }

  submitProject(project) {
    //;
    const dialogRef = this._dialog.open(AreyousureDialogComponent, {});
    const instance = dialogRef.componentInstance;
    instance.message = `You wish to submit project: ${project.reference()}  "${project.projTitle}"`;
    dialogRef.afterClosed().pipe(
      switchMap((val) => (val) ? this._y4projectService.submitProject(project) : null),
      take(1)
    ).subscribe(
      res => {
        if (res) {
          this.openSnackBar('The project has been submitted', 'close');
        } else {
          this.openSnackBar('Trouble creating project', 'close');
        }
        dialogRef.close(true);
      }
    );
    // we could subscribe and wait for the approval to occur then call this?
  }

  /**
   * TODO: provide a year selector
   *
   * @param project - the project we wish to make a copy of
   */
  copyProject(project) {

    const dialogRef = this._dialog.open(Y4ProjectCopyDialogComponent, {});
    const instance = dialogRef.componentInstance;
    instance.message = `Copy: ${project.reference()}  "${project.projTitle}"`;
    dialogRef.afterClosed().pipe(
      switchMap((val) =>

        // TODO place in an API call
        // remove the ID, the dates and replace the course
         (val) ? this._y4projectService.copyProject(project, val) : of(null)

      )
    ).subscribe(
      res => {
        if (res) {
          this.openSnackBar('The project has been copied', 'close');
        } else {
          this.openSnackBar('Project not copied', 'close');
        }
        dialogRef.close(true);
      }
    );
  }

  // currently using the standard material snackbar service
  // campl-ngx-snackbar could be created to display different types of message etc?
  // We could modify sendMessage to send to a snack bar or messageare
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
