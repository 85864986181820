import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, take, tap } from 'rxjs/operators';
import { combineLatest, concat, Subject } from 'rxjs';
import { AuthService } from '../lib/services/auth.service';
import { Sgtopic } from '../lib/models/sgtopic';
import { SgtopicService } from '../lib/services/sgtopic.service';
import { StatusesService } from '../lib/services/statuses.service';
import { Y4ProjectService } from '../lib/services/y4-project.service';
import { environment } from '../../environments/environment';
import { Auth } from '../lib/models/auth';
import { Role } from '../lib/models/role';
import { AreyousureDialogComponent } from 'cued-lib/src/lib/cued-shared';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CourseOffering } from '../lib/models/courseoffering';
import { CourseofferingService } from '../lib/services/courseoffering.service';
import { ICamplNgxPageNav } from 'cued-lib/src/lib/campl-ngx';


/**
 * Implement printOut() to display the current choice
 * in browse bar
 *
 * Here we should be using a courseofferings array
 * and emit the offering/offeringID
 */
export class WrapperClassCourse implements ICamplNgxPageNav {
  offering: CourseOffering;

  constructor(offering: CourseOffering) {
    this.offering = offering;
  }

  getYear() {
    return this.offering.cooOfferingYear;
  }

  printOut(): string | number {
    return this.getYear()+' - '+(this.getYear()+1);
  }
}

/**
 * A component used to display the Projects ready for approval
 *
 * Projects are groups and displayed by the Project (Subject) Group
 *
 * The app-y4-projects-table is used to render and action the list
 *
 * The approval handling is done via the table in its own seperate dialog
 */
@Component({
  selector: 'app-y4-project-approval-list',
  templateUrl: './y4-project-approval-list.component.html',
  styleUrls: ['./y4-project-approval-list.component.css']
})
export class Y4ProjectApprovalListComponent implements OnInit, OnDestroy {

  subscriptions = [];

  //  -- top nav properties --

  offerings: WrapperClassCourse[];
  /**
   * Observable used to set the offerings we will navigate
   * This needs to be called once to setup our navigation
   *
   */
  offerings$ = this._courseOfferingService.list$.pipe(
    take(1),
    map(coos => coos.sort((a, b) => a.cooOfferingYear - b.cooOfferingYear)),
    map(coos => coos.map(coo => new WrapperClassCourse(coo)))
  );


  /**
   * The initial index of our list of browseable courses
   *
   * This will be setup by a subscription to the associated observable
   */
  initialIndex = 0;
  /**
   * Observable collecting the initial index to set our browse list to
   */
  initialIndex$ = combineLatest([this.offerings$, this._appStatus.latestYear()])
    .pipe(
      take(1),
      map(
        ([offerings, lyear]) => offerings.findIndex((ele) => ele.getYear() === lyear)
      ));

  /**
   * The currently selected courseOffering
   *
   * Initial value is set by initialIndex then later by the signal
   * from our browse component
   */
  offering: CourseOffering;

  /**
   * When the year/course changes we update this subject
   */
  offeringSelected$ = new Subject<WrapperClassCourse>();

  /**
   * Find course that matches the initial index of our navigator
   */
  initialOffering$ = combineLatest([this.offerings$, this.initialIndex$]).pipe(
    take(1),
    map(([offerings, initialIndex]) => offerings[initialIndex])
  );

  /**
   * Observable setting the currently selected course, merges event emitted from the navigation (offeringSelected)
   */
  selectedOffering$ = concat(this.initialOffering$, this.offeringSelected$);

  // END: -- top nav properties --


  // initialIndex = 0;

  // offering: CourseOffering;

  permsLookup = environment.permsLookup;

  todos$ = this._y4projectService.projectsToApprove$;
  me$ = this._authService.getMe$();
  allgroups = false;
  groups$ = this._sgtopicService.groups$;
  groupsAllowed = [];
  activeGroup = ''; // used when we have no group assigned

  constructor(
    private _courseOfferingService: CourseofferingService,
    private _y4projectService: Y4ProjectService,
    private _authService: AuthService,
    private _appStatus: StatusesService,
    private _sgtopicService: SgtopicService) {
  }

  /**
   * We will use this if we do not have a topic group set
   * NB some similiar used in y4-project-choices-admin! inc display
   */
  toggleGroup(sgname: string) {
    this._y4projectService.setProjectSGroup(sgname);
    this.activeGroup = sgname;
  }
  ngOnInit(): void {
    // List will be required by the dialog when providing information
    // this.retrieveLatestYearProjects()

    // TODO move this
    // TODO remove the embedded subscription
    // We figure out which groups the user can approve and setup button / list
    this.me$.pipe(take(1)).subscribe(me => {
      // if no project (subject) group has been set we show all groups'
      if (this._testAccess(me, this.permsLookup.toadmin).length > 0) {
        this.groups$.subscribe(
          groups => {
            // can we not doe something like take(1)?
            const firstgroup = Object.keys(groups).sort()[0];
            this._y4projectService.setProjectSGroup(firstgroup);
          }
        );
        this.allgroups = true;
      } else {
        const roles = this._testAccess(me, this.permsLookup.approveY4projectsAccess);
        if (roles.length > 0) {
          // Each associated role should have an associated grouprole record!
          const rolids = roles.map(r => r.id);
          const ugr = me.grouproles.filter(ugri => rolids.includes(ugri.ugrRole.id)).sort();
          this._y4projectService.setProjectSGroup(ugr[0].ugrGroup);
          this.activeGroup = ugr[0].ugrGroup;
          this.groupsAllowed = ugr.map(ugri => ugri.ugrGroup);
          this.allgroups = false;
        }
      }
    });

/*
    // We only need to set up our list once
    // NB these are wrapper course offerings
    // They are required by a child element -> PageNavComponent
    this.offerings$.subscribe(
      offerings => this.offerings = offerings
    );

    // we only need to do this once
    this.initialIndex$.subscribe(
      initialIndex => this.initialIndex = initialIndex
    );
 */
    // our navigation requires both of these prior to init:
    combineLatest([this.initialIndex$, this.offerings$]).subscribe(
      ([i, o]) => {
        this.offerings = o;
        this.initialIndex = i;
      }
    );

    // we wait for changes in our selected item
    this.subscriptions.push(
      this.selectedOffering$.subscribe(
        selectedBrowse => {
          // TODO in the case of no offering we need to throw an error
          this.offering = selectedBrowse.offering;
          this._y4projectService.setYear(selectedBrowse.getYear());
        }
      )
    );

  }

  ngOnDestroy(): void {
    // TODO unsubscribe
    this.subscriptions.map(sub => sub.unsubscribe());
  }

  // similar also used in approval guard and perhaps elsewhere -> can we abstract out into a library?
  private _testAccess(user: Auth, accessroles: string[]): Role[] {
    return user.roles.filter(value => accessroles.includes(value.roleType));
  }


  /**
   * Triggers the given year of projects to be retrieved
   * from the Y4project service
   */
  /* retrieveLatestYearProjects(): void {
    this._statuses.latestYear()
      .subscribe((lyear: any) => {
        this.populateProjects(lyear);
      });
  } */

  /**
   * populateProjects
   *
   * populates the projects array, by trigger a pull of
   * the y4projectsService.projects$ subject
   *
   *
   * @returns void
   * (see y4projectslist - perhaps move elsewhere?)
   */

  populateProjects(selected: WrapperClassCourse) {
    // this.offering = selected.offering;
    // this._y4projectService.setYear(selected.getYear());
    this.offeringSelected$.next(selected);
  }


}
