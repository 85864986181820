import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../lib/services/auth.service';
import { StaffService } from '../lib/services/staff.service';
import { StatusesService } from '../lib/services/statuses.service';
import { StudentService } from '../lib/services/student.service';


/**
 * Provides a list of staff / students to impersonate
 *
 * Students are to only include those on courses that have not yet finished
 *
 * The individual to impersonate can be chosen by displaying staff/students,
 * and filtering by:
 *
 * Students - the start of their crsids (students)
 * Staff - Their surname
 *
 */

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.css']
})
export class ImpersonateComponent implements OnInit, OnDestroy {

  // staff: Staff[];
  // students: Student[];

  // subscriptions
  subscriptions = [];


  public staff$ = this._staffService.staffList$;
  public students$ = this._studentService.list$;

  // list of future courses, including current course
  public futureCourses$ = this._statuses.futureYears$;

  // produces a list of Filter Characters
  filters = [...Array(26)].map((_, i) => String.fromCharCode('A'.charCodeAt(0) + i));
  // The first filter to choose
  selectedFilter = 'A';

  constructor(public _staffService: StaffService,
              private _studentService: StudentService,
              private _authService: AuthService,
              private _statuses: StatusesService,
              private _snackBar: MatSnackBar
    // private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this._staffService.getStaff('A');
    // Once we know the courses set our service to filter these students
    this.subscriptions.push(this._statuses.futureYears$.subscribe(
      courses => this._studentService.setCourses(courses)
    ));
  }
  ngOnDestroy(): void {
    this.subscriptions.map(sub => sub.unsubscribe());
  }

  tabClick(eve) {
    this.selectedFilter = 'A';
    this._staffService.cleanList(); // this will remove entries from our list

    if (eve.tab.textLabel === 'Student') {
      this._studentService.getStudents(this.selectedFilter);
    } else {
      this._staffService.getStaff(this.selectedFilter);
    }
  }

  filterStaff(filter: string) {
    this._staffService.getStaff(filter);
    this.selectedFilter = filter;
  }

  filterStudents(filter: string) {
    this._studentService.getStudents(filter);
    this.selectedFilter = filter;
  }

  impersonateStaff(sid: string) {
    this._authService.impersonateStaff(sid)
    // TODO implement Httpinterceptor for global errors
    // https://blog.angular-university.io/rxjs-error-handling/
      .subscribe(
        res => {
          this._authService.updateMe();
          this._snackBar.open('Successfully impersonated staff member ', 'close', { duration: 2000 });
        },
        err => {
          this._snackBar.open('An error occured !! ', 'close', { duration: 2000 });
          //;
        },
        () =>  {
          //;
        }

      );
    // .subscribe(ret => {
    //  this._authService.updateMe();
    //  this._snackBar.open('Successfully impersonated staff member ', 'close', {duration: 2000}); } );
  }

  impersonateStudent(sid: string) {
    this._authService.impersonateStudent(sid)
    // https://blog.angular-university.io/rxjs-error-handling/
    .subscribe(
      res => {
        this._authService.updateMe();
        this._snackBar.open('Successfully impersonated student ', 'close', { duration: 2000 });
      },
      err => {
        this._snackBar.open('An error occured !! ', 'close', { duration: 2000 });
        //;
      },
      () =>  {
        //;
      }
    );
    // .subscribe(ret => {
    //  this._authService.updateMe();
    //  this._snackBar.open('Successfully impersonated student ', 'close', { duration: 2000 });
    // });
  }
}
