import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Deserializable } from '../models/deserializable.model';
import { Staff } from '../models/staff';
import { Student } from '../models/student';
import { Y4project } from '../models/y4project';
import { Y4projectselection } from '../models/y4projectselection';

import { StudentService } from './student.service';
import { Y4ProjectService } from './y4-project.service';
import { Y4ProjectselectionService } from './y4-projectselection.service';
import { Y4ProjectChoicesAdminFiltersService } from './y4-project-choices-admin-filters.service';

/**
 * This service is intended to abstract away many of the calls currently being
 * made in the y4-choices-admin-component
 * 
 * providing a list of the currently selected courses choices by the three centrics
 * 
 * Project (those choices involving a project - including projects without choices)
 * Supervisor (list those choices associated to the supervisor)
 * Student (those made by the stundent - include students who have not made selections)
 * 
 * It might be sensible to seperate these up even further such that we have a 
 * seperate filter service for teh 3 types of list?
 */

//
// The classes for our three types of table row
// By: Project, Student, Supervisor
//

export class Y4projectselectionTableItem implements Deserializable {

  choices?: Y4projectselection[];
  project?: Y4project;
  allocated?: Y4projectselection[];

  constructor(any) {
    this.deserialize(any);
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}

export class Y4studentselectionTableItem implements Deserializable {

  choices?: Y4projectselection[] = [];
  student?: Student;
  allocated?: Y4projectselection[] = [];
  groups: [] = [];
  allocatedGroups: [] = [];

  constructor(any) {
    this.deserialize(any);
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}

export class Y4supervisorselectionTableItem implements Deserializable {

  owner: Staff;
  choices: {} = {};
  allocated: [] = [];
  groups: [] = [];
  allocatedGroups: [] = [];

  constructor(any) {
    this.deserialize(any);
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  supervisorCrsid() {
    return this.owner.stfCrsid
  }

}

@Injectable({
  providedIn: 'root'
})
export class Y4ProjectChoicesAdminService {

  // eg update unallocated list after allocated student 
  private _updateStudentsSubject$ = new BehaviorSubject<number>(0);
  private _updateStudentsAction$ = this._updateStudentsSubject$.asObservable();

  private _debug = false;
  private _debug_message(msg) { if (this._debug) { console.log(msg) } }
  // how could we provide this?
  // private _debug_tap(msg) { return tap(() => this._debug_message(msg)) }

  constructor(
    private _choicesService: Y4ProjectselectionService,
    private _projectService: Y4ProjectService,
    private _studentService: StudentService,
    private _filterService: Y4ProjectChoicesAdminFiltersService) {

  }

  // This is the query shared by all our lists!!
  private courseSelections$ = this._choicesService.courseChoices$.pipe(
    tap(() => this._debug_message("building courseSelections")),
   
    );
  
  public getCourseSelections$() {
    return this.courseSelections$
  }

  // retrieves the choices for a particular project
  public getChoicesForProject$(projId: number): Observable<Y4projectselection[]> {
    return this._choicesService.projectChoices$(projId)
  }

  //
  // PROJECTS
  //
  //

  // to be joined into our lists - projects / supervisors
  private _projectsNoSelections$ = this._projectService.projectsByCoo$ // Coo has been set by a call to this._projectService.setEgt3Coo(coo);
    .pipe(
      map(projects => projects.filter(proj => proj.projSelections?.length === 0))
    );

  /**
   * retrieve choices and append missing projects
   * TODO move this to the service and call something sensible
   */
  private _projectSelectionsRetrieve$: Observable<Y4projectselectionTableItem[]> = combineLatest([
    this.courseSelections$,
    this._projectsNoSelections$
  ])
    .pipe(
      tap(() => this._debug_message("_projectSelectionsRetrieve$")),
      map(
        // use reduce to group the choices associated to a project
        // TODO: produce an interface to store the reduced array
        ([sels, projs]) => {
          const as = sels.reduce((a, p) => {
            const key = `${p.pslY4project.reference()}`;
            a[key] = a[key] ||
              { project: new Y4project().deserialize(p.pslY4project), choices: {}, allocated: [] };
            a[key].choices[p.pslSerial] = a[key].choices[p.pslSerial] || [];
            a[key].choices[p.pslSerial].push(p);
            if (p.pslAllocated) {
              a[key].allocated.push(p); // use zero serial for allocated projects
            }
            return a;
          }, {});
          //;
          //;
          // append the missing projects
          return projs.reduce((a, p) => {
            const key = `${p.reference()}`;
            a[key] = { project: new Y4project().deserialize(p), choices: {}, allocated: [] };
            return a;
          }, as);
        }
      ),
      map(sels => Object.values(sels).map(sel => new Y4projectselectionTableItem(sel))),
      // map(sels => Object.values(sels)),
      map(sels => sels.sort((a, b) => (a.project.reference() > b.project.reference()) ? -1 : 1))
    );


  // filter by group, allocated, unallocated, choices, allocation
  private projectSelectionsFilter$: Observable<Y4projectselectionTableItem[]> = combineLatest([
    this._projectSelectionsRetrieve$,
    this._filterService.groups$(),
    this._filterService.choices$(),
    this._filterService.nochoices$(),
    this._filterService.unallocatedOnly$(),
    this._filterService.allocatedOnly$(),
    this._filterService.noAgreement$(),
    this._filterService.typeAOnly$(),
    this._filterService.typeBOnly$()]).pipe(
      //
      map(
        ([sels, groups, choices, nochoices, filterUnallocated, filterAllocated, noAgreement, filterA, filterB]) =>
          this.filterSelectionIs(sels, groups, choices, nochoices)
            .filter(sel => (filterAllocated && sel.allocated.length < 1) ? false : true)
            .filter(sel => (filterUnallocated && sel.allocated.length > 0) ? false : true)
            .filter(sel => (filterA && sel.project.projType !== 'a') ? false : true)
            .filter(sel => (filterB && sel.project.projType !== 'b') ? false : true)
            .filter(sel => (noAgreement && sel.allocated.filter(s => s.pslAgreementReturned === null).length === 0) ? false : true)
      )
    );

  /**
   * For our Project centric lists (Project/Superivsor):
   *
   * Filters the choices based on latest selected groups and choices/nochoices
   * only if one choices / nochoices is set filter by this extra field
   * - trouble for student centric lists this will not include the sibling
   *   choices (ie those for other groups)this only includes
   */
  private filterSelectionIs(sels, groups, choices, nochoices) {

    let tmp = sels.filter(
      sel =>
        (groups.length > 0 &&
          !groups.includes(sel.project?.projSubjectgroupTopic.subjGroup)) ? false : true
    );

    if (choices || nochoices) {
      // if choices selected then the row must have choices
      // if nochoices has been selected the must be no choices (show unselected)
      tmp = tmp.filter(sel => (
        (nochoices && (!sel.choices || Object.keys(sel.choices).length < 1)) ||
        (choices && (sel.choices && Object.keys(sel.choices).length > 0))
      ));
    }

    return tmp;
  }

  /**
   * Returns the list of project selections
   * 
   * 
   * @returns Observable<Y4projectselectionTableItem[]>
   */
  public getProjectSelections$() {
    return this.projectSelectionsFilter$
  }





  //
  // SUPERVISORS
  //
  //

  private supervisorSelectionsPre$: Observable<Y4supervisorselectionTableItem[]> = this.courseSelections$
    // this.filterAllocatedOnly$ // only rows with allocated projects of groups
    // TODO: create an interface for the reduction
    .pipe(
      tap(() => this._debug_message("supervisorSelectionsPre$")),
      // map(([sels, groups, choices, nochoices]) => { return this.filterSelectionIs(sels, groups, choices, nochoices) }),
      map(sels => sels.reduce((a, p) => {
        const key = `${p.pslY4project.projOwner.id}`;
        a[key] = a[key] || new Y4supervisorselectionTableItem({ owner: new Staff().deserialize(p.pslY4project.projOwner) })
        a[key].choices[p.pslSerial] = a[key].choices[p.pslSerial] || [];
        a[key].choices[p.pslSerial].push(p);
        a[key].groups.push(p.pslY4project.projSubjectgroupTopic.subjGroup);
        if (p.pslAllocated) {
          a[key].allocated.push(p); // use zero serial for allocated projects
          a[key].allocatedGroups.push(p.pslY4project.projSubjectgroupTopic.subjGroup);
        }
        return a;
      }, {})),
      map(sels => Object.values(sels).map(sel => new Y4supervisorselectionTableItem(sel))),
      map(sels => sels.sort((a, b) => (a['owner'].stfCrsid > b['owner'].stfCrsid) ? -1 : 1)),
      //
    );

  // filter by group choices/nochoices unallocated/allocated
  private supervisorSelectionsFilter$: Observable<Y4supervisorselectionTableItem[]> = combineLatest([
    this.supervisorSelectionsPre$,
    this._filterService.groups$(),
    this._filterService.choicesOnly$(),
    this._filterService.nochoicesOnly$(),
    this._filterService.unallocatedOnly$(),
    this._filterService.allocatedOnly$(),
    this._filterService.noAgreement$(),
    this._filterService.reSortAllocatedSupervisor$()
  ]).pipe(
    map(
      ([sels, groups, choices, nochoices, filterUnallocated, filterAllocated, noAgreement]) =>
        this._filterStudentSelected(sels, groups, choices, nochoices, filterAllocated)
          .filter(sel => (filterUnallocated && sel.allocated.length > 0) ? false : true)
          .filter(sel => (filterAllocated && sel.allocated.length < 1) ? false : true)
          .filter(sel => (noAgreement && sel.allocated.filter(s => s.pslAgreementReturned === null).length === 0) ? false : true)
          .sort((a, b) => ((a.allocated.length - b.allocated.length) * this._filterService.sortAllocated() > 0) ? -1 : 1)
    ),
    map(sels => {
      let arr = (this._filterService.sortAllocated() === 0) ?
        sels : sels.sort((a, b) => ((a.allocated.length - b.allocated.length) * this._filterService.sortAllocated() > 0) ? -1 : 1);
      arr = (this._filterService.sortSupervisor() === 0) ?
        sels : sels.sort((a, b) => this._filterService.sortSupervisor() * ((a.owner.stfSurname > b.owner.stfSurname) ? 1 : -1));
      return arr;
    })
  );

  /**
    * Returns the list of selections by supervisor
    * 
    * 
    * @returns Observable<Y4projectselectionTableItem[]>
    */
  public getSupervisorSelections$() {
    return this.supervisorSelectionsFilter$
  }

  //
  // STUDENTS
  //
  //


  /**
   * Call this when the list of unallocated students should be updated
   */
  public updateUnallocatedStudents() {
    this._updateStudentsSubject$.next(1)
  }

  // Get all the students on the course to be joined into our lists - students
  // wait for updates 
  private studentsNoSelections$ = combineLatest([
    this._studentService.studentsOnCourse$,
    this.courseSelections$ // force recalculation of available students after selection changes
  ]).pipe(
    tap(() => this._debug_message("studentsNoSelections$")),
    map(([list, update]) => list.filter(ele => ele.stuProjectchoices.length === 0))
  );

  /**
   * Append missing students to a list of selections
   *
   * TODO move to a service cf it's sibling 'projectSelections_retrieve'
   */
  private studentSelectionsAppendmissing$: Observable<Y4studentselectionTableItem[]> = combineLatest([
    this.courseSelections$,
    this.studentsNoSelections$])
    .pipe(
      tap(() => this._debug_message("studentSelectionsAppendmissing$")),
      map(([sels, stus]) => {
        const as = sels.reduce((a, p) => {
          // TODO refactor
          // -> should be able to find allocated groups from allocated projects
          // -> should be able to find choices groups from choices
          // create an interface to store the reduction
          const key = `${p.pslStudent?.stuCrsId}`;
          if (p.pslStudent && p.pslY4project) {
            // On occasion the student recrod can be blank (manually removed from database?)
            a[key] = a[key] || new Y4studentselectionTableItem({ student: new Student().deserialize(p.pslStudent) })
            a[key].choices[p.pslSerial] = a[key].choices[p.pslSerial] || [];
            a[key].choices[p.pslSerial].push(p);
            a[key].groups.push(p.pslY4project.projSubjectgroupTopic.subjGroup);
            if (p.pslAllocated) {
              a[key].allocated.push(p); // use zero serial for allocated projects
              a[key].allocatedGroups.push(p.pslY4project.projSubjectgroupTopic.subjGroup); // use zero serial for allocated projects
            }
          }
          return a;
        }, {});
        return stus.reduce((a, p) => {
          // careful as the students with no selections may have just been given a selection / allocation;
          const key = `${p.stuCrsId}`;
          a[key] = a[key] || new Y4studentselectionTableItem({ student: new Student().deserialize(p) });
          return a;
        }, as);
      }),
      map(sels => Object.values(sels).map(sel => new Y4studentselectionTableItem(sel))),
      map(sels => sels.sort((a, b) => (a['student'].stuCrsId > b['student'].stuCrsId) ? -1 : 1)),
      tap(() => this._debug_message("the students and their choices -> including allocated reduction")),
      tap((sels) => this._debug_message(sels))
    );

  /**
   * A list of stundents who have not yet been allocated to a project
   * 
   * This list does not use the current filtering (to provide the full selection list)
   */
  public studentsUnallocated$(): Observable<Student[]> {
    return combineLatest([
      this.studentSelectionsAppendmissing$,
      this.courseSelections$]).pipe(
        tap(() => this._debug_message("studentsUnallocated$")),// force recalculation of available students after selection changes
        map(([tablerows, other]) => tablerows.filter(tablerow => tablerow.allocated.length === 0)),
        map(tablerows => tablerows.map(row => row.student))
      )
  }

  // get the list of crsids that have already been allocated
  // used when validating the bulk upload
  public allocatedStudentCrsids$: Observable<string[]> = this.studentSelectionsAppendmissing$
    .pipe(
      tap(() => this._debug_message("allocatedStudentCrsids$")),
      map(sels => sels.filter(sel => sel['allocated'].length > 0)),
      map(sels => sels.map(sel => new Student().deserialize(sel['student']))),
      map(stus => stus.map(stu => stu.stuCrsId))
      //map(sels => sels.map(sel => sel['student'].stuCrsId))sel
    );

  // filter by group choices/nochoices unallocated/allocated
  private studentSelectionsFilter$: Observable<Y4studentselectionTableItem[]> = combineLatest([
    this.studentSelectionsAppendmissing$,
    this._filterService.groups$(),
    this._filterService.choicesOnly$(),
    this._filterService.nochoicesOnly$(),
    this._filterService.unallocatedOnly$(),
    this._filterService.allocatedOnly$(),
    this._filterService.noAgreement$(),
    this._filterService.reSortStudentSurname$()
  ]).pipe( 
    tap(() => this._debug_message("studentSelectionsFilter$")),
    map(
      ([sels, groups, choices, nochoices, filterUnallocated, filterAllocated, noAgreement]) =>
        this._filterStudentSelected(sels, groups, choices, nochoices, filterAllocated)
          .filter(sel => (filterUnallocated && sel.allocated.length > 0) ? false : true)
          .filter(sel => (filterAllocated && sel.allocated.length < 1) ? false : true)
          .filter(sel => (noAgreement && sel.allocated.filter(s => s.pslAgreementReturned === null).length === 0) ? false : true)
    ),
    map(sels => {
      let sortOrder = this._filterService.sortStudentSurname()
      let arr = (sortOrder === 0) ?
        sels : sels.sort((a, b) => ((a.student.stuLastname > b.student.stuLastname)) ? -1 * sortOrder : 1 * sortOrder);
      return arr;
    })
  );

  /**
    * Returns the list of selections by supervisor
    * 
    * 
    * @returns Observable<Y4projectselectionTableItem[]>
    */
  public getStudentSelections$() {
    return this.studentSelectionsFilter$
  }
  public getAllocatedStudentCrsids$() {
    return this.allocatedStudentCrsids$;
  }
  public getNumberOfStudentsSelections$() {
    return this.studentSelectionsFilter$
      .pipe(
        map(sels => sels.length)
      );
  }
  public getNumberOfStudentsAllocated$() {
    return this.studentSelectionsFilter$
      .pipe(
        map(sels => sels.filter(sel => sel.allocated.length > 0)),
        map(sels => sels.length)
      );
  }
  public getNumberOfFilteredStudentsUnallocated$() {
    return this.studentSelectionsFilter$
      .pipe(
        map(sels => sels.filter(sel => sel.allocated.length == 0)),
        tap( (sels) => this._debug_message(sels)),
        tap( (sels => this._debug_message("unallocated: "+sels.map(sel => sel.student?.stuCrsId)+" - "+sels.length))),
        map(sels => sels.length)
      );
  }
  //
  // Utility functions
  //
  //

  /**
   * Filters the selection based on the group of itself, or siblings
   *
   * ie if one selection for this user is in group x allow all others
   */
  private _filterStudentSelected(sels: any, groups: any, choices, nochoices, allocated) {

    let tmp = sels;
    //;
    if (groups.length > 0) {
      if (allocated) {
        tmp = sels.filter(
          obj => obj.allocatedGroups?.filter(grp => groups.includes(grp)).length > 0
        );
      } else {
        tmp = sels.filter(
          obj => obj.groups?.filter(grp => groups.includes(grp)).length > 0
        );
      }

    }
    if (choices || nochoices) {
      // if choices selected then the row must have choices
      // if nochoices has been selected the must be no choices (show unselected)
      tmp = tmp.filter(sel => (
        (nochoices && (!sel.choices || Object.keys(sel.choices).length < 1)) ||
        (choices && (sel.choices && Object.keys(sel.choices).length > 0))
      ));
    }

    return tmp;
  }
}
