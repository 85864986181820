import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Assistant } from '../lib/models/assistant';
import { AssistantService } from '../lib/services/assistant.service';
import { combineLatest } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Staff } from '../lib/models/staff';

@Component({
  selector: 'app-y4-assistants-manage',
  templateUrl: './y4-assistants-manage.component.html',
  styleUrls: ['./y4-assistants-manage.component.scss']
})
export class Y4AssistantsManageComponent implements OnInit, OnDestroy {


  addAssistantForm: FormGroup;
  validator = Validators.required
  alreadyExists = false;
  subscriptions = [];

  constructor(
    private fb: FormBuilder,
    private _assistantService: AssistantService,
    private _snackBar: MatSnackBar,) {

  }
  ngOnInit(): void {
    this.addAssistantForm = this.fb.group({
      user:  new FormControl('', Validators.required)
    })
    this.subscriptions.push(this.addAssistantForm.valueChanges.pipe(
      tap(() =>
        console.log("form value changed")),
      switchMap((val) => {
          if (this.addAssistantForm.valid) {
            // when the form is valid test to see if the user already exists
            console.log("personnel key: "+this._parseForm().personnelKey())
            return this._assistantService.searchAssistants(this._parseForm().personnelKey(), 1)
          }else{  
            console.log("not valid")
          }
        })
    ).subscribe(
      (res) => {
        console.log(res)
        this.alreadyExists = (res.length > 0) ? true : false
      }
    ))
    // test whether the username is already in the asistants list and if so display

  }

  ngOnDestroy(): void {
    // TODO unsubscribe
    this.subscriptions.map(sub => sub.unsubscribe());
  }

  _parseForm() {
    const parsedForm = this.addAssistantForm.value;
    let parsedUser = new Assistant().deserializeUISLookup(parsedForm.user)

    return parsedUser
  }

  addAssistant() {
    // if the form is valida create a new user 
    // TODO We must archive any old users and generate an old proxy if required 

    let newUser = this._parseForm()

    this._assistantService.addAssistant(newUser).subscribe({
      next: (stf: Staff) => this.onSaveComplete(stf.display_name()),
      error: err => this.onSaveError(err, this.addAssistantForm.value)
    });
  }

  onSaveComplete(message: string): void {
    //this._assistantService.fetchAssistants();
    // interfering with cypress tests finding row in table
    this.openSnackBar('Successfully created user: ' + message, 'close');

  }

  onSaveError(err, p) {
    console.log("an error occured " + err);
  }

  // TODO replace with camplngx version - when written!
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
