<h3 class="mat-title">Manage key dates</h3>


<!--
<div *ngFor="let sta of [].concat.apply([], getCurrentKeyDates(4))">{{sta.date | json}}</div>
-->
<mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Current key dates
            </mat-panel-title>
            <mat-panel-description>
                View the current key dates
                <mat-icon>preview</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngFor="let sta of (currentKeyDates$(14) | async)">
            <span *ngIf="sta.type == 'today'">---- TODAY! {{sta.date.toDateString()}} ----</span>
            <span *ngIf="sta.type != 'today'">{{sta.date.toDateString()}} - <b>{{sta.type}} {{sta.sta.staCode}}:</b> - {{sta.sta.staYear}} {{sta.sta.staDescription}} </span>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngFor="let year of ( currentYears$() | async )">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Key dates dates for {{year}}
            </mat-panel-title>
            <mat-panel-description>
                View and edit details
                <mat-icon><span id="preview{{year}}">preview</span></mat-icon>
            </mat-panel-description>

        </mat-expansion-panel-header>
        <!-- attempt to use observable here failed ;-( )-->
        <!-- possibley because it was a function returning a pipe with filter statusesForYear$(year)-->
        <div *ngFor="let sta of statusesForYear(year)" >
             <b>{{sta.staCode}}</b> ( {{sta.staDescription}} ) 
             Start: <span id="start_{{sta.staYear}}_{{sta.staCode}}">{{sta.startDate().toDateString()}}</span>, 
             End: <span id="end_{{sta.staYear}}_{{sta.staCode}}">{{sta.endDate().toDateString()}}</span> 
             <button mat-icon-button 
             aria-label="Button to edit key date details" 
             matTooltip="Edit details for {{sta.staCode}}"><mat-icon   (click)="editKeydateDialog(sta)" class="{{sta.staCode}}_{{sta.staYear}}_edit">edit</mat-icon></button>
        </div>
    </mat-expansion-panel>
</mat-accordion><br/>
<button mat-raised-button color="primary" (click)="populateStatusesAction()">Populate key dates for {{ (latestYear$|async)+1}}</button>

<!--<div *ngFor="let sta of getCurrentStatuses()">{{sta.info()}}</div>-->
<!-- list all the years that have end dates after this year -->
