<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<mat-tab-group (selectedTabChange)="tabClick($event)">
    <mat-tab>
        <ng-template matTabLabel>
            <button class="nav-back" mat-button *ngIf="(previousProject$ | async)"
                matTooltip="View {{ (previousProject$ | async)?.reference() }}"
                aria-label="Button navigates to the previous project in the list"
                (click)="viewPreviousProject()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            Browse
            <button class="nav-forward" mat-button *ngIf=" (nextProject$ | async) "
                matTooltip="View {{(nextProject$ | async)?.reference() }}"
                aria-label="Button navigates to the next project in the list" (click)="viewNextProject()">
                <mat-icon>arrow_forward</mat-icon>
            </button>
        </ng-template>
        <!-- TODO sort this section out, valign and refactor into components? -->
        <mat-card class="highlight_card"  *ngIf="! ( ( currentProject$ | async )?.allocatedTo().length > 0 ) ">
            <div *ngIf="(projectIsAChoice$ | async)" class="valign">
                <div class="valign-center">
                    <mat-icon>star_border</mat-icon> <span class="md-body-2">You have selected this project</span>
                </div>
                <div class="set-right">
                    <button class="removeChoice" *ngIf="(projectIsAChoice$ | async)" mat-raised-button
                        (click)="removeChoice()"  color="warn" >Remove this selection</button>
                </div>
            </div>

            <div *ngIf="( (projectIsAChoice$ | async)  === false ) && (selectionOpen$ | async) " class="valign">
                <div class="valign-center">
                    <span class="md-body-2">&nbsp;</span>
                </div>
                <div class="set-right">
                    <button  class="addChoice" mat-raised-button color="primary" (click)="addProjectChoice()">Select this project 
                        <span *ngIf="project?.projSelections?.length !=1; else one_student">( {{project?.projSelections?.length}} students already have)</span> 
                        <ng-template #one_student>(1 student already has)</ng-template>
                    </button>
                </div>
            </div>
        </mat-card>

        <app-y4-project-summary [noIndustrial]="true" ></app-y4-project-summary>

    </mat-tab>

    <mat-tab *ngIf=" (selectionOpen$ | async)" id="choiceBasket">
        <ng-template matTabLabel>
            <span *ngIf="!(valid$|async)?.status" class="valign-center choices-invalid" [matTooltip]="'Choices are not valid '+(valid$|async)?.message">
                <mat-icon>report_problem</mat-icon>
            </span>
            <span matBadge="{{ (choices$ | async )?.length}}" matBadgeOverlap="false">Your choices</span>
        </ng-template>
        <app-y4-project-selection-basket [context]="'dialog'" [dialogAlerts]="false"></app-y4-project-selection-basket>
    </mat-tab>
</mat-tab-group>
