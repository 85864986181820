import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, of, BehaviorSubject } from 'rxjs';
import { Staff } from '../models/staff';
import { HttpClient } from '@angular/common/http';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppService } from './app.service';
import { Role } from '../models/role';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})

/**
 * This service provides a staffList$ of staff entries
 *
 * staffList$ is produced and filtered by setting / calling various methods:
 *
 * Initially staffList$ will return staff unfiltered with limit provided by @private {appendlimit}
 *
 * Calling @function {searchBy} - staffList$ updated with staff whose surname begin with @param {query}
 *
 * Other functions/observables available (these use and will trigger further HTTP requests):
 *
 * @function {search} - returns an observable of staff records
 * @public {allStaff$} - list of all staff
 *
 * Access to staffList$ will be restricted by the api
 *
 */
export class StaffService {

  constructor(
    private http: HttpClient,
    pageMessage: CamplNgxMessageBufferService,
    private errorService: ErrorService
  ) {
  }
  // This is a read only service
  // available to a subset of users; to prevent misuse (access to staff lists)

  // TODO: update to api/v1/staff B.E. change required
  baseurl = '/api/v1/staff';
  baseapiurl = '/api/v1/staff';
  appendlimit = '?limit=1000'; // used to

  // Update this to trigger new retrieval (getStaff(filter))
  private filterSubject = new BehaviorSubject<string>('');
  public filterObs$ = this.filterSubject.asObservable();

  // list of CURRENT staff members matching the current filter (by default set to '')
  staffList$ = this.filterObs$.pipe(
    switchMap(f => {
      //const filter = (f === '') ? '?limit=10' : '?limit=200&where={"stfSurname":{"startsWith":"' + f + '"},"stfCancelled":0}';
      //return this.http.get<Staff[]>(this.baseurl + filter);
      const filter = (f === '') ? '?limit=10' : '?limit=200&where={"stfSurname":{"startsWith":"' + f + '"},"stfCancelled":0}';
      return this.http.get<Staff[]>(this.baseapiurl + '' + filter)
    }),
    map(res => res.map((staff: Staff) => new Staff().deserialize(staff))),
    map(res => res.sort((a, b) => a.stfSurname.localeCompare(b.stfSurname)))
  );


  /**
   * @returns an Observable of all Staff records
   * TODO where is this used?
   */
  public allStaff$ = this.http.get<Staff[]>(this.baseurl + this.appendlimit).pipe(
    map(list => list.map(staff => new Staff().deserialize(staff)))
  );

  // To allow use to spy on the observable
  // can we move into a mock object?
  // TODO: use this rather than this.staffList$ directly
  getStaffList$() {
    return this.staffList$;
  }

  /**
   * Triggers the list to updates the query to retrieve records for
   *
   * @param query - the string to search surname for
   */
  searchBy(query: string) {

    const max = 6;
    if (query.length <= max) {
      // we leave the majority of filtering during the map
      // (in an attempt to reduce the number of http requests)
      this.getStaff(query);
      // this.filterByStringSubject$.next(query);
    }
  }

  // runs on component initialization
  //
  init(): void { // Observable<Staff[]> {

  }

  /**
   *
   * Returns an observable of staff records whose surname contains the @param crsidname
   *
   * @param crsidname - surname must contain this value
   * @param limit - max records to return
   */
  search(crsidname: string, limit: number): Observable<Staff[]> {
    const f = (typeof crsidname === 'string') ?
      '?limit=' + limit +
      '&where={"stfCancelled":0,"or":[{"stfSurname":{"contains":"'
      + crsidname + '"}},{"stfCrsid":"' + crsidname + '"}]}' : '';

    return this.http.get<Staff[]>(this.baseurl + f).pipe(
      map(list => list.map(staff => new Staff().deserialize(staff)))
    );
  }

  // moved to assistantService
  // /**
  //  * Returns a list of Assistants
  //  * 
  //  * This list is a super set of staff who can propose projects
  //  * as it include postgrad users (who may have stfCancelled)
  //  * 
  //  * TODO: place in a seperate service assistantService?
  //  * 
  //  * @param filter 
  //  */
  // searchAssistants(crsidname: string, limit: number): Observable<Staff[]> {
  //   const f = (typeof crsidname === 'string') ?
  //     '?limit=' + limit + '&crsidname=' + crsidname : '';

  //   return this.http.get<Staff[]>(this.baseurl + '/assistants' + f).pipe(
  //     map(list => list.map(staff => new Staff().deserialize(staff)))
  //   );
  // }

  /**
   * Retrieve staff whose surname starts with filter
   *
   * @param filter - string containing partial name
   */
  getStaff(filter?: string) {
    this.filterSubject.next(filter);
  }

  /**
   * Add role to a staff member
   * Create a user if not exists
   *
   * @param staffid - staff id of the user
   * @param role - the role (object) we wish to add
   */
  addRole(staffid: number, sgroup: string, role: Role) {
    // we are passing a staff if here not a user id!
    return this.http.post<any>(this.baseurl + '/addrole', { sid: staffid, rid: role.id, sgroup }).pipe(
      catchError(this.errorService.handleErrorPage('Adding a Role'))
    );
    /// staff/addrole -> will check to see if the staff record has a user!
    // return this.http.post<Auth>(this.baseurl+"/addrole",{user:userid, role:role.id})
  }
  /**
   * clear the list by sending an impossible surname
   */
  cleanList() {
    this.getStaff('Some34 impossible12 surnamename');  //
  }

}
