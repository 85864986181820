import { Component, OnInit } from '@angular/core';
import { AuthService } from '../lib/services/auth.service';

/**
 * This component is triggered directly from a route.
 *
 * The template confirms that the session has ended
 * TODO: check it has ended before displaying the session ended message
 */
@Component({
  selector: 'app-stop-impersonating',
  templateUrl: './stop-impersonating.component.html',
  styleUrls: ['./stop-impersonating.component.css']
})
export class StopImpersonatingComponent implements OnInit {

  constructor( private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.stopImpersonating();
  }

}
