
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject, ReplaySubject, BehaviorSubject, combineLatest, merge } from 'rxjs';
import { catchError, concatMap, map, scan, share, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { Y4projectselection } from '../models/y4projectselection';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { Student } from '../models/student';
import { Y4project } from '../models/y4project';
import { AuthService } from './auth.service';
import { Y4ProjectService } from './y4-project.service';
import { AppService } from './app.service';
import { Allocation } from '../models/allocation';
import { ErrorService } from './error.service';

@Injectable({
    providedIn: 'root'
})
export class Y4ProjectselectionService {


    private _debug = false;
    private _debug_message(msg) { if (this._debug) { console.log("y4-projectselections.service: "+msg) } }

    constructor(
        private http: HttpClient,
        private userService: AuthService,
        private projectsService: Y4ProjectService,
        private errorService: ErrorService
    ) { }

    baseurl = '/api/v1/projectselection';
    // TODO append url to variable names
    baseurl1 = 'api/v1/choices';
    coursechoices = '/api/v1/choices/list';
    studentchoices = '/api/v1/choices/studentlist'; // populates the associated objects (y4project)
    bulkallocation = 'api/v1/choices/bulkallocation';

    preallocated = 'api/v1/choices/preallocated';

    valid = 'api/v1/choices/valid';
    validchoices = this.valid + '/1';
    invalidchoices = this.valid + '/0';

    // projectchoices = '/api/choices/projectlist' // populates the associated objects (y4project)

    lim = '?limit=3000'; // lim = "?cooOfferingYear=2019"; // limit=3000" // if not set the default is 30!

    // We could remove these a replace with just a single update_selections subject?
    // private removed_selection_subject = new BehaviorSubject<number>(0);
    // removed_selection_action$ = this.removed_selection_subject.asObservable();

    // private added_selection_subject = new BehaviorSubject<number>(0);
    // added_selection_action$ = this.added_selection_subject.asObservable();

    // todo change back to private!!
    private updatedSelectionSubject = new BehaviorSubject<number>(0);
    updatedSelectionAction$ = this.updatedSelectionSubject.asObservable();

    // Changes triggered to the choices list
    public choicesUpdated$ = // combineLatest([
        // this.removed_selection_action$,
        // this.added_selection_action$,
        this.updatedSelectionAction$.pipe(
            tap(() => this._debug_message("update the choices (updatedSelectionsAction)"))
        );
    // ]);

    validChoices$ = this.http.get<Student[]>(this.validchoices);
    invalidChoices$ = this.http.get<Student[]>(this.invalidchoices);


    // A single choice row
    private currentlyBrowsingChoiceSubject$ = new BehaviorSubject<Y4projectselection>(null);
    public currentlyBrowsingChoice$ = this.currentlyBrowsingChoiceSubject$.asObservable();

    /**
     * Get the student's choices from a choice
     *
     * Order by serial
     *
     * Used in the allocation-dialog
     */
    public currentAdjacentChoices$ = this.currentlyBrowsingChoice$
        .pipe(
            switchMap(cho => this.studentChoices(cho.pslStudent.id)),
            map(choices => choices.map(choice => {
                const cho = new Y4projectselection().deserialize(choice);
                cho.pslY4project = new Y4project().deserialize(cho.pslY4project); // TODO fix dependancies
                return cho;
            })),
            map((choices: Y4projectselection[]) => choices.sort((a, b) => a.pslSerial - b.pslSerial))
        );

    // the EGT3Course we are observing
    private y4projectCourse$ = this.projectsService.egt3CooAction$;



    // NB course_choices does not include non-selected projects!
    // triggered when we have the EGT3 course selected
    /**
     * Gets all the selectionc choices for a course.
     *
     * The course is provided by this.y4projectCourse$
     * The list is updated when this.choicesUpdated$
     *
     * (several methods cause the toggle)
     *
     */
    public courseChoices$ = combineLatest([
        this.y4projectCourse$,
        this.choicesUpdated$])
        .pipe(
            switchMap(([coo, mod]) => this.http.get<Y4projectselection[]>(this.coursechoices + '?cooid=' + coo.id + '&' + this.lim).pipe(
                map(res => res.map((pr: Y4projectselection) => {
                    const prRet = new Y4projectselection().deserialize(pr);
                    prRet.pslY4project = new Y4project().deserialize(pr.pslY4project); // todo place in object function
                    return prRet;
                }
                )
                )))
            , shareReplay()
        );


    student$ = combineLatest([
        this.userService.me$,
        this.choicesUpdated$]).pipe(
            map(([user]) => user.egt3Student())
        );
    /**
     * List of current user's (egt3 student's) Y4project choices
     * see y4-project-selection-basket, y4-project-dialog
     */

    choicesStudent$ = this.student$.pipe(
        switchMap((stu) => this.studentChoices(stu.id)
            // return this.http.get<Y4projectselection[]>(this.baseurl + this.lim + "&pslStudent=" + stu.id)
            .pipe(
                map(res => res.map((pr: Y4projectselection) =>
                    (new Y4projectselection().deserialize(pr)).deserializeProject()
                )
                    .sort((a, b) => (a.pslSerial > b.pslSerial) ? 1 : -1))
            ))
    );

    /**
     * Given the student and after each uchocie update
     * check to see if any of their choices have been preallcoated
     *
     * Join these records with those found in the choicesStudent
     */
    preallocatedStudentChoices$ = this.student$.pipe(
        switchMap((stu) => this.http.get<Y4projectselection[]>(this.preallocated + '?cooid=' + stu.stuCourseOffering.id))
    );


    /**
     * Tests whether the choices are valid for the current student
     *
     * updated each time the choices are updated
     */
    public choicesStudentValid$ =
        combineLatest([this.userService.me$, this.choicesUpdated$])
            .pipe(
                switchMap(([me]) =>
                    this.http.get<any>(this.validchoices + '/' + me.egt3Student().stuCourseOffering.id + '/' + me.egt3Student().id)
                ),
                map((res) =>
                    (res.results.length > 0) ?
                        { status: true, message: 'Your choices are valid!' } : { status: false, message: res.message }


                )
            );


    httpOptions; // ?? from https://angular.io/tutorial/toh-pt6

    /**
     * Returns an observable from http request
     *
     * list of choices with populated associated projects
     *
     * @param stuid id of student associated to the choices
     */
    public studentChoices(stuid: number) {
        return this.http.get<Y4projectselection[]>(this.studentchoices + '/' + stuid);
    }

    /**
     * Returns an observable from http request
     *
     * list of choices for a project with associated populated projects
     *
     * @param projid id of project associated to the choices
     */
    public projectChoices$(projid: number): Observable<Y4projectselection[]> {
        return this.http.get<Y4projectselection[]>(this.baseurl + '?pslY4project=' + projid);
    }

    /**
     * Set the Subject value to be the parameter value
     *
     * @param sel choice to set as the currently browsing item
     */
    public setBrowseChoiceValue(sel: Y4projectselection) {
        this.currentlyBrowsingChoiceSubject$.next(sel);
    }

    /**
     * touches the selections for the user to re-pull
     *
     */
    touchSelections() {
        this.updatedSelectionSubject.next(1);
    }

    /**
     * Updates a project selection
     *
     * and triggers the selection_student to update
     *
     * @param psl - the project selection to patch
     */
    updateSelection(psl: Y4projectselection) {
        // TODO de-reference the psl obeject then override?!
        // thsi way we will not miss other fields if psl is modified
        this.updateSelectionObs(psl).subscribe(
            res => {
                this.updatedSelectionSubject.next(1);
                // this.projectsService.triggerProjectsUpdate(); - not responsive enough
            }
        );
    }

    raiseSelection(psl: Y4projectselection) {
        return this.http.patch<Y4projectselection[]>(this.baseurl1 + '/shiftpriority/' + psl.id + '/up', {}, this.httpOptions)
            .pipe(take(1)).subscribe(
                () => { this.updatedSelectionSubject.next(1) }
            );
    }

    lowerSelection(psl: Y4projectselection) {
        return this.http.patch<Y4projectselection[]>(this.baseurl1 + '/shiftpriority/' + psl.id + '/down', {}, this.httpOptions)
            .pipe(take(1)).subscribe(
                () => { this.updatedSelectionSubject.next(1) }
            );
    }

    /**
     * Sets the selection to be the allocated choice
     *
     * @param psl The selection to set as the allocated choice
     */
    // public allocateSelection(psl: Y4projectselection) {
    //    this.updateSelection(psl);
    // }

    /**
     *
     * @param psl - project selection
     * @returns Obs,Y4projectselection
     */
    public allocateSelectionObs(psl: Y4projectselection) {
        psl.pslAllocated = true;
        return this.updateSelectionObs(psl).pipe(map(res => this.updatedSelectionSubject.next(1)));

    }
    public updateSelectionObs(psl: Y4projectselection) {
        const body = {
            pslStudent: psl.pslStudent.id,
            pslY4project: psl.pslY4project.id,
            pslSerial: psl.pslSerial,
            id: psl.id,
            pslAllocated: psl.pslAllocated,
            pslAgreementReturned: psl.pslAgreementReturned
        };
        return this.http.patch<Y4projectselection[]>(this.baseurl + '/' + psl.id, body, this.httpOptions);
    }
    /**
     * Removes the selection as the allocated choice for this user
     *
     * @param psl The selection to set as the allocated choice
     */
    unAllocateSelection(psl: Y4projectselection) {
        psl.pslAllocated = false;
        psl.pslAgreementReturned = null;
        this.updateSelection(psl);
    }

    /**
     * Add selection calls the http.post function
     *
     * It then initiates an update of the selections via updatedSelectionSubject
     *
     * Clients can therefore listent for the updates by subscribing to the public observables
     *
     * eg (y4-project-selection-dialog, where valid$ = choicesStudentValid$ )
     *
     * return combineLatest([this.valid$, this.testIsValidAction$]).subscribe(([val, active]) => {
     *  if (active == 1 && !val['status']) {
     *   // trigger the dialog
     *   const dialogRef = this.dialog.open(Y4ProjectChoicesStatusDialogComponent, {
     *     //width: '250px',
     *     data: { status: val['status'], message: val['message'] }
     *   });
     * }
     * })
     *
     * @param sel - the selection to remove
     */
    addSelection(student: Student, project: Y4project) {
        const body = { pslStudent: student.id, pslY4project: project.id };
        return this.http.post<Y4projectselection>(this.baseurl, body, this.httpOptions).pipe(
            // tap((newHero: Hero) => this.log(`added hero w/ id=${newHero.id}`)),
            catchError(this.errorService.handleErrorPage<any>('Adding Project Selection ' + JSON.stringify(project))),
            map(del => this.updatedSelectionSubject.next(1)) // the client needs to subscribe to choicesStudentValid$
        );
    }

    /**
     * Remove selection calls the http.delete function
     *
     * Choice selection status is managed cf this.addSelection
     *
     * @param sel - the selection to remove
     */
    removeSelection(sel: any): any { //Y4projectselection) {
        return this.http.delete<any>(this.baseurl1 + '/delete/' + sel.id, this.httpOptions)
            .pipe(
                //catchError(this.errorService.handleErrorPage<any>('Removing selection ' + JSON.stringify(sel))),
                map(del => this.updatedSelectionSubject.next(1))  // the client needs to subscribe to choicesStudentValid$
            );
    }

    /**
     * Insert allocations for students / projects in bulk
     *
     * See backend for explanation of logic
     */
    uploadAllocations(allocations: Allocation[], cooid: number) {
        return this.http.post(this.bulkallocation, { allocations, cooid }).pipe(
            tap( () => this._debug_message("uploaded Allocations")),
            map( () => this.updatedSelectionSubject.next(1)),
            catchError(err =>
                // this.errorService.getPageMessage().sendMessage("ERROR: "+JSON.stringify(err))
                // //;
                // return of("ERROR WAS 'ERE")
                this.errorService.handleErrorToPage('Uploading allocations' + JSON.stringify(err))
            )
            // this.errorService.handleErrorPage('Uploading allocations' + JSON.stringify(err)))
        );
    }


    /**
     * Retrieve the selections for an owner and a course
     */
    //ownerCourse(ownerid: number, courseid: number) {
    //    return this.http.get<Y4projectselection[]>(this.baseurl + '?pslY4project=' + projid);
    //}
}
