<mat-card>
    <mat-card-title>Choices updated</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
    <mat-card-content *ngIf="!data.status; else valid_choices">
        <h3>Please continue with your choices as they are not yet ready to submit</h3>
        <div>
            {{data.message}}
        </div>
    </mat-card-content>
    <ng-template #valid_choices>
        <h3 class="valign-center">Your choices are valid for submission &nbsp; <mat-icon>insert_emoticon</mat-icon>.</h3>
    </ng-template>

    <mat-card-actions>
        <button mat-raised-button class="cancel-button" color="primary" (click)="okay()">
            OK
        </button>
    </mat-card-actions>
</mat-card>