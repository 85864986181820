import { Deserializable } from './deserializable.model';

import { Y4project } from './y4project';
export class Sgtopic implements Deserializable {

    id: number;
    subjGroup?: string;
    subjGroupTopic?: string;
    subjActive?: boolean;
    y4projects?: Y4project[];
    constructor(
        id?: number) {
        this.id = id;
    }
    //     public subjGroup?: string,
    //     public subjGroupTopic?: string,
    //     public subjActive?: boolean,
    //     public y4projects?: Y4project[]
    // ){}

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    label(): string{
        return this.subjGroup.replace('Group ','')+': '+this.subjGroupTopic;
    }
}
