import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject, combineLatest } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CourseOffering } from '../models/courseoffering';
import { AppService } from './app.service';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { ErrorService } from './error.service';


/**
 * A service to provide lists of courses
 *
 * Currently not tied to Y4Projects
 */
@Injectable({
  providedIn: 'root'
})
export class CourseofferingService {

  // todo change to v1 - (blueprints:prefix)
  baseurl = '/api/v1/courseoffering';
  appendlimit = '?limit=10001';

  private cooLikeSubject$ = new BehaviorSubject('');
  public cooLike$ = this.cooLikeSubject$.asObservable().pipe(
    map(coolike => (coolike !== '') ? '&where={"cooName":{"contains":"' + coolike + '"}}' : ''));

  /**
   * Returns the course offerings whice match the value set from the cooLikeSubject
   * (called in the @function {init()} )
   *
   * This will allow the service to be extended to further courses - if sensible to do so
   */
  public offeringsLike$ = this.cooLike$.pipe(
    switchMap(where => this.http.get<CourseOffering[]>(this.baseurl + this.appendlimit + where)),
    map(offerings => offerings.map((coo: CourseOffering) => new CourseOffering().deserialize(coo))),
    shareReplay(1)
  );

  // Todo refactor to offeringsList$
  list$ = this.offeringsLike$;

  constructor(
    private http: HttpClient,
    pageMessage: CamplNgxMessageBufferService,
    private errorService: ErrorService
  ) { }


  /**
   * Returns a full list of course stored
   *
   * If our database stores the undergraduate courses this will be included here
   *
   * TODO: review the use of this in the Y4Project application, can we use offeringsLike$ instead?
   * (y4-project-choices-admin)
   */
  public courseOfferings$ = this.http.get<CourseOffering[]>(this.baseurl + this.appendlimit).pipe(
    map(res => res.map((coo: CourseOffering) => new CourseOffering().deserialize(coo))
    ),
    shareReplay(1)
  );

  /**
   * runs on app startup (app.component) - so our data is ready
   *
   * @param like - match string in the course name
   *
   * TODO: refactor the method name so the call is easier to find
   */
  // Todo find where this is called are we ocalling it too much?
  init(like: string = ''){
    this.cooLikeSubject$.next(like);
  }

  /**
   * TODO: add a method to trigger reload after eg keydates modified 
   */

  /**
   * Return course for year
   *
   * @param year - YYYY
   */
  courseForNextYear$(year$: Observable<number>){
    return combineLatest([year$, this.courseOfferings$]).pipe(
      map( ([year, coos]) => (coos
            .filter(coo => coo.cooOfferingYear === (year + 1)))[0]
    ));
  }

  /**
   * Return a list of active courses (This should be one max)
   * 
   * Used in has-selection-active guard
   */
  selectionActiveCourses$(){
    return this.courseOfferings$.pipe(
      map( coos => coos.filter(coo => coo.getStatus('SELECTION') === 'ACTIVE') )
    )
  }
}
