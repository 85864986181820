import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Y4ProjectslistComponent } from './y4-projectslist/y4-projectslist.component';
import { AuthGuard } from './lib/guards/auth.guard';
import { SelectionActiveGuard } from './lib/guards/selection-active.guard';
import { HasSelectionActiveGuard } from './lib/guards/has-selection-active.guard';
import { IsStaffGuard } from './lib/guards/is-staff.guard';
import { SelectionOrBrowseActiveGuard } from './lib/guards/selection-or-browse-active.guard';
import { TOAdminActiveGuard } from './lib/guards/toadmin-active.guard';
import { ManageY4projectsActiveGuard } from './lib/guards/manage-y4projects-active.guard';
import { ApprovalActiveGuard } from './lib/guards/approval-active.guard';
import { Y4ProjectEditorComponent } from './y4-project-editor/y4-project-editor.component';
import { SubjectGroupTopicEditComponent } from './subject-group-topic-edit/subject-group-topic-edit.component';
import { SubjectGroupTopicListComponent } from './subject-group-topic-list/subject-group-topic-list.component';
import { KeydatesComponent } from './keydates/keydates.component';
import { Y4ProjectSelectionBasketComponent } from './y4-project-selection-basket/y4-project-selection-basket.component';
import { Y4ProjectsbrowseComponent } from './y4-projectsbrowse/y4-projectsbrowse.component';
import { LoginComponent } from './login/login.component';
import { NoaccountComponent } from './noaccount/noaccount.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { LogoutComponent } from './logout/logout.component';
import { StopImpersonatingComponent } from './stop-impersonating/stop-impersonating.component';
import { Y4ProjectChoicesAdminComponent } from './y4-project-choices-admin/y4-project-choices-admin.component';
import { RolesAdminComponent } from './roles-admin/roles-admin.component';
import { IndexComponent } from './index/index.component';
import { Y4ProjectApprovalListComponent } from './y4-project-approval-list/y4-project-approval-list.component';
import { Y4ProjectPreallocatedChoicesComponent } from './y4-project-preallocated-choices/y4-project-preallocated-choices.component';
import { Y4AssistantsManageComponent } from './y4-assistants-manage/y4-assistants-manage.component';
import { Y4AccountsSyncPageComponent } from './y4-accounts-sync-page/y4-accounts-sync-page.component';
import { Y4ExampleProjectsComponent } from './y4-example-projects/y4-example-projects.component';

const routes: Routes = [{
  path: 'fe', canActivateChild: [AuthGuard],
  children: [
    { path: 'approval', component: Y4ProjectApprovalListComponent, canActivate: [ApprovalActiveGuard] },
    { path: 'choices', component: Y4ProjectSelectionBasketComponent, canActivate: [SelectionActiveGuard], data: {  displayBUpload: true } },
    { path: 'manage', component: Y4ProjectslistComponent, canActivate: [ManageY4projectsActiveGuard] },
    // { path: 'manage/:year', component: Y4ProjectslistComponent, canActivate: [ManageY4projectsActiveGuard] },
    { path: 'manage/assistants', component: Y4AssistantsManageComponent, canActivate: [IsStaffGuard] },
    { path: 'admin/choices', component: Y4ProjectChoicesAdminComponent, canActivate: [IsStaffGuard] }, // add an admin guard
    { path: 'admin/choices/:year', component: Y4ProjectChoicesAdminComponent, canActivate: [IsStaffGuard] }, // add an admin guard
    { path: 'admin/roles', component: RolesAdminComponent, canActivate: [TOAdminActiveGuard] },
    // User must be admin and there must be an active selection period
    { path: 'admin/preallocated', component: Y4ProjectPreallocatedChoicesComponent, canActivate: [TOAdminActiveGuard, HasSelectionActiveGuard] },
    { path: 'admin/syncsettings', component:Y4AccountsSyncPageComponent , canActivate: [TOAdminActiveGuard] },
    { path: 'listprojects', component: Y4ProjectsbrowseComponent, canActivate: [SelectionOrBrowseActiveGuard] },
    { path: 'listprojects/:id', component: Y4ProjectsbrowseComponent, canActivate: [SelectionOrBrowseActiveGuard] },
    // { path: 'y4project/:id/edit', component: Y4ProjectEditorComponent },
    { path: 'subjectgrouptopic/:id/edit', component: SubjectGroupTopicEditComponent },
    { path: 'subjectgrouptopic', component: SubjectGroupTopicListComponent },
    { path: 'keydates', component: KeydatesComponent },
    { path: 'impersonate', component: ImpersonateComponent, canActivate: [TOAdminActiveGuard]},
    { path: 'impersonate/stop', component: StopImpersonatingComponent },
    { path: 'index', component: IndexComponent },
    { path: 'exampleprojects', component: Y4ExampleProjectsComponent },
    // { path: 'impersonate/:action', component: ImpersonateComponent },
  ],
},
{
  path: 'public',
  children: [
    { path: 'login', component: LoginComponent },
    { path: 'noaccount', component: NoaccountComponent },
    {
      // here we use our externalUrlRedirectResolver from app.module
      path: 'rlogin',
      component: IndexComponent, // dummy component not used
      resolve: {
        url: 'externalUrlRedirectResolver'
      },
      data: {
        externalUrl: '/auth/google'
      }
    },
    {
      path: 'logout', component: LogoutComponent, // dummy component not used
      resolve: {
        url: 'externalUrlRedirectResolver'
      },
      data: {
        externalUrl: '/public/login' // triggers a refresh
      }
    } // redirect to `first-component`
  ]
},
{ path: 'index', redirectTo: '/fe/index', pathMatch: 'full' },
{ path: '', redirectTo: '/fe/index', pathMatch: 'full' },
{ path: 'home', redirectTo: '/fe/index', pathMatch: 'full' }, ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class IibpAppRoutingModule { }
