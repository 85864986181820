import { Directive, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { StatusesService } from '../services/statuses.service';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[appAllocationActive]'
})
export class AllocationActiveDirective implements OnInit, OnChanges{
  /**
   * Allocation active will test whether or not we app status allows us to (pre)allocate choices
   * 
   * Valid when Y4PREALLOC is open or after end of SELECTION 
   */

  private _courseYear: number
  private _canAllocate = false
  private _today: Date;
  @Input('appAllocationActive') set courseYear(courseYear: number) {

    this._courseYear = courseYear;
    this._today = new Date()

  }


  _updateView() {

    if (this._canAllocate) {
      this.viewContainer.clear(); //FIXME: if don't cear we may end up with two!
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }

  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private _statusService: StatusesService) { }

  ngOnInit() {
    this.actionCourseYear();
  }
  ngOnChanges(changes) {
    if (changes.courseYear) {
      this._courseYear = changes.courseYear.currentValue
      this.actionCourseYear();
    }
  }
  actionCourseYear(){
    this._statusService.list$.pipe(take(1))
      .subscribe((statuss) => {
        // Have we got a SELECTION closed
        let postSelection = statuss.filter(sta => (
          sta.staYear === this._courseYear &&
          sta.staCode === 'SELECTION' &&
          new Date(sta.staEndsOn) < this._today)).length > 0 ? true : false
        // Have we got a Y4PREALLOC active
        let preAllocation = statuss.filter(sta => (
          sta.staYear === this._courseYear &&
          sta.staCode === 'Y4PREALLOC' &&
          sta.staEndsOn > this._today &&
          sta.staStartsOn < this._today
        )).length > 0 ? true : false

        this._canAllocate = (postSelection || preAllocation)
        this._updateView();
      });
  }
}
