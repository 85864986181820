import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.

import { BrowserModule } from '@angular/platform-browser';
import { Injectable,
         Injector,
         InjectionToken,
         ErrorHandler,
         NgModule,
         Inject} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IibpAppRoutingModule } from './iibp-app-routing.module';
import { IibpAppComponent } from './iibp-app.component';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { Y4ProjectslistComponent } from './y4-projectslist/y4-projectslist.component';
import { Y4ProjectEditorComponent } from './y4-project-editor/y4-project-editor.component';
import { SubjectGroupTopicEditComponent } from './subject-group-topic-edit/subject-group-topic-edit.component';

import { environment } from '../environments/environment';
// import { CamplNgxModule } from 'campl-ngx';
import { CamplNgxModule, CuedSharedModule, CuedMaterialsModule, CuedWidgetsModule } from 'cued-lib'; //, CuedAuthModule, CuedWidgetsModule } from 'cued-lib';
import { SubjectGroupTopicListComponent } from './subject-group-topic-list/subject-group-topic-list.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Y4ProjectsTableComponent } from './y4-projects-table/y4-projects-table.component';
import { KeydatesComponent, KeydatesCopyyearDialogComponent } from './keydates/keydates.component';
import { KeydateEditorDialogComponent } from './keydate-editor-dialog/keydate-editor-dialog.component';
import { Y4ProjectSummaryComponent } from './y4-project-summary/y4-project-summary.component';
import { Y4ProjectsbrowseComponent } from './y4-projectsbrowse/y4-projectsbrowse.component';
import { Y4ProjectSelectListingComponent } from './y4-project-select-listing/y4-project-select-listing.component';
import { LoginComponent } from './login/login.component';
import { NoaccountComponent } from './noaccount/noaccount.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { LogoutComponent } from './logout/logout.component';
import { StopImpersonatingComponent } from './stop-impersonating/stop-impersonating.component';
import { Y4ProjectSelectionBasketComponent } from './y4-project-selection-basket/y4-project-selection-basket.component';
import { Y4ProjectSelectionListingComponent } from './y4-project-selection-listing/y4-project-selection-listing.component';
import { Y4ProjectSelectDialogComponent } from './y4-project-select-dialog/y4-project-select-dialog.component';
import { Y4ProjectChoicesAdminComponent } from './y4-project-choices-admin/y4-project-choices-admin.component';
import { Y4ProjectChoiceAllocationDialogComponent } from './y4-project-choices-admin/y4-project-choice-allocation-dialog/y4-project-choice-allocation-dialog.component';
// import { AreyousureDialogComponent } from './areyousure-dialog/areyousure-dialog.component';
import { RolesAdminComponent } from './roles-admin/roles-admin.component';
import { AddRoleDialogComponent } from './add-role-dialog/add-role-dialog.component';
import { HasPermDirective } from './lib/directives/has-perm.directive';
import { IndexComponent } from './index/index.component';
import { Y4ProjectlistAccordionComponent } from './y4-projectlist-accordion/y4-projectlist-accordion.component';
import { Y4OwnerPageComponent } from './y4-owner-page/y4-owner-page.component';
import { Y4ProjectApprovalListComponent } from './y4-project-approval-list/y4-project-approval-list.component';
import { Y4ProjectApprovalDialogComponent } from './y4-project-approval-dialog/y4-project-approval-dialog.component';
import { Y4ProjectStudentCreateComponent } from './y4-project-student-create/y4-project-student-create.component';
import { Y4ProjectCopyDialogComponent } from './y4-project-copy-dialog/y4-project-copy-dialog.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { Y4ProjectSummaryDialogComponent } from './y4-project-summary-dialog/y4-project-summary-dialog.component';
//import { Nl2brPipe } from './nl2br.pipe';

// import { CuedSharedModule } from 'cued-lib/src/lib/cued-shared';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// import { CuedMaterialsModule } from '../../../../projects/cued-materials/src/lib/cued-materials.module';
//import { HttpErrorInterceptor } from '../../../cued-shared/src/lib/interceptors/http-error.interceptor';

//import { AuthService } from '../lib/services/auth.service';

//import { Y4RollbarHttpInterceptor } from './y4-rollbar-http.interceptor.ts.brkn'

// TODO extract into a seperate file

import { RollbarErrorHandler, RollbarService, rollbarFactory } from './y4-log-rollbar';
import { Y4ProjectPreallocatedChoicesComponent } from
  './y4-project-preallocated-choices/y4-project-preallocated-choices.component';
import { Y4ProjectRemoveDialogComponent } from
  './y4-project-remove-dialog/y4-project-remove-dialog.component';
import { Y4ProjectChoicesStatusDialogComponent } from
  './y4-project-choices-status-dialog/y4-project-choices-status-dialog.component';
import { Y4ProjectSelectionFormUpdateDialogComponent } from
  './y4-project-selection-form-update-dialog/y4-project-selection-form-update-dialog.component';
import { AllocationActiveDirective } from './lib/directives/allocation-active.directive';
//import { APP_BASE_HREF } from '@angular/common';
import { EaSelectionsComponent } from './lib/input/ea-selections/ea-selections.component';
import { CrtSelectionsComponent } from './lib/input/crt-selections/crt-selections.component';

// TODO move into the auth module!
import { LoginRedirectService } from './lib/services/login-redirect.service';
import { AssistantSelectionsComponent } from './lib/input/assistant-selections/assistant-selections.component';
import { PresentationDetailsComponent } from './lib/input/presentation-details/presentation-details.component';
import { Y4JobTitlesListComponent } from './y4-job-titles-list/y4-job-titles-list.component';
import { Y4AccountsSyncPageComponent } from './y4-accounts-sync-page/y4-accounts-sync-page.component';
import { Y4CrsidSyncListComponent } from './y4-crsid-sync-list/y4-crsid-sync-list.component';
import { Y4AssistantsManageComponent } from './y4-assistants-manage/y4-assistants-manage.component';
import { Y4ProjectChoiceAllocationSummaryComponent } from './y4-project-choices-admin/y4-project-choice-allocation-summary/y4-project-choice-allocation-summary.component';
import { Y4ProjectChoiceAllocationStudentDialogComponent } from './y4-project-choices-admin/y4-project-choice-allocation-student-dialog/y4-project-choice-allocation-student-dialog.component';
import { Y4ExampleProjectsComponent } from './y4-example-projects/y4-example-projects.component';

/**
 * TODO: move this error class into shared / other module etc
 */
export class MyErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error: Error) {
    alert('error ');
    alert(JSON.stringify(error));
    if (Error) {
      console.log('hi');
    }
    else { console.log('hello'); }
  }
}

@NgModule({
  declarations: [
    IibpAppComponent,
    Y4ProjectslistComponent,
    Y4ProjectEditorComponent,
    SubjectGroupTopicEditComponent,
    SubjectGroupTopicListComponent,
    Y4ProjectsTableComponent,
    KeydatesComponent,
    KeydateEditorDialogComponent,
    KeydatesCopyyearDialogComponent,
    Y4ProjectSummaryComponent,
    Y4ProjectsbrowseComponent,
    Y4ProjectSelectListingComponent,
    LoginComponent,
    NoaccountComponent,
    ImpersonateComponent,
    LogoutComponent,
    StopImpersonatingComponent,
    Y4ProjectSelectionBasketComponent,
    Y4ProjectSelectionListingComponent,
    Y4ProjectSelectDialogComponent,
    Y4ProjectChoicesAdminComponent,
    Y4ProjectChoiceAllocationDialogComponent,
    // AreyousureDialogComponent,
    RolesAdminComponent,
    AddRoleDialogComponent,
    HasPermDirective,
    IndexComponent,
    Y4ProjectlistAccordionComponent,
    Y4OwnerPageComponent,
    Y4ProjectApprovalListComponent,
    Y4ProjectApprovalDialogComponent,
    Y4ProjectStudentCreateComponent,
    Y4ProjectCopyDialogComponent,
    Y4ProjectSummaryDialogComponent,
    Y4ProjectPreallocatedChoicesComponent,
    Y4ProjectRemoveDialogComponent,
    Y4ProjectChoicesStatusDialogComponent,
    Y4ProjectSelectionFormUpdateDialogComponent,
    AllocationActiveDirective,
    EaSelectionsComponent,
    CrtSelectionsComponent,
    AssistantSelectionsComponent,
    PresentationDetailsComponent,
    Y4JobTitlesListComponent,
    Y4AccountsSyncPageComponent,
    Y4CrsidSyncListComponent,
    Y4AssistantsManageComponent,
    Y4ProjectChoiceAllocationSummaryComponent,
    Y4ProjectChoiceAllocationStudentDialogComponent,
    Y4ExampleProjectsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IibpAppRoutingModule,
    ReactiveFormsModule,
    CamplNgxModule.setConfig(environment.config), // sets the templates generic links, title etc
    CKEditorModule,
    CuedMaterialsModule,
    CuedSharedModule,
    CuedWidgetsModule
  ],
  // add this to activate our logging service
  // TODO: https://pusher.com/tutorials/error-handling-angular-part-1
  // providers: [{
  //  provide: ErrorHandler,
  //  useClass: MyErrorHandler
  // }],
  // https://stackoverflow.com/questions/40150393/how-to-redirect-to-an-external-url-from-angular2-route-without-using-component/40395382
  providers: [{
    provide: 'externalUrlRedirectResolver',
    useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      window.location.href = (route.data as any).externalUrl;
    }
  },
  { provide: ErrorHandler, useClass: RollbarErrorHandler },
  { provide: RollbarService, useFactory: rollbarFactory }
  //,
  //{ provide: APP_BASE_HREF, useValue: '/frontend/'}
  // , {
  //   provide: ErrorHandler,
  //   useClass: MyErrorHandler
  // }
  //,
  // Implement an interceptor checking for redirect
  //,{
  //    provide: HTTP_INTERCEPTORS,
  //    useClass: LoginRedirectService,
  //    multi: true
  //}
  ],
  entryComponents: [
    Y4ProjectEditorComponent,
    KeydatesCopyyearDialogComponent
  ],
  bootstrap: [IibpAppComponent]
})
export class IibpAppModule { }
