<h3 class="mat-title">Account synchronisation settings</h3>
<p>
Accounts are synchronised daily and supervisors who are synchronised into the application come from:
</p>


<!--
<div *ngFor="let sta of [].concat.apply([], getCurrentKeyDates(4))">{{sta.date | json}}</div>
-->
<mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Job titles
            </mat-panel-title>
            <mat-panel-description>
                An EDDA record with one of these titles will be included
            </mat-panel-description>
        </mat-expansion-panel-header>
       <app-y4-job-titles-list></app-y4-job-titles-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Specific CRS IDs
            </mat-panel-title>
            <mat-panel-description>
                Include/Exclude users based on CRS IDs for supervisor and assistant roles
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-y4-crsid-sync-list></app-y4-crsid-sync-list>
    </mat-expansion-panel>
</mat-accordion>