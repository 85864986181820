import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
//'../projects/cued-shared/components/areyousure-dialog/areyousure-dialog.component';
import { AreyousureDialogComponent, Deserializable } from 'cued-lib/src/lib/cued-shared';
import { Y4project } from '../lib/models/y4project';
import { Y4projectselection } from '../lib/models/y4projectselection';
import { CourseofferingService } from '../lib/services/courseoffering.service';
import { SgtopicService } from '../lib/services/sgtopic.service';
import { StudentService } from '../lib/services/student.service';
import { Y4ProjectService } from '../lib/services/y4-project.service';
import { Y4ProjectselectionService } from '../lib/services/y4-projectselection.service';
import { Y4ProjectChoicesAdminService, Y4projectselectionTableItem, Y4studentselectionTableItem, Y4supervisorselectionTableItem } from '../lib/services/y4-project-choices-admin.service';
import { Y4ProjectChoiceAllocationDialogComponent } from './y4-project-choice-allocation-dialog/y4-project-choice-allocation-dialog.component';
import { Y4ProjectChoicesAdminBulkUploadService } from '../lib/services/y4-project-choices-admin-bulk-upload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StatusesService } from '../lib/services/statuses.service';
import { MatSort } from '@angular/material/sort';
// import { BulkUploadValidator } from '../lib/validators/bulk.upload.validator.directive'
// import { BulkUploadValidator } from '../lib/validators/bulk.upload.validator.directive'

// TODO move WrapperClassYear into a shared module?
import { ICamplNgxPageNav } from 'cued-lib/src/lib/campl-ngx';

import { environment } from '../../environments/environment';

//
import { Y4ProjectSummaryDialogComponent } from
  '../y4-project-summary-dialog/y4-project-summary-dialog.component';
import { Y4ProjectSelectionFormUpdateDialogComponent } from
  '../y4-project-selection-form-update-dialog/y4-project-selection-form-update-dialog.component';
import { CourseOffering } from '../lib/models/courseoffering';
import { Y4ProjectChoicesAdminFiltersService } from '../lib/services/y4-project-choices-admin-filters.service';
import { Y4ProjectChoiceAllocationStudentDialogComponent } from './y4-project-choice-allocation-student-dialog/y4-project-choice-allocation-student-dialog.component';



/**
 * Implement printOut() to display the current choice
 */
export class WrapperClassYear implements ICamplNgxPageNav {
  year: number;
  coo: CourseOffering; // this is the course id associated with the year

  printOut(): string | number {
    return this.year + ' - ' + (this.year + 1);
  }
}

/**
 * The component is visited by the courseoffering year URL parameter
 * If this does not exist on entry it will take the latestYear()
 *
 */

@Component({
  selector: 'app-y4-project-choices-admin',
  templateUrl: './y4-project-choices-admin.component.html',
  styleUrls: ['./y4-project-choices-admin.component.css']
})
export class Y4ProjectChoicesAdminComponent implements OnInit, OnDestroy {

  private _debug = false;
  private _debug_message(msg) { if (this._debug) { console.log("y4-projectselections.service: "+msg) } }
  
  constructor(
    private _choicesAdmin: Y4ProjectChoicesAdminService, // this is to replace the follwoing 3 services
    private _choicesAdminFilters: Y4ProjectChoicesAdminFiltersService, // this is to replace the follwoing 3 services
    private _choicesService: Y4ProjectselectionService,
    private _projectService: Y4ProjectService,
    private _studentService: StudentService,
    private _statusesService: StatusesService,
    private _cooService: CourseofferingService,
    private _sgtopicsService: SgtopicService,
    private _activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private _fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private _bulkUpload: Y4ProjectChoicesAdminBulkUploadService) {
    // subscribe to our datasources, have not found how to provide observable datasources

    this._subscriptions = [
      this._choicesAdmin.getSupervisorSelections$()
        // .pipe(tap( sels => console.log(sels))) /// used to get sample test fixtures
        .subscribe(sels => { this.supervisorSelections = sels; }),
      this._choicesAdmin.getProjectSelections$()
        .subscribe(sels => { this.projectSelections = sels; }),
      // combine to setup the array of allocated crsids
      combineLatest([this._choicesAdmin.getStudentSelections$(), this._choicesAdmin.getAllocatedStudentCrsids$()])
        .subscribe(([sels, crsids]) => {
          this.studentSelections = sels;
          this.allocatedCrsids = crsids
        }),
      // This monitors the request for the underlying data and helps manage the 'loading...' message
      this._choicesAdmin.getCourseSelections$()
        // used to generate the test fixtures
        .pipe(
          map( sels => sels.filter( (sel) =>  ( 
            sel.pslY4project.projOwner.stfCrsid === 'yysh2' ||
            sel.pslY4project.projOwner.stfCrsid === 'tdw13' ||
            sel.pslY4project.projOwner.stfCrsid === 'tb267' )
           ) ),
        /// used to get sample test fixtures
        //  tap( sels => console.log(sels))
        )
        .subscribe(() => this._loadingRegisterReturn("loaded course selection"))
    ];
    this.isEditable = false;
    // on change of course (nav) set the shared project service info
    this._subscriptions.push(
      this._monitorCurrentCourse$().subscribe((coo) => { this._projectService.setEgt3Coo(coo); })
    )
    this.maxProjects = environment['maxProjects'];

  }


  ngOnDestroy() {
    this._subscriptions.map(sub => sub.unsubscribe());
  }

  isEditable: boolean;

  public maxProjects = 3; // incase it is not set!!
  private _subscriptions;

  // For our navigation component
  years: WrapperClassYear[];
  initialIndex = 0;
  currentYear: number;


  // our datasources for the tables: loaded  by subscribing from _choicesAdmin
  public studentSelections: Y4studentselectionTableItem[];
  public projectSelections: Y4projectselectionTableItem[];
  public supervisorSelections: Y4supervisorselectionTableItem[];
  public allocatedCrsids: string[]; //used to identify the allocated students

  // Observables used to report stats also from _choicesAdmin
  public numberOfStudentsSelections$ = this._choicesAdmin.getNumberOfStudentsSelections$();
  public numberOfStudentsAllocated$ = this._choicesAdmin.getNumberOfStudentsAllocated$();
  public unallocatedStudentSelections$ = this._choicesAdmin.getNumberOfFilteredStudentsUnallocated$();


  // To help manage the 'loading...' message
  loading = true;
  _queryQueue = 0;

  private chosenNavIndexSubject$ = new ReplaySubject<number>(1); // need to set when navigating
  public chosenNavIndex$: Observable<number> = this.chosenNavIndexSubject$.asObservable();

  // returns whether we can provide the bulk upload submit button
  canBulkUploadSubject$ = new BehaviorSubject<boolean>(false);
  canBulkUpload$ = this.canBulkUploadSubject$.asObservable();

  // used in the filter to decide which buttons to display
  selectedTabSubject$ = new BehaviorSubject<string>('By Supervisor');
  selectedTab$ = this.selectedTabSubject$.asObservable();

  public validStudentCrsids$ = this._studentService.studentsOnCourse$.pipe(
    map(students => students.map(stu => stu.stuCrsId))
    // ,shareReplay(1)
    , take(1)
  );
  // used to display an example crsid
  public exampleCrsid$ = this.validStudentCrsids$.pipe(
    map(res => res[0] + 'xyz')
  );

  // How do we know the year of course we are on?
  public validCourseReferences$ = this._projectService.projectsByCoo$.pipe(
    map(projects => projects.map(proj => proj.reference()))
    // ,shareReplay(1)
    // ,take(1)
  );

  // used to display and example reference
  public exampleRef$ = this.validCourseReferences$.pipe(
    map(res => res[0] + '-xyz')
  );

  toggleChoicesCols = ['choiceOverride', 'choice1', 'choice2', 'choice3', 'choice4', 'choice5'];
  toggleOwnerCols = ['allocatedOwner'];
  toggleTitleCols = ['allocatedTitle'];

  showChoices = true;
  showOwner = false;
  showTitle = false;

  // Project Tab - display student Name
  showProjectStudentName = false;

  displayedColumns = ['project', 'type', 'allocated', ...this.toggleChoicesCols];

  displayStudentCols = [
    'studentCrsid',
    'studentLastname',
    'studentPreferredName',
    'studentCollegeCode',
    ...this.toggleChoicesCols,
    'allocatedGroup',
    'allocated'];

  displaySupervisorCols = [
    'supervisor',
    'numberAllocated',
    'allocated',
    ...this.toggleChoicesCols]; // , 'choice1', 'choice2', 'choice3', 'choice4', 'choice5'];

  groups$ = this._sgtopicsService.topics$.pipe(
    map(list => Array.from(new Set(list.map(sgt => sgt.subjGroup)))));

  // The project selections are filtered in the choicesAdmin service by sharing the filters service


  /**
   * Form group to be passed to bulk upload service
   * 
   * Here we will validate on blur (the 'Validate' button serves as on such place to blur)
   * 
   * If valid this.canBulkUploadSubject$ will publish 'true' activating the upload button
   */

  uploadForm = this._fb.group({
    bulkChoices: ['', {
      validators: [this._bulkUpload._parseLinesBulkUploadValidator()],
      asyncValidators: [this._bulkUpload._bulkUploadValidator(this.validStudentCrsids$, this.validCourseReferences$, this._choicesAdmin.getAllocatedStudentCrsids$(), this.canBulkUploadSubject$)],
      updateOn: 'blur'
    }]
    //    bulkChoices: ['', validStudentProjects(this.validStudentCrsids$, this.validCourseReferences$)]
  });

  ngOnInit(): void {
    // unlike other pages we are tracking the course offering for the year
    // so we need to observe the navigation and action the change of course
    this._initializePageNav()

    // 
    this._choicesAdminFilters.handleGroupToggle();

    // This will emit when an upload occurs
    this._bulkUpload.getBulkUpload$(this.uploadForm).subscribe(
      // () => alert('hit the submit button')
      (res) => {
        if (res) {
          this._choicesService.touchSelections();
          this.openSnackBar('Allocations have been loaded', 'close');
        }
      }
    );
  }

  /** 
   * The chosen year (set by the page navigation)
   * is decided by:
   * - parameter of URL
   * - default year (latestYear)
   * sets up:
   * this.initialIndex
   * this.years (an ordered array of objects which provide acess to the particular year)
   */
  _initializePageNav() {
    return combineLatest([
      this._activatedRoute.paramMap, // is this being interpreted strangely by the Editor / linter
      this._cooService.courseOfferings$,
      this._statusesService.latestYear()
    ])
      .pipe(take(1))
      .subscribe(([params, courses, defaultyear]) => {
        // sets up the courses
        this.years = courses
          .filter(course => (course.cooH03Code === 'EGT3'))
          .sort((a, b) => a.cooOfferingYear - b.cooOfferingYear)
          .map(course => {
            const ret = new WrapperClassYear();
            ret.year = course.cooOfferingYear;
            ret.coo = course
            return ret;
          });
        this.currentYear = (params.get('year')) ? +params.get('year') : defaultyear;
        // TODO place EGT3 code into config file??
        const coo = this.years.filter(year => (year.year === this.currentYear));
        this.setNavIndex(coo[0])
        this.initialIndex = this.years.findIndex((ele) => ele.year === this.currentYear);
        //return coo[0];
      })
  }

  /**
   * return the current course 
   * This will change during title navigation
   * 
   * choices as follows: 
   */
  _monitorCurrentCourse$() {

    return this.chosenNavIndex$.pipe(
      map(
        (index) => {
          return this.years[index].coo
        })
    )
  }

  /**
   * Called by our navigation bar to set the year report
   *
   * @param selectedYear - the currently selected object in our nav bar
   */
  setNavIndex(selectedObject: WrapperClassYear) {
    // notify the UI that we are loading data
    this._loadingStart();
    this.chosenNavIndexSubject$.next(this.years.findIndex((year) => year.year == selectedObject.year));
    // TODO: how to toggle the 'loading' message off
  }

  /**
   * Used to monitor the status of our data request
   */
  _loadingStart() {
    this.loading = true;
    // we used to increment this value ie +=1 +=3 (when counting all observables)
    // but the XHR requests get cancelled when nave / prvious is clicked multiple times
    // leading to the page 'hanging'
    this._queryQueue = 1;
  }

  /**
   * As the data is returned we track whether we are still loading some
   */
  _loadingRegisterReturn(debug: string) {
    this._queryQueue -= 1;
    this.loading = (this._queryQueue > 0) ? true : false;
  }

  //
  // Bulk load
  //

  disableSubmit() {
    this.canBulkUploadSubject$.next(false);
  }

  /**
   * Parse our bulk input again and submit to the backend
   * Bug this triggers and runs when content is loaded / ie switching tabs
   */
  uploadAllocations() {
    this._bulkUpload.uploadAllocations();
    this.disableSubmit();
  }

  //
  // Navigate by year
  //

  displayNextYear() {
    this.chosenNavIndexSubject$.next(this.currentYear + 1);
  }
  displayPreviousYear() {
    this.chosenNavIndexSubject$.next(this.currentYear - 1);
  }

  //
  // Selection actions
  // 

  allocateProject(sel: Y4projectselection): void {
    // The selection is passed to the modal via the services 
    // our service are being used as communication services here
    this._choicesService.setBrowseChoiceValue(sel); // explicity sets the value
    this._projectService.setBrowseProject(sel.pslY4project.id); // currentlyBrowsingProject$
    this._studentService.setBrowseStudent(sel.pslStudent.id);
    this.dialog.open(Y4ProjectChoiceAllocationDialogComponent, { height: '90%' });
  }

  allocateProjectSelectStudent(proj: Y4project): void {
    // Allocate the project to a student who may not have selected it

    this._projectService.setBrowseProject(proj.id);
    this.dialog.open(Y4ProjectChoiceAllocationStudentDialogComponent, { height: '90%' });
  }

  unAllocateProject(sel: Y4projectselection): void {
    const dialogRef = this.dialog.open(AreyousureDialogComponent, {});
    const instance = dialogRef.componentInstance;
    instance['message'] = 'You wish to remove this selection ';
    dialogRef.afterClosed()
      .subscribe((val) => { if (val) { this._choicesService.unAllocateSelection(sel); } });
  }
  unAllocateStudent(sel: Y4projectselection): void {
    this.unAllocateProject(sel);
  }

  registerPlanningForm(sel: Y4projectselection): void {
    const dialogRef = this.dialog.open(Y4ProjectSelectionFormUpdateDialogComponent, {
      data: { sel }
    });
  }

  /**
   * maybe trigger a new observable / something else?
   */
  tabClick(eve) {
    this.selectedTabSubject$.next(eve.tab.textLabel);
  }

  // 
  // dataset (this._choicesAdminFilters) ordering and filtering  
  // 
  // TOGGLE OUR SORTS
  // Supervisor list - 'Allocated' and 'num supervising' are mutually exclusive
  sortAllocated() { this._choicesAdminFilters.toggleSortAllocated(); }
  sortSupervisor() { this._choicesAdminFilters.toggleSortSupervisor() }
  // stundent list
  sortStudentSurname() { this._choicesAdminFilters.toggleSortStudentSurname() }

  // TOGGLE and and retreive the filters being used
  // Observables are used in html to set button statuses
  // toggleX - actioned by the UI
  // getX - used by the UI to present button
  // TODO we could move this and the HTML into a chocies_admin_filters component 
  // that calls the admin filters service?
  public toggleGroup(group: string) { this._choicesAdminFilters.toggleGroup(group) }
  public getGroupFilter$() { return this._choicesAdminFilters.groups$() }

  public toggleAllocated() { this._choicesAdminFilters.toggleAllocated() }
  public getAllocatedFilter$() { return this._choicesAdminFilters.allocated$() }

  public toggleUnallocated() { this._choicesAdminFilters.toggleUnallocated() }
  public getUnallocatedFilter$() { return this._choicesAdminFilters.unallocated$() }

  public toggleChoices() { this._choicesAdminFilters.toggleChoices() }
  public getChoicesFilter$() { return this._choicesAdminFilters.choices$() }

  public toggleNochoices() { this._choicesAdminFilters.toggleNochoices() }
  public getNoChoicesFilter$() { return this._choicesAdminFilters.nochoices$() }

  public toggleNoAgreement() { this._choicesAdminFilters.toggleNoAgreement() }
  public getNoAgreementFilter$() { return this._choicesAdminFilters.noAgreement$() }

  public toggleTypeA() { this._choicesAdminFilters.toggleTypeA() }
  public getTypeAFilter$() { return this._choicesAdminFilters.typeA$() }

  public toggleTypeB() { this._choicesAdminFilters.toggleTypeB() }
  public getTypeBFilter$() { return this._choicesAdminFilters.typeB$() }

  /**
   * TOGGLE SOME COLUMN  DISPLAYS
   */

  /**
   * Toggle whether the choices columns are displayed
   *
   * to be displayed after 'studentCollegeCode'
   */
  public toggleChoicesColumns() {
    const insertAt = this.displayStudentCols.indexOf('studentCollegeCode');
    if (this.displayStudentCols.indexOf(this.toggleChoicesCols[0]) > 0) {
      // we remove
      this.displayStudentCols = this.displayStudentCols
        .filter(col => !this.toggleChoicesCols.includes(col));
    } else {
      // we add
      this.displayStudentCols
        .splice(
          insertAt,
          0,
          ...this.toggleChoicesCols);
    }
    this.showChoices = !this.showChoices;
  }
  /**
   * Toggle whether the owner name is displayed
   */
  public toggleOwnerColumn() {
    if (this.displayStudentCols.indexOf(this.toggleOwnerCols[0]) > 0) {
      // we remove
      this.displayStudentCols = this.displayStudentCols
        .filter(col => !this.toggleOwnerCols.includes(col));
    } else {
      // we put this/ese at the end
      this.displayStudentCols
        .splice(
          this.displayStudentCols.length,
          0,
          ...this.toggleOwnerCols);
    }
    this.showOwner = !this.showOwner;
  }

  /**
   * Toggle whether the allocated title is displayed
   * 
   * TODO: does this remove the Owner column isf toggled?
   */
  public toggleTitleColumn() {

    if (this.displayStudentCols.indexOf(this.toggleTitleCols[0]) > 0) {
      // we remove
      this.displayStudentCols = this.displayStudentCols
        .filter(col => !this.toggleTitleCols.includes(col));
    } else {
      // we put this/ese at the end
      this.displayStudentCols
        .splice(
          this.displayStudentCols.length,
          0,
          ...this.toggleTitleCols);
    }
    this.showTitle = !this.showTitle;
  }

  // currently using the standard material snackbar service
  // campl-ngx-snackbar could be created to display different types of message etc?
  // We could modify sendMessage to send to a snack bar or messageare
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  viewProjectSummaryDialog(row) {
    this.dialog.open(Y4ProjectSummaryDialogComponent, { height: '90%', data: { project: row } });
  }
}
