<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>


<h1 mat-dialog-title>Update allocation</h1>

<mat-checkbox (change)=" togglePlanReturn()"  [checked]="planReturned? true : false ">Project plan has been returned</mat-checkbox><br />


<button mat-raised-button color="primary" (click)="update()"  [disabled]="submitted">
    Continue
</button>
<button mat-raised-button color="warn" (click)="cancel()" >
    Cancel
</button>

