<div class="mat-elevation-z8">
  <table mat-table class="full-width-table campl-table" matSort aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ref</th>
      <td mat-cell *matCellDef="let row">{{row.reference()}}</td>
    </ng-container>

    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Title</th>
      <td mat-cell *matCellDef="let row">{{row.projTitle}}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let row">{{row.projType}}</td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let row">{{row.descriptionTeaser(64)}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="right-pad">Status</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="!row.submitted()" matTooltip="Not yet submitted for approval" aria-label="Saved">timelapse
        </mat-icon>
        <mat-icon *ngIf="row.waitingApproval()" matTooltip="Awaiting approval" aria-label="Submitted">
          check_circle_outline</mat-icon>
        <mat-icon *ngIf="row.approved()" matTooltip="Approved" aria-label="Approved">check_circle</mat-icon>
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let row">
        <button *ngIf="showSummary" mat-icon-button matTooltip="View summary" aria-label="View summary">
          <mat-icon (click)="viewProjectSummaryDialog(row)">preview</mat-icon>
        </button>
        <button *ngIf="copy" mat-icon-button matTooltip="Copy project" aria-label="Copy">
          <mat-icon (click)="copyProject(row)">content_copy</mat-icon>
        </button>
        <button *ngIf="edit" mat-icon-button aria-label="Edit project details" matTooltip="Edit project details">
          <mat-icon (click)="editY4ProjectDialog(row.id,offering)">edit</mat-icon>
        </button>
        <button *ngIf="remove" mat-icon-button aria-label="Button to remove project" matTooltip="Remove project">
          <mat-icon (click)="removeY4ProjectDialog(row)">delete_forever</mat-icon>
        </button>
        <button *ngIf="approve" mat-icon-button aria-label="Button to view and approve"
          matTooltip="View and approve project">
          <mat-icon (click)="approveY4ProjectDialog(row)">add_to_photos</mat-icon>
        </button>
        <button *ngIf="submit && !row.submitted()" mat-icon-button matTooltip="Submit for approval"
          aria-label="Submit for approval">
          <mat-icon (click)="submitProject(row)">input</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!--(click)="editY4ProjectDialog(row.id)"-->
    <tr class="mat-row" *matNoDataRow>
      <td [attr.colspan]="displayedColumns.length" class="mat-cell">No projects have been found.</td>
    </tr>
  </table>
  <div>
    <div style="display:flex">

      <div *ngIf="add && offering" style="display:flex; flex-grow:1"><button mat-raised-button color="primary"
          (click)="editY4ProjectDialog(0,offering,type,showTypeCourse)" aria-label="Add new project">Add a new
          project</button></div>
      <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="25"
        [pageSizeOptions]="[10,25, 50, 100, 250, 500]">

      </mat-paginator>
    </div>

  </div>

</div>

<br />
<div>
  <div>
    <h4 class="valign-center"> Status:
      <mat-icon aria-label="Saved">timelapse</mat-icon> - Saved,
      <mat-icon aria-label="Submitted">check_circle_outline</mat-icon> - Submitted,
      <mat-icon aria-label="Approved">check_circle</mat-icon> - Approved
    </h4>
  </div>
  <mat-card>
    <b>Notes:</b>
    <ul *ngIf="ownNotes">
      <li><b>Submitting</b> your project will forward it for approval.</li>
      <li>Your project must be <b>submitted</b>, and <b>approved</b> by a <b>Group Project Coordinator</b> before it
        will be visible to students. </li>
      <li>You can access the project lists and student choices via the <b>IIB Projects</b> -> <b>Student selections</b>
        menu option.</li>
    </ul>
    <ul *ngIf="approve">
      <li>Projects will not appear to students until they have been approved</li>
      <li>Only Approved projects will appear on the <b>IIB Projects -> Student Selections</b> page</li>
    </ul>
    <div  *ngIf="copy" >
      <b>Copying a Project:</b>
      <ul>
        <li>To copy a project browse to the <b>previous</b> year and click on the <mat-icon aria-label="Copy">content_copy</mat-icon> icon against the project.
        </li>
      </ul>
    </div>
  </mat-card>
</div>