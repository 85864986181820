import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Observable, combineLatest } from 'rxjs';

import { Y4project } from '../lib/models/y4project';
import { Y4ProjectService } from '../lib/services/y4-project.service';
import { AuthService } from '../lib/services/auth.service';
import { SgtopicService } from '../lib/services/sgtopic.service';
import { Sgtopic } from '../lib/models/sgtopic';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { startWith, map, debounceTime, switchMap, tap, take, filter } from 'rxjs/operators';

import { StaffService } from '../lib/services/staff.service';
import { Staff } from '../lib/models/staff';
import { EngineeringArea } from '../lib/models/engineeringarea';
import { CourseofferingService } from '../lib/services/courseoffering.service';
import { StatusesService } from '../lib/services/statuses.service';
import { environment } from '../../environments/environment';
import { Auth } from '../lib/models/auth';
// https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/angular.html
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PortalInjector } from '@angular/cdk/portal';
import { Router, RouterEvent, NavigationStart } from '@angular/router';


/**
 * This class is used to enter/edit Y4Project details
 *
 * An example of instantiation is via Y4ProjectsTable.editY4ProjectDialog()
 *
 * The ID of the  project (to edit) is passed by the MAT_DIALOG_DATA object (id)
 * As may:
 *   courseOfferingId - course to create the project in
 *   y4projectType - type of project to create
 *   showTypeCourse - whether to show type and course (not for regular academics)
 *
 */
@Component({
  selector: 'app-y4-project-editor',
  templateUrl: './y4-project-editor.component.html',
  styleUrls: ['./y4-project-editor.component.scss']
})
export class Y4ProjectEditorComponent implements OnInit, OnDestroy {

  private _subscriptions = [];

  /**
   * A WYSIWYG editor
   */
  public ckEditor = ClassicEditor;

  // defaults passed via .data 
  courseOfferingId: number; // this is the course offering we wish to create the project for
  y4projectType: string; // the project Type we wish to create
  showTypeCourse = false; // do we allow a user to attempt to select these?
  y4projectid: number; // 

  permsLookup = environment.permsLookup;
  y4projectForm: FormGroup;

  pageTitle: string;
  alertMessage: string;
  errorMessage: string;
  snackbarDurationInSeconds = 2;
  showform = false;
  // disable the owner selection input field
  isOwnerDisabled = true;
  urlValidate = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  // options for the select
  courses = [];
  types = [];
  topicsList = [];
  subjectgroups = [];
  subjectgroupsTopics = [];

  // data model
  y4project = new Y4project();
  userid = -1; // once retrieved this will be +ve

  staffList: Staff[] = [];

  // Owner related info
  ownerCtrl = new FormControl('', Validators.required);
  filteredOwners$: Observable<Staff[]>;

  // Owner autocomplete
  // selectedOwner is the registered selected staff member from the autocomplete
  selectedOwner: Staff = new Staff();

  /**
   * We are injecting many services here perhaps too many!
   *
   */
  constructor(
    private y4projectService: Y4ProjectService,
    private sgtopics: SgtopicService,
    private statuses: StatusesService,
    private auth: AuthService,
    private cooservice: CourseofferingService,
    private _staffService: StaffService, // protected - so not app wide!
    private fb: FormBuilder,
    // private route: ActivatedRoute, no direct access atm
    private messageBuffer: CamplNgxMessageBufferService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<Y4ProjectEditorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _router: Router
  ) {
  }

  ngOnInit(): void {

    this._setValuesFromData()
    this._setFormDefaults(); // setups form and configures defaults
    this._setPlaceholderValues(); // sets some default values (for a new project)
    this._subscribeToLists(); // listens for select list observable etc

    this._populateY4project();
    this._subscribeToRouterNavigation()

  }

  ngOnDestroy(): void {
    this._unSubscribe()
  }

  /**
   * Values that can be passed via .data of the dialog call
   */
  _setValuesFromData() {
    this.y4projectid = this.data.id;
    this.y4projectType = this.data.y4projectType;
    this.showTypeCourse = this.data.showTypeCourse;
    this.courseOfferingId = this.data.courseOfferingId;
  }

  /**
   * Called when the y4project.id == 0
   */
  _setFormDefaults() {
    this.y4projectForm = this.fb.group({
      projType: [(this.y4projectType) ? { selected: this.y4projectType } : { selected: 'a' }, [Validators.required]],
      projTitle: ['', [Validators.required]],
      projUrl: '', // ['', Validators.pattern(this.urlValidate)],
      projOwner: ['', [this._validStaffMember, Validators.required]],
      projLoggedInAs: ['', [this._validStaffMember, Validators.required]],
      projSubjectgroup: ['', [Validators.required]],
      projTopic: ['', [Validators.required]],
      projOwnerSeqNum: 0,
      projDescription: ['', [Validators.required]],
      projIndustrial: '',
      projContactgroupcentre: 0,
      projSubmittedOn: null,
      projApprovedOn: 'TODO',
      projPublishedOn: 'TODO',
      projPreallocated: { value: false, disabled: true },
      projConfidentiality: false,
      //projSupervisors: [this.selectedAssistants, []],
      projAssistants: ['', []],
      projCourseOffering: ['', [Validators.required]], // the type of form variable changes!
      ownerEmail: ['', []], // TODO provide an email or blank validator
      ownerLocation: ['', []], // TODO provide a location or blank validator
      projMeetingDetails: '',
      projEngineeringAreas: '',
      projTopics: '',
      projSupervisors: ''
    });
    this.showform = true;
  }

  /**
   * Sets the default values for some form variables
   * 
   * Owner, LoggedInAs, OwnerEmail, Owner location, CourseOffering
   * 
   * If editing an existing project these will be overwritten with th ecorrect values 
   */
  _setPlaceholderValues() {

    // A few other things we setup by default:
    // projOwner, projCourseoffering
    // Nb this is a repeat of information we have already requested in the init
    this.setVisibleFields$()
      .pipe(take(1))
      .subscribe(
        (me: any) => {

          const meStaff = new Staff().deserialize(me.staff);
          this.y4projectForm.patchValue({
            projOwner: meStaff, // only patch projOwner if it is this users project!
            projLoggedInAs: meStaff,
            ownerEmail: meStaff.email(),
            ownerLocation: meStaff.stfLocation,
            // The courseOfferingId is to be sent in the data. as this component is used in a dialog only (ATM)
            // Beware if we use from the tag - we need to test for this.courseOfferingId has been set?!
            // The type of form variable changes so thi smay need to to set the selected value rather than the hidden value!!   
            // projCourseOffering: (this.courseOfferingId) ? { selected: this.courseOfferingId } : { selected: course.id }
            projCourseOffering:  { selected: this.courseOfferingId }
          });
        }
      );
  }

  /**
   * We wish to close the dialog if the router navigates to another page
   * This may occur when the session has timed out (found by a XHR request)
   */
  _subscribeToRouterNavigation() {
    this._subscriptions.push(
      this._router.events
        .pipe(
          filter((event: RouterEvent) => event instanceof NavigationStart),
          filter(() => !!this._dialogRef)
        )
        .subscribe(() => {
          console.log("close dialog")
          this._dialogRef.close();
        })
    );
  }

  /**
   * subscribes to the various observables:
   *
   * -EngineeringAreas
   * -'me'
   * -Project (Subject) Group Topics
   * -Course offerings (and current year) (this.courses)
   * -Types
   */
  _subscribeToLists() {

    // configure our auto-complete lists
    this._subscriptions.push(this.sgtopics.list
      .subscribe(
        topics => {
          this.topicsList = topics.sort((a, b) => (a.subjGroup + '' + a.subjGroupTopic > b.subjGroup + '' + b.subjGroupTopic) ? 1 : -1);
          this.parseSubjectgroupsAndTopics(this.topicsList); // DEBUG KEEP ME!!
          this.configureAutoCompleteFilters();
        }
      ))

    // Set this.course
    this._subscriptions.push(combineLatest([this.statuses.latestYear(), this.cooservice.list$])
      .subscribe(
        ([lyear, coos]: any) => {
          this.courses = coos
            .filter(coo => coo.cooOfferingYear > lyear - 2 && coo.cooOfferingYear < lyear + 2)
            .map(coo => ({ id: coo.id, value: coo.cooOfferingYear + ' - ' + (coo.cooOfferingYear + 1) }))
            .sort((a, b) => (a.value > b.value) ? -1 : 1);
        }
      ));

    this.types = this.getTypes();

  }

  /**
   * Populate the project fron the id? stored by the component
   */
  _populateY4project(): void {
    // called once subscribe to parameter field has been extracted
    // Show how to populate a form
    // setValue - all || patchValue - some
    let id = this.y4projectid ? this.y4projectid : 0
    if (id > 0) {
      this.y4projectService.getProject(id)
        .subscribe({
          next: (project: Y4project) => this.displayY4project(project),
          error: err => this.errorMessage = err
        });
    } else {
      this.displayY4project(new Y4project(id) /*id == 0 */);
    }
  }

  /**
   * Remove any subscriptions
   *
   * TODO: where possible remove the need to track subscriptions pipe(take(1)) in addRole()?
   */
  _unSubscribe() {
    this._subscriptions.map(sub => sub.unsubscribe());
  }

  /**
   * sets up the filters to be used by some of our components
   */
  configureAutoCompleteFilters() {

    // We subscribe in HTML
    this.filteredOwners$ = this.y4projectForm.get('projOwner').valueChanges.pipe(
      //
      debounceTime(300),
      map(value => typeof value === 'string' ? value : ''),
      switchMap(value => this._staffService.search(value, 20))
    );

  }

  displayY4project(proj): void {

    this.y4project = new Y4project().deserialize(proj);

    if (this.y4project.id > 0) {
      this.pageTitle = `Edit Project: ${this.y4project.reference()} ${this.y4project.projTitle}`;

      const patch = {
        projOwner: new Staff().deserialize(proj.projOwner),
        projTitle: proj.projTitle,
        projDescription: proj.projDescription,
        projUrl: (proj.projUrl) ? proj.projUrl : '',
        projIndustrial: (proj.projIndustrial) ? proj.projIndustrial : undefined,
        projPreallocated: proj.projPreallocated,
        projConfidentiality: proj.projConfidentiality,
        projSubmittedOn: this.y4project.projSubmittedOn, // therefore we don;t override / we heed to put in the corect format!
        projTopic: { selected: proj.projSubjectgroupTopic.id },
        projSubjectgroup: { selected: proj.projSubjectgroupTopic.subjGroup },

        // These controls may change depending on the user type!
        // maybe just hide them to prevent the users accidently re-choosing
        // Nothing should stop the user choosing others?
        projType: { selected: proj.projType },
        projCourseOffering: { selected: proj.projCourseOffering.id }, // but sometimes this is NOT the proj.projCourseOffering??
        ownerEmail: this.y4project.ownerContactEmail(),
        ownerLocation: this.y4project.ownerContactLocation(),

        // DEBUG
        // TODO
        // projMeetingDatetime
        // projMeetingLocation
        projMeetingDetails: { location: proj.projMeetingLocation, datetime: proj.projMeetingDatetime },

        projEngineeringAreas: proj.projEngineeringAreas.map((ea: EngineeringArea) => new EngineeringArea().deserialize(ea)),
        projTopics: proj.projTopics.map((top: Sgtopic) => new Sgtopic().deserialize(top)).sort((a, b) => (a.label() > b.label()) ? 1 : -1),
        projSupervisors: proj.projSupervisors
          .map((staff: Staff) => new Staff().deserialize(staff))
          .filter(s => s.id !== proj.projOwner.id) // ignore the project owner
      };
      this.y4projectForm.patchValue(patch);

    } else {
      // The title was not a default value
      this.pageTitle = 'Add a new Project';

      // if we have been passed defaults set them up
    }
  }

  /**
   * Set the fields that are visible
   * 
   * For those with manageY4projectsAccess
   *  provide the ability to select a project Type
   * 
   * For those without manageY4projectsAccess
   *  set the project Owner field to disabled (must be the current user)
   */
  setVisibleFields$() {
    return this.auth.me$.pipe(
      tap(me => {
        if (!this._testAccess(me, this.permsLookup.manageY4projectsAccess)) {
          this.y4projectForm.get('projOwner').disable();
        } else {
          this.showTypeCourse = true;
        }
      })
    )
  }

  getTypes() {
    // replace this with a call to a service (which queries the database)
    return [
      { id: 'a', value: 'a' },
      { id: 'b', value: 'b' },
      // { id: 'C', value: 'C' }
    ];
  }

  cancel() {

    this.openSnackBar('Updating project details cancelled: ', 'close');
    this._dialogRef.close(null);
  }

  /**
   * along with saving the project also submit for approval
   *
   */
  saveSubmit() {
    this.y4projectForm.patchValue({
      projSubmittedOn: new Date()
    });
    this.save();
  }

  save() {


    // convert any fields that are objects containing a selected key to the value of the selected key
    const parsedForm = this.y4projectForm.value;

    Object.keys(parsedForm).map((key, index) => {
      parsedForm[key] = (parsedForm[key] != null && typeof (parsedForm[key]) === 'object' &&
        'selected' in parsedForm[key]) ? parsedForm[key].selected : parsedForm[key];
    });

    //;
    // TODO wrap around an object or simliar to do this more sensibly!
    // Here we attempt to find the subjectgroupstopic id from the subjectgroupstopic name
    // saving the topic we need to convert to the topic id

    // thought that I might need to parse this
    parsedForm.projSubjectgroupTopic = parsedForm.projTopic;

    // TODO place this in the form and handle as part of the form
    // (we will need to do this when populating the edit form)
    // Backend expects the supervisors to be a list of staff ids


    // if the owners Email and location have not been modified set as 'default'
    // blank implies n/a
    parsedForm.projOwner = (parsedForm.projOwner) ? parsedForm.projOwner : parsedForm.projLoggedInAs;
    parsedForm.ownerEmail = (parsedForm.ownerEmail === parsedForm.projOwner.email()) ?
      'default' : parsedForm.ownerEmail;
    parsedForm.ownerEmail = (parsedForm.ownerEmail === '') ? 'n/a' : parsedForm.ownerEmail;
    parsedForm.ownerLocation = (parsedForm.ownerLocation === parsedForm.projOwner.stfLocation) ?
      'default' : parsedForm.ownerLocation;
    parsedForm.ownerLocation = (parsedForm.ownerLocation === '') ? 'n/a' : parsedForm.ownerLocation;

    // TODO pass and manage as a class object?
    if (parsedForm.projMeetingDetails && parsedForm.projMeetingDetails.location !== null) {
      parsedForm.projMeetingLocation = parsedForm.projMeetingDetails.location
      parsedForm.projMeetingDatetime = parsedForm.projMeetingDetails.datetime
    }
    
    // however remove if they have just been set to blank..
    if(parsedForm.projMeetingDetails.location === '' || parsedForm.projMeetingDetails.datetime === ''){
      parsedForm.projMeetingLocation='';
      parsedForm.projMeetingDatetime=null;
    }

    let projAssistants = parsedForm.projSupervisors.assistants
    parsedForm.projSupervisors = Array.isArray(projAssistants) ? projAssistants.map(stf => stf.id) : []

    let projAreas = parsedForm.projEngineeringAreas.eas
    parsedForm.projEngineeringAreas = projAreas ? projAreas.map(ass => ass.id) : [];

    let projTopics = parsedForm.projTopics.topics
    parsedForm.projTopics = projTopics ? projTopics.map(top => top.id) : [];

    parsedForm.projOwner = parsedForm.projOwner.id;

    // parsedForm.projCourseOffering =  parsedForm.projCourseOffering.id;

    // Create or update our project
    if (this.y4project.id === 0) {
      this.errorMessage = '';
      this.y4projectService.addProject(parsedForm)
        .pipe(take(1))
        .subscribe({
          next: () => this.onSaveComplete(parsedForm.projTitle),
          //error: err => this.onSaveError(err, parsedForm)
        });
    } else {
      parsedForm.id = this.y4project.id;

      // passing deserialised form would be better
      // but we are required to pass the projOwner as an ID not staff record
      // let myProject = new Y4project().deserialize(parsedForm)

      // We are updating an entry
      let myProject = parsedForm
      this.y4projectService.putProject(myProject).subscribe({
        // TODO does put project return a project?
        next: (y4project: Y4project) => {
          //;
          this.onSaveComplete(parsedForm.projTitle);
        },

      });
    }
  }

  /**
   * Launch a confirmation snackbar and refresh the projects list
   * 
   * @param y4projectTitle A title to display in our message -> but complicates testing!
   */
  onSaveComplete(y4projectTitle: string): void {
    this.showform = false;

    // interfering with cypress tests finding row in table
    this.openSnackBar('Successfully saved project', 'close');

    this.y4projectService.getProjects();
    this._dialogRef.close(null);
  }

  // currently using the standard material snackbar service
  // campl-ngx-snackbar could be created to display different types of message etc?
  // We could modify sendMessage to send to a snack bar or messageare
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  /**
   * populateTopics
   *
   * returns a list of Topics associated to the selected projSubjectgroup
   *
   */
  populateTopics() {
    return this.y4projectForm.get('projSubjectgroup').value ?
      this.getTopics(this.y4projectForm.get('projSubjectgroup').value.selected) :
      [''];
  }

  /**
   * getTopics
   *
   * @param group : any - the group we wish to retrieve the topics for
   *
   * The group-topic associations were populated during
   * this.parseSubjectgroupsAndTopics called from a subscription to the SgtopicService
   *
   * returns array of topic names.
   * TODO: return an array of topic id, values
   * TODO populate subjectgroupsTopics with {id:, value:} objects
   */
  getTopics(group: any) {
    // return this.subjectgroupsTopics.find(x => x.group === group).values)
    // TODO FIX ME this is called alot -> (when hover over remove Assistant button for eg)
    // //
    return this.subjectgroupsTopics[group];
  }

  /**
   * parseSubjectgroupsAndTopics
   *
   * @param sgtArr - an array of sujectgroup topic rows,
   * these rows include the associated subjectgroup
   */
  parseSubjectgroupsAndTopics(sgtArr: Sgtopic[]) {
    //;
    // set the list of unique project (subject) groups
    // this.subjectgroups_arr = [... new Set(sgtArr.map(data => { return { id: data.subjGroup, value:  data.subjGroup } } ))]
    const subjectgroupsArr = [... new Set(
      sgtArr
        .filter(grp => grp.subjActive)
        .map(data => data.subjGroup)
    )];
    // set the project (subject) group select array
    this.subjectgroups = subjectgroupsArr
      .map(grp => ({ id: grp, value: grp }))
      .sort((a, b) => (a.id > b.id) ? 1 : -1); // TEST: will this sort by id?!

    // generate a lookup for the Topics of a Subjectgroup
    // used in topics FormControl
    sgtArr.forEach(function (sgt) {
      // //;
      if (this.subjectgroupsTopics[sgt.subjGroup] === undefined) {
        this.subjectgroupsTopics[sgt.subjGroup] = [];
      }
      // this will be passed to the topics select
      // Configure an array of id: <topicid>, value: <topic name>
      this.subjectgroupsTopics[sgt.subjGroup]
        .push({ id: sgt.id, value: sgt.subjGroupTopic });
    }, this);
  }

  /**
   * Clears the text once a value has been selected
   * 
   */
  clearOnSelectFn() {
    return ''
  }
  /**
     * Returns a value to be displayed in the text field
     * side effects - sets the selectedOwner / projOwner of the project
     *
     * @param value - the staff member to display as the value, or at load a blank string
     */
  displayOwnerFn(value: Staff | string): string | undefined {
    let ret = '';
    if (typeof (value) === 'string') {
      ret = '' + value;
    }
    else if (value) {// makes sure not null
      //;
      // this.selectedOwner = value;
      ret = value.display_name() + ' (' + value.stfCrsid + ')';
    }


    return ret;
  }
  /**
   * On select of the autocomplete option register the selectedOwner
   * This 'guarantees' a valid staff member
   *
   * Only to be used if the user has proxy permissions!
   */
  optionOwnerClicked(event: Event, owner: Staff) {
    const meStaff = new Staff().deserialize(owner);
    this.y4projectForm.patchValue({
      projOwner: meStaff,
      ownerEmail: meStaff.email(),
      ownerLocation: (meStaff.stfLocation !== null) ? meStaff.stfLocation : 'n/a'
      // location: (meStaff.stfLocation !== undefined)? meStaff.stfLocation : '-',
    });
  }

  private _testAccess(user: Auth, accessroles: string[]): boolean {
    return (user.roles.filter(value => accessroles.includes(value.roleType)).length > 0);
  }

  /**
   * Used as synchronous validation
   */
  private _validStaffMember(control: AbstractControl): { [key: string]: any } | null {
    const staff = control.value;
    return (staff && staff.id) ? null : { invalidRecord: { value: staff } };
  }

}
