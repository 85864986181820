<div>
    <div *ngIf="(isUgrad$|async) === true">
        <!-- browse not yet open for student -->
        <div *ngIf="(me$|async).egt3Course().getStatus('SELECTION') === 'FUTURE'">
            <div *ngIf="(me$|async).egt3Course().getStatus('Y4PBROWSE') === 'FUTURE'">
                <h3>Project choice selection is not yet open</h3>
                <p>Project choice selection is not yet open for browsing or selection.</p>
                <p>Browsing will open: {{ (me$|async).egt3Course().getOpens('Y4PBROWSE') }}.</p>
                <p>Selection will open: {{ (me$|async).egt3Course().getOpens('SELECTION') }}.</p>
                <p>Selection will then close: {{ (me$|async).egt3Course().getCloses('SELECTION') }}.</p>
            </div>
            <div *ngIf="(me$|async).egt3Course().getStatus('Y4PBROWSE') === 'ACTIVE'">
                <h3>Project choice selection is open for browsing</h3>
                <p>You are currently only able to view details of the projects offered.</p>
                <p>To browse projects follow the <span class="mat-body-2">'IIB projects -> 'Y4 projects (YYYY-YYYY)'</span> menu option.</p>
                <p>No choice selections can be made until
                    {{ (me$|async).egt3Course().getOpens('SELECTION') }}. </p>
            </div>
        </div>
        <div *ngIf="(me$|async).egt3Course().getStatus('SELECTION') === 'ACTIVE'">
            <h3>Project choice selection is open</h3>
            <p>IIB project choice selection is now open.</p>
            <p>To browse and make choices follow the <span class="mat-body-2">'IIB projects -> 'Y4 projects (YYYY-YYYY)'</span> menu option.</p>
            <p>Your chosen selections can be found in the <span class="mat-body-2">'IIB projects -> 'Your choices'</span> menu option.</p>
            <p>Please remember to order these choices by your preference.</p>
            <p>Selection closes {{ (me$|async).egt3Course().getCloses('SELECTION') }}.</p>
            <p>At which stage your choices will be automatically submitted.</p>
        </div>


        <div *ngIf="(me$|async).egt3Course().getStatus('SELECTION') === 'CLOSED'">
            <h3>Project Choice Selection is now closed</h3>
            <p>IIB project choice selection has now closed.</p>
            <p>Projects are either being assigned or have already been assigned.</p>
            Your choices were:
            <app-y4-project-selection-basket *ngIf="(isUgrad$|async) === true" ro="true"></app-y4-project-selection-basket>
            
        </div>
    </div>
    <app-y4-owner-page *ngIf="(isStaff$|async) === true"></app-y4-owner-page>
</div>
