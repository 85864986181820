import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Y4staffcrsidfilterService } from '../lib/services/y4staffcrsidfilter.service';
import { take } from 'rxjs/operators';
import { Y4staffcrsidfilter } from '../lib/models/y4staffcrsidfilter';

/**
 * This component lists and manages the inclusion / exclusion of users by CRSID
 * 
 * The list is manages will be used in the (currently overnight) synchronisation process
 */

@Component({
  selector: 'app-y4-crsid-sync-list',
  templateUrl: './y4-crsid-sync-list.component.html',
  styleUrls: ['./y4-crsid-sync-list.component.scss']
})
export class Y4CrsidSyncListComponent implements OnInit {

  crsidFilterForm: FormGroup
  types = [
    // {id:'Assistant',value:'Add as an Assistant'},
    {id:'Staff',value:'Include as a Supervisor'},
    {id:'Exclude',value:'Do not load into the application'}]

  constructor(
    private _y4staffcrsidfilterService: Y4staffcrsidfilterService) { }

  ngOnInit(): void {
    //private fb: FormBuilder,
    this.crsidFilterForm =  new FormGroup({
      scfType: new FormControl('', [Validators.required]),
      scfCrsId: new FormControl('', [Validators.required]),
      scfNotes: new FormControl('', [])
    })
  }

  crsidFilters$() {
    return this._y4staffcrsidfilterService.getList$()
  }

  resetCrsidFilterForm(){
    this.crsidFilterForm.get('scfNotes').setValue('')
    this.crsidFilterForm.get('scfType').setValue( '' )
    this.crsidFilterForm.get('scfCrsId').setValue('')
  }

  addCrsidfilter() {
    // convert the Form group to a SCF object and pass to our service
    let SCF = new Y4staffcrsidfilter()
    SCF.scfType = this.crsidFilterForm.get('scfType').value.selected
    SCF.scfCrsId = this.crsidFilterForm.get('scfCrsId').value
    SCF.scfNotes = this.crsidFilterForm.get('scfNotes').value

    this._y4staffcrsidfilterService
      .addCrsidFilter(SCF)
      .pipe(take(1))
      .subscribe(() => {
        this._y4staffcrsidfilterService.refreshList()
        // this.crsidFilterForm.reset()
        this.resetCrsidFilterForm()
      })
  }

  removeCrsidfilter(scf) {
    this._y4staffcrsidfilterService
      .removeCrsidFilter(scf)
      .pipe(take(1))
      .subscribe(() => this._y4staffcrsidfilterService.refreshList())
  }

}
