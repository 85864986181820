import { Deserializable } from './deserializable.model';
import { Staff } from './staff';
import { Y4project } from './y4project';

export class Supervisor  implements Deserializable {

    id: number;
    supIsOwner: boolean;
    supName: string;
    supEmail: string;
    supLocation: string;
    supProject: Y4project;
    supStaff: number;
    // list projects does not return to this level
    // supStaff: Staff;

    constructor() { }

    // display_name(): string {
    //     return this.stfTitle + ' ' + this.stfPrefName + ' ' + this.stfSurname;
    // }

    // email(): string {
    //     // Return the email field for external member / or where an alternative has been set
    //     return this.stfEmail? this.stfEmail : this.stfCrsid + '@eng.cam.ac.uk';
    // }

    deserialize(input: any): this {
        Object.assign(this, input);

        // from the browse / list projects this is not returned (too many levels)
        //this.supStaff = new Staff().deserialize(input.supStaff);
        return this;
    }
}
