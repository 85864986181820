import { Deserializable } from './deserializable.model';

export class Assistant  implements Deserializable {

    id: number;
    assPersonnelKey: string;
    assCrsid: string;
    assEngid: string;
    assEmail: string; //for external staff members
    assSurname: string;
    assFnames: string;
    assPrefName: string;
    assInitials: string;
    assLocation: string;
    assExternal: boolean;
    assTitle: string;
    assGender: string;

    constructor() { }

    display_name(): string {
        const name = this.assTitle + ' ' + this.assPrefName + ' ' + this.assSurname;
        return name.replace(/  +/g, ' ');
    }

    email(): string {
        // Return the email field for external member / or where an alternative has been set
        return this.assEmail? this.assEmail : this.assCrsid + '@cam.ac.uk';
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    deserializeUISLookup(input: any): this {
        let newObj = {
            display_name: input.displayName,
            assPersonnelKey: input.username,
            assEmail: input.email,
            assSurname: input.surname,
            // These are 'badly' implied - so if the user exists in a load load over with EDDA values
            assFnames: input.displayName.replace(input.surname,''),
            assPrefName: input.displayName.replace(input.surname,''),
            // no title from UIS lookup?
            assTitle: ''
        }
        Object.assign(this, newObj)
        return this
    }

    personnelKey(){
        return this.assPersonnelKey
    }
}
