import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { Y4project } from '../lib/models/y4project';
import { Y4ProjectService } from '../lib/services/y4-project.service';
/**
 *   This component works as a dialog
 *   used to allow the switch / setting of the approved status (datestamp)
 *   When viewing this information the reviewer would also like to know:
 *
 *   The number of further projects which the owner has
 *   TODO: FIX: this component is 'driven (called) by the y4projects-table component
 *
 *   TODO: Make clear if the project is a type (b) project
 *
 * The project is displayed via the y4-project-summary component and
 * passed to this component by the Input parameter
 *
 * eg called from y4-project-table
 *
 * @example
 * this.dialog.open(Y4ProjectApprovalDialogComponent, { height: '90%', width: '70%', data: { project } });
 */

@Component({
  selector: 'app-y4-project-approval-dialog',
  templateUrl: './y4-project-approval-dialog.component.html',
  styleUrls: ['./y4-project-approval-dialog.component.css']
})
export class Y4ProjectApprovalDialogComponent implements OnInit {
  project;

  // How many other projects does this owner have?
  /**
   * Find the number of projects currently accepted and offered by the owner of this project
   *
   * TODO: replace the use of service proprty with a method to return this specific answer?
   */
  activeProjectsByUser$ = this._projectService.projects$.pipe(
    map ( projs => projs.filter( proj => proj.projOwner.id === this.project.projOwner.id && proj.projApprovedOn != null )),
  );

  constructor(private _projectService: Y4ProjectService,
              private _dialogRef: MatDialogRef<Y4ProjectApprovalDialogComponent>,
              private _snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit(): void {
    // this._projectService.browseProject(this.data.project);
    this.project = new Y4project().deserialize(this.data.project);
  }
  cancel(){
      // this.openSnackBar('Updating project details cancelled: ', 'close');
      this._dialogRef.close(false);
  }
  approve(){
      this._projectService.approveProject(this.project).subscribe( res => {
        // we should test res
        this.openSnackBar('The project has been approved', 'close');
        this._dialogRef.close(true);
      } );
      // we could subscribe and wait for the approval to occur then call this?
  }

  openSnackBar(message: string, action: string) {
    // this._snackBar.open(message, action, {
    //  duration: 4000,
    // });
  }

}
