
import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, of, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { Student } from '../models/student';
import { HttpClient } from '@angular/common/http';
import { CamplNgxMessageBufferService } from 'cued-lib/src/lib/campl-ngx';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Y4ProjectService } from './y4-project.service';
import { AppService } from './app.service';
import { ErrorService } from './error.service';


/**
 * A Service used to interrogate the database of student information
 *
 * @public {list$} is the core observable
 *
 * list$ will provide an Observable once the courses have been provided (@function {setCourses})
 *
 * return a list of all students and courses
 */
@Injectable({
  providedIn: 'root'
})
export class StudentService{

  constructor(
    private http: HttpClient,
    pageMessage: CamplNgxMessageBufferService,
    private projectsService: Y4ProjectService,
    private errorService: ErrorService // maybe a facade would be able to get rid of this dependancy?
  ) { }
  // This is a read only service
  // available to a subset of users; to prevent misuse (access to student records)
  // Note: at the time of writing (old COMET) the student table does not contain names

  studentFilterSubject$ = new BehaviorSubject<string>('');
  studentFilter$ = this.studentFilterSubject$.asObservable();

  // Set this to filter students on course ids - why does plain Subject not work here?
  coursesFilterSubject$ = new BehaviorSubject<number[]>([]);
  coursesFilter$ = this.coursesFilterSubject$.asObservable();

  // list$ = new ReplaySubject<Student[]>();
  // studentList: Student[];
  baseurl = '/api/v1/student';
  appendlimit = '?limit=1000'; // used to

  /**
   * List of our students
   * filtered by:
   * - student (optional) crsid (starts with)
   * - courseids - we are require to set this via setCourse()
   *
   * requires a call to: setCourses() to setup coursesFilter$
   */
  list$ = combineLatest([this.studentFilter$, this.coursesFilter$]).pipe(
    switchMap(([filter, cooids]) => {
      const c = (cooids) ? '"stuCourseOffering":[' + cooids + ']' : '';
      const f = (filter !== '') ?
        (c !== '') ? '&where={"stuCrsId":{"startsWith":"' + filter.toLowerCase() + '"},' + c + ' }&sort=[{"stuCrsId":"ASC"}]' :
          '&where={"stuCrsId":{"startsWith":"' + filter.toLowerCase() + '"}}&sort=[{"stuCrsId":"ASC"}]' :
        (c !== '') ? '&where={' + c + '}' : '';
      return this.http.get<Student[]>(this.baseurl + this.appendlimit + f);
    }),
    map(list => list.map(student => new Student().deserialize(student)))
  );

  /**
   * The details for the student current browsing
   */
  private currentlyBrowsingStudentSubject$ = new BehaviorSubject<number>(0);
  public currentlyBrowsingStudent$ = this.currentlyBrowsingStudentSubject$.asObservable()
    .pipe(
      switchMap(id => this.http.get<Student[]>(this.baseurl + `?id=${id}`)),
      map(arr => new Student().deserialize(arr[0])),
    );



  private y4projectCourse$ = this.projectsService.egt3CooAction$;
  /**
   * Students who are on a particular course
   *
   * (set by @function {})
   *
   * egt3CooAction is set by projectService.setEgt3Coo()
   * TODO: remove projectService from this service and toggle / set using a function
   *  eg setY4PCourse
   */

  public studentsOnCourse$ = this.y4projectCourse$
    .pipe(
      switchMap(coo => this.http.get<Student[]>(this.baseurl + this.appendlimit + '&stuCourseOffering=' + coo.id)
        .pipe(
          map(list => list.map(student => new Student().deserialize(student)))
        )
      ),
      shareReplay(1)
    );
  /**
   * setBrowseStudent
   *
   * Set the currentlyBrowsingStudent$ observable to the student with the paramter id
   *
   * TODO there should be a way to set the observable to the get query?
   * and also be able to set a value for the subject directly?
   *
   * @param id - id of student to set as our current browse subject
   */
  setBrowseStudent(id: number) {
    this.currentlyBrowsingStudentSubject$.next(id);
  }

  /**  runs on component initialization
   * TODO: remove as the this.list$ is accessible publicly and without setCourses() nothing will be returned
   * maybe provide argument to set the course?
   */
  init(): Observable<Student[]> {

    // this.getStudents()
    // this.list$.subscribe(staff => { this.studentList = staff })
    return this.list$;
  }

  search(crsidname: string, limit: number): Observable<Student[]> {
    const f = (typeof crsidname === 'string') ? '?limit=' + limit + '&where={"stuCrsId":{"contains":"' + crsidname + '"}}' : '';
    return this.http.get<Student[]>(this.baseurl + f).pipe(
      map(list => list.map(student => new Student().deserialize(student)))
    );
  }


  /**
   * This will trigger the filters for our students
   *
   * @param filter crsid
   */
  getStudents(filter?: string) {
    this.studentFilterSubject$.next(filter);
  }

  /**
   * Set the course ids to retrieve our list of students for
   *
   * @param cooids - array of course ids
   */
  setCourses(cooids: number[]): void {
    this.coursesFilterSubject$.next(cooids);
  }

  // NOT used - hopefulyl, thank goodness!
  // /**
  //  * Removes entries from our observable
  //  */
  // cleanList() {
  //   this.list$.next([]);
  // }


}
