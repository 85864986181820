
<h3 mat-h3>Impersonate a user</h3>
<mat-tab-group  (selectedTabChange)="tabClick($event)">
    <mat-tab label="Staff">
        <button mat-raised-button *ngFor="let filter of filters" (click)="filterStaff(filter)" [color]=" (filter == selectedFilter) ? 'primary' : '' ">{{filter}}</button>
        <ul>
            <div *ngFor="let stf of (staff$ | async)">
                <a class="impersonateStaff" (click)="impersonateStaff(stf.id)"> {{stf.display_name()}} ({{stf.stfCrsid}}) </a>
            </div>
        </ul>
    </mat-tab>
    <mat-tab label="Student">
        <button mat-raised-button *ngFor="let filter of filters" (click)="filterStudents(filter)" [color]=" (filter == selectedFilter) ? 'primary' : '' ">{{filter}}</button>
        <ul>
            <div *ngFor="let stu of (students$ | async)">
                <a class="impersonateStudent" (click)="impersonateStudent(stu.id)">{{stu.stuCrsId}} - {{stu.stuCourseOffering.cooName}} ({{stu.stuCourseOffering.cooOfferingYear}})</a>
            </div>
        </ul>
    </mat-tab>
</mat-tab-group>
