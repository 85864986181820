import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { EngineeringArea } from '../../models/engineeringarea';
import { EngineeringareaService } from '../../services/engineeringarea.service';
import { Observable } from 'rxjs/Observable';
import { startWith, map, debounceTime, switchMap, tap, take, combineLatest } from 'rxjs/operators';

@Component({
  selector: 'app-ea-selections',
  templateUrl: './ea-selections.component.html',
  styleUrls: ['./ea-selections.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EaSelectionsComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EaSelectionsComponent),
      multi: true
    }]
})
export class EaSelectionsComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private _subscriptions = [];
  @Input() validator?: ValidatorFn; // send if required

  eaList: EngineeringArea[] = [];
  lastEaFilter = '';
  selectedEAs: EngineeringArea[] = new Array<EngineeringArea>();
  filteredEAs$: Observable<EngineeringArea[]>;

  eaCtrl = new FormControl();
  public eaForm: FormGroup;
  value;
  onChange;
  onTouched;

  constructor(
    private engineeringareas: EngineeringareaService) { }

  ngOnInit(): void {

    this.eaCtrl = this.validator?  new FormControl('', this.validator) :  new FormControl('')
    this.eaForm = new FormGroup({
      eas: this.eaCtrl
    });

    // configure our auto-complete lists
    this._subscriptions.push(this.engineeringareas.getList$()
      .subscribe(
        (eas) => {
          this.eaList = eas.sort((a, b) => (a.eaName > b.eaName) ? 1 : -1);
          this.configureAutoCompleteFilters();
        }
      ))
  }

  ngOnDestroy(): void {
    // (We could take(1) to avoid this)
    this._subscriptions.map( sub => sub.unsubscribe() )
  }

  /**
   * sets up the filters to be used by some of our components
   */
  configureAutoCompleteFilters() {
    this.filteredEAs$ = this.eaCtrl.valueChanges.pipe(
      startWith<string | EngineeringArea[]>(''),
      map(value => typeof value === 'string' ? value : this.lastEaFilter),
      map(filter => this.filterEA(filter))
    );
  }

  removeEA(id: number) {
    this.selectedEAs = this.selectedEAs.filter(ea => ea.id !== id);
    this.writeValue(this.selectedEAs)
    // this.eaForm.patchValue({ eas: this.selectedEAs });
  }

  filterEA(filter: string): EngineeringArea[] {
    this.lastEaFilter = filter;
    // let retarr = this.eaList.slice();
    let retarr = this.eaList.filter(option => (option.eaName.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
    return retarr;
  }

  optionEAClicked(event: Event, ea: EngineeringArea) {
    //;
    // event.stopPropagation();
    this.addEa(ea);
  }

  addEa(ea: EngineeringArea) {
    if ( this.selectedEAs.length > 0 ) {
      this.selectedEAs.push(ea)
    }else{
      this.selectedEAs = [ea]
    }
    //this.eaForm.patchValue({ eas: this.selectedEAs });
    this.writeValue(this.selectedEAs)
    this.eaCtrl.setValue('');
  }

  /**
   * Clears the text once a value has been selected
   * 
   */
   clearOnSelectFn() {
    return ''
  }

  // NG_VALIDATORS
  validate({value}: FormControl) {
    // returns errors or null if valid
    return this.eaForm.get('eas').valid ? null : {invalid: true}
   }
  // ControlValueAccessor implementation
  writeValue(val: any) {
    this.selectedEAs = val
    return val && this.eaForm.patchValue({ eas: val });
  }
  registerOnChange(fn: (val: any) => void) {
    this.eaForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  // ??
  setDisabledState(disabled: boolean) {
    disabled ? this.eaForm.disable()
      : this.eaForm.enable();
  }
}
