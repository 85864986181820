<ng-template #loading_message>
    Loading...
    <mat-progress-bar mode="buffer"></mat-progress-bar>
</ng-template>

<campl-ngx-page-nav *ngIf="initialIndex" [heading]="'Choices for '" [initialIndex]="initialIndex" [values]="years"
    (current)="setNavIndex($event)">
</campl-ngx-page-nav>
<div *appHasPerm="'allocationsAction'" style="float:right">
    <div *appAllocationActive="currentYear">
        <mat-slide-toggle (toggleChange)="isEditable = !isEditable"
            [matTooltip]="isEditable? 'Click to set read only'  : 'Click to edit choices'">
            <div *ngIf="isEditable">Editable</div>
            <div *ngIf="!isEditable">Read only</div>
        </mat-slide-toggle>
    </div>
</div>

<div *appHasPerm="'bulkAllocationsAction'">
    <ul *ngIf="isEditable">
        <li><b>Allocating a non-choice</b> to a student can be done via the <b>Upload allocations</b> tab.</li>
    </ul>
</div>

<button mat-raised-button *ngFor="let group of (groups$ | async)" value="{{group}}" (click)="toggleGroup(group)"
    [color]=" ((getGroupFilter$() | async)?.includes(group)) ? 'primary' : '' ">
    {{group}}
</button>

&nbsp;&nbsp;

<!--<button mat-raised-button (click)="toggleChoices()" [color]=" ((filterChoices$ | async)) ? 'primary' : '' ">
Choices
</button>-->

<button mat-raised-button (click)="toggleAllocated()" [color]=" ((getAllocatedFilter$() | async)) ? 'primary' : '' ">
    Allocated
</button>

<button mat-raised-button (click)="toggleUnallocated()"
    [color]=" ((getUnallocatedFilter$() | async)) ? 'primary' : '' ">
    Unallocated
</button>

-


<button mat-raised-button [matTooltip]="'Projects / students WITH choices'" (click)="toggleChoices()"
    [color]=" ((getChoicesFilter$() | async)) ? 'primary' : '' ">
    Choices
</button>

<button mat-raised-button [matTooltip]="'Projects / students WITHOUT choices'" (click)="toggleNochoices()"
    [color]=" ((getNoChoicesFilter$() | async)) ? 'primary' : '' ">
    No Choices
</button>


<button mat-raised-button [matTooltip]="'Waiting for project planning form to be returned'"
    (click)="toggleNoAgreement()" [color]=" (( getNoAgreementFilter$() | async)) ? 'primary' : '' ">
    * - No Planning Form
</button>


<span *ngIf="(selectedTab$ | async) === 'By project'">
    -
    <button mat-raised-button [matTooltip]="'Type (a) projects'" (click)="toggleTypeA()"
        [color]=" ((getTypeAFilter$() | async)) ? 'primary' : '' ">
        Type (a)
    </button>

    <button mat-raised-button [matTooltip]="'Type (b) projects'" (click)="toggleTypeB()"
        [color]=" ((getTypeBFilter$() | async)) ? 'primary' : '' ">
        Type (b)
    </button>
</span>

<mat-tab-group (selectedTabChange)="tabClick($event)">

    <mat-tab label="By Supervisor">
        <!--<div *ngIf="(supervisorSelections$ | async) as supervisorSelections">-->
        <div *ngIf="supervisorSelections && !loading; else loading_message">

            <div style="float:left">
                Tip: click on <strong>Supervisor</strong> and <strong>Number allocated</strong> to order this table.
            </div>
            <div style="float:right"> Export as:
                <button mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
                <button mat-raised-button (click)="exporter.exportTable('xlsx')">Excel</button>
            </div>
            <table mat-table matTableExporter #exporter="matTableExporter" matSort [dataSource]="supervisorSelections"
                class="full-width-table campl-table">
                <ng-container matColumnDef="supervisor">
                    <th mat-header-cell *matHeaderCellDef class="pointer" (click)="sortSupervisor()">Supervisor</th>
                    <td mat-cell *matCellDef="let row">{{row.owner.display_name()}}</td>
                </ng-container>
                <ng-container matColumnDef="numberAllocated">
                    <th mat-header-cell *matHeaderCellDef class="pointer" (click)="sortAllocated()">
                        Number<br />allocated</th>
                    <td mat-cell *matCellDef="let row">{{row.allocated.length}} <br /> {{ (row.allocated.length >=
                        maxProjects)? ' (MAX)' : '' }}</td>
                </ng-container>
                <ng-container matColumnDef="allocated">
                    <th mat-header-cell *matHeaderCellDef>Allocations</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['allocated'] | keyvalue" class="with-icon">
                            <span> {{sel.value.pslY4project.reference()}} - {{sel.value.pslStudent?.stuCrsId}} </span>
                            <span *ngIf="!sel.value.pslAgreementReturned" matTooltip="Agreement required">*</span>
                            <span *ngIf="isEditable" [ngClass]="'pointer'" [matTooltip]="'Remove allocated selection'"
                                (click)="unAllocateProject(sel.value)">
                                <mat-icon>delete</mat-icon>
                            </span>
                            <span *ngIf="isEditable" [ngClass]="'pointer'"
                                [matTooltip]="'Register return of planning form'"
                                (click)="registerPlanningForm(sel.value)">
                                <mat-icon>edit</mat-icon>
                            </span>

                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="choiceOverride">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][0] | keyvalue">.&nbsp;</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="choice1">
                    <th mat-header-cell *matHeaderCellDef>Choice 1</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][1] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            {{sel.value.pslY4project.reference()}} - {{sel.value.pslStudent?.stuCrsId}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="choice2">
                    <th mat-header-cell *matHeaderCellDef>Choice 2</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][2] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            {{sel.value.pslY4project.reference()}} - {{sel.value.pslStudent?.stuCrsId}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="choice3">
                    <th mat-header-cell *matHeaderCellDef>Choice 3</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][3] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            {{sel.value.pslY4project.reference()}} - {{sel.value.pslStudent?.stuCrsId}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="choice4">
                    <th mat-header-cell *matHeaderCellDef>Choice 4</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][4] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            {{sel.value.pslY4project.reference()}} - {{sel.value.pslStudent?.stuCrsId}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="choice5">
                    <th mat-header-cell *matHeaderCellDef>Choice 5</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][5] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            {{sel.value.pslY4project.reference()}} - {{sel.value.pslStudent?.stuCrsId}}</div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displaySupervisorCols"></tr>
                <tr mat-row *matRowDef="let row; columns: displaySupervisorCols;"></tr>
                <!--(click)="editY4ProjectDialog(row.id)"-->
            </table>

        </div>
    </mat-tab>
    <mat-tab>
        <ng-template matTabLabel>
            <span matBadge=" {{unallocatedStudentSelections$ | async}}" matBadgeOverlap="false">Students</span>
        </ng-template>
        <div *ngIf="studentSelections && !loading; else loading_message">

            <div style="float:left">
                Tip: click on <strong>Surname</strong> to order this table.
            </div><br />
            <div style="float:left"> Show columns:
                <button mat-raised-button (click)="toggleChoicesColumns()"
                    [color]=" (showChoices) ? 'primary' : '' ">Choices</button>
                <button mat-raised-button (click)="toggleOwnerColumn()"
                    [color]=" (showOwner) ? 'primary' : '' ">Owner</button>
                <button mat-raised-button (click)="toggleTitleColumn()"
                    [color]=" (showTitle) ? 'primary' : '' ">Title</button>
            </div>
            <div style="float:right"> Export as:
                <button mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
                <button mat-raised-button (click)="exporter.exportTable('xlsx')">Excel</button>
            </div>
            <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="studentSelections"
                class="full-width-table campl-table">
                <ng-container matColumnDef="studentCrsid">
                    <th mat-header-cell *matHeaderCellDef>CRS ID</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.student.stuCrsId}}</td>
                </ng-container>
                <ng-container matColumnDef="studentLastname">
                    <th mat-header-cell *matHeaderCellDef class="pointer" (click)="sortStudentSurname()">Surname</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.student.stuLastname}}</td>
                </ng-container>
                <ng-container matColumnDef="studentPreferredName">
                    <th mat-header-cell *matHeaderCellDef>Preferred name</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.student.stuPreferredName}}</td>
                </ng-container>
                <ng-container matColumnDef="studentCollegeCode">
                    <th mat-header-cell *matHeaderCellDef>College</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.student.stuCollegeCode}}</td>
                </ng-container>

                <ng-container matColumnDef="choiceOverride">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][0] | keyvalue">
                            .&nbsp;</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="choice1">
                    <th mat-header-cell *matHeaderCellDef>Choice 1</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][1] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            {{sel.value.pslY4project.reference()}}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="choice2">
                    <th mat-header-cell *matHeaderCellDef>Choice 2</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][2] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            {{sel.value.pslY4project.reference()}}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="choice3">
                    <th mat-header-cell *matHeaderCellDef>Choice 3</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][3] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            {{sel.value.pslY4project.reference()}}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="choice4">
                    <th mat-header-cell *matHeaderCellDef>Choice 4</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][4] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            {{sel.value.pslY4project.reference()}}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="choice5">
                    <th mat-header-cell *matHeaderCellDef>Choice 5</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][5] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            {{sel.value.pslY4project.reference()}}</div>
                    </td>
                </ng-container>


                <ng-container matColumnDef="allocatedGroup">
                    <th mat-header-cell *matHeaderCellDef>Group</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['allocated'] | keyvalue">
                            <span (click)="isEditable ? unAllocateStudent(sel.value) : null"
                                [matTooltip]="isEditable ? 'Remove allocated selection / register planning form' : null"
                                [ngClass]="{'pointer' : isEditable}">
                                {{sel.value.pslY4project.groupShort()}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="allocated">
                    <th mat-header-cell *matHeaderCellDef>Allocated to</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['allocated'] | keyvalue" class="with-icon">
                            <span> {{sel.value.pslY4project.reference()}}</span>
                            <span *ngIf="!sel.value.pslAgreementReturned" matTooltip="Agreement required">*</span>
                            <span *ngIf="isEditable" [ngClass]="'pointer'" [matTooltip]="'Remove allocated selection'"
                                (click)="unAllocateProject(sel.value)">
                                <mat-icon>delete</mat-icon>
                            </span>
                            <span *ngIf="isEditable" [ngClass]="'pointer'"
                                [matTooltip]="'Register return of planning form'"
                                (click)="registerPlanningForm(sel.value)">
                                <mat-icon>edit</mat-icon>
                            </span>
                        </div>
                    </td>
                </ng-container>


                <ng-container matColumnDef="allocatedOwner">
                    <th mat-header-cell *matHeaderCellDef>Owner</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['allocated'] | keyvalue">
                            <span (click)="isEditable ? unAllocateStudent(sel.value) : null"
                                [matTooltip]="isEditable ? 'Remove allocated selection / register planning form' : null"
                                [ngClass]="{'pointer' : isEditable}">
                                {{sel.value.pslY4project.projOwner.display_name()}}
                            </span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="allocatedTitle">
                    <th mat-header-cell *matHeaderCellDef>Title</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['allocated'] | keyvalue">
                            <span (click)="isEditable ? unAllocateStudent(sel.value) : null"
                                [matTooltip]="isEditable ? 'Remove allocated selection / register planning form' : null"
                                [ngClass]="{'pointer' : isEditable}">
                                {{sel.value.pslY4project.projTitle}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayStudentCols"></tr>
                <tr mat-row *matRowDef="let row; columns: displayStudentCols;"></tr>
                <!--(click)="editY4ProjectDialog(row.id)"-->
            </table>
        </div>
    </mat-tab>
    <mat-tab label="By Project">
        <div *ngIf="projectSelections && !loading; else loading_message">
            <div style="float:left"> Show:
                <button mat-raised-button (click)="showProjectStudentName =! showProjectStudentName"
                    [color]=" (showProjectStudentName) ? 'primary' : '' ">Names</button>
            </div>
            <!--<div *ngIf="(projectSelections$ | async) as y4projectchoices">-->
            <div style="float:right"> Export as:
                <button mat-raised-button (click)="exporter.exportTable('csv')">CSV</button>
                <button mat-raised-button (click)="exporter.exportTable('xlsx')">Excel</button>
            </div>
            <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="projectSelections"
                class="full-width-table campl-table">
                <ng-container matColumnDef="project">
                    <th mat-header-cell *matHeaderCellDef>Reference</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.project.reference()}}: {{row.project.projOwner.display_name()}}<br>
                        <span (click)="viewProjectSummaryDialog(row.project)" matTooltip="{{row.project.projTitle}}"
                            class="with-icon pointer">
                            {{row.project.titleTeaser()}} &nbsp; <mat-icon>visibility</mat-icon>
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.project.projType}}</td>
                </ng-container>

                <ng-container matColumnDef="allocated">
                    <th mat-header-cell *matHeaderCellDef>Allocated to</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['allocated']" class="with-icon">
                            <span>{{sel.pslStudent?.stuCrsId}} <span
                                    *ngIf="showProjectStudentName">({{sel.pslStudent?.stuLastname}}) </span></span>
                            <span *ngIf="!sel.pslAgreementReturned" matTooltip="Agreement required">*</span>
                            <span *ngIf="isEditable" [ngClass]="'pointer'" [matTooltip]="'Remove allocated selection'"
                                (click)="unAllocateProject(sel)">
                                <mat-icon>delete</mat-icon>
                            </span>
                            <span *ngIf="isEditable" [ngClass]="'pointer'"
                                [matTooltip]="'Register return of planning form'" (click)="registerPlanningForm(sel)">
                                <mat-icon>edit</mat-icon>
                            </span>
                            
                        </div>
                        <span *ngIf="isEditable" [ngClass]="'with-icon pointer'"
                        [matTooltip]="'Allocate project to student'"
                        (click)="allocateProjectSelectStudent(row.project)">
                        <mat-icon>person_add</mat-icon>
                    </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="choiceOverride">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][0] | keyvalue">.&nbsp;
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="choice1">
                    <th mat-header-cell *matHeaderCellDef>Choice 1</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][1] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            <span
                                [ngClass]="{'strikethrough' : allocatedCrsids && allocatedCrsids.includes(sel.value.pslStudent?.stuCrsId)}">{{sel.value.pslStudent?.stuCrsId}}</span>
                            <span *ngIf="showProjectStudentName"> ({{sel.value.pslStudent?.stuLastname}})</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="choice2">
                    <th mat-header-cell *matHeaderCellDef>Choice 2</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][2] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            <span
                                [ngClass]="{'strikethrough' : allocatedCrsids && allocatedCrsids.includes(sel.value.pslStudent?.stuCrsId)}">{{sel.value.pslStudent?.stuCrsId}}</span>
                            <span *ngIf="showProjectStudentName"> ({{sel.value.pslStudent?.stuLastname}})</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="choice3">
                    <th mat-header-cell *matHeaderCellDef>Choice 3</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][3] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            <span
                                [ngClass]="{'strikethrough' : allocatedCrsids && allocatedCrsids.includes(sel.value.pslStudent?.stuCrsId)}">{{sel.value.pslStudent?.stuCrsId}}</span>
                            <span *ngIf="showProjectStudentName"> ({{sel.value.pslStudent?.stuLastname}})</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="choice4">
                    <th mat-header-cell *matHeaderCellDef>Choice 4</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][4] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            <span
                                [ngClass]="{'strikethrough' : allocatedCrsids && allocatedCrsids.includes(sel.value.pslStudent?.stuCrsId)}">{{sel.value.pslStudent?.stuCrsId}}</span>
                            <span *ngIf="showProjectStudentName"> ({{sel.value.pslStudent?.stuLastname}})</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="choice5">
                    <th mat-header-cell *matHeaderCellDef>Choice 5</th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let sel of row['choices'][5] | keyvalue"
                            (click)="isEditable? allocateProject(sel.value) : null"
                            [matTooltip]="isEditable ? 'Allocate this selection' : null"
                            [ngClass]="{'pointer' : isEditable}">
                            <span
                                [ngClass]="{'strikethrough' : allocatedCrsids && allocatedCrsids.includes(sel.value.pslStudent?.stuCrsId)}">{{sel.value.pslStudent?.stuCrsId}}</span>
                            <span *ngIf="showProjectStudentName"> ({{sel.value.pslStudent?.stuLastname}})</span>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <!--(click)="editY4ProjectDialog(row.id)"-->
            </table>
        </div>

    </mat-tab>

    <!-- A work around for not beign abl eto have ngIf and *appHasPerm; we end up with a blank tab for allocaters who can not bulk allocate-->
    <mat-tab *ngIf="isEditable">

        <ng-template matTabLabel>
            <span *appHasPerm="'bulkAllocationsAction'">Upload allocations</span>
        </ng-template>
        <div *appHasPerm="'bulkAllocationsAction'">
            <br />
            <p>This box will allow you to allocate projects to students. The student must NOT already be allocated to a
                project if so you must remove that allocation before proceeding.</p>
            <p>Cut and paste a list of [project reference], [crsid] combinations into the box below eg:<br />

                {{exampleRef$|async}}, {{exampleCrsid$|async}} </p>
            <form [formGroup]="uploadForm">

                <!-- TODO provide asyncValidators into campl-ngx-input-textarea and replace-->
                <mat-form-field appearance="fill" [formGroup]="uploadForm">
                    <mat-label>Paste your rows of allocations here</mat-label>
                    <textarea (click)="disableSubmit()" matInput placeholder="reference, crsid"
                        formControlName="bulkChoices" cdkTextareaAutosize cdkAutosizeMinRows="3"></textarea>
                </mat-form-field>
                <span *ngIf="uploadForm.get('bulkChoices').errors">
                    Unable to import:
                    <div *ngIf="uploadForm.get('bulkChoices').errors?.invalidReferences">
                        The following references are <strong>not</strong> valid:
                        <span *ngFor="let ref of uploadForm.get('bulkChoices').errors.invalidReferences">
                            <strong> {{ref}}</strong>,
                        </span>
                    </div>
                    <div *ngIf="uploadForm.get('bulkChoices').errors?.invalidCrsids">
                        The following CRS IDS are <strong>not</strong> valid:
                        <span *ngFor="let crsid of uploadForm.get('bulkChoices').errors.invalidCrsids">
                            <strong> {{crsid}}</strong>,
                        </span>
                    </div>
                    <div *ngIf="uploadForm.get('bulkChoices').errors?.allocatedCrsids">
                        The following CRS IDS have already got allocation please un-allocate before loading
                        <strong>not</strong> valid:
                        <span *ngFor="let crsid of uploadForm.get('bulkChoices').errors.allocatedCrsids">
                            <strong> {{crsid}}</strong>,
                        </span>
                    </div>
                    <div *ngIf="uploadForm.get('bulkChoices').errors?.invalidLines">
                        The following lines are formatted incorrectly:
                        <span *ngFor="let bline of uploadForm.get('bulkChoices').errors.invalidLines.value">
                            <strong> {{bline}}</strong>,
                        </span>
                    </div>
                </span>
                <br />
                <button mat-raised-button color="primary" [disabled]="(canBulkUpload$ | async)">Validate</button>
                <button mat-raised-button color="primary" [disabled]="(canBulkUpload$ | async) === false"
                    (click)="uploadAllocations()">Upload</button>
            </form>
        </div>
    </mat-tab>
</mat-tab-group>