import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Y4ProjectselectionService } from '../lib/services/y4-projectselection.service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Y4projectselection } from '../lib/models/y4projectselection';

/**
 * Display a dialog used to update the selections eg
 * whether or not the project planning form has been returned
 */

@Component({
  selector: 'app-y4-project-selection-form-update-dialog',
  templateUrl: './y4-project-selection-form-update-dialog.component.html',
  styleUrls: ['./y4-project-selection-form-update-dialog.component.scss']
})
export class Y4ProjectSelectionFormUpdateDialogComponent implements OnInit {

  public submitted = false; // used to disable submit button

  private choice: Y4projectselection;
  public planReturned: boolean;

  public choice$ = this.choicesService.currentlyBrowsingChoice$;

  private updateChoiceSubject$ = new Subject(); // emit next to allocate a choice
  public updateChoice$ = this.updateChoiceSubject$.asObservable();

  // Move to calling component || facade
  public updateChoiceAction$ = combineLatest([
    this.choice$,
    this.updateChoice$])
    .pipe(
      map(([choice]) => choice)
    );
  constructor(
    private choicesService: Y4ProjectselectionService,
    private _dialogRef: MatDialogRef<Y4ProjectSelectionFormUpdateDialogComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any) {

  }

  ngOnInit(): void {

    // Move to calling component || facade?
    const updateSelection$ = this.updateChoice$
      .pipe(
        map((choice) => {
          this.choice.pslAgreementReturned = (this.planReturned) ? (new Date()).toISOString().replace('Z','') : null;
          return choice;
        }),
        switchMap(
          // TODO replace with updateSelectionObs - that will update PlanningForm status
          (choice) => this.choicesService.updateSelectionObs(this.choice)
        )
      ).subscribe(res => {
        this.openSnackBar('Choice updated ', 'close');
        this._dialogRef.close(null);
        this.choicesService.touchSelections();
      });;

    // this._projectService.browseProject(this.data.project);
    this.choice = new Y4projectselection().deserialize(this.data.sel);
    this.planReturned = (this.choice.pslAgreementReturned)? true : false;
  }

  public togglePlanReturn() {
    this.planReturned = !this.planReturned;
  }

  public update() {
    // first disable button
    this.submitted = true;
    this.updateChoiceSubject$.next(new Date());
  }

  public cancel() {
    this.openSnackBar('Update cancelled', 'close');
    this._dialogRef.close(null);
  }

  // currently using the standard material snackbar service
  // campl-ngx-snackbar could be created to display different types of message etc?
  // We could modify sendMessage to send to a snack bar or messageare
  // See elsewhere (y4projects)
  public openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
