import { Component, OnDestroy } from '@angular/core';

import { AuthService } from './lib/services/auth.service';
import { SgtopicService } from './lib/services/sgtopic.service';
import { Auth } from './lib/models/auth';

import { Observable } from 'rxjs/Observable';
import { combineLatest, of, Subscription } from 'rxjs';
import { NavMenu, NavMenuItem } from 'cued-lib/src/lib/campl-ngx';
import { StatusesService } from './lib/services/statuses.service';
import { EngineeringareaService } from './lib/services/engineeringarea.service';
import { CourseofferingService } from './lib/services/courseoffering.service';

import { HasSelectionActiveGuard } from './lib/guards/has-selection-active.guard';

import { environment } from '../environments/environment';

/**
 * The application root component
 *
 * Here we setup the navigation menu as an observable to a campl-ngx-app template
 *
 */
@Component({
  selector: 'app-root',
  template: `
  <span style="display:none">
  version: {{environment['version']}},
  production: {{environment['production']}},
  maxProjects: {{environment['maxProjects']}}
  </span>
  <campl-ngx-app [nav_menu$]="myNav$"></campl-ngx-app>
    `,
  styleUrls: ['./iibp-app.component.css']
})
export class IibpAppComponent implements OnDestroy {

  constructor(
    private auth: AuthService,
    private sgtopic: SgtopicService,
    private statuses: StatusesService,
    private engineeringarea: EngineeringareaService,
    private cooservice: CourseofferingService //,
    //private hasSelectionActiveGuard: HasSelectionActiveGuard
  ) {

    // Set our course code
    this.cooservice.init('EGT3');

    this.configureMenus(null, null); // first call sets the default menus

    // TODO replace these with .take(1) on the observable
    // TO toggle the retrieval for others to subscribe to
    this.subscriptions.push(this.auth.init().subscribe());
    this.subscriptions.push(this.sgtopic.init().subscribe());
    this.subscriptions.push(this.statuses.init().subscribe()); // Could be throwing our error? why?

    this.subscriptions.push(this.engineeringarea.init().subscribe());
    // y4projectService.getProjects();

    // Here we are setting up the User menu
    // If the user is not logged in we need to display the login form!
    // FIXME: auth.init() will redirect to the login if the user is not logged in!!
    this.subscriptions.push(
      combineLatest([this.auth.getMe$(), this.statuses.latestYear()]). // , this.cooservice.list$]).
        subscribe(
          ([me, lyear]: any) => {
            // const courses = coos.filter(coo => coo.cooOfferingYear === lyear)
            this.configureMenus(me, lyear);
          })
    );
    // configure the Navigation Menu dependent on the roles of the user
    // this.myNav$ = of(this.nm);



  }

  subscriptions: Subscription[] = [];

  permsLookup = environment.permsLookup;
  environment = environment;

  // TODO removeme - fixes a test
  title = 'angularapps';

  mY4ExampleProjects: NavMenuItem; // for the examples list
  mY4Projects: NavMenuItem;

  mHome: NavMenuItem;


  mManageProjects: NavMenuItem;
  mAssistantsManage: NavMenuItem;
  mApproveProjects: NavMenuItem;
  mStaffProjects: NavMenuItem;
  mStudentAllocations: NavMenuItem;
  mAdmin: NavMenuItem;


  // Our user menu
  mUser: NavMenuItem;

  mLogin: NavMenuItem;
  nm: NavMenu;

  myNav$: Observable<NavMenu>;

  ngOnDestroy() {
    this.subscriptions.map(sub => sub.unsubscribe());
  }

  // TODO place the menu structure in a config file?
  _resetMenus() {

    this.mY4ExampleProjects = new NavMenuItem().deserialize({
      label: 'Example Projects menu',
      link: '/y4project/',
      subItems: []
    });

    this.mY4Projects = new NavMenuItem().deserialize({
      label: 'IIB Projects menu',
      link: '/y4project/',
      subItems: []
    });

    this.mHome = new NavMenuItem().deserialize({
      label: 'Home',
      link: '/home/',
      subItems: []
    });


    this.mManageProjects = new NavMenuItem().deserialize({
      label: 'List/add/edit project(s)', link: '/fe/manage', subItems: []
    });


    this.mApproveProjects = new NavMenuItem().deserialize({
      label: 'Approve project(s)', link: '/fe/approval', subItems: []
    });

    this.mStaffProjects = new NavMenuItem().deserialize({
      label: 'Your project(s)', link: '/index', subItems: []
    });

    this.mAssistantsManage = new NavMenuItem().deserialize({
      label: 'Manage assistants', link: '/fe/manage/assistants', subItems: []
    });

    this.mStudentAllocations = new NavMenuItem().deserialize({
      label: 'Student selections', link: 'fe/admin/choices', subItems: []
    });

    // TODO: fix me - Observable issue? Mark if the page is NOT active
    // ??https://stackoverflow.com/questions/40507064/how-to-hide-link-by-guard-angular2
    let remindersPostfix = ' (During SELECTION)' // (this.hasSelectionActiveGuard.canActivate())? ' (During SELECTION)' : ' (inactive)';

    this.mAdmin = new NavMenuItem().deserialize({
      label: 'Administration',
      link: '',
      subItems: [
        new NavMenuItem().deserialize({ label: 'Key dates', link: '/fe/keydates', subItems: [] }),
        new NavMenuItem().deserialize({ label: 'Project group topics', link: 'fe/subjectgrouptopic', subItems: [] }),
        new NavMenuItem().deserialize({ label: 'Roles', link: 'fe/admin/roles', subItems: [] }),
        new NavMenuItem().deserialize({ label: 'Reminders' + remindersPostfix, link: 'fe/admin/preallocated', subItems: [] }),
        new NavMenuItem().deserialize({ label: 'Account sync settings', link: 'fe/admin/syncsettings', subItems: [] }),
        this.mAssistantsManage
      ]
    });

    // Our user menu
    this.mUser = new NavMenuItem().deserialize({
      label: 'Not logged in',
      link: '',
      subItems: [{ label: 'Login', link: '/login', subItems: [] }]
    });

    this.mLogin = new NavMenuItem().deserialize({
      label: 'Log in ',
      link: (environment.hasOwnProperty('loginLink')) ? environment['loginLink'] : '/public/login',
      // link: '/auth/google',
      subItems: []
    });

    this.nm = new NavMenu().deserialize({
      title: 'Navigation Menu',
      subMenus: [this.mHome]
    });

  }

  /**
   * dependant on me setup the available menus
   *
   * Requires:
   * this.me
   * this.lyear - latest course year
   *
   * TODO: make sure that the student is on the current course
   */
  configureMenus(me: Auth, lyear: number) {

    // const me = this.me // TODO: Fail if undefined

    this._resetMenus();

    if (!me || !me.id) {
      // reset menus to not logged in:
      this.nm = new NavMenu().deserialize({
        title: 'Navigation Menu',
        subMenus: [this.mHome, this.mLogin]
      });
    } else {

      // //
      // //
      this.mUser.label = me.display_name;

      // if we are impersonating:
      if (me.impersonating) {
        this.mUser.subItems = [new NavMenuItem().deserialize({ label: 'Stop impersonating', link: '/fe/impersonate/stop', subItems: [] })];
      } else {
        this.mUser.subItems = [new NavMenuItem().deserialize({ label: 'Log out', link: '/public/logout', subItems: [] })];
      }

      // else regular logout
      if (me.isStaff()) {

        this.mUser.label = me.staff.display_name();

        // This is our view authorization (it should be very similar if not the same to the BE)
        // TODO store this authorization array in config || DB?
        const allocationAccess = ['to_admin', 'tc_committee', 'tc_member', 'iib_p_coordinator', 'sg_coordinator', 'sg_admin'];
        const adminAccess = ['to_admin'];
        const manageProjectAccess = ['to_admin', 'sg_admin'];

        const approveY4projectsAccess = this.permsLookup.approveY4projectsAccess;

        // TODO can we use environment.permsLookup for this? cf guards?
        // if (this.testAccess(me, allocationAccess)) {

        // All Staff should be provided the ability to view allcoations
        // This will be read only unless a privilege is met in the component
        this.mStudentAllocations.pushItem(
          new NavMenuItem().deserialize({ label: `${lyear - 1} - ${lyear}`, link: `fe/admin/choices/${lyear - 1}`, subItems: [] }));
        this.mStudentAllocations.pushItem(
          new NavMenuItem().deserialize({ label: `${lyear} - ${lyear + 1}`, link: `fe/admin/choices/${lyear}`, subItems: [] }));
        this.mStudentAllocations.pushItem(
          new NavMenuItem().deserialize({ label: `${lyear + 1} - ${lyear + 2}`, link: `fe/admin/choices/${lyear + 1}`, subItems: [] }));

        // also add a link to this years selections - but this does not work on desktop!:
        this.mStudentAllocations.link = `fe/admin/choices/${lyear}`;

        this.mY4Projects.unshiftItem(this.mStudentAllocations);
        // }
        this.mY4Projects.pushItem(this.mStaffProjects);

        if (this.testAccess(me, manageProjectAccess)) {
          this.mY4Projects.pushItem(this.mManageProjects);
        }

        if (this.testAccess(me, approveY4projectsAccess)) {
          this.mY4Projects.pushItem(this.mApproveProjects);
        }

        if (this.testAccess(me, adminAccess)) { // todo place in a config file?

          this.mUser.subItems.unshift(new NavMenuItem().deserialize({ label: 'Impersonate', link: '/fe/impersonate', subItems: [] }));
          this.nm.subMenus = [this.mY4Projects, this.mAdmin, this.mUser];

        } else {
          this.nm.subMenus = [this.mY4Projects, this.mUser];
        }


        // then the menus should include all the staff options
      } else if (me.students.length > 0) {
        // However (FIXME) we do have a staff display name:
        this.mUser.label = me.display_name + ' (' + me.students[0].stuCrsId + ')';
        const stu = me.egt3Student();
        // if we gather the Course offerings open to selection that this user has we can provide them in here
        if (stu && stu.egt3Selection()) {
          const coo = stu.egt3Selection();
          this.mY4Projects.subItems = [
            new NavMenuItem().deserialize(
              {
                label: 'Y4 Projects (' + coo.cooOfferingYear + ' - ' + (coo.cooOfferingYear + 1) + ')',
                link: '/fe/listprojects/' + coo.cooOfferingYear,
                subItems: []
              }),
            new NavMenuItem().deserialize(
              { label: 'Your choices', link: '/fe/choices', subItems: [] })
          ];
          this.nm.subMenus = [this.mY4Projects, this.mUser];
        } else if (stu && stu.egt3Y4PBrowse()) {
          const coo = stu.egt3Y4PBrowse();
          this.mY4Projects.subItems = [
            new NavMenuItem().deserialize(
              {
                label: 'Y4 Projects (' + coo.cooOfferingYear + ' - ' + (coo.cooOfferingYear + 1) + ')',
                link: '/fe/listprojects/' + coo.cooOfferingYear,
                subItems: []
              })
          ];
          this.nm.subMenus = [this.mY4Projects, this.mUser];
        } else {
          // Neither browse or select is open provide a linkto a list of last years projects as examples
          this.mY4ExampleProjects.subItems = [
            new NavMenuItem().deserialize(
              {
                label: 'Project Examples',
                // TODO implement an end point that from a user retrieves last years projects and lists them
                // note we do not provide a year as a parameter as students may decide to retrieve the details 
                // of previous years also..
                link: '/fe/exampleprojects',
                subItems: []
              })
          ];
          this.nm.subMenus = [this.mY4ExampleProjects,this.mUser];
        }
      } else {
        // we are not logged in (at least as a valid user; staff member or student on course)
        this.mUser.label = me.username;
        this.mUser.subItems = [new NavMenuItem().deserialize({ label: 'Logout', link: '/public/logout', subItems: [] })];
        this.nm.subMenus = [this.mHome, this.mUser];
      }
    }
    this.myNav$ = of(this.nm);
  }

  testAccess(user: Auth, accessroles: string[]): boolean {
    return (user.roles.filter(value => accessroles.includes(value.roleType)).length > 0);
  }

  stopImpersonating() {
    this.auth.stopImpersonating();
  }
}
