import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Student } from '../../lib/models/student';
import { StudentService } from '../../lib/services/student.service';
import { Y4ProjectService } from '../../lib/services/y4-project.service';
import { Y4ProjectselectionService } from '../../lib/services/y4-projectselection.service';

import { environment } from '../../../environments/environment';
import { StatusesService } from '../../lib/services/statuses.service';
import { ChoicesManagementService } from '../../lib/services/choices-management.service';
import { Y4projectselection } from '../../lib/models/y4projectselection';
import { Y4project } from '../../lib/models/y4project';
import { Y4ProjectChoicesAdminService } from '../../lib/services/y4-project-choices-admin.service';

import { DropdownItem } from 'cued-lib/src/lib/campl-ngx';
import { AbstractControl, FormBuilder, FormGroup, RequiredValidator, ValidatorFn, Validators } from '@angular/forms';

import { Allocation } from '../../lib/models/allocation';

/**
 * Used to search the autocomplete and allocated to 
 * a student who has not yet been allocated
 */
export class StudentSelect implements DropdownItem {
  student: Student;

  constructor(student) {
    this.student = student
  }

  // returns whether the searchValue is found 
  filter(searchValue: string): boolean {
    return this.student.stuLastname.includes(searchValue) ||
      this.student.stuCrsId.includes(searchValue)
  }

  // displayed in the element
  display(): string {
    return this.student.stuLastname + " (" + this.student.stuCrsId + ")"
  }

}

@Component({
  selector: 'app-y4-project-choice-allocation-dialog',
  templateUrl: './y4-project-choice-allocation-dialog.component.html',
  styleUrls: ['./y4-project-choice-allocation-dialog.component.css']
})
export class Y4ProjectChoiceAllocationDialogComponent implements OnInit, OnDestroy {

  // track whether a student has been selected
  _subscriptions = [];

  // our list autocomplete list
  unallocatedStudentSelectList = []
  // should we pass these by the DATA

  // These may cause issue when we have not set the choice this time but did earlier on!
  // TODO: pass the choice as an optional input (we may be allocated to a non choice)
  public choice$ = this.choicesService.currentlyBrowsingChoice$; // setBrowseChoice has been called
  public studentChoices$ = this.choicesService.currentAdjacentChoices$; // setBrowseChoice has been called
  public student$ = this.choice$.pipe(
    map(cho => new Student().deserialize(cho.pslStudent)))
  //public student$ = this.studentService.currentlyBrowsingStudent$;

  // TODO pass as a required/optional param 
  public project$ = this.projectService.currentlyBrowsingProject$;

  // List of choices, the course should have been initialised via projectService 
  // in the choices-admin component
  public courseSelections$ = this.choicesService.courseChoices$;

  // The number of projects that the owner is currently allocated to!
  // We may only pass the project - when allocating to an unallocated student
  public numProjects$ = combineLatest([this.courseSelections$, this.project$]).pipe(
    map(([s, proj]) => s.filter(isel => isel.pslAllocated === true && isel.pslY4project.projOwner.id === proj.projOwner.id)),
    map(s => s.length)
  );

  // we use observable rather than form - only one button
  private agreementReturnedSubject$ = new BehaviorSubject<boolean>(false);
  public agreementReturned$ = this.agreementReturnedSubject$.asObservable();

  // we use observable rather than form - only one button
  private sendMessagesSubject$ = new BehaviorSubject<boolean>(true);
  public sendMessages$ = this.sendMessagesSubject$.asObservable();

  private allocateChoiceSubject$ = new Subject(); // emit next to allocate a choice
  public allocateChoice$ = this.allocateChoiceSubject$.asObservable();

  // Move to calling component || facade
  public allocateChoiceAction$ = combineLatest([
    this.choice$,
    this.allocateChoice$])
    .pipe(
      map(([choice]) => choice)
    );

  private sub; // used to store our subscription to selection change

  // retrieves the choices for to the project
  public projectChoices$ = this.projectService.currentlyBrowsingProject$
    .pipe(
      switchMap(proj => this.choicesService.projectChoices$(proj.id))
    );

  public allocatedToProject$ = this.projectChoices$
    .pipe(
      map(choices => choices.filter(cho => cho.pslAllocated)),
      map(choices => choices.map(cho => new Student().deserialize(cho.pslStudent)))
    );

  // The sibling choices not allocated
  public choicesNotAllocated$ = combineLatest([this.projectChoices$, this.choice$]).pipe(
    map(([choices, choice]) => choices.filter(cho => (
      !cho.pslAllocated &&
      !(cho.pslStudent?.id === choice.pslStudent?.id))))
  );

  // Students who have also chosen this project 
  // Some of these may have already been allocated a project - but we use this in pre-allocation
  public studentsNotAllocated$ = this.choicesNotAllocated$.pipe(
    map(choices => choices.map(cho => new Student().deserialize(cho.pslStudent)))
  );

  public maxProjects = 3;
  public submitted = false;

  // Is the selection open?
  public selectionOpen$ = combineLatest([
    this.projectService.currentlyBrowsingProject$, this.statusesService.activeStatuses$()])
    .pipe(
      map(([proj, statuses]) =>
        statuses
          .filter(s => (s.staCode === 'SELECTION' && s.staCourseOffering.id === proj.projCourseOffering.id))
          .length)
    );

  constructor(
    private choicesService: Y4ProjectselectionService,
    private projectService: Y4ProjectService,
    private statusesService: StatusesService,
    private _choicesAdmin: Y4ProjectChoicesAdminService,
    private _snackBar: MatSnackBar,
    private _choicesManagementService: ChoicesManagementService,
    private _dialogRef: MatDialogRef<Y4ProjectChoiceAllocationDialogComponent>,
    private fb: FormBuilder) {
    // perhaps switch map then subscribe


    // This updates out choice to allocated
    // to be chained with sending the message
    const updateSelection$ = combineLatest([this.allocateChoiceAction$, this.agreementReturned$])
      .pipe(
        map(([choice, agreement]) => {
          if (agreement) {
            //;
            choice.pslAgreementReturned = (new Date()).toISOString();
          }
          return choice;
        }),
        switchMap(
          (choice) => this.choicesService.allocateSelectionObs(choice)
        )
      );

    // Subscribe to the update of the choice (allocateChoiceAction)
    // Following an update send messages (if Selection is open and send messages toggled)
    this.sub = combineLatest([updateSelection$, this.choicesNotAllocated$, this.selectionOpen$, this.sendMessages$])
      .pipe(
        tap(([sel, choices, isOpen, sendMsg]) => console.log('is open: ' + isOpen + ' sendMsg ' + sendMsg)),
        map(([sel, choices, isOpen, sendMsg]) => (isOpen && sendMsg) ? choices.map(c => c.id) : []),
        tap(choices => console.log(choices)),
        mergeMap((choices) => (choices.length > 0) ? this._choicesManagementService.genAndSendPrealloc$(choices) : of(false))
      )
      .subscribe(res => {
        const message = (res) ? ' & messages sent' : ' - No mesasges sent';
        this.openSnackBar('Allocation made' + message, 'close');
        this._dialogRef.close(null);
      });
    this.maxProjects = environment['maxProjects'];

  }

  ngOnInit() { }

  ngOnDestroy() {
    this._subscriptions.map(sub => sub.unsubscribe())
    this.sub.unsubscribe();
  }

  public toggleAgreement() {
    this.agreementReturnedSubject$.next(!this.agreementReturnedSubject$.getValue());
  }

  public toggleMessages() {
    this.sendMessagesSubject$.next(!this.sendMessagesSubject$.getValue());
  }

  /**
   * This should only be available if a choice$ has been provided
   */
  public allocate() {
    // first disable button
    this.submitted = true;
    this.allocateChoiceSubject$.next(new Date());
  }

  public cancel() {
    this.openSnackBar('Allocation cancelled', 'close');
    this._dialogRef.close(null);
  }

  /**
   * List of students who have not yet been allocated a project
   * 
   * We build a select list with these so that we can create a choice and allocate
   */
  public studentUnallocated$() {
    return this._choicesAdmin.studentsUnallocated$()
  }

  // currently using the standard material snackbar service
  // campl-ngx-snackbar could be created to display different types of message etc?
  // We could modify sendMessage to send to a snack bar or messageare
  // See elsewhere (y4projects)
  public openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
