import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Y4ProjectService } from '../lib/services/y4-project.service';
import { Y4project } from '../lib/models/y4project';
import { Y4ProjectSelectDialogComponent } from '../y4-project-select-dialog/y4-project-select-dialog.component';
import { Y4ProjectSummaryComponent } from '../y4-project-summary/y4-project-summary.component';
import { MatDialog } from '@angular/material/dialog';
import { Student } from '../lib/models/student';
import { AuthService } from '../lib/services/auth.service';
import { combineLatest, Observable, Subject } from 'rxjs';

/**
 * Provides a listing that when selected / clicked
 * opens a modal with selection actions
 *
 * Used when browsing available projects (via accordion)
 */

@Component({
  selector: 'app-y4-project-select-listing',
  templateUrl: './y4-project-select-listing.component.html',
  styleUrls: ['./y4-project-select-listing.component.css']
})
export class Y4ProjectSelectListingComponent implements OnInit, OnDestroy {

  /**
   * Stores a list of subscriptions
   */
  subscriptions = [];

  /**
   * The project we display and provide the selection modal for
   */
  @Input() project: Y4project; // the project to display

  /**
   * Current student who is accessing the selection
   */
  stu: Student;
  detailsClick = new Subject();
  constructor(
    // private projectsService: Y4ProjectService,
    // TODO place in here a service to populate the current user / student - an observable of another service
    private auth: AuthService,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    // TODO: This is inefficient it will occur for all our display listings!
    this.subscriptions.push(this.auth.getMe$().subscribe(
      (me) => this.stu = me.egt3Student()
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  /**
   * Actions the opening of a dialog the users uses when selecting a project
   *
   */
  displaySelectDialog() {
    // this.projectsService.browseProject(this.project); // sets the project so we don't need to pass to our Dialog?
    this.dialog.open(Y4ProjectSelectDialogComponent, { height: '90%', width: '90%', data: { project: this.project, student: this.stu } });
  }
  listenDialogAction() {
    // test edit here
    //;

/*
    //this.listenDialogAction();
    combineLatest([this.auth.me$, this.detailsClick]).
      subscribe(
        ([me, click]) => {
          this.stu = me.egt3Student(); // because of this do we need latest year? TODO provide year as an argument
          // TODO what if student in two egt3 course offerings
          //;
          //;
          // we need to makesure this.stu is configured!
          // this.projectsService.browseProject(this.project)

          this.projectsService.browseProject(this.project);
          this.dialog.open(Y4ProjectSelectDialogComponent, { height: '90%', data: { project: this.project, student: this.stu } });
        }
      ); */
  }
}
